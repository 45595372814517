import React from "react";
import { useGetStudentInfoQuery } from "../../../../../store/analytics/analytics.api";
import { te } from "../../../../../utils/errorsTranslation";

const useFetch = ({
  lessonUUID,
  limit,
  offset,
  order,
  orderBy,
  searchQuery,
}) => {
  const {
    data: studentsData,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useGetStudentInfoQuery({
    lessonUUID,
    limit,
    offset,
    order,
    orderBy,
    searchQuery,
  });

  const [itemsCount, setItemsCount] = React.useState(0);
  const [studentList, setStudentList] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState(null);

  React.useEffect(() => {
    if (isError) {
      if (!error.data?.code && !error.code)
        setErrorMessage(te("general_error"));
      else setErrorMessage(te(error.data?.code ?? error.code));
    } else if (isSuccess) {
      setErrorMessage(null);
      setStudentList(studentsData.students ?? []);
      setItemsCount(studentsData.count ?? 0);
    }
  }, [error, isError, studentsData, isSuccess]);

  return {
    data: {
      studentList,
      itemsCount,
    },
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  };
};

export default useFetch;
