import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../../../styles/ThemeContext";
import Styles from "./styles";

const Settings = () => {
  const { t } = useTranslation("", { keyPrefix: "components.settings" });

  const { setTheme, themeList } = useContext(ThemeContext);

  /*   const languages = {
    it: "Italiano",
    en: "English",
  };

  const changeLanguageHandler = (id) => {
    i18next.changeLanguage(id);
    //Update localStorage
    localStorage.setItem("language", id);
  };
 */
  return (
    <Styles.Settings>
      {/* <div className="d-flex flex-row justify-content-between w-100">
        <span>{t("change_language")}</span>
        <Dropdown className="lang-dropdown">
          <Dropdown.Toggle variant="secondary">{t("language")}</Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.entries(languages).map(([id, name]) => (
              <Dropdown.Item key={id} onClick={() => changeLanguageHandler(id)}>
                {name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div> */}
      <div className="d-flex flex-row justify-content-between w-100">
        <span>{t("change_theme")}</span>
        <Dropdown className="lang-dropdown">
          <Dropdown.Toggle variant="secondary">{t("theme")}</Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(themeList).map((id) => (
              <Dropdown.Item key={id} onClick={() => setTheme(id)}>
                {t(id)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Styles.Settings>
  );
};

export default Settings;
