import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Footer = ({ dark = true }) => {
  const year = new Date().getFullYear();
  return (
    <Styles.Footer $dark={dark}>
      <Container>
        <Row>
          <Col
            sm={{ span: 6, order: "first" }}
            xs={{ span: 12, order: "last" }}
          >
            <div>Evoli {year} - Developed by HOC Lab</div>
          </Col>
          <Col
            className="last-col"
            sm={{ span: 6, order: "last" }}
            xs={{ span: 12, order: "first" }}
          >
            <Link to="/privacy">Privacy Policy</Link>
          </Col>
        </Row>
      </Container>
    </Styles.Footer>
  );
};

const Styles = {};

Styles.Footer = styled.div`
  background-color: ${(p) => (p.$dark ? p.theme.primary : p.theme.textPrimary)};
  color: ${(p) => (p.$dark ? p.theme.textPrimary : p.theme.primary)};
  width: 100%;
  display: flex;
  align-items: center;

  a {
    color: ${(p) => (p.$dark ? p.theme.textPrimary : p.theme.primary)};
    text-decoration: none;
  }

  .last-col {
    text-align: right;
  }

  @media (min-width: 576px) {
    height: 3rem;
  }

  @media (max-width: 576px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;

    .last-col {
      margin-bottom: 0.5rem;
    }
  }
`;

export default Footer;
