import React from "react";
import UsersTable from "./components/users/UsersTable";
import { Container, Row } from "react-bootstrap";
import EvoliMultiButton from "../../../../components/Commons/EvoliMultiButton";
import CoursesTable from "./components/courses/CoursesTable";

const MasterTables = () => {
  const tables = [
    { name: "Users", id: 0 },
    { name: "Courses", id: 1 },
  ];
  const [table, setTable] = React.useState(tables[0].id);

  const TableDictionary = {
    0: <UsersTable />,
    1: <CoursesTable />,
  };

  return (
    <Container className="p-4">
      <Row>
        <h1 className="fw-bolder">Master Tables</h1>
        <p>
          This page contains all the master tables that are used in the
          application.
        </p>
        <div className="d-flex flex-row justify-content-center">
          <EvoliMultiButton
            texts={tables.map((t) => t.name)}
            active={table}
            setActive={setTable}
          />
        </div>
        {/* Table */}
        {TableDictionary[table]}
      </Row>
    </Container>
  );
};

export default MasterTables;
