import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/PageElements/Navbar";
import { toastSuccess } from "../../utils/Toast";
import * as FormStyles from "../../styles/Forms.styles";
import Loading from "../../components/PageElements/Loading";
import { PasswordField } from "../../components/Commons/Form";
import { useResetPasswordMutation } from "../../store/auth/auth.api";
import PageContainer from "../../components/PageElements/PageContainer";
import Header from "../../components/PageElements/Header";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/auth/auth.slice";

const ResetPasswordPage = () => {
  /* Translator hook */
  const { t } = useTranslation("", { keyPrefix: "auth.reset_password" });
  /* Redirect hook */
  const navigate = useNavigate();
  /* Params */
  const params = useParams();

  /* Queries */
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  /* Component state */
  const [resetPswErrorMsg, setResetPswErrorMsg] = useState("");

  //Reset password API call
  const resetPasswordHandler = (newPassword) => {
    //Get the reset password token from the URL
    const resetPasswordToken = params.resetPasswordToken;
    //If the reset password token is present, call the API else show an error
    resetPasswordToken
      ? resetPassword({ resetPasswordToken, password: newPassword })
          .unwrap()
          .then((res) => {
            if (res) {
              navigate("/login");
              toastSuccess(t("toast.password"));
            }
          })
          .catch((err) => {
            setResetPswErrorMsg(t("reset"));
          })
      : setResetPswErrorMsg(t("reset"));
  };

  const isUserAlreadyLogged = useSelector(selectCurrentUser) !== null;

  if (isUserAlreadyLogged) return <Navigate to="/account/edit" replace />;

  //If the page is loading, show the loading page
  if (isLoading) <Loading page={true} />;
  return (
    <>
      <Navbar dark={true} sticky />
      <PageContainer>
        <Header>
          <Container>
            <Header.Title>Reset password</Header.Title>
          </Container>
        </Header>
        <Container className="py-5">
          <Row>
            <Col lg={{ span: 5, offset: 1 }} sm={10}>
              <p className="mb-4">{t("subtitle")}</p>
              <ResetPasswordForm
                resetPswErrorMsg={resetPswErrorMsg}
                resetPasswordHandler={resetPasswordHandler}
                setResetPswErrorMsg={setResetPswErrorMsg}
              />
            </Col>
          </Row>
        </Container>
      </PageContainer>
    </>
  );
};

const ResetPasswordForm = ({
  resetPasswordHandler,
  resetPswErrorMsg,
  setResetPswErrorMsg,
}) => {
  //Translator hook
  const { t } = useTranslation("", { keyPrefix: "auth.reset_password" });

  /* Component state */
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();

    //Check if the password and the confirm password are the same
    if (password !== confirmPassword) {
      setResetPswErrorMsg(t("errors.passwords_match"));
    } else if (password.length < 8 || password.length > 12) {
      //Check if the password is between 8 and 12 characters
      setResetPswErrorMsg(t("errors.password_length"));
    } else {
      //Call the reset password API
      resetPasswordHandler(password);
    }
  };

  return (
    <Form onSubmit={submitHandler} className="">
      <Form.Group className="my-3">
        <FormStyles.FormLabel dark htmlFor="reset-password">
          {t("new_password")}
        </FormStyles.FormLabel>
        <PasswordField
          id="reset-password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <FormStyles.FormLabel dark htmlFor="reset-confirm-password">
          {t("confirm_password")}
        </FormStyles.FormLabel>
        <PasswordField
          id="reset-confirm-password"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Form.Group>
      <FormStyles.EvoliButton type="submit" className="mt-4 w-100 fs-5">
        {t("confirm")}
      </FormStyles.EvoliButton>
      {resetPswErrorMsg && (
        <FormStyles.Error className="mt-3">{resetPswErrorMsg}</FormStyles.Error>
      )}
    </Form>
  );
};

export default ResetPasswordPage;
