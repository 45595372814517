import styled from "styled-components";

const Styles = {};

Styles.ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  .chart-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
  }

  .chart-wrapper {
    height: 23vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export default Styles;
