import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/api.slice";

const teachingsAdapter = createEntityAdapter({
  // The course id is the uuid
  selectId: (teaching) => teaching.uuid,
});

const initialState = teachingsAdapter.getInitialState();

export const teachingsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //Create a new course
    createCourse: builder.mutation({
      query: ({
        name,
        description,
        color,
        understandingScale,
        difficultyScale,
        difficultyEnabled,
        initialKnowledgeEnabled,
      }) => ({
        url: "/teachings/course",
        method: "POST",
        body: {
          name,
          description,
          color,
          understandingScale,
          difficultyScale,
          difficultyEnabled,
          initialKnowledgeEnabled,
        },
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: [{ type: "Teachings", id: "LIST" }],
    }),
    //Create a new unit
    createUnit: builder.mutation({
      query: ({ name, courseUUID }) => ({
        url: "/teachings/unit",
        method: "POST",
        body: { name, courseUUID },
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: (result, error, args) => [
        { type: "Teachings", id: args.courseUUID },
      ],
    }),
    //Create a new lecture
    createVideoLesson: builder.mutation({
      query: ({
        name,
        unitUUID,
        videoId,
        introduction,
        startSecond,
        endSecond,
        courseUUID,
      }) => {
        //Build the body of the request, introduction is optional
        const body = {
          name,
          videoId,
          startSecond,
          endSecond,
          unitUUID,
        };
        if (introduction) body.introduction = introduction;

        return {
          url: "/teachings/lesson/video",
          method: "POST",
          body,
        };
      },
      transformResponse: (response) => response.data,
      invalidatesTags: (result, error, args) => [
        { type: "Teachings", id: args.courseUUID },
      ],
    }),
    //Retrieve the list of courses taught by the current user
    getTeachings: builder.query({
      query: ({
        limit = 12,
        offset = 0,
        order = "ASC",
        orderBy = "name",
        searchQuery = null,
      }) =>
        `/teachings/${limit}/${offset}/${order}/${orderBy}/${
          searchQuery || ""
        }`,
      transformResponse: (response) => {
        teachingsAdapter.setAll(initialState, response.data.teachings);
        return response.data;
      },
      providesTags: [{ type: "Teachings", id: "LIST" }],
    }),
    //Get course details (lectures and enrolled users) with a given uuid
    getTeachingDetails: builder.query({
      query: (uuid) => `/teachings/${uuid}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, uuid) => [{ type: "Teachings", id: uuid }],
      invalidatesTags: ["CourseOverview"],
    }),
    //Get teaching overview of a course with a given uuid
    getTeachingOverview: builder.query({
      query: ({ courseUUID, period = "week" }) =>
        `/teachings/overview/${courseUUID}/${period}`,
      transformResponse: (response) => response.data,
      providesTags: ["CourseOverview"],
    }),
    //Get enrollments of a course with a given uuid
    getCourseEnrollments: builder.query({
      query: ({
        courseUUID,
        limit = 12,
        offset = 0,
        order = "ASC",
        orderBy = "firstName",
        searchQuery = null,
      }) =>
        `/teachings/students/${courseUUID}/${limit}/${offset}/${order}/${orderBy}${
          "/" + searchQuery || ""
        }`,
      transformResponse: (response) => response.data,
    }),
    //Get lesson details (with course and unit)
    getLessonDetails: builder.query({
      query: (uuid) => `/teachings/lesson/${uuid}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, uuid) => [{ type: "Lesson", id: uuid }],
      invalidatesTags: [
        { type: "Visualizations", id: "Info" },
        { type: "Visualizations", id: "LIST" },
      ],
    }),
    //Update a course with a given uuid
    updateCourse: builder.mutation({
      query: ({ uuid, name, description, color }) => {
        const body = {
          name,
        };
        if (description) body.description = description;
        if (color) body.color = color;

        return {
          url: `teachings/${uuid}`,
          method: "PATCH",
          body,
        };
      },
      transformResponse: (response) => response.data,
      invalidatesTags: (result, error, { uuid }) => [
        { type: "Teachings", id: uuid },
      ],
    }),
    //Update a unit with a given uuid
    updateUnit: builder.mutation({
      query: ({ courseUUID, unitUUID, name }) => ({
        url: `/teachings/unit/${unitUUID}`,
        method: "PATCH",
        body: {
          name,
        },
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: (result, error, args) => [
        { type: "Teachings", id: args.courseUUID },
      ],
    }),
    //Update lecture with a given uuid
    updateLesson: builder.mutation({
      query: ({ courseUUID, lessonUUID, name }) => ({
        url: `/teachings/lesson/${lessonUUID}`,
        method: "PATCH",
        body: {
          name,
        },
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: (result, error, args) => [
        { type: "Teachings", id: args.courseUUID },
      ],
    }),
    //Swap the order of two units
    swapUnits: builder.mutation({
      query: ({ courseUUID, unitUUID, direction = "up" }) => ({
        url: `/teachings/unit-order/${unitUUID}/${direction}`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, { courseUUID }) => [
        { type: "Teachings", id: courseUUID },
      ],
    }),
    //Swap the order of two lessons
    swapLessons: builder.mutation({
      query: ({ courseUUID, lessonUUID, direction = "up" }) => ({
        url: `/teachings/lesson-order/${lessonUUID}/${direction}`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, { courseUUID }) => [
        { type: "Teachings", id: courseUUID },
      ],
    }),
    //Delete course with a given uuid
    deleteCourse: builder.mutation({
      query: (uuid) => ({
        url: `/teachings/${uuid}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Teachings", id: "LIST" }],
    }),
    //Delete a unit with a given uuid
    deleteUnit: builder.mutation({
      query: ({ courseUUID, unitUUID }) => ({
        url: `/teachings/unit/${unitUUID}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { courseUUID }) => [
        { type: "Teachings", id: courseUUID },
      ],
    }),
    //Delete a lesson with a given uuid
    deleteLesson: builder.mutation({
      query: ({ courseUUID, lessonUUID }) => ({
        url: `/teachings/lesson/${lessonUUID}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { courseUUID }) => [
        { type: "Teachings", id: courseUUID },
      ],
    }),
  }),
});

//Expose the api
export const {
  useCreateCourseMutation,
  useCreateUnitMutation,
  useCreateVideoLessonMutation,
  useGetTeachingsQuery,
  useGetTeachingDetailsQuery,
  useGetTeachingOverviewQuery,
  useGetCourseEnrollmentsQuery,
  useLazyGetTeachingDetailsQuery,
  useGetLessonDetailsQuery,
  useUpdateCourseMutation,
  useUpdateUnitMutation,
  useUpdateLessonMutation,
  useSwapUnitsMutation,
  useSwapLessonsMutation,
  useDeleteCourseMutation,
  useDeleteUnitMutation,
  useDeleteLessonMutation,
} = teachingsApi;

//Returns the entire query result object
export const selectTaughtCoursesResult =
  teachingsApi.endpoints.getTeachings.select();

//Create selector
export const selectTaughtCoursesData = createSelector(
  selectTaughtCoursesResult,
  (result) => result.data //Normalized state object with ids and entities
);

//Export entity adpater selectors
export const {
  selectAll: selectAllTaughtCourses,
  selectById: selectTaughtCourseById,
  //Pass in a selector that returns the courses slice of the state
} = teachingsAdapter.getSelectors(
  (state) => selectTaughtCoursesData(state) || initialState
);
