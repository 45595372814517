import React from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

const useMenu = (unitUUID) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [active, setActive] = React.useState(0); //0 for feedback, 1 for questions, 2 for visualizations, 3 for students

  //Memoized links
  const links = React.useMemo(
    () => [
      {
        name: "Feedback",
        path: `/teacher/dashboard/${unitUUID}`,
        pattern: `/teacher/dashboard/${unitUUID}`,
      },
      {
        name: "Questions",
        path: `/teacher/dashboard/${unitUUID}/questions`,
        pattern: `/teacher/dashboard/${unitUUID}/questions`,
      },
      {
        name: "Visualizations",
        path: `/teacher/dashboard/${unitUUID}/visualizations`,
        pattern: `/teacher/dashboard/${unitUUID}/visualizations`,
      },
      {
        name: "Students",
        path: `/teacher/dashboard/${unitUUID}/students`,
        pattern: `/teacher/dashboard/${unitUUID}/students`,
      },
    ],
    [unitUUID]
  );

  //Handle menu click event (change active menu item and redirect)
  const handleMenuClick = (id) => {
    if (id < links.length) {
      navigate(links[id].path);
    }
  };

  React.useEffect(() => {
    const pathname = location.pathname;
    links.forEach((link, index) => {
      const match = matchPath(
        {
          path: link.pattern,
          end: true,
        },
        pathname
      );
      if (!!match) {
        setActive(index);
      }
    });
  }, [active, links, location.pathname]);

  return {
    active,
    links,
    handleMenuClick,
  };
};

export default useMenu;
