import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { getRGBAColors } from "../../../../../utils/lecture.utils";
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  zoomPlugin
);

const LessonsUnderstandingHistogram = ({ lessons, numOfLevels }) => {
  const chartData = useMemo(() => {
    const labels = [],
      feedbackList = [];
    let maxValue = 1;
    //For each lesson
    for (let i = 0; i < lessons.length; i++) {
      const { name, feedback } = lessons[i];
      //Labels
      labels.push(name.substring(0, 20));
      //Count understanding values
      const understandingCount = Array(numOfLevels).fill(0);
      feedback.forEach((feedback) => {
        understandingCount[feedback.understanding - 1]++;
      });
      //Update max value
      const max = Math.max(...understandingCount);
      if (max > maxValue) maxValue = max;
      //Update feedback data
      feedbackList.push(understandingCount);
    }
    return {
      labels,
      feedbackList,
      max: maxValue,
    };
  }, [lessons, numOfLevels]);

  const borderColors = useMemo(
    () => getRGBAColors(numOfLevels, 1),
    [numOfLevels]
  );

  const backgroundColors = useMemo(
    () => getRGBAColors(numOfLevels, 0.2),
    [numOfLevels]
  );

  const data = {
    labels: chartData.labels,
    datasets: [
      //For each understanding level
      ...Array(numOfLevels)
        .fill(0)
        .map((_, index) => {
          const backgroundColor = backgroundColors[index];
          const borderColor = borderColors[index];

          const data = chartData.feedbackList.map(
            (feedback) => feedback[index]
          );

          return {
            type: "bar",
            label: `Understanding ${index + 1}`,
            backgroundColor,
            borderColor,
            borderWidth: 2,
            fill: false,
            data,
          };
        }),
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
        max: chartData.max + 1,
        ticks: {
          stepSize: 1,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: false,
      },
      zoom: {
        limits: {
          x: { min: 0, max: lessons.length, minRange: 1 },
        },
        pan: {
          enabled: true, // SET PINCH ZOOM TO TRUE
          mode: "x",
          scaleMode: "x",
        },
        zoom: {
          wheel: {
            enabled: true, // SET SCROOL ZOOM TO TRUE
          },
          pinch: {
            enabled: true, // SET PINCH ZOOM TO TRUE
          },
          mode: "x",
          speed: 10,
        },
      },
      datalabels: {
        formatter: function (value, context) {
          if (value > 0) return value;
          return "";
        },
        color: function (context) {
          return context.dataset.borderColor;
        },
        align: "top",
        anchor: "end",
        padding: -4,
        font: {
          size: "20",
          weight: "bold",
        },
      },
    },
  };

  return <Bar options={options} data={data} plugins={[ChartDataLabels]} />;
};

export default LessonsUnderstandingHistogram;
