import styled from "styled-components";

const Styles = {};

Styles.Answer = styled.div`
  position: relative;
  color: ${(p) => p.theme.primary};
  padding: 1.2rem 1rem 1rem 3rem;
  overflow-wrap: break-word;
`;

export default Styles;
