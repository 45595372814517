import React, { useState } from "react";
import { toHoursMins } from "../../../../utils/lecture.utils";
import { useOutletContext } from "react-router-dom";
import { Accordion, Container } from "react-bootstrap";
import { EvoliButton } from "../../../../styles/Forms.styles";
import Loading from "../../../../components/PageElements/Loading";
import {
  EditLessonModal,
  EditTeachingModal,
  EditUnitModal,
} from "./components/update-modals/UpdateModals";
import {
  CreateLessonModal,
  CreateUnitModal,
} from "./components/create-modals/CreateModals";
import Styles from "./styles";
import useFetch from "./hooks/useFetch";

const Edit = () => {
  //Context hook
  const { course } = useOutletContext();
  const units = course.units;

  /* State */
  const [showCreateUnitModal, setShowCreateUnitModal] = useState(false);
  const [showCreateLessonModal, setShowCreateLessonModal] = useState(false);
  const [showEditCourseModal, setShowEditCourseModal] = useState(false);
  const [showEditUnitModal, setShowEditUnitModal] = useState(false);
  const [showEditLessonModal, setShowEditLessonModal] = useState(false);
  const [tmpUnit, setTmpUnit] = useState(null);
  const [tmpLesson, setTmpLesson] = useState(null);

  const {
    createUnitHandler,
    createLessonHandler,
    updateCourseHandler,
    updateUnitHandler,
    updateLessonHandler,
    deleteCourseHandler,
    deleteUnitHandler,
    deleteLessonHandler,
    swapUnitsHandler,
    swapLessonsHandler,
    isLoading,
  } = useFetch({ course });

  /* Calculate the total number of lessons */
  const lessonsCount = units.reduce((total, unit) => {
    return total + unit.lessons.length;
  }, 0);

  /* Calculate the total number of video minutes: the enrollment has a course, the course has units, a unit has lesson, a lesson could have a startSecond and a endSecond  */
  const totalVideoSeconds = units.reduce((total, unit) => {
    return (
      total +
      unit.lessons.reduce((total, lesson) => {
        if (lesson.startSecond === null || lesson.endSecond === null)
          return total;
        return total + (lesson.endSecond - lesson.startSecond);
      }, 0)
    );
  }, 0);

  const createUnitClickHandler = () => {
    setShowCreateUnitModal(true);
  };

  const createLessonClickHandler = (unit) => {
    setTmpUnit(unit);
    setShowCreateLessonModal(true);
  };

  const editCourseClickHandler = () => {
    setShowEditCourseModal(true);
  };

  const ediUnitHandler = (unit) => {
    setTmpUnit(unit);
    setShowEditUnitModal(true);
  };

  const editLessonHandler = (lesson) => {
    setTmpLesson(lesson);
    setShowEditLessonModal(true);
  };

  if (isLoading) return <Loading />;
  return (
    <Container className="py-5">
      <h2>Edit course</h2>
      <p className="mb-4">
        Here you can edit the course info, create new learning units, add
        lessons to the units, and edit or delete the units and lessons.
      </p>
      <div className="d-flex flex-row justify-content-center align-items-center gap-2 mb-4">
        {/* Button to create a new learning unit */}
        <EvoliButton variant="success" onClick={createUnitClickHandler}>
          <i className="fas fa-plus me-2" /> Create a new unit
        </EvoliButton>
        {/* Button to edit course name or description */}
        <EvoliButton onClick={editCourseClickHandler}>
          <i className="fas fa-edit me-2" /> Edit course info
        </EvoliButton>
        {/* Delete the course btn */}
        <EvoliButton variant="danger" onClick={deleteCourseHandler}>
          <i className="fas fa-trash-alt me-2" /> Delete course
        </EvoliButton>
      </div>
      {/* [n learning unit] • [n lessons] • [xh ym total video length] • [x document pages] */}
      <div className="d-flex flex-row justify-content-start align-items-center gap-2 mt-2">
        {units.length} learning unit
        {units.length !== 1 ? "s" : ""} • {lessonsCount} lesson
        {lessonsCount !== 1 ? "s" : ""} • {toHoursMins(totalVideoSeconds)} total
        video length
      </div>
      {/* Display the units in an accordition, all items should be open at the beginning */}
      <Accordion
        alwaysOpen
        className="mt-3"
        defaultActiveKey={units.map((_, index) => index.toString())}
      >
        {units.map((unit, index) => (
          <LearningUnitItem
            isFirst={unit.order === 1}
            isLast={unit.order === units.length}
            unit={unit}
            index={index}
            key={index}
            createLessonClickHandler={createLessonClickHandler}
            editUnitHandler={ediUnitHandler}
            editLessonHandler={editLessonHandler}
            deleteUnitHandler={deleteUnitHandler}
            deleteLessonHandler={deleteLessonHandler}
            swapUnitsHandler={swapUnitsHandler}
            swapLessonsHandler={swapLessonsHandler}
          />
        ))}
      </Accordion>
      {/* Modals */}
      <CreateUnitModal
        show={showCreateUnitModal}
        onHide={() => setShowCreateUnitModal(false)}
        createUnitHandler={createUnitHandler}
        course={course}
      />
      <CreateLessonModal
        show={showCreateLessonModal}
        onHide={() => setShowCreateLessonModal(false)}
        course={course}
        createLessonHandler={createLessonHandler}
        unit={tmpUnit}
      />
      <EditTeachingModal
        course={course}
        show={showEditCourseModal}
        onHide={() => setShowEditCourseModal(false)}
        updateCourseHandler={updateCourseHandler}
      />
      <EditUnitModal
        show={showEditUnitModal}
        onHide={() => setShowEditUnitModal(false)}
        unit={tmpUnit}
        updateUnitHandler={updateUnitHandler}
      />
      <EditLessonModal
        show={showEditLessonModal}
        onHide={() => setShowEditLessonModal(false)}
        lesson={tmpLesson}
        updateLessonHandler={updateLessonHandler}
      />{" "}
    </Container>
  );
};

const LearningUnitItem = ({
  unit,
  index,
  createLessonClickHandler,
  editUnitHandler,
  editLessonHandler,
  deleteUnitHandler,
  deleteLessonHandler,
  swapUnitsHandler,
  swapLessonsHandler,
  isFirst,
  isLast,
}) => {
  const lessonsCount = unit.lessons.length;
  const totalVideoSeconds = unit.lessons.reduce((total, lesson) => {
    if (lesson.startSecond === null || lesson.endSecond === null) return total;
    return total + (lesson.endSecond - lesson.startSecond);
  }, 0);

  /* const totalDocPages = unit.lessons.reduce((total, lesson) => {
    if (lesson.startPage === null || lesson.endPage === null) return total;
    return total + (lesson.endPage + 1 - lesson.startPage);
  }, 0); */

  return (
    <Accordion.Item eventKey={index.toString()}>
      {/* [unit type] [unit name]          [number of lessons] • [minutes or pages]  */}
      <Accordion.Header>
        <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100 me-4">
          <div>{unit.name}</div>
          <div className="d-flex flex-row justify-content-end align-items-center gap-1">
            <span>
              {lessonsCount} lesson{lessonsCount > 1 ? "s" : ""}
            </span>
            <span>•</span>
            <span>{toHoursMins(totalVideoSeconds)}</span>
            {/* <span>•</span>
            <span>
              {totalDocPages} page{totalDocPages > 1 ? "s" : ""}
            </span> */}
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        {/* Action btns */}
        <div className="d-flex flex-row justify-content-center align-items-center gap-2 w-100 pt-1 pb-3 mb-3 border-bottom">
          {/* Add a lesson btn */}
          <EvoliButton
            variant="success"
            onClick={() => createLessonClickHandler(unit)}
          >
            <i className="fas fa-plus me-2" /> Add a lesson
          </EvoliButton>
          {/* Edit unit btn */}
          <EvoliButton onClick={() => editUnitHandler(unit)}>
            <i className="fas fa-edit me-2" /> Edit unit
          </EvoliButton>
          {/* Move up btn */}
          {!isFirst && (
            <EvoliButton
              variant="secondary"
              onClick={() => swapUnitsHandler(unit, "up")}
            >
              <i className="fas fa-arrow-up" /> Move up
            </EvoliButton>
          )}
          {/* Move down btn */}
          {!isLast && (
            <EvoliButton
              variant="secondary"
              onClick={() => swapUnitsHandler(unit, "down")}
            >
              <i className="fas fa-arrow-down" /> Move down
            </EvoliButton>
          )}
          {/* Delete unit */}
          <EvoliButton
            variant="danger"
            onClick={() => deleteUnitHandler(unit.uuid)}
          >
            <i className="fas fa-trash-alt me-2" /> Delete unit
          </EvoliButton>
        </div>
        {/* Display one row for each lesson */}
        {unit.lessons.length === 0 ? (
          <span className="fst-italic text-muted">
            This unit has no lesson yet, click the "Add a lesson" button to
            create the first lesson.
          </span>
        ) : (
          unit.lessons.map((lesson) => (
            <LessonItem
              lesson={lesson}
              editLessonHandler={editLessonHandler}
              deleteLessonHandler={deleteLessonHandler}
              ù
              swapLessonsHandler={swapLessonsHandler}
              isFirst={lesson.order === 1}
              isLast={lesson.order === unit.lessons.length}
              key={lesson.uuid}
            />
          ))
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

const LessonItem = ({
  lesson,
  editLessonHandler,
  deleteLessonHandler,
  swapLessonsHandler,
  isFirst,
  isLast,
}) => {
  const lessonDuration = (lesson.endSecond ?? 0) - (lesson.startSecond ?? 0);

  return (
    <Styles.LessonItem>
      {/* Actions btns */}
      <div className="lesson-title">
        <div className="d-flex flex-row justify-content-start align-items-center gap-1">
          {/* Edit button */}
          <EvoliButton onClick={() => editLessonHandler(lesson)}>
            <i className="fas fa-edit" />
          </EvoliButton>
          {/* Move up button */}
          {!isFirst && (
            <EvoliButton
              variant="secondary"
              onClick={() => swapLessonsHandler(lesson, "up")}
            >
              <i className="fas fa-arrow-up" />
            </EvoliButton>
          )}
          {/* Move down button */}
          {!isLast && (
            <EvoliButton
              variant="secondary"
              onClick={() => swapLessonsHandler(lesson, "down")}
            >
              <i className="fas fa-arrow-down" />
            </EvoliButton>
          )}
          {/* Delete button */}
          <EvoliButton
            variant="danger"
            onClick={() => deleteLessonHandler(lesson)}
          >
            <i className="fas fa-trash" />
          </EvoliButton>
        </div>
        <div className="title">{lesson.name}</div>
      </div>
      <div className="d-flex flex-row justify-content-end align-items-center gap-2 mt-2">
        <div className="d-flex flex-row justify-content-end align-items-center gap-2">
          <span>{toHoursMins(lessonDuration)}</span>
        </div>
      </div>
    </Styles.LessonItem>
  );
};

export default Edit;
