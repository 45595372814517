import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as FormStyles from "../../styles/Forms.styles";

const BackToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  const scrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  //Show top button when scrolling
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  if (!showTopBtn) return null;
  return (
    <SBackToTop
      className="d-flex align-items-center justify-content-center"
      onClick={scrollToTop}
    >
      <i className="fas fa-arrow-up" />
    </SBackToTop>
  );
};

const SBackToTop = styled(FormStyles.EvoliButton)`
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 10;

  border: 3px solid ${(p) => p.theme.primary};
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  transition: all 0.4s;

  i {
    font-size: 1.8rem;
  }
`;

export default BackToTop;
