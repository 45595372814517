import React from "react";

const useOrder = () => {
  const options = [
    {
      label: "Date (newest first)",
      value: "updatedAtDesc",
    },
    {
      label: "Date (oldest first)",
      value: "updatedAtAsc",
    },
  ];

  const [order, setOrder] = React.useState("DESC"); //ASC or DESC
  const [orderBy, setOrderBy] = React.useState("updatedAt");
  const [orderValue, setOrderValue] = React.useState("updatedAtDesc");

  const reset = React.useCallback(() => {
    setOrderValue("updatedAtDesc");
    setOrder("DESC");
    setOrderBy("updatedAt");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeOrderHandler = (e) => {
    const value = e.target.value;
    setOrderValue(value);
    //The value is in the format [field][order]
    //Example: firstNameAsc or lastNameDesc
    //If the value ends with Asc, the order is ASC, else DESC
    if (value.endsWith("Asc")) {
      setOrder("ASC");
      //Order by is the value without the last 3 characters
      setOrderBy(value.slice(0, -3));
    } else {
      setOrder("DESC");
      //Order by is the value without the last 4 characters
      setOrderBy(value.slice(0, -4));
    }
  };

  //Initialize students list
  React.useEffect(() => {
    reset();
  }, [reset]);

  return {
    changeOrderHandler,
    orderBy,
    order,
    orderValue,
    options,
    reset,
  };
};

export default useOrder;
