import React, { useRef, useState } from "react";
import { Pagination, ProgressBar, Row, Table } from "react-bootstrap";
import Loading from "../../../../components/PageElements/Loading";
import EvoliBackgroundSpan from "../../../../components/Commons/EvoliBackgroundSpan";
import { EvoliButton } from "../../../../styles/Forms.styles";
import VideoEngagementModal from "./components/VideoEngagementModal";
import useFetch from "./hooks/useFetch";
import { useOutletContext } from "react-router-dom";
import InfoTooltip from "../../../../components/Commons/InfoTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { getLastAccessString } from "../../../../utils/time.utils";
import { toHHMMSS } from "../../../../utils/lecture.utils";
import usePagination from "./hooks/usePagination";
import SearchBar from "../../../../components/Form/SearchBar";

const StudentsList = () => {
  //Get lesson from context
  const { lesson } = useOutletContext();

  // Pagination state
  const [page, setPage] = useState(1);
  const [showNames, setShowNames] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryValue, setSearchQueryValue] = useState("");
  const searchInputRef = useRef(null);

  // Pagination hook
  const {
    changeOrderHandler,
    limit,
    offset,
    order,
    orderBy,
    orderValue,
    options,
    reset,
  } = usePagination({
    page,
    searchInputRef,
    setSearchQuery,
    setSearchQueryValue,
  });

  // Query hook
  const {
    data: { studentList, itemsCount },
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  } = useFetch({
    lessonUUID: lesson.uuid,
    limit,
    offset,
    orderBy,
    order,
    searchQuery,
  });

  // Calculate the number of pages
  const pages = Math.ceil(itemsCount / limit);

  const changePageHandler = (pageValue) => {
    if (pageValue >= 1 && pageValue <= pages) setPage(pageValue);
  };

  const changeSearchHandler = (e) => {
    const value = e.target.value;
    setSearchQueryValue(value);
  };

  const submitSearchHandler = (e) => {
    e.preventDefault();
    setSearchQuery(searchQueryValue);
    setPage(1);
  };

  if (isError) return <div className="text-danger fw-bold">{errorMessage}</div>;
  if (isLoading) return <Loading page={false} />;
  if (isSuccess)
    return (
      <>
        <Row className="my-4">
          <div className="d-flex flex-column flex-md-row gap-2 gap-md-3 w-100">
            {/* Order by firstName, lastName, lastCourseAccessDate, totalSecondsViewed, completedLessons */}
            <div className="d-flex flex-column gap-1">
              <label>Order by</label>
              <select
                className="form-select"
                value={orderValue}
                onChange={changeOrderHandler}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            {/* Search box */}
            <div className="d-flex flex-row align-items-end gap-2">
              <div className="d-flex flex-column flex-grow-1 gap-1">
                <label>Search</label>
                <SearchBar
                  className="w-100"
                  inputRef={searchInputRef}
                  placeholder={"Search my students"}
                  onChange={changeSearchHandler}
                  onSubmit={submitSearchHandler}
                />
              </div>
              {/* Reset filter and order */}
              <EvoliButton onClick={reset}>Reset</EvoliButton>
            </div>
          </div>
        </Row>
        <Row>
          <Table striped borderless hover className="w-100">
            <thead>
              <tr className="text-center">
                <th className="d-flex flex-row justify-content-center align-items-center gap-2">
                  <span>Student</span>
                  <EvoliButton onClick={() => setShowNames(!showNames)}>
                    <FontAwesomeIcon icon={showNames ? faEyeSlash : faEye} />
                  </EvoliButton>
                </th>
                <th>
                  <span className="me-1">Total connection time (HH:MM:SS)</span>
                  <InfoTooltip text="The total time the student spent in the lesson page" />
                </th>
                <th>
                  # Visualizations{" "}
                  <InfoTooltip text="A visualization is counted only if the user has played the video at least once." />{" "}
                </th>
                <th>
                  <span className="me-1"> Last access</span>
                  <InfoTooltip text="The last time the student accessed the lesson" />
                </th>
                <th>Video completion</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="w-100">
              {studentList.map((student) => (
                <StudentItem
                  key={student.user.email}
                  student={student.user}
                  lesson={lesson}
                  showNames={showNames}
                />
              ))}
            </tbody>
          </Table>
        </Row>
        {/* Pagination section, show 12 courses */}
        <Row>
          <div className="d-flex flex-column gap-1">
            {/* [start index]-[end index] of [count] courses  */}
            <div className="d-flex flex-row justify-content-center">
              <span className="fs-6 fst-italic">
                {itemsCount === 0 ? 0 : page * limit - limit + 1}-
                {page * limit > itemsCount ? itemsCount : page * limit} of{" "}
                {itemsCount} students
              </span>
            </div>
            {/* Pagination */}
            <div className="d-flex flex-row justify-content-center">
              <Pagination>
                {page === 1 ? (
                  <Pagination.First disabled />
                ) : (
                  <Pagination.First onClick={() => changePageHandler(1)} />
                )}
                {page > 1 && (
                  <Pagination.Item onClick={() => changePageHandler(page - 1)}>
                    {page - 1}
                  </Pagination.Item>
                )}
                <Pagination.Item active>{page}</Pagination.Item>
                {page < pages && (
                  <Pagination.Item onClick={() => changePageHandler(page + 1)}>
                    {page + 1}
                  </Pagination.Item>
                )}
                {page < pages ? (
                  <Pagination.Last onClick={() => changePageHandler(pages)} />
                ) : (
                  <Pagination.Last disabled />
                )}
              </Pagination>
            </div>
          </div>
        </Row>
      </>
    );
};

const StudentItem = ({ student, lesson, showNames }) => {
  const { firstName, lastName } = student;
  const analytics = student.lessonUserAnalytics
    ? student.lessonUserAnalytics[0]
    : null;
  const lastLessonAccessDate = analytics?.lastLessonAccessDate;
  const totalSecondsViewed = analytics?.totalSecondsViewed ?? 0;
  const lessonCompletion = analytics?.lessonCompletion ?? 0;
  const visualizationCount = analytics?.visualizationCount ?? 0;

  const fullName = showNames ? `${lastName} ${firstName}` : "Hidden name";
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <tr className="w-100 text-center">
        <td className={!showNames && "fst-italic"}>{fullName}</td>
        {/* Total seconds viewed */}
        <td>{toHHMMSS(totalSecondsViewed)}</td>
        {/* Visualization count */}
        <td className="text-center">
          <EvoliBackgroundSpan>{visualizationCount}</EvoliBackgroundSpan>
        </td>
        {/* Last access */}
        <td>{getLastAccessString(lastLessonAccessDate)}</td>
        {/* Completion */}
        <td>
          <ProgressBar
            min={0}
            max={100}
            now={lessonCompletion}
            label={`${lessonCompletion}%`}
          />
        </td>
        <td className="d-flex justify-content-center">
          {/* Show details btn with an eye icon */}
          <EvoliButton>
            <FontAwesomeIcon
              icon={faEye}
              className="fas fs-4"
              onClick={() => setShowModal(true)}
            />
          </EvoliButton>
        </td>
      </tr>
      {/* Show details section */}
      {showModal && (
        <VideoEngagementModal
          student={student}
          lesson={lesson}
          show={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default StudentsList;
