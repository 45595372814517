import React from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import Loading from "../../../../../components/PageElements/Loading";
import Styles from "./styles";
import { toHHMMSS } from "../../../../../utils/lecture.utils";
import EvoliModal from "../../../../../components/Commons/EvoliModal";
import useFetch from "./hooks/useFetch";
import VideoViewsLine from "./components/VideoViewsLine";

const VideoEngagementModal = ({ student, lesson, show, onHide }) => {
  const {
    data: analytics,
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  } = useFetch({
    lessonUUID: lesson.uuid,
    studentEmail: student.email,
  });

  if (isError) return <div className="text-danger fw-bold">{errorMessage}</div>;
  if (isLoading) return <Loading page={false} />;
  if (isSuccess)
    return (
      <EvoliModal
        variant="primary"
        show={show}
        onHide={onHide}
        size="lg"
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Student's video views analysis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Styles.ChartContainer className="mb-3">
                <div className="chart-container">
                  <VideoViewsLine
                    intervals={analytics.intervals}
                    start={lesson.startSecond}
                    end={lesson.endSecond}
                    pauseSeconds={analytics.pauseSeconds}
                    seeks={analytics.seeks}
                  />
                </div>
              </Styles.ChartContainer>
            </Row>
            <Row>
              {/* Total Viewing time */}
              <Col xs={12} md={6} lg={3}>
                <Styles.MiniBox>
                  <div className="header">
                    <i className="fas fa-eye"></i>
                    <span className="data">
                      {toHHMMSS(analytics.watchTime)}
                    </span>
                  </div>
                  <div className="description">Total Viewing time</div>
                </Styles.MiniBox>
              </Col>
              {/* Rewatch time */}
              <Col xs={12} md={6} lg={3}>
                <Styles.MiniBox>
                  <div className="header">
                    <i className="fas fa-eye"></i>
                    <span className="data">
                      {toHHMMSS(analytics.rewatchTime)}
                    </span>
                  </div>
                  <div className="description">Rewatch time</div>
                </Styles.MiniBox>
              </Col>
              {/* Number of pauses */}
              <Col xs={12} md={6} lg={3}>
                <Styles.MiniBox>
                  <div className="header">
                    <i className="fas fa-pause"></i>
                    <span className="data">
                      {analytics.pauseSeconds.length}
                    </span>
                  </div>
                  <div className="description">Number of pauses</div>
                </Styles.MiniBox>
              </Col>
              {/* Number of seeks */}
              <Col xs={12} md={6} lg={3}>
                <Styles.MiniBox>
                  <div className="header">
                    <i className="fas fa-search"></i>
                    <span className="data">{analytics.seeks.length}</span>
                  </div>
                  <div className="description">Number of seeks</div>
                </Styles.MiniBox>
              </Col>
            </Row>
            <div className="d-flex flex-column gap-3 mt-3"></div>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row justify-content-center">
          <Button variant="light" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </EvoliModal>
    );
};

export default VideoEngagementModal;
