import moment from "moment";
import { te } from "./errorsTranslation";
import { toastError } from "./Toast";

export const QualityDictionary = {
  small: "240px",
  medium: "360px",
  large: "480px",
  hd720: "720px",
  hd1080: "1080px",
  highres: "1440px",
  auto: "auto",
  default: "default",
};

/**
 * Get the icon, the color and the type of the value relative to a reaction
 * @param {number} value - the value of the reaction, it can be 1, 2 or 3
 * @param {number} alpha - rgba alfpha parameter, from 0 to 1
 * @returns {Object} data - the data relative to the reaction
 * @returns {string} data.icon - the icon relative to the reaction
 * @returns {color} data.color - the color relative to the reaction
 * @returns {type} data.type - the type relative to the reaction, it can be "error", "warning" or "success"
 */
export const getReactionStyle = (value, alpha = 1) => {
  let face = "fas fa-smile",
    color = `rgba(0, 255, 0, ${alpha})`;
  if (value === 1) {
    face = "fas fa-dizzy";
    color = `rgba(255, 0, 0, ${alpha})`;
  }
  if (value === 2) {
    face = "fas fa-meh";
    color = `rgba(255, 255, 0, ${alpha})`;
  }

  return { icon: face, color };
};

/**
 * Return the reaction on a chapter of a given user
 * @param {string} email - the email of the user
 * @param {Chapter} chapter - the chapter instance
 * @returns {ChapterReaction} - the reaction of the given user on the given chapter
 */
export const getUserChapterReaction = (email, chapter) => {
  const reactions = chapter.reactions.filter((r) => r.user.email === email);
  if (reactions.length > 0) return reactions[0];
  return null;
};

/**
 * Get the seconds in number from a string formatted as HH:MM:SS
 * @param {string} value - time formatted like HH:MM:SS
 * @returns {number} - converted number of seconds
 */
export const getSecondsFromHHMMSS = (value) => {
  const [str1, str2, str3] = value.split(":");

  const val1 = Number(str1);
  const val2 = Number(str2);
  const val3 = Number(str3);

  if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    // seconds
    return val1;
  }

  if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    // minutes * 60 + seconds
    return val1 * 60 + val2;
  }

  if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
    // hours * 60 * 60 + minutes * 60 + seconds
    return val1 * 60 * 60 + val2 * 60 + val3;
  }

  return 0;
};

/**
 * Format the seconds number to a string formatted like HH:MM:SS
 * @param {number} secs - the seconds
 * @returns {string} - seconds formatted like HH:MM:SS
 */
export const toHHMMSS = (secs) => {
  const secNum = parseInt(secs.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hours, minutes, seconds]
    .map((val) => (val < 10 ? `0${val}` : val))
    .filter((val, index) => val !== "00" || index > 0)
    .join(":")
    .replace(/^0/, "");
};

export const toHHMM = (secs) => {
  const secNum = parseInt(secs.toString(), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;

  return [hours, minutes]
    .map((val) => (val < 10 ? `0${val}` : val))
    .filter((val, index) => val !== "00" || index > 0)
    .join(":")
    .replace(/^0/, "");
};

export const toHoursMins = (secs) => {
  const hours = Math.floor(secs / 3600);
  const minutesLeft = Math.floor((secs - hours * 3600) / 60);
  const secondsLeft = secs - hours * 3600 - minutesLeft * 60;
  if (hours > 0) return `${hours}h ${minutesLeft}min`;
  if (minutesLeft > 0) return `${minutesLeft}min`;
  return `${secondsLeft}sec`;
};

export const getSeconds = (seconds) => {
  const hhmmss = toHHMMSS(seconds);
  //Get only the SS part
  return parseInt(hhmmss.split(":")[2]);
};

export const getMinutes = (seconds) => {
  const hhmmss = toHHMMSS(seconds);
  //Get only the MM part
  return parseInt(hhmmss.split(":")[1]);
};

export const getHours = (seconds) => {
  const hhmmss = toHHMMSS(seconds);
  //Get only the HH part
  return parseInt(hhmmss.split(":")[0]);
};

export const getDays = (seconds) => {
  const hhmmss = toHHMMSS(seconds);
  //Get only the HH part
  return Math.floor(parseInt(hhmmss.split(":")[0]) / 24);
};

/**
 * Return the array of questions in the chapter with the asnwers
 * @param {Lecture} lecture - the lecture instance
 * @param {Array<Question>} questions - the array of questions
 * @param {Chapter} chapter - the chapter instance
 * @returns {Array<Question>} - the list of question belonging to the chapter
 */
export const getQuestionsInChapter = (lecture, questions, chapter) => {
  const chapterQuestions =
    lecture.type === "video"
      ? questions.filter(
          (q) =>
            !q.parent &&
            q.videoTs >= chapter.startSecond &&
            q.videoTs < chapter.endSecond
        )
      : lecture.type === "doc"
      ? questions.filter(
          (q) =>
            !q.parent &&
            q.pageNumber >= chapter.startPage &&
            q.pageNumber < chapter.endPage
        )
      : [];
  return chapterQuestions.map((q) => {
    const answers = questions.filter((a) => a.parent?.uuid === q.uuid);
    return { ...q, answers };
  });
};

/**
 * Return the current chapter given the current video second or the current page number
 * @param {Lecture} lecture - the lecture instance
 * @param {number} second - the current video seconds if the lecture is of type "video"
 * @param {number} page - the current page number if the lecture is of type "doc"
 * @param {Array<Chapter>} chapters - the list of ordered chapters
 * @returns
 */
export const getCurrentChapter = (lecture, second, page, chapters) => {
  const filtered =
    lecture.type === "video"
      ? chapters.filter((c) => c.startSecond <= second && c.endSecond > second)
      : chapters.filter((c) => c.startPage <= page && c.endPage >= page);
  if (filtered.length > 0) return filtered[0];
  return null;
};

/**
 * Given a chapter returns the next one
 * @param {Lecture} lecture - the lecture instance
 * @param {Array<Chapter>} chapters - the array of chapters
 * @param {Chapter} chapter - the chapter instance
 * @returns {Chapter} - the next chapter instance
 */
export const getNextChapter = (lecture, chapters, chapter) => {
  const filtered = chapters
    .sort((c1, c2) => {
      if (lecture.type === "video") return c1.startSecond - c2.startSecond;
      else return c1.startPage - c2.startPage;
    })
    .filter((c) => {
      if (lecture.type === "video") return c.startSecond > chapter.startSecond;
      else return c.startPage > chapter.startPage;
    });

  if (filtered.length > 0) return filtered[0];
  return null;
};

/**
 * Initialize the notifications number, in particular it sets the number of unread messages and questions
 * @param {string} lastVisualization - the datetime in string of the last visualization
 * @param {Array<Question>} questions - the list of questions
 * @returns {Object} data - an object containing the number of notifications
 * @returns {number} data.chat - the number of unread chat messages
 * @returns {number} data.questions - the number of unread questions
 */
export const initNotifications = (lastVisualization, questions) => {
  if (lastVisualization) {
    const last = moment(lastVisualization);
    return {
      questions: questions.filter((q) => moment(q.createdAt).isAfter(last))
        .length,
    };
  }
  //If first access all messages and questions are new
  return { questions: questions.length };
};

/**
 * Given the chapters map in which each chapter is a pair [uuid, chapter] returns an array with the chapters for the chapters
 * ordered by page or videoTs in according to the lecture type
 * @param {Object} chapters - the chapters map
 * @param {Chapter} chapters[chapter.uuid] - the chapter
 * @returns {Array<Chapter>} - the array of ordered chapters
 */
export const getOrderedChaptersArray = (lecture, chapters) => {
  return Object.values(chapters)
    .sort((a, b) => {
      if (lecture.type === "video") return a.startSecond - b.startSecond;
      if (lecture.type === "doc") return a.startPage - b.startPage;
      return 0;
    })
    .map((c, i) => ({ ...c, index: i }));
};

export const convertISO8601ToSeconds = (input) => {
  var reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
  var hours = 0,
    minutes = 0,
    seconds = 0,
    totalseconds;

  if (reptms.test(input)) {
    var matches = reptms.exec(input);
    if (matches[1]) hours = Number(matches[1]);
    if (matches[2]) minutes = Number(matches[2]);
    if (matches[3]) seconds = Number(matches[3]);
    totalseconds = hours * 3600 + minutes * 60 + seconds;
  }

  return totalseconds;
};

/**
 * Given an error object from the API it shows a toast with the error message
 * @param {Error} err - the error object
 */
export const toastApiError = (err) => {
  if (!err.data?.code && !err.code) toastError(te("general_error"));
  else toastError(te(err.data?.code ?? err.code));
};

/**
 * Given an error object from the API it returns the error message
 * @param {Error} err - the error object
 * @returns {string} - the error message
 */
export const getErrorMessageTranslation = (err) => {
  if (!err.data?.code) return te("general_error");
  else return te(err.data.code);
};

//Given the period from 0 to duration and an array of intervals {startSecond, endSecond}
//return the total number of different seconds seen by the student
export const getSeenSeconds = (duration, intervals) => {
  let seenSeconds = 0;
  let seenSecondsArray = new Array(duration).fill(0);
  intervals.forEach((interval) => {
    //Convert start and end seconds to integers
    const start = parseInt(interval.start);
    const end = parseInt(interval.end);
    for (let i = start; i < end; i++) {
      seenSecondsArray[i] = 1;
    }
  });
  seenSecondsArray.forEach((seen) => {
    if (seen === 1) seenSeconds++;
  });
  return seenSeconds;
};

//Give the pages from 0 to duration and an array of intervals {page, time}
//return the total number of different pages seen by the student
export const getSeenPages = (duration, intervals) => {
  let seenPages = 0;
  let seenPagesArray = new Array(duration).fill(0);
  intervals.forEach(({ page, time }) => {
    if (time >= 10) seenPagesArray[page] = 1;
  });
  seenPagesArray.forEach((seen) => {
    if (seen === 1) seenPages++;
  });
  return seenPages;
};

export const getTotalSeconds = (lecture, intervals) => {
  return intervals.reduce((acc, interval) => {
    if (lecture.type === "video") return acc + (interval.end - interval.start);
    else if (lecture.type === "doc") return acc + interval.time;
    return 0;
  }, 0);
};

export const reactionsEnum = {
  0: "fas fa-circle text-muted fs-4",
  1: "fas fa-dizzy text-danger fs-4",
  2: "fas fa-meh text-warning fs-4",
  3: "fas fa-smile text-success fs-4",
};

/**
 * Given a number n and an alpha value returns an array of n colors in rgba format where the first one is red, the middle one is yellow and the last one is green and the other colors are nuances.
 * @param {number} n - the number of colors
 * @param {number} alpha - the alpha parameter from 0 to 1
 * @returns {Array<string>} - the array of colors
 */
export const getRGBAColors = (n, alpha) => {
  if (n < 3) {
    throw new Error("Number of colors should be at least 3.");
  }

  const colors = [];

  // Calculate color step for nuances
  const step = Math.floor(255 / (n - 2));

  // Generate red to yellow colors
  for (let i = 0; i < Math.floor(n / 2); i++) {
    const red = 255;
    const green = i * step;
    const blue = 0;
    const color = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    colors.push(color);
  }

  // Generate yellow to green colors
  for (let i = Math.floor(n / 2); i < n - 1; i++) {
    const red = 255 - (i - Math.floor(n / 2)) * step;
    const green = 255;
    const blue = 0;
    const color = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    colors.push(color);
  }

  // Add the last green color
  colors.push(`rgba(0, 255, 0, ${alpha})`);

  return colors;
};

/**
 * Given a number n returns an array of n colors in rgb format where the first one is red, the middle one is yellow and the last one is green and the other colors are nuances.
 * @param {number} n - the number of colors
 * @returns {Array<string>} - the array of colors
 */
export const getRGBColors = (n) => {
  if (n < 3) {
    throw new Error("Number of colors should be at least 3.");
  }

  const colors = [];

  // Calculate color step for nuances
  const step = Math.floor(255 / (n - 2));

  // Generate red to yellow colors
  for (let i = 0; i < Math.floor(n / 2); i++) {
    const red = 255;
    const green = i * step;
    const blue = 0;
    const color = `rgb(${red}, ${green}, ${blue})`;
    colors.push(color);
  }

  // Generate yellow to green colors
  for (let i = Math.floor(n / 2); i < n - 1; i++) {
    const red = 255 - (i - Math.floor(n / 2)) * step;
    const green = 255;
    const blue = 0;
    const color = `rgb(${red}, ${green}, ${blue})`;
    colors.push(color);
  }

  // Add the last green color
  colors.push(`rgb(0, 255, 0)`);

  return colors;
};

/**
 * Given a number n returns an array of n colors in hex format where the first one is red, the middle one is yellow and the last one is green and the other colors are nuances.
 * @param {number} n - the number of colors
 * @returns {Array<string>} - the array of colors
 */
export const getHexColors = (n) => {
  if (n < 3) {
    throw new Error("Number of colors should be at least 3.");
  }

  const colors = [];

  // Calculate color step for nuances
  const step = Math.floor(255 / (n - 2));

  // Generate red to yellow colors
  for (let i = 0; i < Math.floor(n / 2); i++) {
    const red = 255;
    const green = i * step;
    const blue = 0;
    const color = `#${rgbToHex(red)}${rgbToHex(green)}${rgbToHex(blue)}`;
    colors.push(color);
  }

  // Generate yellow to green colors
  for (let i = Math.floor(n / 2); i < n - 1; i++) {
    const red = 255 - (i - Math.floor(n / 2)) * step;
    const green = 255;
    const blue = 0;
    const color = `#${rgbToHex(red)}${rgbToHex(green)}${rgbToHex(blue)}`;
    colors.push(color);
  }

  // Add the last green color
  colors.push(`#00ff00`);

  return colors;
};

export const rgbToHex = (rgb) => {
  const hex = rgb.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};
