import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/PageElements/Navbar";
import { toastSuccess } from "../../utils/Toast";
import * as SharedStyles from "../../styles/Shared.styles";
import * as FormStyles from "../../styles/Forms.styles";
import Loading from "../../components/PageElements/Loading";
import { useForgotPasswordMutation } from "../../store/auth/auth.api";
import { getErrorMessageTranslation } from "../../utils/lecture.utils";

const ForgotPasswordPage = () => {
  /* Translator function */
  const { t } = useTranslation("", { keyPrefix: "auth.forgot_password" });
  /* Redirect hook */
  const navigate = useNavigate();

  /* Queries */
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  /* Component state */
  const [errorMsg, setErrorMsg] = useState("");

  /* API call */
  const forgotPasswordHandler = (email) => {
    forgotPassword(email)
      .unwrap()
      .then(() => {
        navigate("/login");
        toastSuccess("Email sent correctly");
      })
      .catch((err) => {
        setErrorMsg(getErrorMessageTranslation(err));
      });
  };

  //If the page is loading, show the loading page
  if (isLoading) return <Loading page={true} />;
  return (
    <SharedStyles.PageContainer
      dark
      fluid
      fill="true"
      className="d-flex flex-column"
    >
      <Row>
        <Navbar />
      </Row>
      <Container className="m-auto">
        <Col
          xl={4}
          lg={5}
          md={8}
          sm={10}
          className="d-flex flex-column flex-grow-1 justify-content-center"
        >
          <SharedStyles.Title
            dark
            className="fs-1 fw-bolder p-2 text-uppercase"
          >
            {t("title")}
          </SharedStyles.Title>
          <SharedStyles.Paragraph dark className="w-100 text-center mb-0">
            {t("subtitle")}
          </SharedStyles.Paragraph>
          <ForgotPasswordForm
            forgotPasswordHandler={forgotPasswordHandler}
            errorMsg={errorMsg}
          />
        </Col>
      </Container>
    </SharedStyles.PageContainer>
  );
};

const ForgotPasswordForm = ({ forgotPasswordHandler, errorMsg }) => {
  /* Translator function */
  const { t } = useTranslation("", { keyPrefix: "auth.forgot_password" });
  /* Component state */
  const [email, setEmail] = useState("");

  /* Submit handler */
  const submitHandler = (e) => {
    //Prevent default form submit
    e.preventDefault();

    //Call the forgot password handler
    forgotPasswordHandler(email);
  };

  return (
    <Form className="p-2" onSubmit={submitHandler}>
      <Form.Group className="my-3">
        <FormStyles.FormLabel dark htmlFor="forgot-email">
          {t("email")}
        </FormStyles.FormLabel>
        <FormStyles.FormControl
          type="email"
          id="forgot-email"
          placeholder={t("email")}
          className=" fs-5"
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
      <FormStyles.EvoliButton type="submit" className="mt-4 w-100 fs-5">
        {t("send")}
      </FormStyles.EvoliButton>
      {errorMsg && <FormStyles.Error>{errorMsg}</FormStyles.Error>}
      <SharedStyles.Paragraph dark className="w-100 text-center mt-1 mb-0">
        {t("no_account")} <Link to="/register">{t("sign_up")}</Link>
      </SharedStyles.Paragraph>
    </Form>
  );
};

export default ForgotPasswordPage;
