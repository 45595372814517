import styled from "styled-components";
import { EvoliButton } from "../../styles/Forms.styles";

const SearchBar = ({ placeholder, onChange, onSubmit, inputRef }) => {
  return (
    <SSearchBar onSubmit={onSubmit}>
      <input
        ref={inputRef}
        type="text"
        className="search-input"
        placeholder={placeholder}
        onChange={onChange}
        maxLength="50"
      />
      <EvoliButton type="submit" className="search-icon">
        <i className="fa fa-search" />
      </EvoliButton>
    </SSearchBar>
  );
};

export default SearchBar;

const SSearchBar = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0;

  .search-input {
    border-radius: 0.375rem 0 0 0.375rem;
    padding-left: 10px;
    height: 2.3rem;
    border: 1px solid ${(props) => props.theme.secondary};
    border-right: none;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  .search-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 2.3rem;
    background-color: ${(p) => p.theme.accent};
    border: 1px solid ${(props) => props.theme.primary};
    border-left: none;
    height: 2.3rem;
    border-radius: 0 1rem 1rem 0;
    color: ${(p) => p.theme.textPrimary};
    cursor: pointer;
  }
`;
