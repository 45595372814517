import React from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { EvoliButton } from "../../../../styles/Forms.styles";
import { useCalculateAllBIMutation } from "../../../../store/admin/admin.api";
import { PacmanLoader } from "react-spinners";

const AdminBI = () => {
  const [calculateAllBI, { isError, isLoading, isSuccess, data }] =
    useCalculateAllBIMutation();

  const handleCalculateAllBI = () => {
    calculateAllBI();
  };

  return (
    <Container className="py-5">
      <Row>
        <Col>
          {/* Center the div */}
          <div className="d-flex flex-column justify-content-center gap-3">
            <EvoliButton
              className="p-4"
              size="lg"
              variant="warning"
              disabled={isLoading || isError}
              onClick={handleCalculateAllBI}
            >
              {isLoading ? (
                <PacmanLoader />
              ) : (
                <span className="fw-bolder">CALCULATE ALL BI</span>
              )}
            </EvoliButton>
            {/* Display the number of errors */}
            {isSuccess && (
              <div>
                <h2>BI Process Result</h2>
                {data?.totalErrors === 0 ? (
                  <Alert variant="success">BI calculated successfully</Alert>
                ) : (
                  <>
                    <Alert variant="danger">
                      {data?.totalErrors ?? "/"} errors found
                    </Alert>
                    <Alert variant="danger">
                      {data?.courseErrors ?? "/"} course errors found
                    </Alert>
                    <Alert variant="danger">
                      {data?.unitErrors ?? "/"} unit errors found
                    </Alert>
                    <Alert variant="danger">
                      {data?.lessonErrors ?? "/"} lesson errors found
                    </Alert>
                  </>
                )}
              </div>
            )}
          </div>
          {/* Handle error in api call */}
          {isError && (
            <Alert variant="danger">
              There was an error while processing the BI
            </Alert>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AdminBI;
