import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/api.slice";

const enrollmentsAdapter = createEntityAdapter({
  //The enrollment id is the uuid
  selectId: (enrollment) => enrollment.course.uuid,
});

const initialState = enrollmentsAdapter.getInitialState();

export const studentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //Enroll to a course with a given uuid
    createEnrollment: builder.mutation({
      query: (uuid) => ({
        url: `/enrollments/${uuid}`,
        method: "POST",
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: [{ type: "Enrollments", id: "LIST" }, "Teachers"],
    }),
    //Get course by code
    getCourseByCode: builder.query({
      query: (code) => `/enrollments/courses/${code}`,
      transformResponse: (response) => response.data,
    }),
    //Retrieve the list of courses followed by the current user
    getEnrollments: builder.query({
      query: ({
        limit = 12,
        offset = 0,
        order = "ASC",
        orderBy = "name",
        teacherEmail = "all",
        searchQuery = null,
      }) => {
        return `/enrollments/${limit}/${offset}/${order}/${orderBy}/${teacherEmail}/${
          searchQuery || ""
        }`;
      },
      transformResponse: (response) => {
        // {count, enrollments} = response.data;
        enrollmentsAdapter.setAll(initialState, response.data.enrollments);
        return response.data;
      },
      providesTags: [{ type: "Enrollments", id: "LIST" }],
    }),
    //Get enrollments details with a given uuid
    getEnrollmentDetails: builder.query({
      query: (uuid) => `/enrollments/${uuid}`,
      transformResponse: (response) => response.data,
      providesTags: [{ type: "Enrollments", id: "Selected" }],
    }),
    //Get lesson details with a given uuid
    getLessonDetails: builder.query({
      query: (uuid) => `/enrollments/lesson/${uuid}`,
      transformResponse: (response) => response.data,
      providesTags: (result, error, uuid) => [{ type: "Lesson", id: uuid }],
      invalidatesTags: ["NextLesson", "PrevLesson"],
    }),
    //Get teachers of the student's course enrollments
    getTeachers: builder.query({
      query: () => "/enrollments/teachers",
      transformResponse: (response) => response.data,
      providesTags: ["Teachers"],
    }),
    //Get the next lesson of a given one
    getNextLesson: builder.query({
      query: (uuid) => `/enrollments/next-lesson/${uuid}`,
      transformResponse: (response) => response.data,
      providesTags: ["NextLesson"],
    }),
    //Get the previous lesson of a given one
    getPrevLesson: builder.query({
      query: (uuid) => `/enrollments/prev-lesson/${uuid}`,
      transformResponse: (response) => response.data,
      providesTags: ["PrevLesson"],
    }),
    //Search for courses
    searchCourses: builder.query({
      query: ({ limit = 12, offset = 0, searchQuery = "" }) =>
        `/enrollments/search/${limit}/${offset}${
          searchQuery && "/" + searchQuery
        }`,
      transformResponse: (response) => response.data,
    }),
    //Unenroll from a course with a given uuid
    unenroll: builder.mutation({
      query: (uuid) => ({
        url: `/enrollments/${uuid}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Enrollments", id: "LIST" }, "Teachers"],
    }),
  }),
});

//Expose the api
export const {
  useCreateEnrollmentMutation,
  useLazyGetCourseByCodeQuery,
  useGetEnrollmentsQuery,
  useGetEnrollmentDetailsQuery,
  useGetLessonDetailsQuery,
  useGetTeachersQuery,
  useGetPrevLessonQuery,
  useGetNextLessonQuery,
  useSearchCoursesQuery,
  useUnenrollMutation,
} = studentApiSlice;

//Returns the entire query result object
const selectEnrollmentsResult =
  studentApiSlice.endpoints.getEnrollments.select();

//Create momoized selector
const selectEnrollmentsData = createSelector(
  selectEnrollmentsResult,
  (result) => result.data //Normalized state object with ids and entities
);

export const {
  selectAll: selectAllEnrollments,
  selectById: selectEnrollmentByCourseUUID,
  //Pass in a selector that returns the courses slice of the state
} = enrollmentsAdapter.getSelectors(
  (state) => selectEnrollmentsData(state) ?? initialState
);
