import React from "react";
import styled from "styled-components";

const TextArea = ({ value = "", maxLength, ...props }) => {
  return (
    <>
      <Styles.TextArea {...props} maxLength={maxLength} value={value} />
      {/* Character count */}
      {maxLength && (
        <div className="d-flex justify-content-end">
          <span className="text-muted fst-italic">
            {value.length}/{maxLength}
          </span>
        </div>
      )}
    </>
  );
};

export default TextArea;

const Styles = {};

Styles.TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  box-shadow: none;
  padding: 0.5em 1.5em;
  background-color: ${(p) => p.theme.textPrimary};
  color: ${(p) => p.theme.primary};
  border: 3px solid ${(p) => p.theme.primary};
  border-radius: 2rem;

  &::placeholder {
    text-transform: uppercase;
    color: ${(p) => p.theme.secondary};
  }
`;
