import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import * as FormStyles from "../../../../styles/Forms.styles";
import { useTranslation } from "react-i18next";

import { selectCurrentUser } from "../../../../store/auth/auth.slice";
import { toastSuccess } from "../../../../utils/Toast";
import { getErrorMessageTranslation } from "../../../../utils/lecture.utils";
import { useUpdateProfileMutation } from "../../../../store/auth/auth.api";
import { useSelector } from "react-redux";
import Loading from "../../../../components/PageElements/Loading";

const EditProfile = () => {
  /* Redux state */
  const user = useSelector(selectCurrentUser);

  /* Component state */
  const [updateProfileErrorMsg, setUpdateProfileErrorMsg] = useState("");

  /* Queries */
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  /* Handler for the update profile action */
  const updateProfileHandler = (firstName, lastName) => {
    updateProfile({ firstName, lastName })
      .unwrap()
      .then((_) => {
        toastSuccess("User info changed correctly");
        setUpdateProfileErrorMsg("");
      })
      .catch((err) => {
        setUpdateProfileErrorMsg(getErrorMessageTranslation(err));
      });
  };

  //If the page is loading, show the loading page
  if (isLoading) return <Loading />;
  return (
    <Container className="py-5">
      <Row>
        <Col xs={12} md={6}>
          <UpdateProfileForm
            user={user}
            updateProfileHandler={updateProfileHandler}
            updateProfileErrorMsg={updateProfileErrorMsg}
          />
        </Col>
      </Row>
    </Container>
  );
};

const UpdateProfileForm = ({
  user,
  updateProfileHandler,
  updateProfileErrorMsg,
}) => {
  /* Translator function */
  const { t } = useTranslation("", { keyPrefix: "auth.profile" });

  //Every time the user changes, update the state
  useEffect(() => {
    setEmail(user.email);
    setFirstName(user.firstName);
    setLastName(user.lastName);
  }, [user]);

  /* Component state */
  const [email, setEmail] = useState(user.email);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);

  //Submit form handler
  const submitHandler = (e) => {
    //Prevent page refresh
    e.preventDefault();

    //Call the changeProfileHandler function
    updateProfileHandler(firstName, lastName);
  };

  return (
    <Form onSubmit={submitHandler}>
      <Form.Group className="mb-4">
        <FormStyles.FormLabel dark htmlFor="profile-email">
          {t("email")}
        </FormStyles.FormLabel>
        <FormStyles.FormControl
          id="profile-email"
          className="fs-4 text-dark"
          type="email"
          defaultValue={email}
          disabled
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <FormStyles.FormLabel dark htmlFor="profile-first-name">
          {t("first_name")}
        </FormStyles.FormLabel>
        <FormStyles.FormControl
          id="profile-first-name"
          className="fs-4"
          type="text"
          placeholder={t("first_name")}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <FormStyles.FormLabel dark htmlFor="profile-last-name">
          {t("last_name")}
        </FormStyles.FormLabel>
        <FormStyles.FormControl
          id="profile-last-name"
          className="fs-4"
          type="text"
          placeholder={t("last_name")}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Form.Group>
      <FormStyles.EvoliButton type="submit" className="mt-4 w-100 fs-5">
        {t("change_name")}
      </FormStyles.EvoliButton>
      {updateProfileErrorMsg && (
        <FormStyles.Error className="mt-3">
          {updateProfileErrorMsg}
        </FormStyles.Error>
      )}
    </Form>
  );
};

export default EditProfile;
