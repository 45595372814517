import React, { useContext } from "react";
import { Scatter } from "react-chartjs-2";
import { toHHMMSS } from "../../../../../utils/lecture.utils";
import { ThemeContext } from "../../../../../styles/ThemeContext";

const PausesScatterPlot = ({ start, end, pauses }) => {
  /* Load theme from Theme Context */
  const { theme } = useContext(ThemeContext);
  const duration = end - start;

  const data = {
    datasets: [
      {
        label: "Pauses",
        data: pauses.map((pause) => ({ x: pause - start, y: 0 })),
        backgroundColor: `rgba(${theme.accentRGB},0.4)`,
        borderColor: `rgba(${theme.accentRGB},1)`,
        pointRadius: 8,
        pointHoverRadius: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "linear",
        beginAtZero: true,
        min: 0,
        max: duration,
        ticks: {
          beginAtZero: true,
          color: theme.primary,
          callback: (value) => toHHMMSS(value),
        },
        grid: {
          display: true,
          borderDash: [1, 1],
          color: theme.primary,
        },
        title: {
          display: true,
          text: "Video seconds",
          color: theme.primary,
        },
      },
      //No y axis
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
        /* Edit the title value to x the HH:MM:SS of the x */
        callbacks: {
          label: (tooltipItem) => toHHMMSS(tooltipItem.parsed.x),
        },
      },
      zoom: {
        limits: {
          x: { min: 0, max: duration, minRange: 1 },
        },
        pan: {
          enabled: true, // SET PINCH ZOOM TO TRUE
          mode: "x",
          scaleMode: "x",
        },
        zoom: {
          wheel: {
            enabled: true, // SET SCROOL ZOOM TO TRUE
          },
          pinch: {
            enabled: true, // SET PINCH ZOOM TO TRUE
          },
          mode: "x",
          speed: 10,
        },
      },
    },
  };

  return <Scatter data={data} options={options} />;
};

export default PausesScatterPlot;
