import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AppNavbar from "../../components/PageElements/Navbar";
import { Container } from "react-bootstrap";
import Footer from "../../components/PageElements/Footer";
import Styles from "./styles";
import BackToTop from "../../components/PageElements/BackToTop";

const TeacherHome = () => {
  //Redirect hook
  const navigate = useNavigate();
  //Location hook
  const location = useLocation();

  const [active, setActive] = useState(1); //1 for my enrollments, 2 for all courses

  const handleMenuClick = (id) => {
    setActive(id);
    if (id === 1) {
      navigate("/teacher/home/courses");
    } else if (id === 2) {
      navigate("/teacher/home/new-course");
    }
  };

  useEffect(() => {
    if (location.pathname === "/teacher/home/courses") {
      setActive(1);
    } else if (location.pathname === "/teacher/home/new-course") {
      setActive(2);
    }
  }, [location]);

  return (
    <>
      <AppNavbar dark sticky="true" />
      <Styles.CoursesContainer>
        <Styles.Strip>
          <Container>
            <h1 className="title">My Teachings</h1>
            <div className="menu">
              <div
                className={`menu-item ${active === 1 && "active"}`}
                onClick={() => handleMenuClick(1)}
              >
                My courses
              </div>
              <div
                className={`menu-item ${active === 2 && "active"}`}
                onClick={() => handleMenuClick(2)}
              >
                Create course
              </div>
            </div>
          </Container>
        </Styles.Strip>
        <Outlet />
      </Styles.CoursesContainer>
      <Footer />
      <BackToTop />
    </>
  );
};

export default TeacherHome;
