import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectCurrentToken,
  selectCurrentUser,
} from "../store/auth/auth.slice";

const RedirectToHomeIfLogged = () => {
  const token = useSelector(selectCurrentToken);
  const role = useSelector((state) => state.auth.role);
  const user = useSelector(selectCurrentUser);

  const privacyPolicyAccepted = user?.privacyPolicy;

  const redirectTo = !privacyPolicyAccepted
    ? "/account/accept-privacy-policy"
    : role === "student"
    ? "/student/home/enrollments"
    : "/teacher/home/courses";

  return !token ? <Outlet /> : <Navigate to={redirectTo} replace />;
};

export default RedirectToHomeIfLogged;
