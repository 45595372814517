import React from "react";
import Navbar from "../../components/PageElements/Navbar";
import PageContainer from "../../components/PageElements/PageContainer";
import Header from "../../components/PageElements/Header";
import { Container } from "react-bootstrap";
import Footer from "../../components/PageElements/Footer";
import BackToTop from "../../components/PageElements/BackToTop";
import useMenu from "./hooks/useMenu";
import { Outlet } from "react-router-dom";

const PrivacyPolicy = () => {
  const { active, handleMenuClick, links } = useMenu();

  return (
    <>
      <Navbar dark={true} />
      <PageContainer>
        <Header>
          <Container>
            <Header.Title>Privacy Policy</Header.Title>
            <Header.Menu>
              {links.map((link, index) => (
                <Header.MenuItem
                  key={index}
                  className={active === index && "active"}
                  onClick={() => handleMenuClick(index)}
                >
                  {link.name}
                </Header.MenuItem>
              ))}
            </Header.Menu>
          </Container>
        </Header>
        <Outlet />
      </PageContainer>
      <Footer />
      <BackToTop />
    </>
  );
};

export default PrivacyPolicy;
