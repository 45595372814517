import React, { useState, useRef } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ClipLoader from "react-spinners/ClipLoader";
import indexLogo from "../assets/images/index.svg";
import anytimeImg from "../assets/images/service1.png";
import feedbackImg from "../assets/images/service2.png";
import whoWatchedImg from "../assets/images/service3.png";
import messagesImg from "../assets/images/service4.png";
import hocLabImg from "../assets/images/hocLab.png";
import blendedImg from "../assets/images/blendedLearning.png";
import logoPolimi from "../assets/images/logoPolimi.png";
import evoliLogo from "../assets/images/evoli.png";
import Navbar from "../components/PageElements/Navbar";
import * as FormStyles from "../styles/Forms.styles";
import { useSendContactMessageMutation } from "../store/auth/auth.api";
import { toastApiError } from "../utils/lecture.utils";
import { toastSuccess } from "../utils/Toast";
import Footer from "../components/PageElements/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChalkboardTeacher,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from "react-player";
import BackToTop from "../components/PageElements/BackToTop";

const IndexPage = () => {
  /* Translator function */
  const { t } = useTranslation("", { keyPrefix: "landing" });

  //Queries
  const [sendContactMessage, { isLoading: isSendingMessage }] =
    useSendContactMessageMutation();

  /* Component state */
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const aboutRef = useRef(null);
  const featuresRef = useRef(null);
  const contactFormRef = useRef(null);

  const isSendMessageDisabled =
    name.trim().length === 0 ||
    email.trim().length === 0 ||
    subject.trim().length === 0 ||
    message.trim().length === 0;

  const submitContactFormHandler = (e) => {
    e.preventDefault();

    if (!isSendMessageDisabled) {
      sendContactMessage({ name, email, subject, message })
        .unwrap()
        .then(() => {
          contactFormRef.current?.reset();
          toastSuccess(t("contact.success"));
        })
        .catch((err) => {
          toastApiError(err);
        });
    }
  };

  return (
    <>
      <Navbar sticky />
      <Hero>
        <Container>
          <Row>
            <Col
              lg={6}
              md={12}
              className="d-flex flex-column justify-content-center align-items-center pb-5"
            >
              <img className="evoli-logo" src={evoliLogo} alt="EVOLI" />
              <h2 className="px-4 mt-0">{t("logo.slogan")}</h2>
            </Col>
            <Col
              lg={6}
              md={12}
              className="d-flex flex-column align-items-center pb-5"
            >
              <img src={indexLogo} alt="Polimi" className="animated" />
            </Col>
          </Row>
        </Container>
      </Hero>

      <About ref={aboutRef}>
        <Container>
          <Row className="d-flex align-items-center">
            <Col lg={6} className="d-flex flex-column align-items-center">
              <img src={blendedImg} alt="What is Evoli" />
            </Col>
            <Col lg={6} className="pt-4 pt-lg-0 content">
              <SectionTitle>{t("strip.title")}</SectionTitle>
              <p>
                <FontAwesomeIcon
                  icon={faUserGraduate}
                  size="lg"
                  className="me-2"
                />
                Evoli is a video annotation tool that collects both explicit
                feedback from students and log analytics. It is designed to
                support video-based learning activities, where students watch
                videos, ask questions and provide feedback to the teacher.
              </p>
              <p>
                <FontAwesomeIcon
                  icon={faChalkboardTeacher}
                  size="lg"
                  className="me-2"
                />
                Based on these, Evoli gives to the teacher a measure of the
                students’ engagement through structured learning dashboards.
              </p>
            </Col>
          </Row>
        </Container>
      </About>

      <Services ref={featuresRef}>
        <Container id="services">
          <Row>
            <SectionTitle>{t("services.title")}</SectionTitle>
          </Row>
          <Row>
            <Col lg={6} className="service">
              <ServiceCard>
                <img src={anytimeImg} alt="anytime" />
                <h2>{t("services.service1.title")}</h2>
                <p>{t("services.service1.description")}</p>
              </ServiceCard>
            </Col>
            <Col lg={6} className="service">
              <ServiceCard>
                <img src={feedbackImg} alt="feedback" />
                <h2>{t("services.service2.title")}</h2>
                <p>{t("services.service2.description")}</p>
              </ServiceCard>
            </Col>
            <Col lg={6} className="service">
              <ServiceCard>
                <img src={whoWatchedImg} alt="who watched" />
                <h2>{t("services.service3.title")}</h2>
                <p>{t("services.service3.description")}</p>
              </ServiceCard>
            </Col>
            <Col lg={6}>
              <ServiceCard className="service">
                <img src={messagesImg} alt="messages" />
                <h2>{t("services.service4.title")}</h2>
                <p>{t("services.service4.description")}</p>
              </ServiceCard>
            </Col>
          </Row>
        </Container>
      </Services>

      {/* YouTube video */}
      <Strip fluid>
        <Container className="py-4">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <PlayerWrapper>
              <ReactPlayer
                className="react-player"
                url="https://www.youtube.com/watch?v=JP8H3QlcQG8&t=1s&ab_channel=EVOLI"
                width="100%"
                height="100%"
              />
            </PlayerWrapper>
          </div>
        </Container>
      </Strip>

      <Contact>
        <Container id="contact">
          <Row>
            <SectionTitle>{t("contact.title")}</SectionTitle>
          </Row>
          <Row>
            <Container>
              <Form ref={contactFormRef} onSubmit={submitContactFormHandler}>
                <Row>
                  <Col
                    lg={6}
                    xs={12}
                    className="d-flex flex-column justify-content-center align-items-center"
                  >
                    <FormStyles.FormControl
                      className="fs-4 mt-4"
                      type="text"
                      maxLength={50}
                      placeholder={t("contact.name")}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <FormStyles.FormControl
                      className="fs-4 mt-4"
                      type="email"
                      maxLength={50}
                      placeholder={t("contact.email")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <FormStyles.FormControl
                      className="fs-4 my-4"
                      type="text"
                      maxLength={100}
                      placeholder={t("contact.subject")}
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                    />
                  </Col>
                  <Col
                    lg={6}
                    xs={12}
                    className="d-flex flex-column flex-grow-1 justify-content-center"
                  >
                    <FormStyles.FormControl
                      className="fs-4 my-4"
                      maxLength={500}
                      as="textarea"
                      placeholder={t("contact.message")}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <div className="d-flex flex-row justify-content-center">
                    <FormStyles.EvoliButton
                      disabled={isSendMessageDisabled}
                      type="submit"
                      className="mt-4 mx-5 px-5 fs-5"
                    >
                      {isSendingMessage ? (
                        <ClipLoader size={20} />
                      ) : (
                        t("contact.send")
                      )}
                    </FormStyles.EvoliButton>
                  </div>
                </Row>
              </Form>
            </Container>
          </Row>
        </Container>
      </Contact>

      <Strip fluid>
        <Container>
          <Row>
            <Col className="d-flex flex-column justify-content-center align-items-center">
              <h2 className="mt-5">{t("who.title")}</h2>
              <p>{t("who.description")}</p>
              <div className="d-flex flex-row justify-content-center align-items-center mb-5">
                <a href="https://www.polimi.it/">
                  <img src={logoPolimi} alt="Polimi" className="mx-4" />
                </a>
                <a href="https://www.hoc.polimi.it/">
                  <img src={hocLabImg} alt="HOC lab" className="mx-4" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </Strip>

      {/* <FContainer className="mx-0 px-0" fluid>
        <Container className="py-4">
          <Row>
            <Col lg={4} xs={12} className="mb-2">
              <h4>{t("footer.project")}</h4>
              <p
                className="footer-ref"
                onClick={() => aboutRef.current.scrollIntoView()}
              >
                {t("footer.about")}
              </p>
              <p
                className="footer-ref"
                onClick={() => featuresRef.current.scrollIntoView()}
              >
                {t("footer.features")}
              </p>
            </Col>
            <Col lg={4} xs={12} className="mb-2">
              <h4>{t("footer.who_we_are")}</h4>
              <p
                className="footer-ref"
                onClick={() => contactFormRef.current.scrollIntoView()}
              >
                {t("footer.contact_us")}
              </p>
            </Col>
            <Col lg={4} xs={12} className="mb-2">
              <h4>{t("footer.conditions")}</h4>
              <Link to="#">
                <p>{t("footer.privacy_policy")}</p>
              </Link>
            </Col>
          </Row>
        </Container>
      </FContainer> */}
      <Footer />
      <BackToTop />
    </>
  );
};

const Hero = styled.section`
  display: flex;
  align-items: center;
  padding-top: 5vh;
  padding-bottom: 5vh;

  width: 100%;
  height: 80vh;

  .evoli-logo {
    height: 15vh;
    font-weight: 600;

    @media (max-width: 961px) {
      margin-top: 2rem;
    }
  }

  h2 {
    font-size: 5vh;
    font-style: italic;
    font-weight: 100;
    text-align: center;
    color: ${(p) => p.theme.primary};
  }

  button {
    font-size: 1.5rem;
    width: 12rem;
  }

  .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
  }

  @-webkit-keyframes up-down {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }

  @keyframes up-down {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
`;

const Strip = styled(Container)`
  background-color: ${(props) => props.theme.secondary};
  color: ${(p) => p.theme.textPrimary};

  h2 {
    text-transform: uppercase;
    font-weight: 700;
  }

  p {
    font-size: 1.2rem;
  }

  img {
    @media (max-width: 961px) {
      width: 30vw;
    }

    @media (min-width: 961px) {
      width: 15vw;
    }
  }
`;

const SectionTitle = styled.h3`
  font-weight: 700;
  font-size: 2rem;
`;

const About = styled.section`
  background-color: ${(p) => p.theme.primary};
  color: ${(p) => p.theme.textPrimary};
  padding-top: 10vh;
  padding-bottom: 10vh;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .content p {
    font-size: 1.2rem;
  }

  img {
    width: 80%;
  }
`;

const Services = styled.section`
  padding-top: 10vh;
  padding-bottom: 10vh;

  .service {
    margin: 10px 0 10px 0;
  }
`;

const ServiceCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  min-height: 28vh;
  padding: 3vh;
  margin: 0;

  background-color: ${(p) => p.theme.primary};
  color: ${(p) => p.theme.textPrimary};

  img {
    height: 6vh;
  }

  h2 {
    font-size: 2rem;
  }
`;

const PlayerWrapper = styled.div`
  position: relative;
  aspect-ratio: 16/9;
  height: 50vh;
  border-radius: 1rem;
  overflow: hidden;

  /* Put the player in the center */
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const Contact = styled.section`
  padding-top: 5vh;
  padding-bottom: 5vh;

  input {
    min-width: 32vw;
    margin: 0 auto 0 auto;
  }

  textarea {
    min-width: 40vw;
    height: 20rem;
    border-radius: 2em;
  }
`;

/* const FContainer = styled(Container)`
  background-color: ${(props) => props.theme.primary};
  margin: auto 0 auto 0 !important;
  color: ${(p) => p.theme.textPrimary};

  h4 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0;
  }

  p {
    margin: 0;
    color: ${(p) => p.theme.textPrimary};

    &.footer-ref {
      cursor: pointer;
    }
  }

  a {
    text-decoration: ${(p) => p.theme.textPrimary};
  }
`; */

export default IndexPage;
