import { apiSlice } from "../api/api.slice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //Login with email and password
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login",
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: [
        "Enrollments",
        { type: "Teachings", id: "LIST" },
        "UserStats",
      ],
    }),
    //Logout
    logout: builder.mutation({
      query: () => "/auth/logout",
    }),
    //Accept the privacy policy
    acceptPrivacyPolicy: builder.mutation({
      query: () => ({
        url: "/auth/accept-privacy-policy",
        method: "POST",
        body: {},
      }),
      transformResponse: (response) => response.data,
    }),
    //Disconnect from all devices
    disconnectFromAllDevices: builder.mutation({
      query: () => "/auth/disconnect",
    }),
    //Confirm account with a given confirmation code
    confirmAccount: builder.query({
      query: (confirmationCode) => ({
        url: `/auth/confirm/${confirmationCode}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
    }),
    //Request the reset password email
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: "/auth/forgotPassword",
        method: "POST",
        body: { email },
      }),
      transformResponse: (response) => response.data,
    }),
    //Update the user profile data (first name and last name)
    updateProfile: builder.mutation({
      query: (data) => ({
        url: "/auth/profile",
        method: "PATCH",
        body: {
          firstName: data.firstName,
          lastName: data.lastName,
        },
      }),
      transformResponse: (response) => response.data,
    }),
    //Update the user password
    updatePassword: builder.mutation({
      query: (data) => ({
        url: "/auth/password",
        method: "PATCH",
        body: {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        },
      }),
      transformResponse: (response) => response.data,
    }),
    //Register a new user
    register: builder.mutation({
      query: (data) => ({
        url: "/auth/register",
        method: "POST",
        body: {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          password: data.password,
          privacyAccepted: data.privacyAccepted,
        },
      }),
      transformResponse: (response) => response.data,
    }),
    //Reset password with a given reset password token
    resetPassword: builder.mutation({
      query: (data) => ({
        url: "/auth/resetPassword",
        method: "POST",
        body: {
          resetPasswordToken: data.resetPasswordToken,
          password: data.password,
        },
      }),
    }),
    //Send a contact message
    sendContactMessage: builder.mutation({
      query: (data) => ({
        url: "/auth/contact",
        method: "POST",
        body: {
          name: data.name,
          email: data.email,
          subject: data.subject,
          message: data.message,
        },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useAcceptPrivacyPolicyMutation,
  useDisconnectFromAllDevicesMutation,
  useLazyConfirmAccountQuery,
  useForgotPasswordMutation,
  useUpdateProfileMutation,
  useUpdatePasswordMutation,
  useRegisterMutation,
  useResetPasswordMutation,
  useSendContactMessageMutation,
} = authApiSlice;
