import React from "react";
import { useTranslation } from "react-i18next";
import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import AppNavbar from "../../components/PageElements/Navbar";
import PageContainer from "../../components/PageElements/PageContainer";
import Footer from "../../components/PageElements/Footer";
import { EvoliButton } from "../../styles/Forms.styles";
import BackToTop from "../../components/PageElements/BackToTop";

const NotFoundPage = () => {
  // Translator hook
  const { t } = useTranslation("", { keyPrefix: "error.404" });
  // Redirect hook
  const navigate = useNavigate();

  return (
    <>
      <AppNavbar />
      <PageContainer min={false}>
        <Styles.ErrorContainer>
          <i className="maintenance-icon fas fa-exclamation-triangle" />
          <h1 className="title">{t("title")}</h1>
          <p>{t("description")}</p>
          <EvoliButton
            className="text-center text-uppercase"
            size="md"
            onClick={() => navigate("/")}
          >
            {t("continue")}
          </EvoliButton>
        </Styles.ErrorContainer>
      </PageContainer>
      <Footer />
      <BackToTop />
    </>
  );
};

export default NotFoundPage;
