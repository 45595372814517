import React, { useState, useRef } from "react";
import ReactPlayer from "react-player/youtube";
import { useDispatch, useSelector } from "react-redux";
import {
  pause,
  play,
  resetPlayer,
  seekTo,
  selectGetCurrentVideoSeconds,
  selectPlaybackRate,
  selectPlaying,
  selectVolume,
  setPlayingInterval,
  setSliderValue,
} from "../../../../store/media/media.slice";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import useKeyboard from "./hooks/useKeyboard";
import PlayerControls from "./components/PlayerControls/PlayerControls";
import { useOutletContext, useParams } from "react-router-dom";
import Styles from "./styles";

const Video = () => {
  const { lesson } = useOutletContext();
  const params = useParams();
  const seekSecond = params.seekTo ?? 0;

  //Redux state
  const playing = useSelector(selectPlaying);

  //Component state
  const [started, setStarted] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const playerRef = useRef(null);

  const mouseEnterHandler = () => {
    if (playing && started) setShowControls(true);
  };

  const mouseLeaveHandler = () => {
    if (playing && started) setShowControls(false);
  };

  return (
    <Styles.VideoWrapper
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <Player
        lesson={lesson}
        playerRef={playerRef}
        setShowControls={setShowControls}
        seekSecond={seekSecond}
        started={started}
        setStarted={setStarted}
      />
      {playerRef.current &&
        started &&
        (!playing || (playing && showControls)) && (
          <PlayerControls lesson={lesson} />
        )}
    </Styles.VideoWrapper>
  );
};

const Player = ({
  lesson,
  setShowControls,
  playerRef,
  seekSecond = 0,
  started,
  setStarted,
}) => {
  //Dispatch hook
  const dispatch = useDispatch();

  const start = lesson.startSecond;
  const end = lesson.endSecond;
  //Youtube player config
  const config = {
    youtube: {
      height: "100%",
      playerVars: {
        autoplay: seekSecond ? 1 : 0, //Don't autoplay
        controls: 0, //Don't show controls
        disablekb: 1, //Disable keyboard controls
        fs: 0, //Disable fullscreen
        modestbranding: 1, //Hide youtube logo
        rel: 0, //Don't show related videos
        showinfo: 0, //Don't show video info
        start: start, //Start at the start second
        end: end, //End at the end second
      },
    },
  };

  //Redux state
  const getVideoSecond = useSelector(selectGetCurrentVideoSeconds);
  const playing = useSelector(selectPlaying);
  const volume = useSelector(selectVolume);
  const playbackRate = useSelector(selectPlaybackRate);

  const onReady = () => {
    //When the player is ready to play then register it in the store
    dispatch(resetPlayer(playerRef.current));
    //Seek to the start second
    dispatch(setPlayingInterval({ startSecond: lesson, endSecond: end }));
    //Seek to the start second
    dispatch(seekTo(seekSecond));
    //Play the video
    dispatch(play());
  };

  const onPlay = () => {
    //Since if the user click on the player and not on the play button, the video will start playing, but the playing variable is not changed, we need to change it manually
    dispatch(play());
  };

  //When the video is paused, log the event
  const onPause = () => {
    //Since if the user click on the player and not on the pause button, the video will pause, but the playing variable is not changed, we need to change it manually
    dispatch(pause());
    if (started) setShowControls(true);
  };

  //Video playing function
  const onProgress = () => {
    //Get and update video time
    const currentSecond = getVideoSecond();
    //If the video is playing and the current time is lower than the start second, pause the video
    if (currentSecond < start) {
      dispatch(pause());
      dispatch(seekTo(start));
      //If the video is playing and the current time is greater than the end second, pause the video
    } else if (currentSecond >= end) {
      dispatch(pause());
      dispatch(seekTo(end));
      //If the video is playing and the current time is between the start and end seconds, update the video time
    } else {
      if (!isNaN(currentSecond))
        dispatch(setSliderValue(currentSecond - start));
    }
  };

  //When the video starts, set started to true
  const onStart = () => {
    //If the video is not started, set started to true
    if (!started) setStarted(true);
  };

  //Keyboard listener
  useKeyboard({ started, lesson });

  return (
    <ReactPlayer
      ref={playerRef}
      className="react-player"
      width="100%"
      height="100%"
      url={`https://www.youtube.com/watch?v=${lesson.videoId}`}
      playing={playing}
      controls={false}
      light={seekSecond ? false : true}
      volume={volume / 100}
      progressInterval={200}
      playbackRate={playbackRate}
      config={config}
      onStart={onStart}
      onReady={onReady}
      onPause={onPause}
      onPlay={onPlay}
      onProgress={onProgress}
      onClickPreview={() => setShowControls(true)}
    />
  );
};

export default Video;
