import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../../components/PageElements/Navbar";
import * as SharedStyles from "../../../styles/Shared.styles";
import * as FormStyles from "../../../styles/Forms.styles";
import Loading from "../../../components/PageElements/Loading";
import { PasswordField } from "../../../components/Commons/Form";
import { useLoginMutation } from "../../../store/auth/auth.api";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../../store/auth/auth.slice";
import { getErrorMessageTranslation } from "../../../utils/lecture.utils";
import PageContainer from "../../../components/PageElements/PageContainer";
import Footer from "../../../components/PageElements/Footer";
import Header from "../../../components/PageElements/Header";
import Styles from "./styles";
import BackToTop from "../../../components/PageElements/BackToTop";

const LoginPage = () => {
  /* Translator function */
  const { t } = useTranslation("", { keyPrefix: "auth.login" });
  /* Dispatch hook */
  const dispatch = useDispatch();
  /* Redirect hook */
  const navigate = useNavigate();
  /* Location */
  const location = useLocation();

  /* Queries */
  const [login, { isLoading: isLoggingIn }] = useLoginMutation();

  /* Component state */
  const [errorMsg, setErrorMsg] = useState("");

  /* Handler for the login action */
  const loginHandler = (email, password, isStudent) => {
    //Login query
    login({ email: email, password: password })
      .unwrap()
      .then((res) => {
        //Save the credentials in the redux store
        dispatch(
          setCredentials({
            ...res.data,
            role: isStudent ? "student" : "teacher",
          })
        );

        //Now check if the user has accepted the privacy policy
        const user = res.data.user;
        if (user.privacyPolicy) {
          //Check if the user comes from a specific page
          const from = location.state?.from;
          from
            ? navigate(from)
            : isStudent
            ? navigate("/student/home/enrollments")
            : navigate("/teacher/home/courses");
        } else {
          navigate("/account/accept-privacy-policy");
        }
      })
      .catch((err) => {
        setErrorMsg(getErrorMessageTranslation(err));
      });
  };

  //If the page is loading, show the loading page
  if (isLoggingIn) return <Loading page={true} />;
  return (
    <>
      <Navbar dark={true} sticky />
      <PageContainer>
        <Header>
          <Container>
            <Header.Title>{t("login")}</Header.Title>
          </Container>
        </Header>
        <Container className="py-5">
          <Row>
            <Col md={{ span: 5, offset: 1 }} sm={12}>
              <LoginForm loginHandler={loginHandler} errorMsg={errorMsg} />
            </Col>
          </Row>
        </Container>
      </PageContainer>
      <Footer />
      <BackToTop />
    </>
  );
};

const LoginForm = ({ loginHandler, errorMsg }) => {
  /* Translator function */
  const { t } = useTranslation("", { keyPrefix: "auth.login" });

  /* Component state */
  const emailRef = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isStudent, setIsStudent] = useState("student");

  /* Login handler */
  const onLogin = (e) => {
    //Prevent the default behavior of the form
    e.preventDefault();

    //Call the login handler
    loginHandler(email, password, isStudent);
  };

  /* Set the default status of the role input element in according to the last time */
  useEffect(() => {
    //Get the role from the localstorage
    const role = localStorage.getItem("role");
    role === "teacher" ? setIsStudent(false) : setIsStudent(true);
    //Focus on the email input
    emailRef.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form onSubmit={onLogin}>
      <Form.Group>
        <FormStyles.FormLabel htmlFor="login-email">
          {t("email")}
        </FormStyles.FormLabel>
        <FormStyles.FormControl
          required
          ref={emailRef}
          type="email"
          id="login-email"
          placeholder={t("email")}
          className="fs-5"
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="my-3">
        <FormStyles.FormLabel htmlFor="login-password">
          {t("password")}
        </FormStyles.FormLabel>
        <PasswordField
          required
          id="login-password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>
      <SharedStyles.Paragraph>{t("login_as")}</SharedStyles.Paragraph>
      <Styles.RoleSelector>
        <span
          className={isStudent ? "active" : ""}
          onClick={() => setIsStudent(true)}
        >
          {t("student")}
        </span>
        <span
          className={!isStudent ? "active" : ""}
          onClick={() => setIsStudent(false)}
        >
          {t("teacher")}
        </span>
      </Styles.RoleSelector>
      {errorMsg && (
        <FormStyles.Error className="mt-3">{errorMsg}</FormStyles.Error>
      )}
      <FormStyles.EvoliButton type="submit" className="mt-3 w-100 fs-5">
        {t("login")}
      </FormStyles.EvoliButton>
      <SharedStyles.Paragraph className="w-100 text-center mt-1 mb-0">
        {t("no_account")} <Link to="/register">{t("sign_up")}</Link>
      </SharedStyles.Paragraph>
      <SharedStyles.Paragraph className="w-100 text-center">
        <Link to="/forgot-password">{t("forgot_password")}</Link>
      </SharedStyles.Paragraph>
    </Form>
  );
};

export default LoginPage;
