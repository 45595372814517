import React from "react";
import { useGetCourseEnrollmentsQuery } from "../../../../../store/teachings/teachings.api";
import { te } from "../../../../../utils/errorsTranslation";

const useFetch = ({
  courseUUID,
  limit,
  offset,
  order,
  orderBy,
  searchQuery,
}) => {
  const { data, isLoading, isError, error, isSuccess } =
    useGetCourseEnrollmentsQuery({
      courseUUID,
      limit,
      offset,
      order,
      orderBy,
      searchQuery,
    });

  const [errorMessage, setErrorMessage] = React.useState(null);

  //Check if there is an error
  React.useEffect(() => {
    if (isError) {
      if (!error.data?.code && !error.code)
        setErrorMessage(te("general_error"));
      else setErrorMessage(te(error.data?.code ?? error.code));
    } else if (isSuccess) {
      setErrorMessage(null);
    }
  }, [data, isError, error, isSuccess]);

  return {
    data: {
      enrollments: data?.enrollments ?? [],
      itemsCount: data?.count ?? 0,
    },
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  };
};

export default useFetch;
