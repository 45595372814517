import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetEnrollmentDetailsQuery } from "../../../store/enrollments/enrollments.api";
import { toastApiError } from "../../../utils/lecture.utils";

const useFetch = () => {
  /* Navigation hook */
  const navigate = useNavigate();
  /* Params hook */
  const params = useParams();

  /* Component state */
  const [skip, setSkip] = useState(true);

  /* Query hooks */
  const { data, isLoading, isUninitialized, isSuccess, isError, error } =
    useGetEnrollmentDetailsQuery(params.courseUUID, {
      skip,
    });

  useEffect(() => {
    const courseUUID = params.courseUUID;
    if (!courseUUID) navigate("/student/home/enrollments");
    else setSkip(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isError) {
      if (error) toastApiError(error);
      navigate("/student/home/enrollments");
    }
  }, [error, isError, navigate]);

  return { data, isLoading, isUninitialized, isSuccess, isError, error };
};

export default useFetch;
