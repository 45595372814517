import { Card } from "react-bootstrap";
import styled from "styled-components";

const Styles = {};

Styles.CourseCard = styled(Card)`
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  .header-colored {
    min-height: 3rem;
    background-color: ${(props) => props.color};
  }

  .progress {
    height: 5px;
  }

  .card-body {
    .card-title {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box !important;
      white-space: normal;
      height: 3rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    cursor: pointer;
  }
`;

export default Styles;
