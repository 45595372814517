import styled from "styled-components";

const Styles = {};

//Fill all the space between the navbar and the footer
Styles.CoursesContainer = styled.div`
  min-height: calc(100vh - 3.5rem - 3rem);

  .search-box-container {
    display: flex;
    flex-direction: column;
    order: 1;

    @media (min-width: 768px) {
      justify-content: flex-end;
      margin-left: auto;
      flex-grow: 1;
    }
  }
`;

Styles.Strip = styled.div`
  position: relative;
  height: 23vh;
  background-color: rgba(${(props) => props.theme.secondaryRGB}, 0.8);
  color: ${(props) => props.theme.textPrimary};
  display: flex;
  align-items: center;

  .title {
    text-transform: capitalize;
    font-size: 3rem;
  }

  .menu {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0;
    font-size: 1.2rem;
  }

  .menu-item {
    font-weight: 500;
    cursor: pointer;
    padding-bottom: 0.5rem;
    margin-bottom: 0;

    &.active {
      border-bottom: 0.3rem solid ${(props) => props.theme.textPrimary};
      padding-bottom: 0.2rem;
    }
  }
`;

export default Styles;
