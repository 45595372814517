import styled from "styled-components";

const Styles = {};

//Fill all the space between the navbar and the footer
Styles.CoursesContainer = styled.div`
  min-height: calc(100vh - 3.5rem - 3rem);

  .search-box-container {
    display: flex;
    flex-direction: column;
    order: 1;

    @media (min-width: 768px) {
      justify-content: flex-end;
      margin-left: auto;
      flex-grow: 1;
    }
  }
`;

export default Styles;
