import React from "react";
import styled from "styled-components";
import { toHHMMSS } from "../../../../../utils/lecture.utils";

export const QuestionsTimeBar = ({ lesson, questions }) => {
  const duration = lesson.endSecond - lesson.startSecond;
  const offset = lesson.startSecond;

  //Utility function to get video percentage
  const getPercentage = (second) => {
    return ((second - offset) / duration) * 100;
  };

  return (
    <Styles.TimeBarContainer>
      <Styles.TimeBar>
        <Styles.TimeSection />
        {/* Questions */}
        {questions.map((q, index) => (
          <Styles.QuestionPlaceholder
            key={index}
            left={getPercentage(q.second)}
          />
        ))}
      </Styles.TimeBar>
      <div className="d-flex flex-row justify-content-between mt-0">
        <span className="fs-6">{toHHMMSS(0)}</span>
        <span className="fs-6">{toHHMMSS(duration)}</span>
      </div>
    </Styles.TimeBarContainer>
  );
};

export default QuestionsTimeBar;

const Styles = {};

Styles.TimeBarContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 4rem !important;
  max-height: 4rem !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`;

Styles.TimeBar = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

Styles.TimeSection = styled.div`
  position: absolute;
  left: 0;
  height: 0.5rem;
  width: 100%;
  background-size: 400% 400%;
  background-color: ${(p) => p.theme.primary};
`;

Styles.QuestionPlaceholder = styled.div`
  position: absolute;
  left: ${(props) => props.left}%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background-color: ${(props) => props.theme.textPrimary};
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.accent};
`;
