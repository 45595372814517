import React, { useEffect, useMemo, useState } from "react";
import Navbar from "../../components/PageElements/Navbar";
import { Container } from "react-bootstrap";
import Footer from "../../components/PageElements/Footer";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/PageElements/Header";
import PageContainer from "../../components/PageElements/PageContainer";
import { selectCurrentRole } from "../../store/auth/auth.slice";
import { useSelector } from "react-redux";
import EvoliBreadcrumb from "../../components/PageElements/EvoliBreadcrumb";
import BackToTop from "../../components/PageElements/BackToTop";

const ProfilePage = () => {
  //Redirect hook
  const navigate = useNavigate();
  //Location hook
  const location = useLocation();

  //Redux state
  const role = useSelector(selectCurrentRole);

  //Component state
  const [active, setActive] = useState(1); //0 for my account, 1 for edit account, 2 for security

  //Memoized links
  const links = useMemo(
    () => ["/account", "/account/edit", "/account/security"],
    []
  );

  //Handle menu click event (change active menu item and redirect)
  const handleMenuClick = (id) => {
    if (id < links.length) {
      setActive(id);
      navigate(links[id]);
    }
  };

  /* Change active menu item when the location changes */
  useEffect(() => {
    links.forEach((link, index) => {
      if (location.pathname === link) {
        setActive(index);
      }
    });
  }, [location, links]);

  const breadcrumbItems = [
    {
      name: role === "student" ? "My Learning" : "My Teachings",
      to:
        role === "student"
          ? "/student/home/enrollments"
          : "/teacher/home/courses",
      active: false,
    },
    {
      name: "Account",
      to: "/account",
      active: true,
    },
  ];

  return (
    <>
      <Navbar sticky />
      <PageContainer>
        <Header>
          <Container>
            <EvoliBreadcrumb items={breadcrumbItems} />
            <Header.Title>Account</Header.Title>
            <Header.Menu>
              <Header.MenuItem
                className={active === 0 && "active"}
                onClick={() => handleMenuClick(0)}
              >
                My account
              </Header.MenuItem>
              <Header.MenuItem
                className={active === 1 && "active"}
                onClick={() => handleMenuClick(1)}
              >
                Edit account
              </Header.MenuItem>
              <Header.MenuItem
                className={active === 2 && "active"}
                onClick={() => handleMenuClick(2)}
              >
                Security
              </Header.MenuItem>
            </Header.Menu>
          </Container>
        </Header>
        <Outlet />
      </PageContainer>
      <Footer />
      <BackToTop />
    </>
  );
};

export default ProfilePage;
