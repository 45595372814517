import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Styles from "./styles";
import { Accordion, Container, ProgressBar } from "react-bootstrap";
import { toHoursMins } from "../../../../utils/lecture.utils";
import StudentFeedbackResume from "../../../../components/Commons/StudentFeedbackResume";

const StudentCourseContent = () => {
  const { course } = useOutletContext();
  //Get the course units
  const units = course.units;
  /* Calculate the total number of lessons */
  const lessonsCount = units.reduce((total, unit) => {
    return total + unit.lessons.length;
  }, 0);
  //Get the number of lessons with a feedback
  const completedLessons = units.reduce((total, unit) => {
    return (
      total +
      unit.lessons.reduce((total, lesson) => {
        return total + (lesson.feedback.length > 0 ? 1 : 0);
      }, 0)
    );
  }, 0);
  const percentage = (completedLessons / lessonsCount) * 100;
  //Color is based on the percentage of completed lessons: red < 33%, yellow < 66%, green > 66%
  const completionColor =
    percentage < 33 ? "danger" : percentage < 66 ? "warning" : "success";

  /* Calculate the total number of video minutes: the enrollment has a course, the course has units, a unit has lessons, a lesson could have a startSecond and a endSecond  */
  const totalVideoSeconds = units.reduce((total, unit) => {
    return (
      total +
      unit.lessons.reduce((total, lesson) => {
        if (lesson.startSecond === null || lesson.endSecond === null)
          return total;
        return total + (lesson.endSecond - lesson.startSecond);
      }, 0)
    );
  }, 0);

  return (
    <Container className="py-5">
      <h2>Course content</h2>
      {/* [n learning unit] • [n lessons] • [xh ym total video length] • [x document pages] */}
      <div className="d-flex flex-row justify-content-start align-items-center gap-2 mt-2">
        {units.length} learning unit{units.length !== 1 ? "s" : ""} •{" "}
        {lessonsCount} lesson
        {lessonsCount !== 1 ? "s" : ""} • {toHoursMins(totalVideoSeconds)} total
        video length
      </div>
      {/* Show a progress bar with the course completion */}
      <div className="mt-4">
        <span>
          My course completion ({completedLessons} / {lessonsCount})
        </span>
        <ProgressBar
          className="mt-2"
          min={0}
          max={lessonsCount}
          now={completedLessons}
          variant={completionColor}
        />
      </div>

      {/* Display the units in an accordition, all items should be open at the beginning */}
      <Accordion
        className="mt-3"
        defaultActiveKey={units.map((_, index) => index.toString())}
      >
        {units.map((unit, index) => (
          <LearningUnitItem
            unit={unit}
            course={course}
            index={index}
            key={unit.uuid}
          />
        ))}
      </Accordion>
    </Container>
  );
};

const LearningUnitItem = ({ course, unit, index }) => {
  const lessonsCount = unit.lessons.length;
  const totalVideoSeconds = unit.lessons.reduce((total, lesson) => {
    if (lesson.startSecond === null || lesson.endSecond === null) return total;
    return total + (lesson.endSecond - lesson.startSecond);
  }, 0);
  //Get number of lesson completed
  const completedLessons = unit.lessons.reduce((total, lesson) => {
    return total + (lesson.feedback.length > 0 ? 1 : 0);
  }, 0);
  const percentage = (completedLessons / lessonsCount) * 100;
  //Color is based on the percentage of completed lessons: red < 33%, yellow < 66%, green > 66%
  const completionColor =
    percentage < 33 ? "danger" : percentage < 66 ? "warning" : "success";

  return (
    <Accordion.Item eventKey={index.toString()}>
      {/* [unit type] [unit name]          [number of lessons] • [minutes or pages]  */}
      <Accordion.Header>
        <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100 me-4">
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            {unit.type === "video" && <i className="fas fa-video" />}
            {unit.type === "doc" && <i className="fas fa-file-alt" />}
            <h2 className="fs-6">{unit.name}</h2>
          </div>
          <div className="d-flex flex-row justify-content-end align-items-center gap-1">
            <span>
              {lessonsCount} lesson{lessonsCount !== 1 ? "s" : ""}
            </span>
            <span>•</span>
            <span>{toHoursMins(totalVideoSeconds)}</span>
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        {/* Show a progress bar with the unit completion */}
        <div className="mb-3">
          <span>
            My unit completion ({completedLessons} / {lessonsCount})
          </span>
          <ProgressBar
            className="mt-2"
            min={0}
            max={lessonsCount}
            now={completedLessons}
            variant={completionColor}
          />
        </div>
        {/* Display one row for each lesson */}
        {unit.lessons.map((lesson) => (
          <LessonItem
            lesson={lesson}
            unit={unit}
            course={course}
            key={lesson.uuid}
          />
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
};

const LessonItem = ({ lesson, course }) => {
  const navigate = useNavigate();

  const lessonDuration = (lesson.endSecond ?? 0) - (lesson.startSecond ?? 0);
  const feedback = lesson.feedback[0] ? lesson.feedback[0] : {};

  //When the user clicks on the lesson title, navigate to the unit page
  const clickPlayHandler = () => {
    navigate(`/student/lesson/${lesson.uuid}`);
  };

  return (
    <Styles.LessonItem>
      <div
        className="lesson-title"
        onClick={clickPlayHandler}
        data-tooltip-content="Play lesson"
        data-tooltip-id="evoli-tooltip"
      >
        <i className="fas fa-play-circle fs-4" />
        <div className="title">{lesson.name}</div>
      </div>
      <div className="d-flex flex-row justify-content-end align-items-center gap-2 mt-2">
        <div className="d-flex flex-row justify-content-end align-items-center gap-2">
          <span>{toHoursMins(lessonDuration)}</span>
          {/* Show the student lesson feedback resume */}
          <StudentFeedbackResume
            difficulty={feedback?.difficulty}
            understanding={feedback?.understanding}
            initialKnowledge={feedback?.initialKnowledge}
            percentage={false}
            colored={true}
            difficultyScale={course.difficultyScale}
            understandingScale={course.understandingScale}
            difficultyEnabled={course.difficultyEnabled}
            initialKnowledgeEnabled={course.initialKnowledgeEnabled}
          />
        </div>
      </div>
    </Styles.LessonItem>
  );
};

export default StudentCourseContent;
