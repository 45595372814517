import React, { useState, useRef, useEffect } from "react";
import RangeSlider from "react-bootstrap-range-slider";
import ReactPlayer from "react-player";
import { toHHMMSS } from "../../../../../../../utils/lecture.utils";
import Styles from "./styles";

const SelectIntervalVideoPlayer = ({ start, end, videoId, setDuration }) => {
  //Player config
  const config = {
    youtube: {
      height: "100%",
      playerVars: {
        playsinline: 0,
        modestbranding: 1,
        showinfo: 0,
        controls: 0,
        rel: 0,
        fs: 0,
      },
    },
  };

  //Component state
  const [playing, setPlaying] = useState(true);
  const [volume, setVolume] = useState(50);
  const [lastActiveVolume, setLastActiveVolume] = useState(50);
  const [videoTime, setVideoTime] = useState(start); //Current video time, referring to the entire YouTube video
  const playerRef = useRef(null);

  const getCurrentVideoSeconds = () => {
    return playerRef.current?.getCurrentTime();
  };

  const seekTo = (seconds) => {
    playerRef.current.seekTo(seconds);
  };

  const onProgress = () => {
    const currentTime = getCurrentVideoSeconds();
    if (currentTime < start || currentTime > end) {
      playerRef.current.seekTo(start);
      setPlaying(false);
      setVideoTime(start);
      seekTo(start);
    } else {
      setVideoTime(currentTime);
    }
  };

  const onReady = () => {
    //Play the video
    setPlaying(true);
  };

  const changeLastActiveVolume = (value) => {
    //Volume represents the last volume value different than 0
    if (value !== 0) setLastActiveVolume(value);
  };

  const toggleMute = () => {
    //If is mute, return to the last value different than 0
    if (volume === 0) {
      setVolume(parseInt(lastActiveVolume));
    } else {
      //If is not muted, mute, but don't edit the last volume value different than 0
      setVolume(0);
    }
  };

  //Handlers for the time slider
  const moveTimeSliderHandler = (e) => {
    //Stop the video when the user is moving the slider
    setPlaying(false);
    //Get slider value
    const sliderValue = e.target.value;
    //Update the video time
    setVideoTime(sliderValue);
  };

  const ReleaseTimeSliderHandler = (e) => {
    setPlaying(false);
    //Get slider value
    const sliderValue = parseInt(e.target.value);
    //Update the time
    setVideoTime(sliderValue);
    //Seek to the slider value
    seekTo(sliderValue);
  };

  //When start second change seek back to the start second
  useEffect(() => {
    seekTo(start);
  }, [start]);

  return (
    <Styles.VideoWrapper>
      <ReactPlayer
        ref={playerRef}
        className="youtube-video w-100 h-100"
        url={`https://www.youtube.com/watch?v=${videoId}`}
        playing={playing}
        controls={false}
        volume={volume / 100}
        progressInterval={200}
        config={config}
        onReady={onReady}
        onDuration={(duration) => setDuration(duration)}
        onPause={() => setPlaying(false)}
        onPlay={() => setPlaying(true)}
        onProgress={onProgress}
      />
      <Styles.VideoControlsContainer>
        <div className="timeline">
          <RangeSlider
            min={start}
            max={end}
            tooltip="off"
            value={videoTime}
            onChange={moveTimeSliderHandler}
            onAfterChange={ReleaseTimeSliderHandler}
            size="lg"
          />
        </div>
        <Styles.Controls>
          <div className="start">
            {playing ? (
              /* Pause icon */
              <i className="fas fa-pause" onClick={() => setPlaying(false)} />
            ) : (
              /* Play icon */
              <i className="fas fa-play" onClick={() => setPlaying(true)} />
            )}
            <Styles.VolumeContainer>
              <div onClick={toggleMute}>
                {volume === 0 ? (
                  /* Volume 0 icon */
                  <i className="fas fa-volume-mute" />
                ) : volume > 0 && volume < 33 ? (
                  /* Volume off icon */
                  <i className="fas fa-volume-off" />
                ) : volume >= 33 && volume < 66 ? (
                  /* Volume low icon */
                  <i className="fas fa-volume-down" />
                ) : (
                  /* Volume high icon */
                  <i className="fas fa-volume-up" />
                )}
              </div>
              <RangeSlider
                className="volume-slider"
                value={volume}
                tooltip="off"
                onChange={(e) => setVolume(parseInt(e.target.value))}
                onAfterChange={(e) => changeLastActiveVolume(e.target.value)}
                min={0}
                max={100}
              />
            </Styles.VolumeContainer>
            <Styles.DurationContainer>
              {toHHMMSS(videoTime - start)} / {toHHMMSS(end - start)}
            </Styles.DurationContainer>
          </div>
        </Styles.Controls>
      </Styles.VideoControlsContainer>
    </Styles.VideoWrapper>
  );
};

export default SelectIntervalVideoPlayer;
