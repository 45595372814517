import React from "react";

const usePagination = ({
  limit,
  page,
  searchInputRef,
  setSearchQuery,
  setSearchQueryValue,
}) => {
  const options = [
    {
      label: "First name (A-Z)",
      value: "firstNameAsc",
    },
    {
      label: "First name (Z-A)",
      value: "firstNameDesc",
    },
    {
      label: "Last name (A-Z)",
      value: "lastNameAsc",
    },
    {
      label: "Last name (Z-A)",
      value: "lastNameDesc",
    },
    {
      label: "Status (active first)",
      value: "statusAsc",
    },
    {
      label: "Status (inactive first)",
      value: "statusDesc",
    },
    {
      label: "Role (A-Z)",
      value: "roleAsc",
    },
    {
      label: "Role (Z-A)",
      value: "roleDesc",
    },
    {
      label: "Date created (oldest first)",
      value: "createdAtAsc",
    },
    {
      label: "Date created (newest first)",
      value: "createdAtDesc",
    },
  ];

  const [order, setOrder] = React.useState("ASC"); //ASC or DESC
  const [orderBy, setOrderBy] = React.useState("lastName");
  const [orderValue, setOrderValue] = React.useState("lastNameAsc");
  const offset = (page - 1) * limit;

  const reset = React.useCallback(() => {
    setOrderValue("lastNameAsc");
    setOrder("ASC");
    setOrderBy("lastName");
    setSearchQuery("");
    setSearchQueryValue("");
    if (searchInputRef.current) searchInputRef.current.value = "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeOrderHandler = (e) => {
    const value = e.target.value;
    setOrderValue(value);
    //The value is in the format [field][order]
    //Example: firstNameAsc or lastNameDesc
    //If the value ends with Asc, the order is ASC, else DESC
    if (value.endsWith("Asc")) {
      setOrder("ASC");
      //Order by is the value without the last 3 characters
      setOrderBy(value.slice(0, -3));
    } else {
      setOrder("DESC");
      //Order by is the value without the last 4 characters
      setOrderBy(value.slice(0, -4));
    }
  };

  //Initialize students list
  React.useEffect(() => {
    reset();
  }, [reset]);

  return {
    changeOrderHandler,
    offset,
    orderBy,
    order,
    orderValue,
    options,
    reset,
  };
};

export default usePagination;
