import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "../auth/auth.slice";
import { toastError } from "../../utils/Toast";
import { te } from "../../utils/errorsTranslation";

//Get base url from the environment variables
const baseUrl = process.env.REACT_APP_SERVER_URL;

const baseQuery = fetchBaseQuery({
  baseUrl,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  const status = result?.error?.originalStatus || result?.error?.status;

  if (status === 403) {
    //Send refresh token to get new access token
    const refreshResult = await baseQuery("/auth/refresh", api, extraOptions);
    if (refreshResult?.data) {
      const user = api.getState().auth.user;
      const role = api.getState().auth.role;
      //store the new token
      const accessToken = refreshResult.data?.data.accessToken;
      api.dispatch(setCredentials({ accessToken, user, role }));
      //retry the original request with the new token
      result = baseQuery(args, api, extraOptions);
    } else {
      //If refresh token is not valid
      //logout user
      api.dispatch(logOut());
      //Toast error
      toastError("Your session has expired. Please log in again.");
    }
  } else if (
    status === 401 &&
    (result?.error?.data?.code === "account_disconnected" ||
      result?.error?.data?.code === "privacy_policy_not_accepted")
  ) {
    //If the user is disconnected
    //logout user
    api.dispatch(logOut());
    //Toast error
    toastError(te(result.error.data.code));
  }

  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Auth",
    "User",
    "Course",
    "CourseOverview",
    "Teachings",
    "Teachers",
    "UserStats",
    "Enrollments",
    "Feedback",
    "Questions",
    "VideoIntervals",
    "Visualizations",
    "Lesson",
    "NextLesson",
    "PrevLesson",
  ],
  endpoints: (builder) => ({}),
});
