import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import {
  pause,
  play,
  resetPlayer,
  seekTo,
  selectGetCurrentVideoSeconds,
  selectIsPlayerReady,
  selectPlaybackRate,
  selectPlaying,
  selectVolume,
  setPlayingInterval,
  setSliderValue,
} from "../../store/media/media.slice";
import useKeyboard from "./hooks/useKeyboard";
import Styles from "./styles";
import PlayerControls from "./components/PlayerControls";

const SimpleVideoPlayer = ({
  lesson,
  startAt = lesson.startSecond,
  acceptEvents = false,
}) => {
  const dispatch = useDispatch();

  const start = lesson.startSecond;
  const end = lesson.endSecond;
  const MAX_ABS_DELTA_TIME = 3.0; //2 is ok, but 3 is better
  //Youtube player config
  const config = {
    youtube: {
      height: "100%",
      playerVars: {
        autoplay: 1, //Don't autoplay
        controls: 0, //Don't show controls
        disablekb: 1, //Disable keyboard controls
        fs: 0, //Disable fullscreen
        modestbranding: 1, //Hide youtube logo
        rel: 0, //Don't show related videos
        showinfo: 0, //Don't show video info
        start: start, //Start second
        end: end, //End second
      },
    },
  };

  //Redux state
  const isPlayerReady = useSelector(selectIsPlayerReady);
  const getVideoSecond = useSelector(selectGetCurrentVideoSeconds);
  const playing = useSelector(selectPlaying);
  const volume = useSelector(selectVolume);
  const playbackRate = useSelector(selectPlaybackRate);
  const [started, setStarted] = useState(false);
  const [lastSecond, setLastSecond] = useState(0);
  const [showControls, setShowControls] = useState(true);
  const outPlayerRef = useRef(null);
  const playerRef = useRef(null);

  const onReady = () => {
    //When the player is ready to play then register it in the store
    dispatch(resetPlayer(playerRef.current));
    //Set the playing interval
    dispatch(setPlayingInterval({ startSecond: start, endSecond: end }));
    //Seek to the start second
    dispatch(seekTo(startAt));
    //Play the video
    dispatch(play());
  };

  const onPlay = () => {
    //Since if the user click on the player and not on the play button, the video will start playing, but the playing variable is not changed, we need to change it manually
    dispatch(play());
    outPlayerRef.current.focus();
  };

  //When the video is paused, log the event
  const onPause = () => {
    //Since if the user click on the player and not on the pause button, the video will pause, but the playing variable is not changed, we need to change it manually
    dispatch(pause());
    //Show the controls
    setShowControls(true);
    outPlayerRef.current.focus();
  };

  //Video playing function
  const onProgress = () => {
    //Get and update video time
    const currentSecond = getVideoSecond();
    //The delta time is useful to understand if YouTube "jumps" to a new second when the video starts playing
    const deltaTime = currentSecond - lastSecond;
    setLastSecond(currentSecond);
    //If the video is playing and the current time is lower than the start second, pause the video
    if (currentSecond < start) {
      dispatch(seekTo(start));
      //If the video is playing and the current time is greater than the end second, pause the video
    } else if (currentSecond >= end && playing) {
      //Prevent the YouTube "jump" when video is loaded
      if (Math.abs(deltaTime) < MAX_ABS_DELTA_TIME) dispatch(seekTo(start));
      //If the video is playing and the current time is between the start and end seconds, update the video time
    } else if (!isNaN(currentSecond)) {
      const roundedCurrentSecond = Math.floor(currentSecond);
      dispatch(setSliderValue(roundedCurrentSecond - start));
    }
  };

  //When the video starts, set started to true
  const onStart = () => {
    //If the video is not started, set started to true
    if (!started) setStarted(true);
    setShowControls(true);
    //Seek to the start second
    dispatch(seekTo(startAt));
  };

  //Keyboard listener
  useKeyboard({ started, lesson, acceptEvents });

  const mouseEnterHandler = () => {
    if (playing) setShowControls(true);
  };

  const mouseLeaveHandler = () => {
    if (playing) setShowControls(false);
  };

  return (
    <Styles.VideoWrapper
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <button ref={outPlayerRef} style={{ position: "absolute", opacity: 0 }}>
        Dummy Button
      </button>
      <ReactPlayer
        ref={playerRef}
        className="w-100 h-100"
        url={`https://www.youtube.com/watch?v=${lesson.videoId}&t=${start}`}
        playing={playing}
        controls={false}
        volume={volume / 100}
        progressInterval={500}
        playbackRate={playbackRate}
        config={config}
        onStart={onStart}
        onReady={onReady}
        onPause={onPause}
        onPlay={onPlay}
        onProgress={onProgress}
      />
      {isPlayerReady && (!playing || (playing && showControls)) && (
        <PlayerControls lesson={lesson} />
      )}
    </Styles.VideoWrapper>
  );
};

export default SimpleVideoPlayer;
