import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import {
  disconnectSocket,
  selectIsConnectionFailed,
  selectIsReconnectionFailed,
  startConnecting,
} from "../../../store/feedback/feedback.slice";
import { useNavigate } from "react-router-dom";
import { toastError } from "../../../utils/Toast";
import { selectCurrentToken } from "../../../store/auth/auth.slice";

const useSocket = ({ lessonUUID, skip }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isConnectionFailed = useSelector(selectIsConnectionFailed);
  const isReconnectionFailed = useSelector(selectIsReconnectionFailed);
  const token = useSelector(selectCurrentToken);

  useLegacyEffect(() => {
    if (!skip) {
      //Start the connection the connection when the component is mounted
      if (lessonUUID && token) {
        dispatch(startConnecting({ lessonUUID, token }));
      }
    }
    return () => {
      if (!skip) {
        //When exiting the page, disconnect the socket
        dispatch(disconnectSocket());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  //If socket fail the connection
  useEffect(() => {
    if (isConnectionFailed || isReconnectionFailed) {
      //Reset the state before redirecting
      dispatch(disconnectSocket());
      navigate("/student/home/enrollments"); //Redirect to home
      //If reconnecting fails, show error toast
      if (isReconnectionFailed) toastError("Connection error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectionFailed, isReconnectionFailed]);

  return null;
};

export default useSocket;
