import React from "react";
import { Outlet } from "react-router-dom";
import Loading from "../../components/PageElements/Loading";
import AppNavbar from "../../components/PageElements/Navbar";
import Footer from "../../components/PageElements/Footer";
import PageContainer from "../../components/PageElements/PageContainer";
import { Container } from "react-bootstrap";
import EvoliBreadcrumb from "../../components/PageElements/EvoliBreadcrumb";
import useFetch from "./hooks/useFetch";
import BackToTop from "../../components/PageElements/BackToTop";
import { formatDate } from "../../utils/time.utils";
import useMenu from "./hooks/useMenu";
import Header from "../../components/PageElements/Header";

const EnrollmentPage = () => {
  const { active, handleMenuClick, links } = useMenu();
  const { data: enrollment, isLoading, isUnitialized, isSuccess } = useFetch();

  const breadcrumbItems = [
    {
      name: "My Learning",
      to: "/student/home/enrollments",
      active: false,
    },
    {
      name: enrollment?.course?.name,
      to: `/student/course/${enrollment?.course?.uuid}/content`,
      active: true,
    },
  ];

  const course = enrollment?.course;

  if (!enrollment || isUnitialized || isLoading) return <Loading />;
  if (isSuccess)
    return (
      <>
        <AppNavbar dark sticky="true" />
        <PageContainer>
          {/* Header section: display the main course information */}
          <Header>
            <Container>
              {/* Breadcrumb */}
              <EvoliBreadcrumb items={breadcrumbItems} />
              {/* Title and brief description */}
              <Header.Title>{enrollment.course.name}</Header.Title>
              {enrollment.course.briefDescription && (
                <p>{enrollment.course.briefDescription}</p>
              )}
              {/* Enrollment count */}
              <div className="d-flex flex-row justify-content-start align-items-center gap-2 mt-3 fs-5">
                <i className="fas fa-users" />
                <span>
                  {enrollment.course.enrollmentsCount} students enrolled
                </span>
              </div>
              {/* Created by [teacher name] on [creation date] */}
              <div className="d-flex flex-row justify-content-start align-items-center gap-2 mt-2 fs-5">
                <i className="fas fa-user" />
                <div>
                  Created by{" "}
                  <span className="fw-bold">
                    {enrollment.course.teacher.firstName}{" "}
                    {enrollment.course.teacher.lastName}
                  </span>{" "}
                  on {formatDate(new Date(enrollment.course.createdAt))}
                </div>
              </div>
              {/* Menu */}
              <Header.Menu>
                {links.map((link, index) => (
                  <Header.MenuItem
                    key={index}
                    className={active === index && "active"}
                    onClick={() => handleMenuClick(index)}
                  >
                    {link.name}
                  </Header.MenuItem>
                ))}
              </Header.Menu>
            </Container>
          </Header>
          {/* Content section: display the course content */}
          <Outlet context={{ course }} />
        </PageContainer>
        <Footer />
        <BackToTop />
      </>
    );
};

export default EnrollmentPage;
