import React from "react";

const LimitSelect = ({
  limit,
  setLimit,
  values = [10, 25, 50, 100],
  label = "Limit",
}) => {
  return (
    <div className="d-flex flex-column gap-1">
      <label>{label}</label>
      <select
        className="form-select"
        value={limit}
        onChange={(e) => setLimit(e.target.value)}
      >
        {values.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LimitSelect;
