import styled from "styled-components";

export const AdminPageContainer = styled.div`
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const PageContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: auto;
`;

export const LayoutContent = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  .h-full {
    height: 100%;
  }
  .flex-1 {
    flex: 1;
  }
`;

export const LogContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  color: ${(props) => props.theme.textPrimary};
  border: 2px solid ${(props) => props.theme.primary};
  background-color: ${(props) => props.theme.secondary};
  overflow-y: auto;
`;

export const MessageDetailPageContainer = styled.div`
  height: 100%;
  padding: 3.5rem 1.5rem 1.5rem 1.5rem;
  @media (max-width: 768px) {
    padding: 3.5rem 0.5rem 0.5rem 0.5rem;
  }

  overflow-y: auto;
`;
export const MessageDetailContainer = styled.div`
  position: relative;
  height: ${(props) => (props.showReplyBox ? "100%" : "auto")};
  max-height: 100%;
  max-width: min(80rem, calc(100% - 4rem));
  margin: auto;
  display: flex;
  flex-direction: column;

  color: #000;
  border: 2px solid ${(props) => props.theme.primary};
  background-color: ${(props) => props.theme.textPrimary};
  border-radius: 0.5rem;

  @media (max-width: 768px) {
    max-width: 100%;
  }

  .back-to-message-list {
    top: -3rem;
    color: ${(props) => props.theme.textPrimary};
    background-color: ${(props) => props.theme.primary};
    padding: 0.5rem;
    border-radius: 0.5rem;

    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
    width: 12rem;
    text-align: center;
    position: absolute;
    &:hover {
      color: ${(props) => props.theme.primary};
      background-color: ${(props) => props.theme.textPrimary};
    }
  }

  .message {
    overflow-y: auto;
    flex: 1;
    padding: 1rem;
  }

  .reply-box {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.secondary};
    display: ${(props) => (props.showReplyBox ? "static" : "none")};

    padding: 0;
  }
  .reply-editor {
    background-color: ${(props) => props.theme.textPrimary};
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .reply-icon {
    padding: 1rem;
    font-size: 2rem;
    float: right;
    color: ${(props) => props.theme.accent};
    cursor: pointer;
  }
  .close-icon {
    fill: ${(props) => props.theme.accent};
    cursor: pointer;
  }

  .message-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .message-subject {
    font-size: 1.5rem;
  }
  .action-buttons {
    display: flex;
    padding: 0.5rem;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }
`;

export const AnnounceTextboxContainer = styled.div`
  flex: 1;
  min-height: 15rem;
  display: flex;
  flex-direction: column;
`;

export const MessageCard = styled.div`
  .message-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const SideMenu = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.secondary};
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem 0 1rem 1rem;
  min-width: 12rem;

  @media (min-width: 768px) {
    max-width: 20rem;
  }

  @media (max-width: 768px) {
    width: 3.5rem;
    min-width: 0;
    padding: 0.5rem 0 0.5rem 0.5rem;
  }

  .admin-list {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    font-size: 1.2rem;
    margin-top: 0.5rem;
    height: 100%;
    overflow-y: auto;
    color: ${(props) => props.theme.textPrimary};

    .admin-list-item {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      font-size: 1.2rem;
      cursor: pointer;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: center;

        span {
          display: none;
        }
      }

      i {
        font-size: 1.5rem;
        width: 1.5rem;
        text-align: center;
      }

      &.active {
        font-weight: 700;
        border-right: 4px solid ${(props) => props.theme.textPrimary};

        i {
          font-size: 1.8rem;
          width: 1.8rem;
        }
      }
    }
  }
`;

export const MaintenanceComponent = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
  .maintenance-label {
    font-weight: bold;
    color: ${(props) => props.theme.textPrimary};
  }
  .maintenance-warning {
    color: red;
    background-color: #f1f5f7;
    font-weight: bold;
    padding: 0.5rem;
    border-radius: 9px;
  }
  .form-check-input {
    cursor: pointer;
    width: 3rem;
    height: 1.5rem;
    &:checked {
      background-color: ${(props) => props.theme.accent};
    }
  }
`;

export const StyledMultiRangeSlider = styled.div`
  .slider {
    position: relative;
    width: 200px;
  }

  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
  }

  .slider__track,
  .slider__range {
    border-radius: 3px;
    height: 5px;
  }

  .slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
  }

  .slider__range {
    background-color: ${(p) => p.theme.primary};
    z-index: 2;
  }

  .slider__left-value,
  .slider__right-value {
    color: #dee2e6;
    font-size: 12px;
    margin-top: 20px;
  }

  .slider__left-value {
    left: 6px;
  }

  .slider__right-value {
    right: -4px;
  }

  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 200px;
    outline: none;
  }

  .thumb--left {
    z-index: 3;
  }

  .thumb--right {
    z-index: 4;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  input[type="text"] {
    border: 2px solid ${(props) => props.theme.primary};
    border-radius: 1em;
    padding: 0.5em;
  }

  .slider__label {
    position: absolute;
    top: 1.2rem;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: 5rem;
    text-align: center;
    border: 2px solid ${(props) => props.theme.primary};
    color: ${(props) => props.theme.textPrimary};
    background-color: ${(props) => props.theme.secondary};
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 99rem;
  }
`;

export const OnlineLessonCardContainer = styled.div`
  max-width: 20rem;
  width: 100%;

  .card-body {
    padding: 0.5rem;
    border: 2px solid ${(props) => props.theme.primary};
    border-top: none;
  }

  .card-title {
    background-color: ${(props) => props.theme.secondary};
    color: ${(props) => props.theme.textPrimary};
    padding: 0.5rem;
    border-bottom: 2px solid ${(props) => props.theme.primary};
    border-radius: 0.5rem 0.5rem 0 0;
  }
`;

export const CourseDetailsWrapper = styled.div``;

export const EnrolledStudentCardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .card-body {
    padding: 0.5rem;
    border: 2px solid ${(props) => props.theme.primary};
    border-top: none;
    overflow-y: auto;
    height: 100%;
  }

  .card-title {
    background-color: ${(props) => props.theme.secondary};
    color: ${(props) => props.theme.textPrimary};
    padding: 0.5rem;
    border-bottom: 2px solid ${(props) => props.theme.primary};
    border-radius: 0.5rem 0.5rem 0 0;
  }
`;
