import React from "react";
import { Accordion } from "react-bootstrap";
import { toHHMMSS, toHoursMins } from "../../../../utils/lecture.utils";
import { useNavigate } from "react-router-dom";

import Loading from "../../../../components/PageElements/Loading";
import Styles from "./styles";
import useFetch from "./hooks/useFetch";
import StudentFeedbackResume from "../../../../components/Commons/StudentFeedbackResume";

const CourseContent = ({ lesson }) => {
  const course = lesson.unit.course;

  const {
    data: units,
    isLoading,
    isError,
    errorMessage,
  } = useFetch(course.uuid);

  if (isLoading) return <Loading page={false} />;
  if (isError) return <div className="text-danger fw-bold">{errorMessage}</div>;
  if (units.length > 0)
    return (
      <Styles.Container>
        {/* Display the units in an accordition, all items should be open at the beginning */}
        <Accordion
          className="mt-1"
          defaultActiveKey={units.map((_, index) => index.toString())}
          alwaysOpen
        >
          {units.map((unit, index) => (
            <LearningUnitItem
              unit={unit}
              activeLesson={lesson}
              course={course}
              index={index}
              key={unit.uuid}
            />
          ))}
        </Accordion>
      </Styles.Container>
    );
};

export default CourseContent;

const LearningUnitItem = ({ unit, index, activeLesson, course }) => {
  const lessonsCount = unit.lessons.length;
  const totalVideoSeconds = unit.lessons.reduce((total, lesson) => {
    if (lesson.startSecond === null || lesson.endSecond === null) return total;
    return total + (lesson.endSecond - lesson.startSecond);
  }, 0);
  /*   const totalDocPages = unit.lessons.reduce((total, lesson) => {
    if (lesson.startPage === null || lesson.endPage === null) return total;
    return total + (lesson.endPage + 1 - lesson.startPage);
  }, 0); */

  return (
    <Accordion.Item eventKey={index.toString()}>
      {/* [unit type] [unit name]          [number of lessons] • [minutes or pages]  */}
      <Accordion.Header>
        <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100 me-4">
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            {unit.type === "video" && <i className="fas fa-video" />}
            {unit.type === "doc" && <i className="fas fa-file-alt" />}
            <h2 className="fs-6">{unit.name}</h2>
          </div>
          <div className="d-flex flex-row justify-content-end align-items-center gap-1">
            <span>
              {lessonsCount} lesson{lessonsCount !== 1 ? "s" : ""}
            </span>
            <span>•</span>
            <span>{toHoursMins(totalVideoSeconds)}</span>
            {/* <span>•</span>
            <span>
              {totalDocPages} page{totalDocPages > 1 ? "s" : ""}
            </span> */}
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        {/* Display one row for each lesson */}
        {unit.lessons.map((lesson) => (
          <LessonItem
            lesson={lesson}
            course={course}
            active={lesson.uuid === activeLesson.uuid}
            key={lesson.uuid}
          />
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
};

const LessonItem = ({ lesson, active, course }) => {
  const navigate = useNavigate();

  const feedback = lesson.feedback[0] ?? {};
  const lessonDuration = (lesson.endSecond ?? 0) - (lesson.startSecond ?? 0);
  const lessonPages = (lesson.endPage ?? 0) - (lesson.startPage ?? 0);

  //When the user clicks on the lesson title, navigate to the unit page
  const clickPlayHandler = () => {
    navigate(`/student/lesson/${lesson.uuid}`);

    //Reload the page
    window.location.reload();
  };

  return (
    <Styles.LessonItem className={active && "active"}>
      <div className="lesson-title" onClick={clickPlayHandler}>
        <i
          className="fas fa-play-circle fs-4"
          data-tooltip-content="Play lesson"
          data-tooltip-id="evoli-tooltip"
        />
        <div className="title">{lesson.name}</div>
      </div>
      <div className="d-flex flex-row justify-content-end align-items-center gap-2 mt-2">
        <div className="d-flex flex-row justify-content-end align-items-center gap-2">
          {lessonDuration > 0 && <span>{toHHMMSS(lessonDuration)}</span>}
          {lessonPages > 0 && <span>{lessonPages} pages</span>}
          <StudentFeedbackResume
            difficulty={feedback.difficulty}
            understanding={feedback.understanding}
            initialKnowledge={feedback.initialKnowledge}
            initialKnowledgeEnabled={course.initialKnowledgeEnabled}
            difficultyEnabled={course.difficultyEnabled}
            colored={true}
            percentage={false}
            difficultyScale={course.difficultyScale}
            understandingScale={course.understandingScale}
          />
        </div>
      </div>
    </Styles.LessonItem>
  );
};
