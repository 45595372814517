import styled from "styled-components";

const Styles = {};

Styles.RangeContainer = styled.div`
  margin-bottom: 2rem;

  .my-range-slider {
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
`;

Styles.MediaContainer = styled.div`
  &.miniature {
    position: relative;
    border-radius: 20px;
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
    margin-top: 1rem auto;
  }

  &.expanded {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
  }
`;

export default Styles;
