import React from "react";
import { RingLoader } from "react-spinners";
import styled, { useTheme } from "styled-components";

const Loading = () => {
  const theme = useTheme();

  const color = theme?.accent || "#5eb2f2";
  const size = "10rem";

  return (
    <LoadingContainer>
      <RingLoader color={color} size={size} />
    </LoadingContainer>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  background: ${(props) => props.theme.primary};
  z-index: 1000;
`;

export default Loading;
