import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../store/auth/auth.slice";
import Styles from "./styles";

const MyProfile = () => {
  /* Redux state */
  const user = useSelector(selectCurrentUser);

  return (
    <Container className="py-5">
      {/* Show email, first name and last name */}
      <div className="d-flex flex-column mb-2">
        <Styles.Label>Email:</Styles.Label>
        <Styles.Value>{user.email}</Styles.Value>
      </div>
      <div className="d-flex flex-column mb-2">
        <Styles.Label>First name:</Styles.Label>
        <Styles.Value>{user.firstName}</Styles.Value>
      </div>
      <div className="d-flex flex-column mb-2">
        <Styles.Label>Last name:</Styles.Label>
        <Styles.Value>{user.lastName}</Styles.Value>
      </div>
    </Container>
  );
};

export default MyProfile;
