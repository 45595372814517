import { Container } from "react-bootstrap";
import styled from "styled-components";

const Header = ({ children, ...props }) => {
  return (
    <Styles.Header {...props}>
      <Container>{children}</Container>
    </Styles.Header>
  );
};

const Styles = {};

Styles.Header = styled.div`
  position: relative;
  min-height: 14rem;
  background-color: rgba(${(props) => props.theme.secondaryRGB}, 0.8);
  color: ${(props) => props.theme.textPrimary};
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 3rem;
`;

Header.Title = styled.h1`
  text-transform: capitalize;
  font-size: 3rem;
  font-weight: bold;
`;

Header.Menu = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0;
  font-size: 1.2rem;
`;

Header.MenuItem = styled.div`
  font-weight: 500;
  cursor: pointer;
  padding-bottom: 0.5rem;
  margin-bottom: 0;

  &.active {
    border-bottom: 0.3rem solid ${(props) => props.theme.textPrimary};
    padding-bottom: 0.2rem;
  }
`;

export default Header;
