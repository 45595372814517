import styled from "styled-components";

const Styles = {};

Styles.FeedbackPage = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

Styles.Reaction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.active {
    color: ${(p) => p.theme.accent} !important;

    i {
      color: ${(p) => p.theme.accent} !important;
      font-size: 3.5rem !important;
    }
  }

  i {
    font-size: 3rem !important;

    &.unclear {
      color: rgb(${(p) => p.theme.redReactionRGB});
    }

    &.half {
      color: rgb(${(p) => p.theme.yellowReactionRGB});
    }

    &.clear {
      color: rgb(${(p) => p.theme.greenReactionRGB});
    }

    &:hover {
      font-size: 3.5rem !important;
      transition: all 0.3s ease-in-out;
    }
  }

  .description {
    text-align: center;
  }
`;

Styles.CompletionCircle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  border: 3px solid ${(p) => p.theme.primary};
  background-color: ${(p) => p.theme.textPrimary};
  color: ${(p) => p.theme.primary};
  font-size: 1rem;
  font-weight: bold;
`;

export default Styles;
