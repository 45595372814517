import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { apiSlice } from "./api/api.slice";
import authReducer from "./auth/auth.slice";
import feedbackReducer from "./feedback/feedback.slice";
import mediaReducer from "./media/media.slice";
import feedbackMiddleware from "./feedback/feedback.middleware";
import storage from "redux-persist/lib/storage";

//I want only the auth slice to be persisted
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

//Combine all reducers
const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  feedback: feedbackReducer,
  media: mediaReducer,
});

//Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

//Create the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([apiSlice.middleware, feedbackMiddleware]),
  devTools: process.env.NODE_ENV !== "production",
});

//Create a persistor
export const persistor = persistStore(store);
