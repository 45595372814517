import React, { useState, useEffect } from "react";
import * as Styles from "./styles";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import MaintenanceSwitch from "../../components/Admin/MaintenanceSwitch";
import Navbar from "../../components/PageElements/Navbar";

/* Items list */
const items = [
  { name: "insights", icon: "fas fa-chart-line", link: "/admin" },
  { name: "models", icon: "fas fa-database", link: "/admin/models" },
  { name: "logs", icon: "fas fa-clipboard-list", link: "/admin/logs" },
  { name: "messages", icon: "fas fa-inbox", link: "/admin/messages" },
  { name: "bi", icon: "fas fa-chart-bar", link: "/admin/bi" },
  { name: "active-users", icon: "fas fa-users", link: "/admin/active-users" },
];

const AdminPage = () => {
  /* Component state */
  const [selectedItem, setSelectedItem] = useState(items[0].name);

  const location = useLocation();

  useEffect(() => {
    //Find the item in the items list that matches the current path
    // it's the elements after /admin/ in the url
    const item = items.find(
      (item) => item.name === location.pathname.split("/")[2]
    );
    if (item) setSelectedItem(item.name);
    else setSelectedItem(items[0].name);
    // Update active link when the location changes
  }, [location.pathname]);

  return (
    <Styles.AdminPageContainer fluid>
      <Navbar navLinks dark="true" fixed="top" />
      <Styles.PageContentWrapper>
        <SideMenu
          items={items}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
        <Styles.LayoutContent>
          <Outlet />
        </Styles.LayoutContent>
      </Styles.PageContentWrapper>
    </Styles.AdminPageContainer>
  );
};

const SideMenu = ({ items, selectedItem, setSelectedItem }) => {
  /* hooks */
  const navigate = useNavigate();

  const { t } = useTranslation("", { keyPrefix: "admin" });

  const clickItemHandler = (item) => {
    setSelectedItem(item.name);
    navigate(item.link);
  };

  return (
    <Styles.SideMenu>
      <div className="admin-list">
        {items.map((item, idx) => {
          if (item.name === "__separator__") return <hr key={idx} />;
          return (
            <div
              key={item.name}
              className={`admin-list-item ${
                selectedItem === item.name && "active"
              }`}
              onClick={() => clickItemHandler(item)}
            >
              <i className={item.icon} />
              <span>{t(item.name)}</span>
            </div>
          );
        })}
      </div>
      <MaintenanceSwitch />
    </Styles.SideMenu>
  );
};

export default AdminPage;
