import React from "react";
import styled from "styled-components";

const EvoliCheckbox = ({ checked, onChange, color }) => {
  return (
    <CheckBoxWrapper color={color}>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <svg viewBox="0 0 35.6 35.6">
        <circle r="17.8" cy="17.8" cx="17.8" className="background"></circle>
        <circle r="14.37" cy="17.8" cx="17.8" className="stroke"></circle>
        <polyline
          points="11.78 18.12 15.55 22.23 25.17 12.87"
          className="check"
        ></polyline>
      </svg>
    </CheckBoxWrapper>
  );
};

export default EvoliCheckbox;

const CheckBoxWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;

  &:hover .check {
    stroke-dashoffset: 0;
  }

  .background {
    fill: ${(p) => p.color};
    transition: ease all 0.6s;
    -webkit-transition: ease all 0.6s;
  }

  .stroke {
    fill: none;
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-width: 2px;
    stroke-dashoffset: 100;
    stroke-dasharray: 100;
    transition: ease all 0.6s;
    -webkit-transition: ease all 0.6s;
  }

  .check {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
    stroke-dashoffset: 22;
    stroke-dasharray: 22;
    transition: ease all 0.6s;
    -webkit-transition: ease all 0.6s;
  }

  input[type="checkbox"] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    opacity: 0;
    -appearance: none;
    -webkit-appearance: none;
  }

  input[type="checkbox"]:hover {
    cursor: pointer;
  }

  input[type="checkbox"]:checked + svg .background {
    fill: ${(p) => p.color};
  }

  input[type="checkbox"]:checked + svg .stroke {
    stroke-dashoffset: 0;
  }

  input[type="checkbox"]:checked + svg .check {
    stroke-dashoffset: 0;
  }
`;
