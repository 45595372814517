import React from "react";
import styled from "styled-components";

const PageContainer = ({ min = true, ...props }) => {
  return (
    <Styles.PageContainer $min={min}>{props.children}</Styles.PageContainer>
  );
};

const Styles = {};

Styles.PageContainer = styled.div`
  ${(p) =>
    p.$min
      ? "min-height: calc(100vh - 3.5rem - 3rem);"
      : "height: calc(100vh - 3.5rem - 3rem);"}
  color: ${(props) => props.theme.primary};
`;

export default PageContainer;
