import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { CirclePicker } from "react-color";
import * as FormStyles from "../../../../../../styles/Forms.styles";
import ReactSwitch from "react-switch";
import EvoliModal from "../../../../../../components/Commons/EvoliModal";
import TextArea from "../../../../../../components/Form/TextArea";

export const EditTeachingModal = ({
  show,
  onHide,
  course,
  updateCourseHandler,
}) => {
  const [name, setName] = useState(course.name || "");
  const [description, setDescription] = useState(course.description || "");
  const [color, setColor] = useState(course.color);
  const defaultColors = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
  ];

  useEffect(() => {
    setName(course.name);
    setDescription(course.description);
    setColor(course.color);
  }, [course]);

  const clickSaveHandler = (e) => {
    e.preventDefault();

    //Update course
    if (name.trim().length > 0 && color.trim().length === 7) {
      updateCourseHandler({ name, description, color }, onHide);
    }
  };

  const clickResetHandler = () => {
    setName(course.name);
    setDescription(course.description);
    setColor(course.color);
  };

  return (
    <EvoliModal show={show} onHide={onHide}>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Edit course</Modal.Title>
      </Modal.Header>
      <Form onSubmit={clickSaveHandler}>
        <Modal.Body>
          <Form.Group className="mb-3">
            <FormStyles.FormLabel htmlFor="create-folder-name">
              Course name
            </FormStyles.FormLabel>
            <FormStyles.FormControl
              id="create-folder-name"
              required
              type="text"
              maxLength={100}
              placeholder="Insert course name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <FormStyles.FormLabel htmlFor="create-folder-description">
              Description
            </FormStyles.FormLabel>
            <TextArea
              id="create-folder-description"
              required
              type="textfield"
              maxLength={500}
              placeholder="Insert course description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <p>Choose a color for your course:</p>
            <CirclePicker
              width="100%"
              color={color}
              onChangeComplete={(color) => setColor(color.hex)}
              colors={defaultColors}
              className="d-flex justify-content-center"
            />
          </Form.Group>
          <Form.Label>Understanding scale:</Form.Label>
          {/* Orizontal radio buttons with 3, 5, 7, 10 options */}
          <Form.Group className="mb-3">
            <Form.Check
              inline
              label="3"
              name="understandingScale"
              type="radio"
              id="understandingScale3"
              checked={course.understandingScale === 3}
              disabled
            />
            <Form.Check
              inline
              label="5"
              name="understandingScale"
              type="radio"
              id="understandingScale5"
              checked={course.understandingScale === 5}
              disabled
            />
            <Form.Check
              inline
              label="7"
              name="understandingScale"
              type="radio"
              id="understandingScale7"
              checked={course.understandingScale === 7}
              disabled
            />
            <Form.Check
              inline
              label="10"
              name="understandingScale"
              type="radio"
              id="understandingScale10"
              checked={course.understandingScale === 10}
              disabled
            />
          </Form.Group>
          {/* Enable or disable difficulty with a switch */}
          <Form.Group className="mb-3 d-flex flex-row align-items-center gap-3">
            <Form.Label>Enable difficulty feedback:</Form.Label>
            <ReactSwitch
              checked={course.difficultyEnabled}
              disabled
              onChange={() => {}}
            />
          </Form.Group>
          {/* If difficulty is enabled, show difficulty scale */}
          {course.difficultyEnabled && (
            <Form.Group className="mb-3">
              <Form.Label>Difficulty scale:</Form.Label>
              <Form.Check
                inline
                label="3"
                name="difficultyScale"
                type="radio"
                id="difficultyScale3"
                checked={course.difficultyScale === 3}
                disabled
              />
              <Form.Check
                inline
                label="5"
                name="difficultyScale"
                type="radio"
                id="difficultyScale5"
                checked={course.difficultyScale === 5}
                disabled
              />
              <Form.Check
                inline
                label="7"
                name="difficultyScale"
                type="radio"
                id="difficultyScale7"
                checked={course.difficultyScale === 7}
                disabled
              />
              <Form.Check
                inline
                label="10"
                name="difficultyScale"
                type="radio"
                id="difficultyScale10"
                checked={course.difficultyScale === 10}
                disabled
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <FormStyles.EvoliButton variant="light" type="submit">
            Save
          </FormStyles.EvoliButton>
          <FormStyles.EvoliButton
            variant="secondary"
            onClick={clickResetHandler}
          >
            Reset
          </FormStyles.EvoliButton>
        </Modal.Footer>
      </Form>
    </EvoliModal>
  );
};

export const EditUnitModal = ({ show, onHide, unit, updateUnitHandler }) => {
  const [name, setName] = useState(unit?.name || "");

  useEffect(() => {
    setName(unit?.name || "");
  }, [unit]);

  const clickSaveHandler = (e) => {
    e.preventDefault();

    //Update unit
    if (name.trim().length > 0)
      updateUnitHandler({ unitUUID: unit.uuid, name }, onHide);
  };

  const clickResetHandler = () => {
    setName(unit.name);
  };

  return (
    <EvoliModal show={show} onHide={onHide}>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Edit unit</Modal.Title>
      </Modal.Header>
      <Form onSubmit={clickSaveHandler}>
        <Modal.Body>
          {/* Unit name */}
          <FormStyles.FormGroup className="mb-3">
            <FormStyles.FormLabel htmlFor="unit-name">
              Unit name*
            </FormStyles.FormLabel>
            <FormStyles.FormControl
              type="text"
              id="unit-name"
              placeholder="Enter unit name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormStyles.FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <FormStyles.EvoliButton variant="light" type="submit">
            Save
          </FormStyles.EvoliButton>
          <FormStyles.EvoliButton
            variant="secondary"
            onClick={clickResetHandler}
          >
            Reset
          </FormStyles.EvoliButton>
        </Modal.Footer>
      </Form>
    </EvoliModal>
  );
};

//Modal to edit a lesson, the editable field is only the name
export const EditLessonModal = ({
  show,
  onHide,
  lesson,
  updateLessonHandler,
}) => {
  const [name, setName] = useState(lesson?.name);

  useEffect(() => {
    setName(lesson?.name);
  }, [lesson]);

  const clickSaveHandler = (e) => {
    e.preventDefault();

    //Update lesson
    if (name.trim().length > 0)
      updateLessonHandler({ lessonUUID: lesson.uuid, name }, onHide);
  };

  const clickResetHandler = () => {
    setName(lesson.name);
  };

  return (
    <EvoliModal show={show} onHide={onHide}>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Edit lesson</Modal.Title>
      </Modal.Header>
      <Form onSubmit={clickSaveHandler}>
        <Modal.Body>
          {/* Lesson name */}
          <FormStyles.FormGroup className="mb-3">
            <FormStyles.FormLabel htmlFor="lesson-name">
              Lesson name*
            </FormStyles.FormLabel>
            <FormStyles.FormControl
              type="text"
              id="lesson-name"
              placeholder="Enter lesson name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormStyles.FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <FormStyles.EvoliButton type="submit">Save</FormStyles.EvoliButton>
          <FormStyles.EvoliButton
            variant="secondary"
            onClick={clickResetHandler}
          >
            Reset
          </FormStyles.EvoliButton>
        </Modal.Footer>
      </Form>
    </EvoliModal>
  );
};
