import React from "react";
import { useGetLessonDetailsQuery } from "../../../store/teachings/teachings.api";
import {
  useGetNextLessonQuery,
  useGetPrevLessonQuery,
} from "../../../store/enrollments/enrollments.api";
import { toastApiError } from "../../../utils/lecture.utils";
import { useNavigate } from "react-router-dom";

const useFetch = (lessonUUID) => {
  const [nextLessonUUID, setNextLessonUUID] = React.useState(null);
  const [prevLessonUUID, setPrevLessonUUID] = React.useState(null);

  const navigate = useNavigate();

  /* Query hooks */
  const {
    data: lesson,
    isLoading: isLoading1,
    isError: isError1,
    error: error1,
    isSuccess: isSuccess1,
  } = useGetLessonDetailsQuery(lessonUUID);
  const {
    data: nextLesson,
    isLoading: isLoading2,
    isError: isError2,
    error: error2,
    isSuccess: isSuccess2,
  } = useGetNextLessonQuery(lessonUUID);
  const {
    data: prevLesson,
    isLoading: isLoading3,
    isError: isError3,
    error: error3,
    isSuccess: isSuccess3,
  } = useGetPrevLessonQuery(lessonUUID);

  const isLoading = isLoading1 || isLoading2 || isLoading3;
  const isError = isError1 || isError2 || isError3;
  const error = error1 ?? error2 ?? error3;
  const isSuccess = isSuccess1 && isSuccess2 && isSuccess3;

  React.useEffect(() => {
    if (isError || !lessonUUID || (isSuccess && !lesson)) {
      if (error) toastApiError(error);
      navigate("/teacher/home/courses");
      return;
    } else if (isSuccess) {
      if (nextLesson) setNextLessonUUID(nextLesson?.uuid);
      if (prevLesson) setPrevLessonUUID(prevLesson?.uuid);
    }
  }, [
    error,
    isError,
    isSuccess,
    lesson,
    lessonUUID,
    navigate,
    nextLesson,
    prevLesson,
  ]);

  return {
    data: {
      lesson,
      nextLessonUUID,
      prevLessonUUID,
    },
    isLoading,
    isSuccess,
  };
};

export default useFetch;
