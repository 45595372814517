import React from "react";
import { toHoursMins } from "../../../../utils/lecture.utils";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { EvoliButton } from "../../../../styles/Forms.styles";
import Styles from "./styles";
import Number from "../../../../components/Animations/Number";
import useFetch from "./hooks/useFetch";
import EvoliMultiButton from "../../../../components/Commons/EvoliMultiButton";
import HashLoader from "react-spinners/HashLoader";
import { useTheme } from "styled-components";
import { OverviewCard } from "../../../../styles/Shared.styles";

const Content = () => {
  const { course } = useOutletContext();
  const theme = useTheme();

  const units = course.units;
  const [overviewPeriod, setOverviewPeriod] = React.useState(0); // 0 = 1 day, 1 = 7 days, 2 = 30 days
  const texts = ["Last 24 hours", "Last 7 days", "Last 30 days"];

  //Get the course overview
  const { overview, isError, isFetching } = useFetch({
    courseUUID: course.uuid,
    period:
      overviewPeriod === 0 ? "day" : overviewPeriod === 1 ? "week" : "month",
  });

  /* Calculate the total number of chapters */
  const lessonsCount = units.reduce((total, unit) => {
    return total + unit.lessons.length;
  }, 0);

  /* Calculate the total number of video minutes: the enrollment has a course, the course has lectures, a lecture has chapter, a chapter could have a startSecond and a endSecond  */
  const totalVideoSeconds = units.reduce((total, unit) => {
    return (
      total +
      unit.lessons.reduce((total, lesson) => {
        if (lesson.startSecond === null || lesson.endSecond === null)
          return total;
        return total + (lesson.endSecond - lesson.startSecond);
      }, 0)
    );
  }, 0);

  const oveviewVideoHours = overview?.viewTime
    ? Math.floor(overview.viewTime / 3600)
    : 0;
  const oveviewVideoMinutesLeft = overview?.viewTime
    ? Math.floor((overview.viewTime - oveviewVideoHours * 3600) / 60)
    : 0;

  return (
    <Container className="py-5">
      {/* Overview */}
      {!isError && (
        <>
          <Row>
            <h2>Quick Overview</h2>
          </Row>
          <Row className="mb-3">
            {/* New enrolled users this week */}
            <Col xs={6} sm={4} md={{ span: 2, offset: 1 }}>
              <OverviewCard>
                <div className="number">
                  {isFetching ? (
                    <HashLoader color={theme.primary} className="m-2" />
                  ) : (
                    <Number number={overview?.newEnrollmentsCount ?? 0} />
                  )}
                </div>
                <div className="text">
                  New <br />
                  enrollments
                </div>
              </OverviewCard>
            </Col>
            {/* Active students */}
            <Col xs={6} sm={4} md={2}>
              <OverviewCard>
                <div className="number">
                  {isFetching ? (
                    <HashLoader color={theme.primary} className="m-2" />
                  ) : (
                    <Number number={overview?.activeStudentsCount ?? 0} />
                  )}
                </div>
                <div className="text">
                  Active <br />
                  students
                </div>
              </OverviewCard>
            </Col>
            {/* Hours of visualizations */}
            <Col xs={6} sm={4} md={2}>
              <OverviewCard>
                {isFetching ? (
                  <div className="number">
                    <HashLoader color={theme.primary} className="m-2" />
                  </div>
                ) : (
                  <div className="number">
                    <Number number={oveviewVideoHours} />
                    <span>:</span>
                    {oveviewVideoMinutesLeft < 10 && (
                      <span className="leading-zero">0</span>
                    )}
                    <Number number={oveviewVideoMinutesLeft} />
                  </div>
                )}
                <div className="text">
                  Connection time
                  <br />
                  (HH:MM)
                </div>
              </OverviewCard>
            </Col>
            {/* Feedback */}
            <Col xs={6} sm={4} md={2}>
              <OverviewCard>
                <div className="number">
                  {isFetching ? (
                    <HashLoader color={theme.primary} className="m-2" />
                  ) : (
                    <Number number={overview?.feedbackCount ?? 0} />
                  )}
                </div>
                <div className="text">
                  Feedback
                  <br />
                  given
                </div>
              </OverviewCard>
            </Col>
            {/* Questions */}
            <Col xs={6} sm={4} md={2}>
              <OverviewCard>
                <div className="number">
                  {isFetching ? (
                    <HashLoader color={theme.primary} className="m-2" />
                  ) : (
                    <Number number={overview?.questionsCount ?? 0} />
                  )}
                </div>
                <div className="text">
                  Questions
                  <br />
                  asked
                </div>
              </OverviewCard>
            </Col>
          </Row>
          <Row className="mb-5">
            <div className="d-flex flex-row justify-content-center">
              <EvoliMultiButton
                active={overviewPeriod}
                setActive={setOverviewPeriod}
                texts={texts}
              />
            </div>
          </Row>
        </>
      )}
      {/* Title */}
      <Row>
        <Col>
          <h2>Students' feedback organized by learning units and lessons</h2>
          <div className="d-flex flex-row justify-content-between align-items-center gap-2">
            {/* [n learning unit] • [n chapters] • [xh ym total video length] • [x document pages] */}
            <div className="d-flex flex-row justify-content-start align-items-center gap-2 mt-2 fs-5">
              {units.length} learning unit
              {units.length > 1 || units.length === 0 ? "s" : ""} •{" "}
              {lessonsCount} lesson
              {lessonsCount > 1 || lessonsCount === 0 ? "s" : ""} •{" "}
              {toHoursMins(totalVideoSeconds)} total video length
            </div>
          </div>
          {/* Display the lectures in an accordition, all items should be open at the beginning */}
          <Accordion
            alwaysOpen
            className="mt-3"
            defaultActiveKey={units.map((_, index) => index.toString())}
          >
            {units.map((unit, index) => (
              <LearningUnitItem unit={unit} index={index} key={index} />
            ))}
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

const LearningUnitItem = ({ unit, index }) => {
  const lessonsCount = unit.lessons.length;
  const totalVideoSeconds = unit.lessons.reduce((total, lesson) => {
    if (lesson.startSecond === null || lesson.endSecond === null) return total;
    return total + (lesson.endSecond - lesson.startSecond);
  }, 0);

  const navigate = useNavigate();

  const clickAnalysisHandler = (e) => {
    //Navigate to the analysis page
    navigate(`/teacher/dashboard/${unit.uuid}`);
  };

  return (
    <Accordion.Item eventKey={index.toString()}>
      {/* [lecture type] [lecture name]          [number of chapters] • [minutes or pages]  */}
      <Accordion.Header>
        <div className="d-flex flex-row justify-content-between align-items-center gap-2 w-100 me-4">
          <h2 className="fs-6">{unit.name}</h2>
          <div className="d-flex flex-row justify-content-end align-items-center gap-1">
            <span>
              {lessonsCount} lesson{lessonsCount > 1 ? "s" : ""}
            </span>
            <span>•</span>
            <span>{toHoursMins(totalVideoSeconds)}</span>
            {/* <span>•</span>
            <span>
              {totalDocPages} page{totalDocPages > 1 ? "s" : ""}
            </span> */}
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        {/* Button that redirect to unit analysis */}
        <div className="d-flex flex-row justify-content-start align-items-center mb-2"></div>
        {/* Display one row for each chapter */}
        {unit.lessons.length === 0 ? (
          <span className="fst-italic text-muted">
            This unit has no lesson yet, click the "Edit course" button to
            create the first lesson.
          </span>
        ) : (
          <>
            {/* Action buttons */}
            <div className="d-flex flex-row justify-content-center align-items-center gap-2 w-100 pb-3 mb-3 border-bottom">
              <EvoliButton id="analysis-btn" onClick={clickAnalysisHandler}>
                <i className="fas fa-chart-bar me-2" /> Unit feedback analysis
              </EvoliButton>
            </div>
            {/* Lessons */}
            {unit.lessons.map((lesson) => (
              <LessonItem lesson={lesson} unit={unit} key={lesson.uuid} />
            ))}
          </>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

const LessonItem = ({ lesson }) => {
  const navigate = useNavigate();

  const totalVideoSeconds = (lesson.endSecond ?? 0) - (lesson.startSecond ?? 0);

  const clickPlayHandler = () => {
    navigate(`/teacher/lesson/${lesson.uuid}`);
  };

  return (
    <Styles.LessonItem>
      <div className="d-flex flex-row gap-2">
        <i
          className="fas fa-chart-bar fs-4 play"
          data-tooltip-content="Go to lesson analysis"
          data-tooltip-id="evoli-tooltip"
          onClick={clickPlayHandler}
        />
        <div className="title">{lesson.name}</div>
      </div>
      <div className="d-flex flex-row justify-content-end align-items-center gap-2 mt-2">
        <div className="d-flex flex-row justify-content-end align-items-center gap-2">
          <span>{toHoursMins(totalVideoSeconds)}</span>
        </div>
      </div>
    </Styles.LessonItem>
  );
};

export default Content;
