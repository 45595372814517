import React from "react";
import useOrder from "./hooks/useOrder";
import useFetch from "./hooks/useFetch";
import Loading from "../PageElements/Loading";
import { EvoliButton } from "../../styles/Forms.styles";
import QuestionItem from "../Question/Question";
import { Pagination } from "react-bootstrap";
import VideoModal from "../QuestionModal/VideoModal";
import LimitSelect from "../Form/LimitSelect";

const CourseQuestions = ({ course }) => {
  const [limit, setLimit] = React.useState(100);
  const [page, setPage] = React.useState(1);
  const [showVideoModal, setShowVideoModal] = React.useState(false);
  const [videoSecond, setVideoSecond] = React.useState(0);
  const [lesson, setLesson] = React.useState(null);

  const offset = (page - 1) * limit;
  const courseUUID = course.uuid;

  const { changeOrderHandler, orderBy, order, orderValue, options, reset } =
    useOrder();

  const { questions, count, isLoading, isSuccess, isError, errorMessage } =
    useFetch({
      courseUUID: courseUUID,
      limit: limit,
      offset: offset,
      order: order,
      orderBy: orderBy,
    });

  const pages = Math.ceil(count / limit);

  const changePageHandler = (pageValue) => {
    if (pageValue >= 1 && pageValue <= pages) setPage(pageValue);
  };

  const clickVideoCallback = (second, lesson) => {
    setLesson(lesson);
    setVideoSecond(second);
    setShowVideoModal(true);
  };

  if (isLoading) return <Loading />;
  if (isError) return <div className="text-danger fw-bold">{errorMessage}</div>;
  if (isSuccess)
    return (
      <>
        <div className="d-flex flex-column flex-md-row gap-2 gap-md-3 w-100 mb-4">
          {/* Limit */}
          <LimitSelect limit={limit} setLimit={setLimit} />
          {/* Order by course name, teacher name, completion rate, understanding rate */}
          <div className="d-flex flex-column gap-1">
            <label>Order by</label>
            <select
              className="form-select"
              value={orderValue}
              onChange={changeOrderHandler}
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          {/* Reset filter and order */}
          <div className="d-flex flex-column justify-content-end">
            <EvoliButton onClick={reset}>Reset</EvoliButton>
          </div>
        </div>
        {/* Questions section */}
        <div className="d-flex flex-column gap-4">
          {questions.map((question) => (
            <QuestionItem
              key={question.uuid}
              lesson={question.lesson}
              question={question}
              clickVideoCallback={() =>
                clickVideoCallback(question.second, question.lesson)
              }
              playerActive={true}
              showLessonInfo={true}
            />
          ))}
        </div>
        {/* Pagination section */}
        <div className="d-flex flex-column gap-1">
          {/* [start index]-[end index] of [count] feedback  */}
          <div className="d-flex flex-row justify-content-center">
            <span className="fs-6 fst-italic">
              {count === 0 ? 0 : page * limit - limit + 1}-
              {page * limit > count ? count : page * limit} of {count} feedback
            </span>
          </div>
          {/* Pagination */}
          <div className="d-flex flex-row justify-content-center">
            <Pagination>
              {page === 1 ? (
                <Pagination.First disabled />
              ) : (
                <Pagination.First onClick={() => changePageHandler(1)} />
              )}
              {page > 1 && (
                <Pagination.Item onClick={() => changePageHandler(page - 1)}>
                  {page - 1}
                </Pagination.Item>
              )}
              <Pagination.Item active>{page}</Pagination.Item>
              {page < pages && (
                <Pagination.Item onClick={() => changePageHandler(page + 1)}>
                  {page + 1}
                </Pagination.Item>
              )}
              {page < pages ? (
                <Pagination.Last onClick={() => changePageHandler(pages)} />
              ) : (
                <Pagination.Last disabled />
              )}
            </Pagination>
          </div>
        </div>
        {/* Video modal */}
        {showVideoModal && (
          <VideoModal
            show={showVideoModal}
            onHide={() => setShowVideoModal(false)}
            lesson={lesson}
            startSecond={videoSecond}
          />
        )}
      </>
    );
};

export default CourseQuestions;
