import { io } from "socket.io-client";
import { te } from "../../utils/errorsTranslation";
import { toastApiError } from "../../utils/lecture.utils";
import { toastError } from "../../utils/Toast";
import {
  connectionEstablished,
  connectionFailed,
  disconnectSocket,
  reconnectionEstablished,
  reconnectionFailed,
  startConnecting,
  startReconnecting,
} from "./feedback.slice";

/* Middleware that intercept the startConnecting action and establish a soscket connection.
Once the connection is established, bind the listeners */
const feedbackMiddleware = (store) => {
  //Socket instance
  let socket = null;

  return (next) => (action) => {
    //If action startConnecting, create a socket connection
    if (startConnecting.match(action)) {
      //If socket is already connected, disconnect it
      if (socket?.connected) socket.disconnect();

      //Get the socket url from the environment variables
      const socketUrl = process.env.REACT_APP_SERVER_URL;
      //Extract the payload
      const { lessonUUID, token } = action.payload;
      //Establish a connection
      socket = io(socketUrl, {
        withCredentials: true,
        reconnectionAttempts: 3,
        auth: { token },
        query: { lessonUUID },

        //Uncomment this to test the reconnection
        /* reconnectionDelay: 10000,
        reconnectionDelayMax: 10000, */
      });

      //Uncomment this to test the reconnection
      /* socket.on("connect", () => {
        console.log("recovered?", socket.recovered);

        setTimeout(() => {
          if (socket.io.engine) {
            // close the low-level connection and trigger a reconnection
            socket.io.engine.close();
          }
        }, 10000);
      }); */

      //On connection authorized dispatch the connectionEstablished action
      socket.on("connection:authorized", ({ visualizationCode }) => {
        store.dispatch(connectionEstablished(visualizationCode));
      });

      //On error dispatch the disconnectSocket action, NOTE: I disconnect socket every time I have an error
      socket.on("error", (err) => {
        toastApiError(err);
      });

      //Fired upon an attempt to reconnect.
      socket.io.on("reconnect_attempt", (attempt) => {
        store.dispatch(startReconnecting());
        console.log("reconnect_attempt", attempt);
      });

      //Fired when couldn't reconnect within reconnectionAttempts
      socket.io.on("reconnect_failed", () => {
        toastError(te("socket_error"));
        store.dispatch(reconnectionFailed());
        console.log("reconnect_failed");
      });

      //Fired upon a successful reconnection.
      socket.io.on("reconnect", (attempt) => {
        store.dispatch(reconnectionEstablished());
        console.log("reconnect", attempt);
      });

      //Fired upon a disconnection.
      socket.on("disconnect", (reason) => {
        console.log("disconnect", reason);
        //Understand if the disconnection is originated by the server, with socket.disconnect() or by the client
        if (reason === "io server disconnect") {
          socket.disconnect();
          store.dispatch(connectionFailed());
        }
      });
    }

    //If action is disconnectSocket, disconnect the socket
    if (disconnectSocket.match(action)) {
      if (socket?.connected) socket.disconnect();
    }

    //Pass to the next middleware
    next(action);
  };
};

export default feedbackMiddleware;
