import React, { useContext } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { ThemeContext } from "../../styles/ThemeContext";
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  zoomPlugin
);

const UnitLessonsVisualizationsHistogram = ({
  lessons,
  visualizators,
  feedbackCount,
}) => {
  //Load theme
  const { theme } = useContext(ThemeContext);
  //Find the max value
  const max = Math.max(...feedbackCount, ...visualizators);

  const data = {
    labels: lessons.map((l) => {
      //If name is too long, cut it at 20 characters and add "..."
      if (l.name.length > 20) return l.name.substring(0, 20) + "...";
      return l.name;
    }),
    datasets: [
      {
        type: "bar",
        label: "Visualizators number",
        backgroundColor: `rgba(${theme.secondaryRGB},0.5)`,
        borderColor: `rgba(${theme.secondaryRGB},1)`,
        borderWidth: 2,
        fill: false,
        data: visualizators,
      },
      {
        type: "bar",
        label: "Feedback number",
        backgroundColor: `rgba(${theme.accentRGB},0.5)`,
        borderColor: `rgba(${theme.accentRGB},1)`,
        borderWidth: 2,
        fill: false,
        data: feedbackCount,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
        max: max,
        ticks: {
          stepSize: 1,
          beginAtZero: true,
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },

    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US").format(context.parsed.y);
            }
            return label;
          },
        },
      },
      zoom: {
        limits: {
          x: { min: 0, max: lessons.length, minRange: 1 },
        },
        pan: {
          enabled: true, // SET PINCH ZOOM TO TRUE
          mode: "x",
          scaleMode: "x",
        },
        zoom: {
          wheel: {
            enabled: true, // SET SCROOL ZOOM TO TRUE
          },
          pinch: {
            enabled: true, // SET PINCH ZOOM TO TRUE
          },
          mode: "x",
          speed: 10,
        },
      },
      datalabels: {
        formatter: function (value, context) {
          if (value > 0) return value;
          return "";
        },
        color: function (context) {
          return context.dataset.borderColor;
        },
        align: "top",
        anchor: "end",
        padding: -4,
        font: {
          size: "20",
          weight: "bold",
        },
      },
    },
  };

  return <Bar options={options} data={data} plugins={[ChartDataLabels]} />;
};

export default UnitLessonsVisualizationsHistogram;
