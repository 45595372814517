import React, { useEffect, useState } from "react";
import {
  useGetFeedbackQuery,
  useUpsertLessonFeedbackMutation,
} from "../../../../../store/feedback/feedback.api";
import { useGetUserVideoAnalyticsQuery } from "../../../../../store/analytics/analytics.api";
import {
  useGetNextLessonQuery,
  useGetPrevLessonQuery,
} from "../../../../../store/enrollments/enrollments.api";
import { te } from "../../../../../utils/errorsTranslation";

const useFetch = (lessonUUID) => {
  //Query hooks
  const {
    data: myFeedback,
    isLoading: isLoading1,
    isError: isError1,
    error: error1,
    isSuccess: isSuccess1,
  } = useGetFeedbackQuery(lessonUUID);
  const {
    data: visualizationInfo,
    isLoading: isLoading2,
    isError: isError2,
    error: error2,
    isSuccess: isSuccess2,
  } = useGetUserVideoAnalyticsQuery(lessonUUID);
  const {
    data: nextLesson,
    isLoading: isLoading3,
    isError: isError3,
    error: error3,
    isSuccess: isSuccess3,
  } = useGetNextLessonQuery(lessonUUID);
  const {
    data: prevLesson,
    isLoading: isLoading5,
    isError: isError5,
    error: error5,
    isSuccess: isSuccess5,
  } = useGetPrevLessonQuery(lessonUUID);
  const [createFeedback, { isLoading: isLoading4 }] =
    useUpsertLessonFeedbackMutation();

  //Component state
  const [completionRate, setCompletionRate] = React.useState(0);
  const [nextLessonUUID, setNextLessonUUID] = React.useState(null);
  const [prevLessonUUID, setPrevLessonUUID] = React.useState(null);

  const isError = isError1 || isError2 || isError3 || isError5;
  const error = error1 ?? error2 ?? error3 ?? error5;
  const isLoading =
    isLoading1 || isLoading2 || isLoading3 || isLoading4 || isLoading5;
  const isSuccess = isSuccess1 && isSuccess2 && isSuccess3 && isSuccess5;
  const data = {
    studentFeedback: myFeedback,
    completionRate,
    nextLessonUUID,
    prevLessonUUID,
  };

  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (isError) {
      if (!error.data?.code && !error.code)
        setErrorMessage(te("general_error"));
      else setErrorMessage(te(error.data?.code ?? error.code));
    } else if (isSuccess) {
      //isSuccess3 instead of nextLesson because nextLesson can be null
      setErrorMessage(null);
      setCompletionRate(visualizationInfo.percentage);
      setNextLessonUUID(nextLesson?.uuid);
      setPrevLessonUUID(prevLesson?.uuid);
    }
  }, [
    error,
    isError,
    myFeedback,
    visualizationInfo,
    nextLesson,
    isSuccess,
    prevLesson?.uuid,
  ]);

  return { data, isLoading, isError, errorMessage, isSuccess, createFeedback };
};

export default useFetch;
