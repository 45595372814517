import React from "react";
import { useGetAdminUsersQuery } from "../../../../../../store/admin/admin.api";
import { userColumns } from "../columns";
import { Container, Pagination, Row, Table } from "react-bootstrap";
import usePagination from "./hooks/usePagination";
import SearchBar from "../../../../../../components/Form/SearchBar";
import { EvoliButton } from "../../../../../../styles/Forms.styles";
import Loading from "../../../../../../components/PageElements/Loading";

const columns = userColumns;

const UsersTable = () => {
  //Pagination
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchQueryValue, setSearchQueryValue] = React.useState("");
  const searchInputRef = React.useRef(null);

  const {
    changeOrderHandler,
    offset,
    orderBy,
    order,
    orderValue,
    options,
    reset,
  } = usePagination({
    limit,
    page,
    searchInputRef,
    setSearchQuery,
    setSearchQueryValue,
  });

  const { isSuccess, data, isFetching, isError } = useGetAdminUsersQuery({
    limit,
    offset,
    order,
    orderBy,
    searchQuery,
  });

  const changePageHandler = (pageValue) => {
    if (pageValue >= 1 && pageValue <= pages) setPage(pageValue);
  };

  const changeSearchHandler = (e) => {
    const value = e.target.value;
    setSearchQueryValue(value);
  };

  const submitSearchHandler = (e) => {
    e.preventDefault();
    setSearchQuery(searchQueryValue);
    setPage(1);
  };

  const itemsCount = data?.count ?? 0;
  const pages = Math.ceil(itemsCount / limit);

  if (isFetching) return <Loading />;
  if (isError)
    return (
      <div className="fw-bolder text-danger">Error while fetching data</div>
    );
  if (isSuccess)
    return (
      <Container>
        <Row>
          <div className="d-flex flex-column flex-md-row gap-2 gap-md-3 w-100 mb-4">
            {/* Limit */}
            <div className="d-flex flex-column gap-1">
              <label>Limit</label>
              <select
                className="form-select"
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            {/* Order by course name, teacher name, completion rate, understanding rate */}
            <div className="d-flex flex-column gap-1">
              <label>Order by</label>
              <select
                className="form-select"
                value={orderValue}
                onChange={changeOrderHandler}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            {/* Search box */}
            <div className="d-flex flex-row align-items-end gap-2">
              <div className="d-flex flex-column flex-grow-1 gap-1">
                <label>Search</label>
                <SearchBar
                  className="w-100"
                  inputRef={searchInputRef}
                  placeholder={"Search by name or email"}
                  onChange={changeSearchHandler}
                  onSubmit={submitSearchHandler}
                />
              </div>
              {/* Reset filter and order */}
              <EvoliButton onClick={reset}>Reset</EvoliButton>
            </div>
          </div>
          {/* If itemsCount is 0 display a message instead of the table */}
          {itemsCount === 0 ? (
            <div className="fst-italic">No users found</div>
          ) : (
            <>
              {/* Table section */}
              <Table bordered>
                <thead>
                  <tr>
                    {columns.map((column) => (
                      <th key={column.name}>{column.header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data?.users.map((user) => (
                    <tr key={user.email}>
                      {columns.map((column) => (
                        <td key={column.name}>{column.getCell(user)}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
              {/* Pagination section, show 12 courses */}
              <div className="d-flex flex-column gap-1">
                {/* [start index]-[end index] of [count] courses  */}
                <div className="d-flex flex-row justify-content-center">
                  <span className="fs-6 fst-italic">
                    {itemsCount === 0 ? 0 : page * limit - limit + 1}-
                    {page * limit > itemsCount ? itemsCount : page * limit} of{" "}
                    {itemsCount} users
                  </span>
                </div>
                {/* Pagination */}
                <div className="d-flex flex-row justify-content-center">
                  <Pagination>
                    {page === 1 ? (
                      <Pagination.First disabled />
                    ) : (
                      <Pagination.First onClick={() => changePageHandler(1)} />
                    )}
                    {page > 1 && (
                      <Pagination.Item
                        onClick={() => changePageHandler(page - 1)}
                      >
                        {page - 1}
                      </Pagination.Item>
                    )}
                    <Pagination.Item active>{page}</Pagination.Item>
                    {page < pages && (
                      <Pagination.Item
                        onClick={() => changePageHandler(page + 1)}
                      >
                        {page + 1}
                      </Pagination.Item>
                    )}
                    {page < pages ? (
                      <Pagination.Last
                        onClick={() => changePageHandler(pages)}
                      />
                    ) : (
                      <Pagination.Last disabled />
                    )}
                  </Pagination>
                </div>
              </div>
            </>
          )}
        </Row>
      </Container>
    );
};

export default UsersTable;
