import React, { useRef, useState } from "react";
import {
  Container,
  Pagination,
  ProgressBar,
  Row,
  Table,
} from "react-bootstrap";
import useFetch from "./hooks/useFetch";
import Loading from "../../../../components/PageElements/Loading";
import { EvoliButton } from "../../../../styles/Forms.styles";
import { useOutletContext } from "react-router-dom";
import InfoTooltip from "../../../../components/Commons/InfoTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { getLastAccessString } from "../../../../utils/time.utils";
import { toHHMMSS } from "../../../../utils/lecture.utils";
import usePagination from "./hooks/usePagination";
import SearchBar from "../../../../components/Form/SearchBar";
import LimitSelect from "../../../../components/Form/LimitSelect";

const Students = () => {
  const { course } = useOutletContext();
  //Count the number of lessons in the course, a course has more units, a unit has more lessons
  const courseLessonsCount = course.units.reduce(
    (acc, unit) => acc + unit.lessons.length,
    0
  );

  /* Component state */
  const [limit, setLimit] = useState(100); //Number of students per page
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [showNames, setShowNames] = useState(false);
  const [searchQueryValue, setSearchQueryValue] = useState("");
  const searchInputRef = useRef(null);

  const {
    changeOrderHandler,
    offset,
    order,
    orderBy,
    orderValue,
    options,
    reset,
  } = usePagination({
    limit,
    page,
    searchInputRef,
    setSearchQuery,
    setSearchQueryValue,
  });

  const {
    data: { enrollments, itemsCount },
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  } = useFetch({
    courseUUID: course.uuid,
    limit,
    offset,
    order,
    orderBy,
    searchQuery,
  });

  const pages = Math.ceil(itemsCount / limit);

  const changePageHandler = (pageValue) => {
    if (pageValue >= 1 && pageValue <= pages) setPage(pageValue);
  };

  const changeSearchHandler = (e) => {
    const value = e.target.value;
    setSearchQueryValue(value);
  };

  const submitSearchHandler = (e) => {
    e.preventDefault();
    setSearchQuery(searchQueryValue);
    setPage(1);
  };

  if (isLoading) return <Loading />;
  if (isError) return <div className="fw-bold text-danger">{errorMessage}</div>;
  if (isSuccess)
    return (
      <Container className="py-5">
        <Row>
          <h2>Students enrolled</h2>
          <p className="mb-4">
            Discover how many students are enrolled in your course and how they
            are progressing through the lessons.
          </p>
          {/* Ordering */}
          <div className="d-flex flex-column flex-md-row gap-2 gap-md-3 w-100 mb-4">
            {/* Limit */}
            <LimitSelect limit={limit} setLimit={setLimit} />
            {/* Order by firstName, lastName, lastCourseAccessDate, totalSecondsViewed, completedLessons */}
            <div className="d-flex flex-column gap-1">
              <label>Order by</label>
              <select
                className="form-select"
                value={orderValue}
                onChange={changeOrderHandler}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            {/* Search box */}
            <div className="d-flex flex-row align-items-end gap-2">
              <div className="d-flex flex-column flex-grow-1 gap-1">
                <label>Search</label>
                <SearchBar
                  className="w-100"
                  inputRef={searchInputRef}
                  placeholder={"Search my students"}
                  onChange={changeSearchHandler}
                  onSubmit={submitSearchHandler}
                />
              </div>
              {/* Reset filter and order */}
              <EvoliButton onClick={reset}>Reset</EvoliButton>
            </div>
          </div>
        </Row>
        <Row>
          <Table striped borderless hover>
            <thead>
              <tr className="text-center">
                <th className="d-flex flex-row justify-content-center align-items-center gap-2">
                  <span>Student</span>
                  <EvoliButton onClick={() => setShowNames(!showNames)}>
                    <FontAwesomeIcon icon={showNames ? faEyeSlash : faEye} />
                  </EvoliButton>
                </th>
                <th>
                  <span className="me-1"> Last course access</span>
                  <InfoTooltip text="The last time the student accessed a lesson of the course" />
                </th>
                <th>
                  <span className="me-1">Total connection time (HH:MM:SS)</span>
                  <InfoTooltip text="The total time the student spent in the lesson pages of the course" />
                </th>
                <th>
                  <span className="me-1">Completed lessons</span>
                  <InfoTooltip text="The number of lessons that the student has given a feedback" />
                </th>
              </tr>
            </thead>
            <tbody>
              {enrollments.map((enrollment) => (
                <StudentItem
                  key={enrollment.user.email}
                  enrollment={enrollment}
                  showNames={showNames}
                  lessonCount={courseLessonsCount}
                />
              ))}
            </tbody>
          </Table>
        </Row>
        <Row>
          {/* Pagination section, show 12 courses */}
          <div className="d-flex flex-column gap-1">
            {/* [start index]-[end index] of [count] courses  */}
            <div className="d-flex flex-row justify-content-center">
              <span className="fs-6 fst-italic">
                {itemsCount === 0 ? 0 : page * limit - limit + 1}-
                {page * limit > itemsCount ? itemsCount : page * limit} of{" "}
                {itemsCount} students
              </span>
            </div>
            {/* Pagination */}
            <div className="d-flex flex-row justify-content-center">
              <Pagination>
                {page === 1 ? (
                  <Pagination.First disabled />
                ) : (
                  <Pagination.First onClick={() => changePageHandler(1)} />
                )}
                {page > 1 && (
                  <Pagination.Item onClick={() => changePageHandler(page - 1)}>
                    {page - 1}
                  </Pagination.Item>
                )}
                <Pagination.Item active>{page}</Pagination.Item>
                {page < pages && (
                  <Pagination.Item onClick={() => changePageHandler(page + 1)}>
                    {page + 1}
                  </Pagination.Item>
                )}
                {page < pages ? (
                  <Pagination.Last onClick={() => changePageHandler(pages)} />
                ) : (
                  <Pagination.Last disabled />
                )}
              </Pagination>
            </div>
          </div>
        </Row>
      </Container>
    );
};

const StudentItem = ({ enrollment, showNames, lessonCount }) => {
  const {
    user: { firstName, lastName },
    lastCourseAccessDate,
    totalSecondsViewed,
    completedLessons,
    //lastAccessedLesson,
  } = enrollment;

  //Get the Viewing time in HH:MM format
  let viewTime = toHHMMSS(totalSecondsViewed);

  const fullName = showNames ? `${lastName} ${firstName}` : "Hidden name";
  const percentage = (completedLessons / lessonCount) * 100;
  //Color is based on the percentage of completed lessons: red < 33%, yellow < 66%, green > 66%
  const color =
    percentage < 33 ? "danger" : percentage < 66 ? "warning" : "success";

  return (
    <tr className="w-100 text-center">
      <td className={!showNames ? "fst-italic" : ""}>{fullName}</td>
      <td>{getLastAccessString(lastCourseAccessDate)}</td>
      <td>{viewTime}</td>
      <td>
        <ProgressBar
          min={0}
          max={lessonCount}
          now={completedLessons}
          label={
            <span className="fw-bold">
              {completedLessons} / {lessonCount}
            </span>
          }
          variant={color}
        />
      </td>
    </tr>
  );
};

export default Students;
