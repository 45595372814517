import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18/i18n";
import Loading from "./components/PageElements/Loading";
import { MyThemeProvider } from "./styles/ThemeContext";
import "bootstrap/dist/css/bootstrap.min.css";
/* Redux */
import { persistor, store } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

const loadingMarkup = <Loading page={true} />;

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={loadingMarkup} persistor={persistor}>
          <MyThemeProvider>
            <App />
          </MyThemeProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
