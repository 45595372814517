import React, { useState } from "react";
import Loading from "../../../../components/PageElements/Loading";
import Styles from "./styles";
import { Container } from "react-bootstrap";
import useFetch from "./hooks/useFetch";
import {
  DifficultyIcon,
  InitialKnowledgeIcon,
  UnderstandingIcon,
} from "../../../../components/Commons/FeedbackIcons";
import { EvoliButton } from "../../../../styles/Forms.styles";
import useOrder from "./hooks/useOrder";
import FeedbackSummaryChart from "../../../../components/Charts/FeedbackSummaryChart";
import LessonFeedback from "../../../../components/LessonFeedback/LessonFeedback";

const ClassroomFeedback = ({ lesson }) => {
  const { summary, isLoading, isError, errorMessage, isSuccess } = useFetch({
    lessonUUID: lesson.uuid,
  });

  const course = lesson.unit.course;
  const understandingScale = course.understandingScale;
  const difficultyScale = course.difficultyScale;
  const isDifficultyEnabled = course.difficultyEnabled;
  const isInitialKnowledgeEnabled = course.initialKnowledgeEnabled;

  if (isLoading) return <Loading page={false} />;
  if (isError) return <div className="text-danger fw-bold">{errorMessage}</div>;
  if (isSuccess)
    return (
      <Styles.Container>
        {/* Understanding */}
        <div className="d-flex flex-column gap-2">
          <h2 className="fs-5">
            <UnderstandingIcon className="me-1" />
            Collective Understanding Analysis
          </h2>
          <span>
            Explore How Classmates Have Grasped the Lesson: Levels from 1
            ("Completely Unclear") to {understandingScale} ("Completely Clear")
          </span>
          <div
            style={{ height: "30vh" }}
            className="d-flex flex-row justify-content-center align-items-center mt-3"
          >
            <FeedbackSummaryChart
              summary={summary.understandingSummary}
              numOfLevels={understandingScale}
            />
          </div>
        </div>
        {/* Initial knowledge */}
        {isInitialKnowledgeEnabled && (
          <div className="d-flex flex-column gap-2">
            <h2 className="fs-5 mt-3">
              <InitialKnowledgeIcon className="me-1" />
              Collective Initial Knowledge Analysis
            </h2>
            <span>
              Explore the starting point of your classmates: Levels from 1 ("No
              knowledge") to {understandingScale} ("Expert")
            </span>
            <div
              style={{ height: "30vh" }}
              className="d-flex flex-row justify-content-center align-items-center mt-3"
            >
              <FeedbackSummaryChart
                summary={summary.initialKnowledgeSummary}
                numOfLevels={understandingScale}
              />
            </div>
          </div>
        )}
        {/* Difficulty */}
        {isDifficultyEnabled && (
          <div className="d-flex flex-column gap-2">
            <h2 className="fs-5 mt-3">
              <DifficultyIcon className="me-1" />
              Collective Difficulty Analysis
            </h2>
            <span>
              Explore How Classmates Have Found the Lesson: Levels from 1 ("Very
              easy") to {difficultyScale} ("Very difficult")
            </span>
            <div
              style={{ height: "30vh" }}
              className="d-flex flex-row justify-content-center align-items-center mt-3"
            >
              <FeedbackSummaryChart
                summary={summary.difficultySummary}
                numOfLevels={difficultyScale}
                scaleInverted={true}
              />
            </div>
          </div>
        )}
        {/* Feedback */}
        <FeedbackTable lesson={lesson} />
      </Styles.Container>
    );
};

const FeedbackTable = ({ lesson }) => {
  const course = lesson.unit.course;
  const [limit, setLimit] = useState(25);
  const { changeOrderHandler, orderBy, order, orderValue, options, reset } =
    useOrder();

  return (
    <Container>
      {/* Title */}
      <h4 className="mt-3">Comments</h4>
      <div className="d-flex flex-column flex-md-row gap-2 gap-md-3 w-100 mb-4">
        {/* Limit */}
        <div className="d-flex flex-column gap-1">
          <label>Limit</label>
          <select
            className="form-select"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        {/* Order by course name, teacher name, completion rate, understanding rate */}
        <div className="d-flex flex-column gap-1">
          <label>Order by</label>
          <select
            className="form-select"
            value={orderValue}
            onChange={changeOrderHandler}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        {/* Reset filter and order */}
        <div className="d-flex flex-column justify-content-end">
          <EvoliButton onClick={reset}>Reset</EvoliButton>
        </div>
      </div>
      <LessonFeedback
        difficultyEnabled={course.difficultyEnabled}
        difficultyScale={course.difficultyScale}
        initialKnowledgeEnabled={course.initialKnowledgeEnabled}
        understandingScale={course.understandingScale}
        limit={limit}
        order={order}
        orderBy={orderBy}
        lessonUUID={lesson.uuid}
      />
    </Container>
  );
};

export default ClassroomFeedback;
