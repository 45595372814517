import { Row } from "react-bootstrap";
import styled from "styled-components";

const Styles = {};

Styles.ReactionRow = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;

  .placeholder-reaction {
    opacity: 0.7;
  }

  :hover {
    .placeholder-reaction {
      display: none;
    }
  }
`;

Styles.Reaction = styled.div`
  display: ${(props) => (props.visible ? "block" : "none")};

  ${Styles.ReactionRow}:hover & {
    display: block;
  }

  .fas {
    &:hover {
      cursor: pointer;
      opacity: 1;
      transform: scale(1.2);

      transition: transform 0.2s ease-in-out;
    }

    opacity: ${(props) => (props.userReacted ? "1" : "0.5")};
  }
`;

export default Styles;
