import styled from "styled-components";

const Styles = {};

Styles.ContentSection = styled.div`
  background-color: ${(p) => p.theme.textPrimary};
  color: ${(p) => p.theme.primary};
`;

Styles.LessonItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 0.5rem;

  .play {
    color: ${(p) => p.theme.accent};
    cursor: pointer;
    font-size: 1.5rem;
  }
`;

export default Styles;
