import styled from "styled-components";

const Styles = {};

Styles.PageContainer = styled.div`
  background: ${(p) => p.theme.textPrimary};
  overflow: hidden;
  width: 100vw;
  height: 100%;

  @media (min-width: 961px) {
    --sidebar-width: ${(props) => props.sidebarWidth}rem;
    --panel-width: calc(
      ${(props) => props.panelWidth}vw - var(--sidebar-width)
    );
  }

  @media (max-width: 961px) and (orientation: portrait) {
    --panel-width: 100vw;
    --sidebar-width: 0;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 961px) and (orientation: landscape) {
    --sidebar-width: ${(props) => props.sidebarWidth}rem;
    --panel-width: calc(100vw - var(--sidebar-width));
  }

  .main-content {
    display: flex;
    flex-direction: column;

    @media (orientation: landscape) or (min-width: 961px) {
      margin-right: var(--sidebar-width);
      padding: ${(props) =>
        props.isPanelOpen && !props.isFullScreen
          ? "0 var(--panel-width) 0 0"
          : "0"};
      width: calc(100% - var(--sidebar-width));
      position: absolute;
      display: flex;
      height: 100% !important;
    }

    @media (max-width: 961px) and (orientation: portrait) {
      width: 100%;
      height: ${(props) =>
        props.isPanelOpen && !props.isFullScreen
          ? "0"
          : props.portraitContentHeight + "vh"};
    }
  }

  .waiting {
    border: 3px solid ${(p) => p.theme.primary};
    background: ${(p) => p.theme.accent};
    color: ${(p) => p.theme.textPrimary};
    padding: 1rem;
  }

  .send-button {
    color: ${(p) => p.theme.textPrimary};
    background: ${(p) => p.theme.secondary};
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 99em;
  }

  .slidepanel-controls {
    background: ${(p) => p.theme.primary};
    color: ${(p) => p.theme.secondary};
    height: fit-content;
    border-radius: 0.5em;

    .fas {
      :hover {
        transform: scale(1.2);
      }
    }
  }
`;

Styles.SidePanel = styled.div`
  display: flex;
  flex-direction: column;
  width: var(--panel-width);
  background-color: #fff;
  z-index: 100;
  transition: all 0.2s ease-in-out;

  @media (orientation: landscape) or (min-width: 961px) {
    position: absolute;
    right: var(--sidebar-width) !important;
    border-radius: 10px 0 0 10px;
    height: 100%;
    right: 0;
  }

  @media (max-width: 961px) and (orientation: portrait) {
    height: 90vh;
  }

  .resize-btn {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 0.4rem;
    color: ${(p) => p.theme.accent};
    font-size: 1.2rem;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }

    @media (max-width: 961px) {
      display: none;
    }
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 1rem;
    color: ${(p) => p.theme.accent};
    font-size: 1.2rem;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }
  }

  .tab-content {
    height: 100%;
  }

  .tab-pane {
    height: 100%;
    border-radius: 0.5em 0 0 0;
  }

  .nav-tabs {
    border-bottom: none;
  }

  .content {
    overflow-y: scroll;
  }
`;

Styles.Sidenav = styled.div`
  border: none;
  z-index: 10;

  color: ${(p) => p.theme.textPrimary};
  background: ${(p) => p.theme.primary};

  @media (orientation: landscape) or (min-width: 961px) {
    position: absolute;
    height: 100%;
    width: ${(p) => p.sidebarWidth}rem;
    right: 0;
    display: flex;
    flex-direction: column;
  }

  @media (orientation: portrait) and (max-width: 961px) {
    width: 100%;
    height: 10vh;
  }

  #sidenav-content {
    width: 5rem;
  }

  .menu-bars {
    background: ${(p) => p.theme.primary};
    margin: 1.5rem 0 0.2rem 1.5rem;
  }

  .lecture-type {
    color: #f45058;
  }

  .menu {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    color: ${(p) => p.theme.textPrimary};
    background: ${(p) => p.theme.primary};

    @media (orientation: landscape) or (min-width: 961px) {
      flex-direction: column;
    }
  }

  .menu-item {
    position: relative !important;
    all: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 0 0.5rem 0;
    cursor: pointer;
    width: 100%;
    transition: all 0.2s ease-in-out;

    @media (max-width: 961px) and (orientation: portrait) {
      justify-content: center;

      &.hide-sm {
        display: none;
      }
    }

    .notification {
      @media (orientation: landscape) or (min-width: 961px) {
        position: absolute;
        top: 0;
        right: 0;
      }

      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;

      background-color: red;
      color: ${(p) => p.theme.textPrimary};
      font-size: 0.8rem;
      border-radius: 50%;
      margin: 0.3rem;
      height: 15px;
      width: 15px;

      &.always {
        background-color: ${(p) => p.theme.textPrimary};
        color: ${(p) => p.theme.primary};
      }
    }

    &.slides {
      @media (max-width: 961px) and (orientation: portrait) {
        display: none;
      }
    }

    &.settings {
      @media (orientation: landscape) or (min-width: 961px) {
        margin-top: auto;
      }
    }

    &.active {
      color: ${(p) => p.theme.accent};

      :hover {
        color: ${(p) => p.theme.accent};
      }
    }

    i {
      font-size: 1.5rem;

      @media (max-width: 961px) and (orientation: portrait) {
        font-size: 2.3rem;
      }
    }

    &:hover {
      color: ${(p) => p.theme.textPrimary};
      background: rgba(var(--secondary-dark-rgb), 0.2);
    }

    .item-name {
      padding-top: 0.2rem;
      font-size: 0.8rem;

      @media (max-width: 961px) {
        display: none;
      }
    }

    &.completed {
      background-color: #198754;
      color: #fff;
      font-weight: bold;

      @media (max-width: 961px) and (orientation: landscape) {
        display: none;
      }
    }

    &.no-feedback {
      background-color: #ffc107;
      color: #000;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .nav-reactions {
    @media (max-width: 961px) and (orientation: portrait) {
      display: none;
    }
  }
`;

export default Styles;
