import React, { useEffect, useState } from "react";
import Loading from "../../../../components/PageElements/Loading";
import UnitLessonsVisualizationsHistogram from "../../../../components/Charts/UnitLessonsVisualizationsHistogram";
import { useOutletContext } from "react-router-dom";
import Styles from "./styles";
import useFetch from "./hooks/useFetch";
import { Col, Container, Row } from "react-bootstrap";

const UnitVisualizations = () => {
  const { unit } = useOutletContext();

  const {
    data: { unitVisualizations, unitFeedbackCountPerLesson },
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  } = useFetch({ unit });

  const [lessons, setLessons] = useState([]);
  const [visualizators, setVisualizators] = useState([]);
  const [feedbackCount, setFeedbackCount] = useState([]);

  //Check if there is an error
  useEffect(() => {
    if (isSuccess) {
      const tmpVisualizators = [],
        tmpLessons = [],
        tmpFeedbackCounts = [];

      unitVisualizations.forEach((d) => {
        tmpVisualizators.push(d.visualizators);
        tmpLessons.push(d.lesson);
      });
      unitFeedbackCountPerLesson.forEach((d) => {
        tmpFeedbackCounts.push(d.count);
      });
      setVisualizators(tmpVisualizators);
      setLessons(tmpLessons);
      setFeedbackCount(tmpFeedbackCounts);
    }
  }, [isSuccess, unitVisualizations, unitFeedbackCountPerLesson]);

  if (isLoading) return <Loading page={false} />;
  if (isError) return <div className="text-danger fw-bold">{errorMessage}</div>;
  if (isSuccess)
    return (
      <Container className="py-5">
        <Row>
          <Col>
            <h2>Unit visualizations</h2>
            <p>
              This chart shows the number of different users (“visualizators”)
              and the students that have completed the lesson of the unit giving
              a feedback.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Styles.ChartContainer>
              <div className="chart-container">
                <UnitLessonsVisualizationsHistogram
                  lessons={lessons}
                  visualizators={visualizators}
                  feedbackCount={feedbackCount}
                />
              </div>
            </Styles.ChartContainer>
          </Col>
        </Row>
      </Container>
    );
};

export default UnitVisualizations;
