import React from "react";
import { te } from "../utils/errorsTranslation";

const useErrorMessage = ({ isError, error }) => {
  const [errorMessage, setErrorMessage] = React.useState(null);

  //Check if there is an error
  React.useEffect(() => {
    if (isError) {
      if (!error.data?.code && !error.code)
        setErrorMessage(te("general_error"));
      else setErrorMessage(te(error.data?.code ?? error.code));
    } else {
      setErrorMessage(null);
    }
  }, [isError, error]);

  return errorMessage;
};

export default useErrorMessage;
