import { useEffect, useState } from "react";
import { useGetQuestionsQuery } from "../../../../../store/feedback/feedback.api";
import useErrorMessage from "../../../../../hooks/useErrorMessage";

const useFetch = ({ lessonUUID, limit, offset }) => {
  const [questions, setQuestions] = useState([]);
  const [itemsCount, setItemsCount] = useState(0);

  const { data, isLoading, isError, isSuccess, error } = useGetQuestionsQuery({
    lessonUUID,
    limit,
    offset,
    order: "ASC",
  });

  const errorMessage = useErrorMessage({ isError, error });

  useEffect(() => {
    if (isSuccess) {
      setQuestions(data.rows);
      setItemsCount(data.count);
    }
  }, [isSuccess, data]);

  return {
    data: {
      questions,
      itemsCount,
    },
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  };
};

export default useFetch;
