import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const InfoTooltip = ({ text }) => {
  return (
    <Style
      icon={faInfoCircle}
      data-tooltip-content={text}
      data-tooltip-id="evoli-tooltip"
    />
  );
};

export default InfoTooltip;

const Style = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.accent};
`;
