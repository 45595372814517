import React, { useMemo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { getRGBAColors, getRGBColors } from "../../utils/lecture.utils";
import { useTheme } from "styled-components";
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({
  feedbackList,
  numOfLevels,
  title = null,
  scaleInverted = false,
}) => {
  const theme = useTheme();

  //Initialize feedback counts
  const feedbackCounts = useMemo(() => {
    const arr = Array(numOfLevels).fill(0);
    feedbackList.forEach((feedback) => {
      arr[feedback - 1]++;
    });
    return arr;
  }, [numOfLevels, feedbackList]);

  //Get border colors
  const borderColors = useMemo(() => {
    let colors = getRGBAColors(numOfLevels, 1);
    if (scaleInverted) colors = colors.reverse();
    return colors;
  }, [numOfLevels, scaleInverted]);

  //Get background colors
  const backgroundColors = useMemo(() => {
    let colors = getRGBAColors(numOfLevels, 0.2);
    if (scaleInverted) colors = colors.reverse();
    return colors;
  }, [numOfLevels, scaleInverted]);

  //Labels are from 1 to numOfLevels
  const labels = useMemo(() => {
    const arr = [];
    for (let i = 1; i <= numOfLevels; i++) arr.push(i);
    return arr;
  }, [numOfLevels]);

  const data = {
    labels,
    datasets: [
      {
        data: feedbackCounts,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 2,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutout: "60%",
    spacing: 1,
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      title: {
        display: title ? true : false,
        text: title,
        position: "bottom",
        color: theme.primary,
      },
      emptyDoughnut: {
        color: theme.primary,
        width: 10,
        radiusDecrease: 20,
      },
    },
  };

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;
      ctx.save();

      //Get the percentage value
      const sum = data.datasets[0].data.reduce(
        (a, b, index) => a + b * (index + 1),
        0
      );
      const numOfFeedbacks = data.datasets[0].data.reduce((a, b) => a + b, 0);
      //Prepare the common style
      ctx.font = "bolder 1rem sans-serif";
      ctx.fillStyle = theme.primary;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      let text = "No data";

      if (numOfFeedbacks > 0) {
        const percentage =
          numOfFeedbacks > 0
            ? Math.round((sum / (numOfFeedbacks * numOfLevels)) * 100)
            : 0;
        //Get color of the text
        const colors = getRGBColors(numOfLevels);
        let colorIndex = Math.round(percentage / (100 / numOfLevels)) - 1;
        if (scaleInverted) colorIndex = numOfLevels - colorIndex - 1;
        const color = colors[colorIndex];

        ctx.fillStyle = color;
        text = `${percentage}%`;
      }
      //Draw text in the center
      ctx.fillText(
        text,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      );
    },
  };

  const emptyDoughnut = {
    id: "emptyDoughnut",
    afterDraw(chart, args, options) {
      const { datasets } = chart.data;
      const { color, width, radiusDecrease } = options;
      let hasData = false;

      for (let i = 0; i < datasets.length; i += 1) {
        const dataset = datasets[i];
        hasData |= dataset.data.length > 0;
      }

      //Sum of all the datasets values
      const sum = datasets.reduce(
        (a, b) => a + b.data.reduce((c, d) => c + d, 0),
        0
      );

      if (!hasData || sum === 0) {
        const {
          chartArea: { left, top, right, bottom },
          ctx,
        } = chart;
        const centerX = (left + right) / 2;
        const centerY = (top + bottom) / 2;
        const r = Math.min(right - left, bottom - top) / 2;

        ctx.beginPath();
        ctx.lineWidth = width || 2;
        ctx.strokeStyle = color || "rgba(255, 128, 0, 0.5)";
        ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
        ctx.stroke();
      }
    },
  };

  return (
    <Doughnut
      data={data}
      options={options}
      plugins={[textCenter, emptyDoughnut]}
    />
  );
};

export default DoughnutChart;
