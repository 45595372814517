import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const PrivacyPolicyEng = () => {
  return (
    <Container className="py-5">
      <Row>
        <Col>
          <h2 className="text-center">
            PRIVACY STATEMENT PURSUANT TO ARTICLE 13 OF THE GENERAL DATA
            PROTECTION REGULATION (EU) NO 2016/679 ('REGULATION' OR 'GDPR')
          </h2>

          <p>
            This Privacy Statement is addressed to individuals who qualify as
            users of the EVOLI system, a teaching and learning support tool
            developed as a prototype for research purposes by Politecnico di
            Milano, to inform the about the processing of the personal data
            collected as part of use of the system.
          </p>

          <h3>Data Controller</h3>
          <p>
            The Data Controller is Politecnico di Milano, in the person of the
            Director General delegated by the pro-tempore Rector. Contact
            details: <a href="mailto:dirgen@polimi.it">dirgen@polimi.it</a>.
          </p>

          <h3>Data Protection Officer and contact points</h3>
          <p>
            Email:{" "}
            <a href="mailto:privacy@polimi.it" target="blank">
              privacy@polimi.it
            </a>
          </p>

          <h3>Internal Data Processor</h3>
          <p>
            The internal data processor is Nicoletta Di Blas. Contact details:{" "}
            <a href="mailto:nicoletta.diblas@polimi.it" target="blank">
              nicoletta.diblas@polimi.it
            </a>
            .
          </p>

          <h3>Description and purpose of the study</h3>
          <p>
            Evoli is an online platform for teaching through the use of video
            and multimedia learning materials. It was designed exclusively to
            support teaching and learning, aiming to facilitate
            student-professor dialogue on the clarity of shared educational
            materials and does not supersede traditional assessment or control
            systems. The professor registers on the platform where he/she
            creates a space ('course') to which he/she will allow his/her
            students to access. In this space, the professor creates learning
            units and access, for each of them, to videos uploaded on the
            YouTube platform or access to other learning materials. Students
            register on the platform, participate in the courses, comment on the
            videos/media, ask questions, see their peers' questions and, in
            case, propose their own answers. The professor has access to a range
            of data on students' use of the platform, in non-anonymous form,
            including information expressly provided by students (e.g.,
            questions on certain topics), as well as data on system use (time
            spent watching a video, any interruptions, etc.). Students can see
            questions asked by other students in non-anonymous form; they can
            see data on other students' opinions (e.g., the average level of
            understanding of a video) in aggregate and anonymous form.
          </p>
          <p>
            Students accessing and using the platform must be at least 16 years
            of age to be allowed to use the services provided on the platform.
            In the case of minors under 16 years of age, authorisation and
            express consent of the minor's parents will be required.
          </p>

          <h3>Purpose of Data Processing, Legal Basis and Nature of Data</h3>
          <p>
            Politecnico di Milano is the Data Controller of processing
            operations related to the use of the EVOLI platform and research
            activities carried out on the basis of the data collected.
            Politecnico will process your common personal data (name, surname,
            gender, school level in which you teach, or which you attend as a
            student, disciplinary area, email, name of institution, country, log
            data) only with your prior, specific and express consent,
            exclusively in support of teaching and learning, and for carrying
            out research based on the data collected, only to the extent this
            data are indispensable to achieve the said purposes. Specifically,
            the purposes of collecting personal data through EVOLI are as
            follows:
          </p>
          <ol>
            <li>
              offer a tool for improving teaching activities where videos/media
              are shared with students;
            </li>
            <li>
              support research into the effectiveness of technology in
              education, particularly video/media annotation systems;
            </li>
          </ol>
          <p>
            The legitimacy basis for processing your data for the
            above-mentioned purposes is your express consent pursuant to Article
            6(1)(a). Providing your data for these purposes is optional;
            however, as it this data is indispensable for you to access the
            platform, if you refuse to provide it you will not be able to access
            the platform’s services. You may withdraw your consent for the
            processing of your data for the aforementioned purposes pursuant to
            Article 7 of the GDPR, at any time with no need to providing any
            justification. No further data concerning you will be collected,
            without prejudice to the use of any data already collected to
            determine, without altering the data, the results of the research.
          </p>

          <h3>Data Treatment Methods and Actors Involved</h3>
          <p>
            The following data will be collected and processed for the use of
            the platform. On registration: full name, gender, e-mail address,
            school level, disciplinary area, affiliation, country. During use:
            date and time of access to lessons, time of connection to lessons,
            user behaviour while watching videos/media (seconds of the video
            when the user presses the play, pause, forward and backward 'seek'
            keys). The platform also collects a series of data expressly
            provided by the user, namely: self-assessment (expressed on a
            numerical scale) of certain learning relevant aspects of the use of
            videos/media, such as level of comprehension, perceived level of
            difficulty, level of prior knowledge of the topic, level of interest
            in the topic; comments explaining the proposed self-assessment;
            questions on specific aspects of the videos/media shared by the
            professor; answers to questions posed by other students, if any.
          </p>
          <p>
            The data will be processed via computer and telematic tools, with
            organisation and processing logic related exclusively to the
            purposes of the research and, in any case, in such a way as to
            guarantee their security and confidentiality in accordance with the
            regulations in force. Specifically, data will be retained on
            Politecnico di Milano’s servers, duly encrypted and password
            protected. Information that could allow for the identification of
            participants (name, surname, e-mail address) will be stored in a
            database hosted on a server of Politecnico di Milano and managed
            internally. Access to the database by researchers authorised by
            Politecnico di Milano and in charge of analysing the collected data
            will not be direct as it will be mediated by an access API. The
            actors involved in the processing of data are duly authorised staff
            of the Department of Electronics, Information and Bioengineering,
            and are the only persons authorised to access the data collected.
          </p>
          <p>In this case, Politecnico di Milano undertakes to: </p>
          <ul>
            <li>
              carry out subsequent stages of processing and storing of the
              collected data for the afore mentioned purposes, pseudonomysing
              all information, in such a way that the data subjects are not
              directly identified;
            </li>
            <li>
              enter or retain the data in pseudonymised form, in a database
              accessible to all participants in the research project;
            </li>
            <li>
              have the data only processed by specifically designated actors;
            </li>
          </ul>
          <p>
            With regard to the use of the YouTube platform, please refer to the
            specific privacy policy set out here:{" "}
            <a
              href="https://www.youtube.com/intl/ALL_it/howyoutubeworks/our-commitments/protecting-user-data/#privacy-guidelines"
              target="blank"
            >
              https://www.youtube.com/intl/ALL_it/howyoutubeworks/our-commitments/protecting-user-data/#privacy-guidelines
            </a>
          </p>

          <h3>Use of personal data in anonymous form</h3>
          <p>
            Please note that the data shall only be disclosed in anonymous form
            by means of, for example, educational activities, scientific
            publications, statistics and scientific conferences.
          </p>

          <h3>Scope of data circulation</h3>
          <p>
            Personal data shall be processed by the Data Controller within the
            European Union. Should it become necessary for technical and/or
            operational reasons to use entities located outside the European
            Union, or should it become necessary to transfer some of the
            collected data to technical systems and services managed in the
            Cloud and located outside the European Union, data processing shall
            be regulated in accordance with Chapter 5 of the Regulation and
            authorised on the basis of specific decisions by the European Union.
            All necessary precautions shall therefore be taken in order to
            guarantee the maximum protection of personal data, basing the
            transfer: a) on adequacy decisions of the recipient third countries
            expressed by the European Commission; b) on adequate guarantees
            expressed by the third party recipient pursuant to Art. 46 of the
            Regulation; c) on the adoption of Binding Corporate Rules.
          </p>

          <h3>Retention</h3>
          <p>
            Personal data will only be stored for as long as strictly necessary.
            In particular:
          </p>
          <ol>
            <li>
              data collected to fulfil the first processing purpose (support for
              educational activities) will be retained for the time strictly
              required to perform the planned activities;
            </li>
            <li>
              data collected to fulfil the second processing purpose (research)
              will be retained for 5 years, with possible retention for 5
              additional years in pseudonymised format;
            </li>
          </ol>
          <p>
            Subsequently, the data will be anonymised. The data will also be
            processed for administrative/accounting purposes and retained for
            the time strictly necessary for pursuing said purposes, subject to a
            ten-year period to ensure the fiscal, accounting and administrative
            fulfilments required by law and subject to possibly longer terms,
            which cannot be determined in advance, as a result of different
            conditions for lawful processing (e.g., legal proceedings that
            require processing for more than ten years).
          </p>

          <h3>Exercising privacy rights</h3>
          <p>
            Pursuant to and for the purposes of Articles 15 et seq. of the GDPR,
            you may access your personal data and verify their content, origin,
            accuracy, location (including in relation to third countries where
            the data are located and/or the subjects to whom the Data may be
            communicated), request a copy of, integrate, update, rectify them
            and, in the cases provided for by applicable law, cancel, restrict
            and transform them into anonymous form, request data portability,
            withdraw your consent given pursuant to Article 7 of the GDPR and
            lodge a complaint with the competent supervisory authority pursuant
            to Article 77 of the GDPR (Personal Data Protection Authority).
          </p>
          <p>
            We also inform you that you may object to the processing of your
            personal data in accordance with Article 21 of the Regulation. Any
            change to the original data may have a significant effect on the
            results of the study. Therefore, when exercising rights involving
            variation/integration of the recorded data, the requested changes
            may be noted and recorded in the margin of the original data without
            modifying the latter. You may terminate your participation in
            Evoli-supported educational activities at any time and without
            giving any reason, and data will be used for research purposes; in
            this case, no other data concerning you will be collected, without
            prejudice to the use of any data already collected to determine,
            without altering the data, the results of the research. To exercise
            your privacy rights, you may contact the DPO or the Data Controller
            at the above-mentioned addresses.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicyEng;
