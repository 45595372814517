import styled from "styled-components";

const Styles = {};

Styles.Reaction = styled.i`
  position: absolute;
  left: ${(props) => props.left}%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: ${(props) => props.color};
  font-size: 1rem;
  border: 1px solid ${(props) => props.color};
  border-radius: 50%;
  padding: 0.25rem;
  /* Display true when mouse is over the bar */
  display: none;
`;

Styles.Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  width: 100%;
  padding-left: 1rem;
  padding: 0.25rem 1rem 0.25rem 1rem;

  .start {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: inherit;

    .chapter-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: 961px) {
        display: none;
      }
    }
  }

  .control-btn {
    background: none;
    border: none;
    color: inherit;
    padding: 0;
    height: 30px;
    width: 30px;
    font-size: 1.1rem;
    cursor: pointer;
    opacity: 0.85;
    transition: opacity 150ms ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  .action-btn {
    text-align: center;
    cursor: pointer;
  }
`;

Styles.VolumeContainer = styled.div`
  display: flex;
  align-items: center;

  .volume-slider {
    width: 0;
    transform-origin: left;
    transform: scaleX(0);
    transition: width 150ms ease-in-out, transform 150ms ease-in-out;
  }

  &:hover .volume-slider,
  .volume-slide:focus-within {
    width: 80px;

    input {
      width: 80px;
    }

    transform: scaleX(1);
  }
`;

Styles.DurationContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
`;

Styles.VideoControlsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  color: white;
  z-index: 100;
  opacity: 0;
  transition: opacity 150ms ease-in-out;

  &:hover {
    opacity: 1;
  }

  &.paused {
    opacity: 1;
    ${Styles.Controls} button .pause-icon {
      display: none;
    }
  }

  &:not(.paused) {
    ${Styles.Controls} button .play-icon {
      display: none;
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent);
    width: 100%;
    aspect-ratio: 6 / 1;
    z-index: -1;
    pointer-events: none;
  }

  .timeline {
    position: relative;
    display: flex;
    align-items: center;
    background-color: ${(p) => p.theme.textPrimary};
    opacity: 0.85;
    margin: 0 0.3rem;
    height: 5px;
    border-radius: 50px;
    z-index: 100;

    &:hover {
      height: 10px;
      transition: all 0.2s ease-in-out;

      ${Styles.Reaction} {
        display: block;
      }
    }

    .range-slider__wrap {
      width: 100%;
      height: 100%;
    }

    .range-slider__tooltip__label {
      display: none;
    }

    input[type="range"] {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) !important;
      height: 100%;
      width: 100%;
      padding: 0px;

      -webkit-appearance: none;
      background-color: transparent;

      /* CHROME */
      &::-webkit-slider-runnable-track {
        background-color: transparent;
        -webkit-appearance: none;
      }

      &::-webkit-slider-thumb {
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        -webkit-appearance: none;
        background-color: ${(p) => p.theme.accent};
      }

      /* FF */
      &::-moz-range-progress {
        -webkit-appearance: none;
        background-color: transparent;
      }

      &::-moz-range-track {
        background-color: transparent;
      }

      /* IE*/
      input[type="range"]::-ms-fill-lower {
        background-color: transparent;
      }
      input[type="range"]::-ms-fill-upper {
        background-color: transparent;
      }
    }
  }
`;

Styles.VideoWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
  background-color: black;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: black;
  }

  .question-btn {
    position: absolute;
    bottom: 5%;
    right: 1%;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 4rem;
    }

    span {
      position: absolute;
      color: ${(p) => p.theme.textSecondary};
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 700;
      z-index: 100;
    }
  }

  &:hover,
  &:focus-within {
    ${Styles.VideoControlsContainer} {
      opacity: 1;
    }
  }
`;

Styles.TimeSection = styled.div`
  position: absolute;

  height: 100%;
  left: ${(props) => props.left}%;
  width: ${(props) => props.width}%;

  ${(props) =>
    props.active
      ? `background: linear-gradient(270deg, ${(p) => p.theme.accent}, ${
          props.color
        });`
      : `background-color: ${props.color};`}
  background-size: 400% 400%;

  border-left: 3px solid ${(p) => p.theme.primary};
  ${(props) => props.active && "animation: animate 1.5s ease infinite;"}

  cursor: pointer;

  @keyframes animate {
    0% {
      background-position: 0% 50%;
      height: 100%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export default Styles;
