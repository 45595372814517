import React, { useState } from "react";
import PageContainer from "../../../components/PageElements/PageContainer";
import Header from "../../../components/PageElements/Header";
import { Col, Container, Form, Row } from "react-bootstrap";
import Footer from "../../../components/PageElements/Footer";
import BackToTop from "../../../components/PageElements/BackToTop";
import { Link, useNavigate } from "react-router-dom";
import { Error, EvoliButton } from "../../../styles/Forms.styles";
import { useAcceptPrivacyPolicyMutation } from "../../../store/auth/auth.api";
import { te } from "../../../utils/errorsTranslation";
import Loading from "../../../components/PageElements/Loading";
import { useDispatch, useSelector } from "react-redux";
import { acceptPrivacyPolicy } from "../../../store/auth/auth.slice";
import NavbarWithoutAccount from "../../../components/PageElements/NavbarWithoutAccount";

const AcceptPrivacyPolicy = () => {
  const navigate = useNavigate();
  const role = useSelector((state) => state.auth.role);
  const dispatch = useDispatch();

  const redirectTo =
    role === "student" ? "/student/home/enrollments" : "/teacher/home/courses";
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [researchPurposeAccepted, setResearchPurposeAccepted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const checkboxesAccepted = privacyAccepted && researchPurposeAccepted;

  const [acceptPolicy, { isLoading }] = useAcceptPrivacyPolicyMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!checkboxesAccepted) {
      setErrorMessage(
        "You must accept both the Privacy Policy and the research purposes"
      );
      setIsError(true);
    } else {
      acceptPolicy({ privacyAccepted: true })
        .unwrap()
        .then(() => {
          setIsError(false);
          setErrorMessage("");
          //Set the user's privacy policy as accepted
          dispatch(acceptPrivacyPolicy());
          //Redirect to the home page
          navigate(redirectTo);
        })
        .catch((err) => {
          setIsError(true);
          if (!err.data?.code && !err.code)
            setErrorMessage(te("general_error"));
          else setErrorMessage(te(err.data?.code ?? err.code));
        });
    }
  };

  if (isLoading) return <Loading />;
  return (
    <>
      <NavbarWithoutAccount dark={true} />
      <PageContainer>
        <Header>
          <Container>
            <Header.Title>Acceptance of Privacy Policy required</Header.Title>
          </Container>
        </Header>
        <Container className="py-5">
          <Row>
            <Col lg={6} sm={12}>
              <p>
                We have updated our Privacy Policy to be in line with the GDPR.
                To continue using our service, you must agree to our Privacy
                Policy.
              </p>
              {/* Add checkbox for the privacy policy */}
              <Form.Check
                type="checkbox"
                checked={privacyAccepted}
                onChange={() => setPrivacyAccepted((c) => !c)}
                className="mb-3"
                label={
                  <span>
                    I have read and agree with the{" "}
                    <Link to="/privacy" target="blank">
                      Privacy Policy
                    </Link>
                  </span>
                }
                required
              />
              <Form onSubmit={handleSubmit}>
                {/* Add checkbox for the research purpose */}
                <Form.Check
                  type="checkbox"
                  checked={researchPurposeAccepted}
                  onChange={() => setResearchPurposeAccepted((c) => !c)}
                  className="mb-3"
                  label="I agree that my data are used for scientific research purposes"
                  required
                />
                {/* Error message */}
                {isError && <Error className="mt-3">{errorMessage}</Error>}
                {/* Submit button */}
                <EvoliButton
                  type="submit"
                  className="mt-3 w-100"
                  disabled={!checkboxesAccepted}
                >
                  Accept conditions
                </EvoliButton>
              </Form>
            </Col>
          </Row>
        </Container>
      </PageContainer>
      <Footer />
      <BackToTop />
    </>
  );
};

export default AcceptPrivacyPolicy;
