import React from "react";
import { useGetTeachingOverviewQuery } from "../../../../../store/teachings/teachings.api";
import { toastApiError } from "../../../../../utils/lecture.utils";

const useFetch = ({ courseUUID, period = "week" }) => {
  const {
    data: overview,
    isLoading,
    isError,
    error,
    isSuccess,
    isFetching,
  } = useGetTeachingOverviewQuery({ courseUUID, period });

  React.useEffect(() => {
    if (isError || !courseUUID) {
      if (error) toastApiError(error);
    }
  }, [isError, error, courseUUID]);

  return {
    overview,
    isLoading,
    isError,
    error,
    isSuccess,
    isFetching,
  };
};

export default useFetch;
