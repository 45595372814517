import { useEffect, useState } from "react";
import { useGetFeedbackSummaryByLessonQuery } from "../../../../../store/feedback/feedback.api";
import { te } from "../../../../../utils/errorsTranslation";

const useFetch = ({ lessonUUID }) => {
  const { data, isLoading, isError, error, isSuccess } =
    useGetFeedbackSummaryByLessonQuery(lessonUUID);

  //Component state
  const [errorMessage, setErrorMessage] = useState(null);

  //Check if there is an error
  useEffect(() => {
    if (isError) {
      if (!error.data?.code && !error.code)
        setErrorMessage(te("general_error"));
      else setErrorMessage(te(error.data?.code ?? error.code));
    } else if (isSuccess) {
      setErrorMessage(null);
    }
  }, [isError, error, isSuccess]);

  return {
    summary: data,
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  };
};

export default useFetch;
