import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import * as FormStyles from "../../../../../../styles/Forms.styles";
import SelectIntervalVideoPlayer from "./components/SelectIntervalVideoPlayer";
import MultiRangeSlider from "multi-range-slider-react";
import { toHHMMSS } from "../../../../../../utils/lecture.utils";
import { TimeInput } from "../../../../../../components/Commons/Form";
import getVideoId from "get-video-id";
import Styles from "./styles";
import EvoliModal from "../../../../../../components/Commons/EvoliModal";
import ReactPlayer from "react-player";

export const CreateUnitModal = ({
  show,
  onHide,
  course,
  createUnitHandler,
}) => {
  const [name, setName] = useState("");

  useEffect(() => {
    setName("");
  }, []);

  const clickSaveHandler = (e) => {
    e.preventDefault();

    //Update unit
    if (name.trim().length > 0)
      createUnitHandler({ name, courseUUID: course.uuid }, onHide);
  };

  const clickResetHandler = () => {
    setName("");
  };

  return (
    <EvoliModal show={show} onHide={onHide}>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Create unit</Modal.Title>
      </Modal.Header>
      <Form onSubmit={clickSaveHandler}>
        <Modal.Body>
          {/* Unit name */}
          <FormStyles.FormGroup className="mb-3">
            <FormStyles.FormLabel htmlFor="unit-name">
              Unit name*
            </FormStyles.FormLabel>
            <FormStyles.FormControl
              type="text"
              id="unit-name"
              placeholder="Enter unit name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormStyles.FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <FormStyles.EvoliButton type="submit">Save</FormStyles.EvoliButton>
          <FormStyles.EvoliButton
            variant="secondary"
            onClick={clickResetHandler}
          >
            Reset
          </FormStyles.EvoliButton>
        </Modal.Footer>
      </Form>
    </EvoliModal>
  );
};

//Modal to edit a lesson, the editable field is only the name
export const CreateLessonModal = ({
  show,
  onHide,
  unit,
  course,
  createLessonHandler,
}) => {
  const [name, setName] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoId, setVideoId] = useState("");
  const [startSecond, setStartSecond] = useState(0);
  const [endSecond, setEndSecond] = useState(1);
  const [duration, setDuration] = useState(1);
  const [canPlay, setCanPlay] = useState(false);

  useEffect(() => {
    reset();
  }, []);

  const changeStartSecondHandler = (value) => {
    //If value is greater than endSecond, set endSecond to value
    if (value > endSecond) {
      setEndSecond(value);
    }
    setStartSecond(value);
  };

  const changeEndSecondHandler = (value) => {
    //If value is smaller than startSecond, set startSecond to value
    if (value < startSecond) {
      setStartSecond(value);
    }
    setEndSecond(value);
  };

  const clickSaveHandler = (e) => {
    e.preventDefault();

    //Update lesson
    if (name.trim().length > 0) {
      createLessonHandler(
        {
          name,
          videoUrl,
          videoId,
          startSecond,
          endSecond,
          unitUUID: unit.uuid,
          courseUUID: course.uuid,
        },
        onHide
      );
    }
  };

  const reset = () => {
    setName("");
    setVideoUrl("");
    setVideoId("");
    setStartSecond(0);
    setEndSecond(100);
  };

  const onChangeVideoURLHandler = (e) => {
    const value = e.target.value;
    setVideoUrl(value);
    const { id, service } = getVideoId(value);
    if (service === "youtube" && id) {
      setVideoId(id);
    } else {
      setVideoId("");
    }
  };

  //Every time the videoUrl changes, check if the player can play the video
  useEffect(() => {
    const canPlayVideo = videoUrl && ReactPlayer.canPlay(videoUrl);
    setCanPlay(canPlayVideo);
    //If the video can't be played, reset the videoId
    if (!canPlayVideo) {
      setVideoId("");
      setStartSecond(0);
      setEndSecond(1);
    } else {
      setStartSecond(0);
      setEndSecond(duration);
    }
  }, [videoUrl, duration]);

  return (
    <EvoliModal show={show} onHide={onHide}>
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Create a new video lesson</Modal.Title>
      </Modal.Header>
      <Form onSubmit={clickSaveHandler}>
        <Modal.Body>
          {/* Lesson name */}
          <FormStyles.FormGroup className="mb-3">
            <FormStyles.FormLabel htmlFor="lesson-name">
              Lesson name
            </FormStyles.FormLabel>
            <FormStyles.FormControl
              type="text"
              id="lesson-name"
              placeholder="Enter lesson name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormStyles.FormGroup>
          {/* Video URL */}
          <FormStyles.FormGroup className="mb-3">
            <FormStyles.FormLabel htmlFor="video-url">
              Video URL
            </FormStyles.FormLabel>
            <FormStyles.FormControl
              type="text"
              id="video-url"
              placeholder="Enter video URL"
              value={videoUrl}
              onChange={onChangeVideoURLHandler}
            />
          </FormStyles.FormGroup>
          {canPlay && (
            <>
              <Styles.MediaContainer className="miniature mb-3">
                <SelectIntervalVideoPlayer
                  videoId={videoId}
                  start={startSecond}
                  end={endSecond}
                  duration={duration}
                  setDuration={setDuration}
                  setCanPlay={setCanPlay}
                />
              </Styles.MediaContainer>
              <Styles.RangeContainer>
                <MultiRangeSlider
                  min={0}
                  max={duration}
                  minValue={startSecond}
                  maxValue={endSecond}
                  step={1}
                  onInput={(e) => {
                    setStartSecond(e.minValue);
                    setEndSecond(e.maxValue);
                  }}
                  ruler={false}
                  labels={[toHHMMSS(0), toHHMMSS(duration)]}
                  className="my-range-slider"
                />
              </Styles.RangeContainer>
              {/* Start second */}
              <FormStyles.FormGroup className="mb-3">
                <FormStyles.FormLabel htmlFor="start-second">
                  YouTube video start second
                </FormStyles.FormLabel>
                <TimeInput
                  id="start-second"
                  seconds={startSecond}
                  setSeconds={changeStartSecondHandler}
                  width={"100%"}
                />
              </FormStyles.FormGroup>
              {/* End second */}
              <FormStyles.FormGroup className="mb-3">
                <FormStyles.FormLabel htmlFor="end-second">
                  YouTube video end second
                </FormStyles.FormLabel>
                <TimeInput
                  id="end-second"
                  seconds={endSecond}
                  setSeconds={changeEndSecondHandler}
                  width={"100%"}
                />
              </FormStyles.FormGroup>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <FormStyles.EvoliButton type="submit">Save</FormStyles.EvoliButton>
          <FormStyles.EvoliButton variant="secondary" onClick={reset}>
            Reset
          </FormStyles.EvoliButton>
        </Modal.Footer>
      </Form>
    </EvoliModal>
  );
};
