import styled from "styled-components";
import { Alert, Badge, Container, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const PageContainer = styled(Container)`
  background-color: ${(props) =>
    props.dark ? props.theme.primary : props.theme.textPrimary};
  min-height: ${(props) => (props.fill ? "100vh" : "auto")};
  color: ${(props) =>
    props.dark ? props.theme.textPrimary : props.theme.primary};
`;

export const Paragraph = styled.p`
  color: ${(props) =>
    props.dark ? props.theme.textPrimary : props.theme.primary};

  a {
    color: ${(props) =>
      props.dark ? props.theme.textPrimary : props.theme.primary};
  }
`;

export const Title = styled.h2`
  color: ${(props) =>
    props.dark ? props.theme.textPrimary : props.theme.primary};
`;

export const SBadge = styled(Badge)`
  background-color: ${(props) =>
    props.dark ? props.theme.primary : props.theme.textPrimary};
  color: ${(props) =>
    props.dark ? props.theme.textPrimary : props.theme.primary};
  font-size: 0.5rem !important;
`;

export const DarkModal = styled(Modal)`
  font-size: 1.2rem;
  color: ${(p) => p.theme.primary};

  .modal-content {
    border-radius: 30px !important;
    border: 4px solid ${(p) => p.theme.primary} !important;
  }
`;

export const DarkAlert = styled(Alert)`
  background-color: ${(p) => p.theme.primary};
  color: ${(p) => p.theme.textPrimary};
  border-radius: 99em;
  border: none;
  text-align: center;
  padding: 0.5rem;

  &.warning {
    background-color: #eb9b34;
    border-radius: 9px;
    font-size: 1.2rem;

    p {
      text-align: left;
    }
  }

  &.error {
    background-color: red;
  }
`;

export const ClickableIcon = styled.i`
  cursor: pointer;
  color: ${(p) => p.theme.accent};

  &:hover {
    transform: scale(1.2);
  }

  &.delete {
    color: red;
  }
`;

export const ClickableSpan = styled.span`
  cursor: pointer;
  color: ${(p) => p.theme.accent};
  font-weight: bold;

  &:hover {
    transform: scale(1.2);
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 2em;
  border: none;
  box-shadow: none;
  padding: 0.5em 1.5em;
  width: 80%;
  aspect-ratio: 16/9;
  overflow: hidden;
  background-color: ${(p) => p.theme.secondary};
  color: ${(p) => p.theme.textPrimary};

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
`;

export const ReactionRow = styled(Row)`
  .reactions-wrapper {
    background-color: #fff;
  }

  .placeholder-reaction {
    opacity: 0.5;
  }

  :hover {
    .placeholder-reaction {
      display: none;
    }
  }
`;

export const OverviewCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 0;

  .number {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    height: 4rem;
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
    text-align: center;
  }

  .text {
    font-size: 1.2rem;
    font-family: "Montserrat", sans-serif;
    text-align: center;
  }

  .subtext {
    font-size: 0.8rem;
    font-style: italic;
    text-align: center;
  }
`;

export const Reaction = styled.div`
  display: ${(props) => (props.visible ? "block" : "none")};

  ${ReactionRow}:hover & {
    display: block;
  }

  .fas {
    &:hover {
      cursor: pointer;
      opacity: 1;
      transform: scale(1.2);

      transition: transform 0.2s ease-in-out;
    }

    opacity: ${(props) => (props.userReacted ? "1" : "0.5")};
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 1.5rem;
  text-align: center;
  color: ${(p) => p.theme.textPrimary};
`;

export const MediaContainer = styled.div`
  &.miniature {
    position: relative;
    border-radius: 20px;
    width: 100%;
    aspect-ratio: 4/3;
    overflow: hidden;
  }

  &.expanded {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
  }
`;

export const LimitText = ({ length, text }) => {
  const { t } = useTranslation("", { keyPrefix: "styles" });

  const [expand, setExpand] = useState(false);

  //If the text is shorter than the limit, return the text
  //Else, return the text with a link "show more"
  if (text.length <= length) {
    return <span>{text}</span>;
  } else {
    return (
      <span>
        {expand ? text : text.substring(0, length) + "..."}
        <ClickableSpan onClick={() => setExpand(!expand)}>
          {expand ? t("show_less") : t("show_more")}
        </ClickableSpan>
      </span>
    );
  }
};

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  background-color: ${(p) => p.theme.primary};
  color: ${(p) => p.theme.textPrimary};
  padding: 1rem;
  border-radius: 15px;

  .data {
    font-size: 1.6rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    color: ${(p) => p.theme.textPrimary};
  }

  .description {
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${(p) => p.theme.textPrimary};
  }

  i {
    font-size: 2em;
    color: ${(p) => p.theme.accent};

    &:hover {
      transform: scale(1.2);
    }
  }
`;
