import React, { useEffect, useState } from "react";
import * as FormStyles from "../../styles/Forms.styles";
import classNames from "classnames";
import { getSecondsFromHHMMSS, toHHMMSS } from "../../utils/lecture.utils";
import styled from "styled-components";

export const PasswordField = (props) => {
  const [hidePwd, setHidePwd] = useState(true);
  const placeholder = props.placeholder || "Password";

  return (
    <div className="d-flex">
      <FormStyles.FormControl
        {...props}
        className="show-pwd fs-5"
        type={hidePwd ? "password" : "text"}
        placeholder={placeholder}
        maxLength={12}
      />
      <FormStyles.PasswordShowButton
        variant="outline-secondary"
        id="button-addon2"
        className="fs-5"
        $show={!hidePwd}
        onClick={() => setHidePwd(!hidePwd)}
      >
        <i
          className={classNames("fas", {
            "fa-eye": !hidePwd,
            "fa-eye-slash": hidePwd,
          })}
        />
      </FormStyles.PasswordShowButton>
    </div>
  );
};

export const TimeInput = ({ seconds, setSeconds, width = "120px" }) => {
  const [value, setValue] = useState(toHHMMSS(seconds));

  useEffect(() => {
    setValue(toHHMMSS(seconds));
  }, [seconds]);

  const onChange = (event) => {
    setValue(event.target.value);
  };

  const onBlur = (event) => {
    const value = event.target.value;
    const seconds = Math.max(0, getSecondsFromHHMMSS(value));

    setSeconds(seconds);
    const time = toHHMMSS(seconds);
    setValue(time);
  };

  return (
    <Styles.TimeInput
      type="text"
      placeholder="HH:MM:SS"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      $width={width}
    />
  );
};

const Styles = {};

Styles.TimeInput = styled.input`
  text-align: right;
  height: 40px;
  width: ${(p) => p.$width};
  padding: 0.5em 1em;
  background-color: ${(p) => p.theme.textPrimary};
  color: ${(p) => p.theme.primary};
  border-radius: 99em;
  border: 3px solid ${(p) => p.theme.primary};

  &::placeholder {
    text-transform: uppercase;
    color: ${(p) => p.theme.textSecondary};
  }
`;
