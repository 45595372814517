import React from "react";
import { Button, Card, Col, Container, Dropdown, Row } from "react-bootstrap";
import Loading from "../../../../components/PageElements/Loading";
import useFetch from "./hooks/useFetch";
import Styles from "../styles";
import SearchBar from "../../../../components/Form/SearchBar";

const NewEnrollment = () => {
  //Search input ref
  const searchInputRef = React.useRef();
  //Search query
  const [searchQuery, setSearchQuery] = React.useState("");
  //Query hooks
  const {
    courseFound,
    noCourseFound,
    getCourseByCodeHandler,
    isLoading,
    enrollHandler,
  } = useFetch();

  //Search handler
  const submitSearchHandler = (e) => {
    e.preventDefault();

    //Get the course by code
    getCourseByCodeHandler(searchQuery);
  };

  return (
    <Container className="py-4">
      <Row>
        <p>
          To enroll to a course, insert the code the instructor provided you
          with:
        </p>
        {/* Search box */}
        <SearchBar
          inputRef={searchInputRef}
          placeholder={"Insert code"}
          onChange={(e) => setSearchQuery(e.target.value)}
          onSubmit={submitSearchHandler}
        />
      </Row>
      {/* Loading spinner */}
      {isLoading ? (
        <Row>
          <Loading page={false} />
        </Row>
      ) : (
        <Row className="mt-3">
          {/* If no courses are found, then display a message, otw display the course card */}
          {noCourseFound && (
            <p className="text-muted fst-italic">Invalid code</p>
          )}
          {courseFound && (
            <CourseCard course={courseFound} enrollHandler={enrollHandler} />
          )}
        </Row>
      )}
    </Container>
  );
};

const CourseCard = ({ course, enrollHandler }) => {
  const isEnrolled = course.isStudentEnrolled;

  const cardColor = course.color;

  const enrollClickHandler = () => {
    enrollHandler(course);
  };

  return (
    <Col lg={3} md={4} sm={6} xs={12}>
      <Styles.CourseCard color={cardColor}>
        <Card.Header className="header-colored">
          {!isEnrolled && (
            <div className="d-flex flex-row justify-content-end">
              {/* Menu btn */}
              <Dropdown>
                <Dropdown.Toggle variant="light" size="sm" as={CustomToggle}>
                  <i className="fas fa-ellipsis-v" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={enrollClickHandler}>
                    Enroll
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </Card.Header>
        <Card.Body>
          <Card.Title>{course.name}</Card.Title>
          <Card.Subtitle className="text-muted">{`${course.teacher.firstName} ${course.teacher.lastName}`}</Card.Subtitle>
        </Card.Body>
        <Card.Footer>
          {isEnrolled ? (
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-row align-items-center">
                <i className="fas fa-check-circle me-2" />
                <span>Enrolled</span>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-row align-items-center">
                <i className="fas fa-times-circle me-2" />
                <span>Not enrolled</span>
              </div>
            </div>
          )}
        </Card.Footer>
      </Styles.CourseCard>
    </Col>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    ref={ref}
    size="sm"
    variant="light"
  >
    {/* Render custom icon here */}
    {children}
  </Button>
));

export default NewEnrollment;
