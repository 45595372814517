import styled from "styled-components";

const Styles = {};

Styles.CircleColor = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 2px solid ${(p) => p.theme.textPrimary};
  background-color: ${(props) => props.color};
`;

export default Styles;
