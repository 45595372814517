import { Container } from "react-bootstrap";
import styled from "styled-components";

const Styles = {};

Styles.MainContainer = styled(Container)`
  padding: 2rem;
`;

Styles.OverviewCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 0;
  margin: 1rem;

  .content {
    border: 1px solid ${(props) => props.theme.secondary};
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: 0 0 0.2rem ${(props) => props.theme.secondary};
    min-width: 10rem;
    background-color: ${(props) => props.theme.textPrimary};

    .number {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 3rem;
      height: 4rem;
      font-weight: bold;
      font-family: "Montserrat", sans-serif;
      text-align: center;
    }

    .text {
      font-size: 1.2rem;
      font-family: "Montserrat", sans-serif;
      text-align: center;
    }

    .subtext {
      font-size: 0.8rem;
      font-style: italic;
      text-align: center;
    }
  }
`;

export default Styles;
