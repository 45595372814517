import styled from "styled-components";

const Styles = {};

Styles.LessonItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 0.5rem;

  .lesson-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    color: ${(p) => p.theme.accent};
    cursor: pointer;

    .title {
      text-decoration: underline;
    }
  }
`;

export default Styles;
