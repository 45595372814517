import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isEstablishingConnection: false,
  isConnectionFailed: false,
  //if the connection is established, then isEstablishingConnection and isConnectionFailed are false
  isConnected: false,
  isReconnecting: false,
  isReconnectionFailed: false,
  //if the reconnection is established, then isReconnecting and isReconnectionFailed are false
  visualizationCode: null,
};

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    //Start the socket connection, the middleware will handle the rest
    startConnecting: (state) => {
      state.isEstablishingConnection = true;
      //Reset the connection status
      state.isConnectionFailed = false;
      state.isReconnecting = false;
      state.isReconnectionFailed = false;
    },
    //Connection successful established, save the data
    connectionEstablished: (state, action) => {
      const visualizationCode = action.payload;
      state.visualizationCode = visualizationCode;
      //Update the connection status to success parameters
      state.isEstablishingConnection = false;
      state.isConnectionFailed = false;
      state.isConnected = true;
    },
    //Connection failed, update the state
    connectionFailed: (state) => {
      state.isEstablishingConnection = false;
      state.isConnectionFailed = true;
      state.isConnected = false;
    },
    //Start the reconnection process, the middleware will handle the rest
    startReconnecting: (state) => {
      state.isReconnecting = true;
      //Reset the reconnection status
      state.isReconnectionFailed = false;
      state.isConnected = false;
    },
    //Reconnection successful established, save the data
    reconnectionEstablished: (state) => {
      state.isReconnecting = false;
      state.isReconnectionFailed = false;
      state.isConnected = true;
    },
    //Reconnection failed, update the state
    reconnectionFailed: (state) => {
      state.isReconnecting = false;
      state.isReconnectionFailed = true;
      state.isConnected = false;
    },
    //Disconnect socket in case user manually disconnects
    disconnectSocket: (state) => {
      //Reset the state
      return initialState;
    },
  },
});

export const {
  startConnecting,
  connectionEstablished,
  connectionFailed,
  startReconnecting,
  reconnectionEstablished,
  reconnectionFailed,
  disconnectSocket,
} = feedbackSlice.actions;

export default feedbackSlice.reducer;

//Selectors
//Return the connection boolean trackers
export const selectIsEstablishingConnection = (state) =>
  state.feedback.isEstablishingConnection;
export const selectIsConnectionFailed = (state) =>
  state.feedback.isConnectionFailed;
export const selectIsConnected = (state) => state.feedback.isConnected;
export const selectIsReconnecting = (state) => state.feedback.isReconnecting;
export const selectIsReconnectionFailed = (state) =>
  state.feedback.isReconnectionFailed;
export const selectVisualizationCode = (state) =>
  state.feedback.visualizationCode;
