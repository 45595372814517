import React, { useCallback, useRef, useState } from "react";
import Loading from "../../../../components/PageElements/Loading";
import {
  Card,
  Col,
  Container,
  Pagination,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { EvoliButton } from "../../../../styles/Forms.styles";
import { useNavigate } from "react-router-dom";
import Styles from "./styles";
import useFetch from "./hooks/useFetch";
import InfoTooltip from "../../../../components/Commons/InfoTooltip";
import SearchBar from "../../../../components/Form/SearchBar";

const TeacherCourses = () => {
  //Redirect hook
  const navigate = useNavigate();

  /* Component state */
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("ASC"); //ASC or DESC
  const [orderBy, setOrderBy] = useState("name"); //"name"
  const [searchQuery, setSearchQuery] = useState("");
  const limit = 12;
  const offset = (page - 1) * limit;
  const [orderValue, setOrderValue] = useState("nameAsc");
  const [searchQueryValue, setSearchQueryValue] = useState("");
  const searchInputRef = useRef(null);

  //Fetch courses
  const {
    data: { courses, itemsCount },
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  } = useFetch({
    limit,
    offset,
    order,
    orderBy,
    searchQuery,
  });

  const pages = Math.ceil(itemsCount / limit);

  const reset = useCallback(() => {
    setOrderValue("nameAsc");
    setOrder("ASC");
    setOrderBy("name");
    setSearchQuery("");
    setSearchQueryValue("");
    if (searchInputRef.current) searchInputRef.current.value = "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeOrderHandler = (e) => {
    const value = e.target.value;
    setOrderValue(value);
    switch (value) {
      case "nameAsc":
        setOrder("ASC");
        setOrderBy("name");
        break;
      case "nameDesc":
        setOrder("DESC");
        setOrderBy("name");
        break;
      default:
        setOrderValue("nameAsc");
        break;
    }
  };

  const changePageHandler = (pageValue) => {
    if (pageValue >= 1 && pageValue <= pages) setPage(pageValue);
  };

  const changeSearchHandler = (e) => {
    const value = e.target.value;
    setSearchQueryValue(value);
  };

  const submitSearchHandler = (e) => {
    e.preventDefault();
    setSearchQuery(searchQueryValue);
    setPage(1);
  };

  // Handler for going to course detail
  const goToCourseHandler = (course) => {
    navigate("/teacher/course/" + course.uuid + "/content");
  };

  if (isLoading) return <Loading />;
  if (isError) return <div className="fw-bold text-danger">{errorMessage}</div>;
  if (isSuccess)
    return (
      <Container>
        <Row className="my-4">
          <div className="d-flex flex-column flex-md-row gap-2 gap-md-3 w-100">
            {/* Order by course name, teacher name, completion rate, understanding rate */}
            <div className="d-flex flex-column gap-1">
              <label>Order by</label>
              <select
                className="form-select"
                value={orderValue}
                onChange={changeOrderHandler}
              >
                <option value="nameAsc">Course name (A-Z)</option>
                <option value="nameDesc">Course name (Z-A)</option>
              </select>
            </div>
            {/* Search box */}
            <div className="d-flex flex-row align-items-end gap-2">
              <div className="d-flex flex-column flex-grow-1 gap-1">
                <label>Search</label>
                <SearchBar
                  className="w-100"
                  inputRef={searchInputRef}
                  placeholder={"Search my courses"}
                  onChange={changeSearchHandler}
                  onSubmit={submitSearchHandler}
                />
              </div>
              {/* Reset filter and order */}
              <EvoliButton onClick={reset}>Reset</EvoliButton>
            </div>
          </div>
        </Row>
        <Row>
          {courses.map((course) => (
            <CourseCard
              key={course.uuid}
              course={course}
              goToCourseHandler={goToCourseHandler}
            />
          ))}
        </Row>
        <Row>
          {/* Pagination section, show 12 courses */}
          <div className="d-flex flex-column gap-1">
            {/* [start index]-[end index] of [count] courses  */}
            <div className="d-flex flex-row justify-content-center">
              <span className="fs-6 fst-italic">
                {itemsCount === 0 ? 0 : page * limit - limit + 1}-
                {page * limit > itemsCount ? itemsCount : page * limit} of{" "}
                {itemsCount} courses
              </span>
            </div>
            {/* Pagination */}
            <div className="d-flex flex-row justify-content-center">
              <Pagination>
                {page === 1 ? (
                  <Pagination.First disabled />
                ) : (
                  <Pagination.First onClick={() => changePageHandler(1)} />
                )}
                {page > 1 && (
                  <Pagination.Item onClick={() => changePageHandler(page - 1)}>
                    {page - 1}
                  </Pagination.Item>
                )}
                <Pagination.Item active>{page}</Pagination.Item>
                {page < pages && (
                  <Pagination.Item onClick={() => changePageHandler(page + 1)}>
                    {page + 1}
                  </Pagination.Item>
                )}
                {page < pages ? (
                  <Pagination.Last onClick={() => changePageHandler(pages)} />
                ) : (
                  <Pagination.Last disabled />
                )}
              </Pagination>
            </div>
          </div>
        </Row>
      </Container>
    );
};

const CourseCard = ({ course, goToCourseHandler }) => {
  const completionRate =
    course.enrollmentsCount === 0 || course.lessonsCount === 0
      ? 0
      : course.lessonsWithFeedbackCount /
        (course.lessonsCount * course.enrollmentsCount); //Completion rate is the ratio of chapters reacted to total chapters

  const cardColor = course.color ?? "#000000";

  //When click on body of card, redirect to course page
  const cardClickHandler = () => {
    goToCourseHandler(course);
  };

  return (
    <Col lg={3} md={4} sm={6} xs={12}>
      <Styles.CourseCard color={cardColor}>
        <Card.Header className="header-colored"></Card.Header>
        <Card.Body onClick={cardClickHandler}>
          <Card.Title>{course.name}</Card.Title>
          <div className="d-flex flex-column gap-0 mt-4">
            <ProgressBar
              className="progress"
              now={Math.ceil(completionRate * 100)}
            />
            <span className="fw-light d-flex flex-row align-items-center gap-1">
              {`${Math.ceil(completionRate * 100)}% completed`}
              <InfoTooltip text="Completion rate: the number of lessons that have received at least one feedback divided by the total number of lessons multiplied by the total number of enrolled students." />
            </span>
          </div>
        </Card.Body>
        <Card.Footer>
          {/* Show number of enrollments */}
          <div className="d-flex flex-row justify-content-between fw-light">
            <div className="d-flex flex-row align-items-center">
              <i className="fas fa-users me-2" />
              <span>{`${course.enrollmentsCount} enrollments`}</span>
            </div>
          </div>
        </Card.Footer>
      </Styles.CourseCard>
    </Col>
  );
};

export default TeacherCourses;
