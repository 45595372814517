import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import { toastSuccess } from "../../../../utils/Toast";
import { getHexColors, toastApiError } from "../../../../utils/lecture.utils";
import Loading from "../../../../components/PageElements/Loading";
import { EvoliButton } from "../../../../styles/Forms.styles";
import Styles from "./styles";
import useFetch from "./hooks/useFetch";
import EvoliCheckbox from "../../../../components/Commons/EvoliCheckbox";
import * as FormStyles from "../../../../styles/Forms.styles";
import TextArea from "../../../../components/Form/TextArea";
import {
  DifficultyIcon,
  InitialKnowledgeIcon,
  UnderstandingIcon,
} from "../../../../components/Commons/FeedbackIcons";

const LessonFeedback = ({ lesson, show, onHide }) => {
  const navigate = useNavigate();
  const course = lesson.unit.course;

  //Component state
  const [understanding, setUnderstanding] = useState(null); //From 1 to understandingScale
  const [difficulty, setDifficulty] = useState(null); //From 1 to difficultyScale
  const [initalKnowledge, setInitialKnowledge] = useState(null); //From 1 to understandingScale
  const [text, setText] = useState("");
  const understandingScale = course.understandingScale;
  const difficultyScale = course.difficultyScale;
  const isDifficultyEnabled = course.difficultyEnabled;
  const isInitialKnowledgeEnabled = course.initialKnowledgeEnabled;
  const colors = getHexColors(understandingScale);
  const isSendButtonDisabled =
    !understanding ||
    text.trim().length === 0 ||
    (isDifficultyEnabled && !difficulty) ||
    (isInitialKnowledgeEnabled && !initalKnowledge);

  const submitFormHandler = (e) => {
    e.preventDefault();

    const body = {
      understanding,
      text,
    };

    if (isDifficultyEnabled) body.difficulty = difficulty;
    if (isInitialKnowledgeEnabled) body.initialKnowledge = initalKnowledge;

    if (!isSendButtonDisabled) upsertFeedbackHandler(body);
  };

  //Fetch hook
  const {
    errorMessage,
    isError,
    isLoading,
    isSuccess,
    createFeedback,
    data: { studentFeedback, nextLessonUUID, prevLessonUUID },
  } = useFetch(lesson?.uuid);

  useEffect(() => {
    if (isSuccess) {
      if (studentFeedback) {
        setUnderstanding(studentFeedback.understanding);
        setText(studentFeedback.text ?? "");
        if (course.difficultyEnabled) setDifficulty(studentFeedback.difficulty);
        if (course.initialKnowledgeEnabled)
          setInitialKnowledge(studentFeedback.initialKnowledge);
      }
    }
  }, [
    isSuccess,
    studentFeedback,
    course.difficultyEnabled,
    course.initialKnowledgeEnabled,
  ]);

  const changeLessonHandler = (uuid) => {
    const nextLessonUrl = `/student/lesson/${uuid}`;
    navigate(nextLessonUrl);
    //Refresh page
    window.location.reload();
  };

  const upsertFeedbackHandler = (data) => {
    if (data)
      //Create feedback
      createFeedback({
        lessonUUID: lesson.uuid,
        ...data,
      })
        .unwrap()
        .then(() => {
          toastSuccess(
            "Thank you for your feedback! Now the lesson is complete."
          );
          onHide();
        })
        .catch((err) => {
          toastApiError(err);
        });
  };

  if (isLoading) return <Loading />;
  if (isError) return <div className="text-danger fw-bold">{errorMessage}</div>;
  return (
    <Styles.FeedbackPage>
      {/* Feedback content */}
      <Container className="py-5">
        <Row>
          <Col>
            <h2 className="fw-bold mb-3 text-center">Lesson feedback</h2>
            <p>To complete the lesson, you have to provide your feedback.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={submitFormHandler}>
              {/* Understanding scale */}
              <h5 className="mt-2">
                <UnderstandingIcon className="me-2" />
                Rate your understanding level:
              </h5>
              <div className="d-flex flex-row justify-content-center align-items-center gap-5">
                {/* Label */}
                <span className="text-center fw-bold">Completely unclear</span>
                {/* Cycle from 1 to understanding sclae */}
                {[...Array(understandingScale).keys()].map((i) => (
                  <div
                    className="d-flex flex-column justify-content-center align-items-center gap-2"
                    key={i}
                  >
                    <EvoliCheckbox
                      checked={understanding === i + 1}
                      onChange={() => setUnderstanding(i + 1)}
                      color={colors[i]}
                    />
                    <span className="fs-5 fw-bold">{i + 1}</span>
                  </div>
                ))}
                {/* Label */}
                <span className="text-center fw-bold">Completely clear</span>
              </div>
              {/* Initial knowdlege scale */}
              {isInitialKnowledgeEnabled && (
                <>
                  <h5 className="mt-3">
                    <InitialKnowledgeIcon className="me-2" />
                    Rate your prior knowdlege level:
                  </h5>
                  <div className="d-flex flex-row justify-content-center align-items-center gap-5">
                    {/* Label */}
                    <span className="text-center fw-bold">Nothing</span>
                    {/* Cycle from 1 to understanding scale */}
                    {[...Array(understandingScale).keys()].map((i) => (
                      <div
                        className="d-flex flex-column justify-content-center align-items-center gap-2"
                        key={i}
                      >
                        <EvoliCheckbox
                          checked={initalKnowledge === i + 1}
                          onChange={() => setInitialKnowledge(i + 1)}
                          color={colors[i]}
                        />
                        <span className="fs-5 fw-bold">{i + 1}</span>
                      </div>
                    ))}
                    {/* Label */}
                    <span className="text-center fw-bold">Everything</span>
                  </div>
                </>
              )}
              {/* Difficulty scale */}
              {isDifficultyEnabled && (
                <>
                  <h5 className="fs-5 mt-3">
                    <DifficultyIcon className="me-2" />
                    Rate your difficulty level:
                  </h5>
                  <div className="d-flex flex-row justify-content-center align-items-center gap-5">
                    {/* Label */}
                    <span className="text-center fw-bold">
                      Very <br /> easy
                    </span>
                    {/* Cycle from 1 to understanding scale */}
                    {[...Array(difficultyScale).keys()].map((i) => (
                      <div
                        className="d-flex flex-column justify-content-center align-items-center gap-2"
                        key={i}
                      >
                        <EvoliCheckbox
                          checked={difficulty === i + 1}
                          onChange={() => setDifficulty(i + 1)}
                          color={colors[difficultyScale - i - 1]}
                        />
                        <span className="fs-5 fw-bold">{i + 1}</span>
                      </div>
                    ))}
                    {/* Label */}
                    <span className="text-center fw-bold">
                      Very <br /> difficult
                    </span>
                  </div>
                </>
              )}
              <p className="mt-3">
                Let your professor and your classmates know what you did not
                understand:
              </p>
              <FormStyles.FormGroup>
                <FormStyles.FormLabel htmlFor="comment">
                  Comment
                </FormStyles.FormLabel>
                <TextArea
                  id="comment"
                  required
                  type="textfield"
                  maxLength={500}
                  placeholder="Please comment on your feedback"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
              </FormStyles.FormGroup>
              <div className="d-flex justify-content-center">
                <EvoliButton
                  type="submit"
                  variant="success"
                  size="lg"
                  disabled={isSendButtonDisabled}
                >
                  Send feedback
                </EvoliButton>
              </div>
            </Form>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <div className="d-flex flex-row justify-content-center align-items-center gap-2">
              {/* Go to previous lesson button */}
              {prevLessonUUID && (
                <EvoliButton
                  variant="success"
                  onClick={() => changeLessonHandler(prevLessonUUID)}
                >
                  <i className="fas fa-arrow-left me-1" />
                  Previous lesson
                </EvoliButton>
              )}
              {/* Back to video button */}
              <EvoliButton variant="warning" onClick={onHide}>
                <i className="fas fa-video me-1" />
                Back to video
              </EvoliButton>
              {/* Go to next lesson button */}
              {nextLessonUUID && (
                <EvoliButton
                  variant="success"
                  onClick={() => changeLessonHandler(nextLessonUUID)}
                >
                  Go to next lesson
                  <i className="fas fa-arrow-right ms-1" />
                </EvoliButton>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      {/* Buttons */}
      <Container className="bottom"></Container>
    </Styles.FeedbackPage>
  );
};

export default LessonFeedback;
