export const getLastAccessString = (lastAccessDate) => {
  //If the date is null, return "Never"
  if (!lastAccessDate) return "Never";
  //If the date is less than 1 hour, return the minutes
  const date = new Date(lastAccessDate);
  const now = new Date();
  const diff = now - date;
  const diffInHours = diff / (1000 * 60 * 60);
  if (diffInHours < 1) {
    return `${Math.round(diffInHours * 60)} mins ago`;
  }
  //If the date is less than 1 day, return the hours
  const diffInDays = diffInHours / 24;
  if (diffInDays < 1) {
    return `${Math.round(diffInHours)} hours ago`;
  }
  //If the date is less than 1 month, return the days
  const diffInMonths = diffInDays / 30;
  if (diffInMonths < 1) {
    return `${Math.round(diffInDays)} days ago`;
  }
  //Else return "More than 1 month ago"
  return "More than 1 month ago";
};

/**
 * Get the date formatted as "YYYY-MM-DD"
 * @param {Date} date - The date to format
 * @param {object} options - The options
 * @param {string} options.lang - The language to use (default: "en")
 * @param {string} options.country - The country to use (default: "US")
 * @returns {string} The date formatted as "YYYY-MM-DD"
 */
export const formatDate = (
  date,
  options = {
    lang: "en",
    country: "US",
  }
) => {
  const langCoutnry = `${options.lang}-${options.country}`;

  return new Intl.DateTimeFormat(langCoutnry, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);
};
