import React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/PageElements/Navbar";
import PageContainer from "../../components/PageElements/PageContainer";
import Footer from "../../components/PageElements/Footer";
import Header from "../../components/PageElements/Header";
import { Container } from "react-bootstrap";
import useFetch from "./hooks/useFetch";
import EvoliBreadcrumb from "../../components/PageElements/EvoliBreadcrumb";
import Loading from "../../components/PageElements/Loading";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useMenu from "./hooks/useMenu";
import BackToTop from "../../components/PageElements/BackToTop";

const TeacherLesson = () => {
  const params = useParams();
  const navigate = useNavigate();

  /* Query hooks */
  const {
    data: { lesson, nextLessonUUID, prevLessonUUID },
    isLoading,
    isSuccess,
  } = useFetch(params.lessonUUID);
  // Menu hook
  const { active, links, handleMenuClick } = useMenu();

  const breadcrumbItems = [
    {
      name: "My Teachings",
      to: "/teacher/home/courses",
      active: false,
    },
    {
      name: lesson?.unit?.course?.name ?? "Course",
      to: `/teacher/course/${lesson?.unit?.course?.uuid}/content`,
      active: false,
    },
    {
      name: lesson?.name ?? "Lesson",
      to: `/teacher/lesson/${lesson?.uuid}`,
      active: true,
    },
  ];

  const clickGoToPrevLesson = () => {
    if (prevLessonUUID) {
      navigate(`/teacher/lesson/${prevLessonUUID}`);
      //reload the page
      window.location.reload();
    }
  };

  const clickGoToNextLesson = () => {
    if (nextLessonUUID) {
      navigate(`/teacher/lesson/${nextLessonUUID}`);
      //reload the page
      window.location.reload();
    }
  };

  if (isLoading) return <Loading />;
  if (isSuccess)
    return (
      <>
        <Navbar />
        <PageContainer>
          <Header>
            <Container>
              <EvoliBreadcrumb items={breadcrumbItems} />
              <Header.Title>
                {prevLessonUUID && (
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="me-2"
                    onClick={clickGoToPrevLesson}
                    data-tooltip-content="Go to previous lesson"
                    data-tooltip-id="evoli-tooltip"
                    style={{ cursor: "pointer" }}
                  />
                )}
                {lesson?.name}
                {nextLessonUUID && (
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="ms-2 cursor-pointer"
                    onClick={clickGoToNextLesson}
                    data-tooltip-content="Go to next lesson"
                    data-tooltip-id="evoli-tooltip"
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Header.Title>
              <Header.Menu>
                {links.map((link, index) => (
                  <Header.MenuItem
                    key={index}
                    className={active === index && "active"}
                    onClick={() => handleMenuClick(index)}
                  >
                    {link.name}
                  </Header.MenuItem>
                ))}
              </Header.Menu>
            </Container>
          </Header>
          <Container className="py-4 position-relative">
            <Outlet context={{ lesson }} />
          </Container>
        </PageContainer>
        <Footer />
        <BackToTop />
      </>
    );
};

export default TeacherLesson;
