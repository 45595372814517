import { useDispatch, useSelector } from "react-redux";
import {
  pause,
  play,
  seekTo,
  selectGetCurrentVideoSeconds,
  selectLastActiveVolume,
  selectPlaybackRate,
  selectPlaying,
  selectSliderValue,
  selectVolume,
  setLastActiveVolume,
  setPlaybackRate,
  setSliderValue,
  setVolume,
} from "../../../../../../store/media/media.slice";
import { Dropdown } from "react-bootstrap";
import { toHHMMSS } from "../../../../../../utils/lecture.utils";
import RangeSlider from "react-bootstrap-range-slider";
import Styles from "./styles";
import { useState } from "react";

const PlayerControls = ({ lesson }) => {
  const dispatch = useDispatch();
  const [isMovingSlider, setIsMovingSlider] = useState(false);

  const start = lesson.startSecond;
  const end = lesson.endSecond;
  const min = 0;
  const max = end - start;
  const name = lesson.name;

  const volume = useSelector(selectVolume);
  const lastActiveVolume = useSelector(selectLastActiveVolume);
  const sliderValue = useSelector(selectSliderValue);
  const playbackRate = useSelector(selectPlaybackRate);
  const playing = useSelector(selectPlaying);
  const getVideoSecond = useSelector(selectGetCurrentVideoSeconds);

  const changeLastActiveVolume = (value) => {
    //Volume represents the last volume value different than 0
    if (value !== 0) dispatch(setLastActiveVolume(value));
  };

  const toggleMute = () => {
    //If is mute, return to the last value different than 0
    if (volume === 0) {
      dispatch(setVolume(parseInt(lastActiveVolume)));
    } else {
      //If is not muted, mute, but don't edit the last volume value different than 0
      dispatch(setVolume(0));
    }
  };

  const onMoveTimeSlider = (e) => {
    //Get slider value
    const sliderValue = e.target.value;
    //When the user starts moving the slider, save the time
    if (!isMovingSlider) {
      dispatch(pause());
      setIsMovingSlider(true);
    }
    //Update the slider
    dispatch(setSliderValue(sliderValue));
  };

  const onReleaseTimeSlider = (e) => {
    //Set the slider as not moving
    setIsMovingSlider(false);
    //Get slider value
    const sliderValue = parseInt(e.target.value);
    //Update the time
    const seekToSecond = sliderValue + parseInt(start);
    dispatch(seekTo(seekToSecond));
  };

  const deltaSeek = (delta) => {
    //Get the current time
    const seekFromSecond = Math.round(getVideoSecond());
    //Set the new time
    let seekToSecond = seekFromSecond + delta;
    //If the new time is lower than 0, set it to 0
    if (seekToSecond < start) seekToSecond = start;
    //If the new time is greater than the max, set it to the max
    if (seekToSecond > end) seekToSecond = end;
    //Update the time
    dispatch(seekTo(seekToSecond));
  };

  return (
    <Styles.VideoControlsContainer>
      <div className="timeline">
        <RangeSlider
          min={min}
          max={max}
          tooltip="off"
          value={sliderValue || min}
          onChange={onMoveTimeSlider}
          onAfterChange={onReleaseTimeSlider}
          size="lg"
        />
      </div>
      <Styles.Controls>
        <div className="start">
          {playing ? (
            /* Pause icon */
            <i className="fas fa-pause" onClick={() => dispatch(pause())} />
          ) : (
            /* Play icon */
            <i className="fas fa-play" onClick={() => dispatch(play())} />
          )}
          <Styles.VolumeContainer>
            <div onClick={toggleMute}>
              {volume === 0 ? (
                /* Volume 0 icon */
                <i className="fas fa-volume-mute" />
              ) : volume > 0 && volume < 33 ? (
                /* Volume off icon */
                <i className="fas fa-volume-off" />
              ) : volume >= 33 && volume < 66 ? (
                /* Volume low icon */
                <i className="fas fa-volume-down" />
              ) : (
                /* Volume high icon */
                <i className="fas fa-volume-up" />
              )}
            </div>
            <RangeSlider
              className="volume-slider"
              value={volume}
              tooltip="off"
              onChange={(e) => dispatch(setVolume(parseInt(e.target.value)))}
              onAfterChange={(e) => changeLastActiveVolume(e.target.value)}
              min={0}
              max={100}
            />
          </Styles.VolumeContainer>
          <Styles.DurationContainer>
            {toHHMMSS(sliderValue || 0)} / {toHHMMSS(max)}
          </Styles.DurationContainer>
          {name && <span className="lesson-name">· {name}</span>}
        </div>
        <div className="d-flex flex-row align-items-center gap-2">
          <span className="action-btn" onClick={() => deltaSeek(-5)}>
            -5s
          </span>
          <span className="action-btn" onClick={() => deltaSeek(5)}>
            +5s
          </span>
          {/* Dropdpwn for changing the playback rate */}
          <Dropdown>
            <Dropdown.Toggle className="action-btn" as={"span"}>
              {playbackRate}x
            </Dropdown.Toggle>
            <Dropdown.Menu variant="dark">
              {/* From 2 to 0.25 */}
              {[2, 1.75, 1.5, 1.25, 1, 0.75, 0.5, 0.25].map((rate) => (
                <Dropdown.Item
                  key={rate}
                  onClick={() => dispatch(setPlaybackRate(rate))}
                >
                  {rate}x
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Styles.Controls>
    </Styles.VideoControlsContainer>
  );
};

export default PlayerControls;
