import React, { useState } from "react";
import StudentFeedbackResume from "../Commons/StudentFeedbackResume";
import { PacmanLoader } from "react-spinners";
import { Col, Container, Pagination, Row, Table } from "react-bootstrap";
import useFetch from "./hooks/useFetch";
import { getLastAccessString } from "../../utils/time.utils";

const LessonFeedback = ({
  difficultyEnabled,
  difficultyScale,
  initialKnowledgeEnabled,
  understandingScale,
  limit,
  order,
  orderBy,
  lessonUUID,
}) => {
  const [page, setPage] = useState(1);
  const offset = (page - 1) * limit;

  const { feedback, count, isLoading, isError, errorMessage, isSuccess } =
    useFetch({ lessonUUID, limit, offset, order, orderBy });

  const pages = Math.ceil(count / limit);

  const changePageHandler = (pageValue) => {
    if (pageValue >= 1 && pageValue <= pages) setPage(pageValue);
  };

  if (isLoading) return <PacmanLoader />;
  if (isError) return <div className="text-danger fw-bold">{errorMessage}</div>;
  if (isSuccess)
    return (
      <Container>
        <Row>
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Feedback</th>
                  <th>Comment</th>
                  <th>Created at</th>
                </tr>
              </thead>
              <tbody>
                {feedback.map((feedback, index) => (
                  <tr key={index}>
                    <td>
                      <StudentFeedbackResume
                        difficulty={feedback.difficulty}
                        initialKnowledge={feedback.initialKnowledge}
                        understanding={feedback.understanding}
                        colored={true}
                        difficultyEnabled={difficultyEnabled}
                        difficultyScale={difficultyScale}
                        initialKnowledgeEnabled={initialKnowledgeEnabled}
                        percentage={false}
                        understandingScale={understandingScale}
                      />
                    </td>

                    <td>{feedback.text}</td>
                    {/* Updated at formatted */}
                    <td className="text-muted fst-italic">
                      {getLastAccessString(feedback.updatedAt)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* Pagination section */}
            <div className="d-flex flex-column gap-1">
              {/* [start index]-[end index] of [count] feedback  */}
              <div className="d-flex flex-row justify-content-center">
                <span className="fs-6 fst-italic">
                  {count === 0 ? 0 : page * limit - limit + 1}-
                  {page * limit > count ? count : page * limit} of {count}{" "}
                  feedback
                </span>
              </div>
              {/* Pagination */}
              <div className="d-flex flex-row justify-content-center">
                <Pagination>
                  {page === 1 ? (
                    <Pagination.First disabled />
                  ) : (
                    <Pagination.First onClick={() => changePageHandler(1)} />
                  )}
                  {page > 1 && (
                    <Pagination.Item
                      onClick={() => changePageHandler(page - 1)}
                    >
                      {page - 1}
                    </Pagination.Item>
                  )}
                  <Pagination.Item active>{page}</Pagination.Item>
                  {page < pages && (
                    <Pagination.Item
                      onClick={() => changePageHandler(page + 1)}
                    >
                      {page + 1}
                    </Pagination.Item>
                  )}
                  {page < pages ? (
                    <Pagination.Last onClick={() => changePageHandler(pages)} />
                  ) : (
                    <Pagination.Last disabled />
                  )}
                </Pagination>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
};

export default LessonFeedback;
