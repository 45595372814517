import { MaintenanceComponent } from "../../pages/admin/styles";
import { useState } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toastSuccessBottom } from "../../utils/Toast";
import { EvoliButton, CancelButton } from "../../styles/Forms.styles";
import EvoliModal from "../Commons/EvoliModal";

const MaintenanceSwitch = () => {
  //TODO: probably should be centralized state
  const [maintenance, setMaintenance] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleConfirm = () => {
    setMaintenance(true);
    setShow(false);
  };

  const handleShow = () => setShow(true);
  return (
    <>
      <MaintenanceComponent>
        <div>
          <div className="d-flex gap-3 align-items-center pb-3">
            <div className="maintenance-label">Maintenance mode</div>
            <Form.Switch
              checked={maintenance}
              onChange={(e) => {
                if (e.target.checked) handleShow();
                else {
                  setMaintenance(false);
                  toastSuccessBottom("Maintenance mode deactivated");
                }
              }}
            />
          </div>
          {maintenance && (
            <div className="maintenance-warning">
              WARNING: Maintenance mode is active!
            </div>
          )}
        </div>
      </MaintenanceComponent>
      <EvoliModal show={show} onHide={handleClose}>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Activate maintenance mode?</Modal.Title>
        </Modal.Header>
        <Modal.Body>the platform will be unreachable by users</Modal.Body>
        <Modal.Footer>
          <CancelButton variant="secondary" onClick={handleClose}>
            Cancel
          </CancelButton>
          <EvoliButton variant="primary" onClick={handleConfirm}>
            Confirm
          </EvoliButton>
        </Modal.Footer>
      </EvoliModal>
    </>
  );
};

export default MaintenanceSwitch;
