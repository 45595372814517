import React from "react";
import { useTranslation } from "react-i18next";
import Reactions from "../reactions/Reactions";
import { confirmDeleteQuestion } from "../../../../utils/Alert";
import Styles from "./styles";
import { EvoliButton } from "../../../../styles/Forms.styles";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectCurrentUser } from "../../../../store/auth/auth.slice";
import { useSelector } from "react-redux";

const Answer = ({
  answer,
  isFirst,
  reactionHandler,
  deleteQuestionHandler,
}) => {
  //Translator hook
  const { t } = useTranslation("", { keyPrefix: "components.chat" });
  const currentUser = useSelector(selectCurrentUser);

  //Desctructure answer
  const {
    uuid,
    text,
    updatedAt,
    user: { email, lastName, firstName },
  } = answer;

  //Helper function to get reaction values
  const getReactionValues = () => {
    const values = new Array(5).fill(0).map(() => {
      return { value: 0, userReacted: false };
    });

    Object.values(answer.reactions || {}).forEach((reaction) => {
      values[reaction.value - 5].value += 1;

      if (reaction.email === currentUser.email) {
        values[reaction.value - 5].userReacted = true;
      }
    });

    return values;
  };

  const clickOnDeleteHandler = () => {
    confirmDeleteQuestion({
      translationFunction: t,
      callback: () => deleteQuestionHandler(uuid),
    });
  };

  return (
    <Styles.Answer isFirst={isFirst}>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <span className="fw-bold">{`${lastName} ${firstName}`}</span>
        <span>
          {new Date(updatedAt).toLocaleString([], {
            dateStyle: "short",
            timeStyle: "short",
          })}
        </span>
      </div>
      <span>{text}</span>
      <div className="d-flex flex-row justify-content-between align-items-center mt-4 mx-2 gap-2">
        <Reactions
          message={answer}
          values={getReactionValues()}
          reactionHandler={reactionHandler}
        />
        {currentUser.email === email && (
          <EvoliButton variant="danger" onClick={() => clickOnDeleteHandler()}>
            <FontAwesomeIcon className="me-1" icon={faTrash} />
            Delete
          </EvoliButton>
        )}
      </div>
    </Styles.Answer>
  );
};

export default Answer;
