import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb,
  faMountain,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";

export const UnderstandingIcon = ({ className, ...props }) => {
  return (
    <FontAwesomeIcon
      data-tooltip-content="Undestanding"
      data-tooltip-id="evoli-tooltip"
      icon={faLightbulb}
      className={className}
      {...props}
    />
  );
};

export const DifficultyIcon = ({ className, ...props }) => {
  return (
    <FontAwesomeIcon
      data-tooltip-content="Difficulty"
      data-tooltip-id="evoli-tooltip"
      icon={faMountain}
      className={className}
      {...props}
    />
  );
};

export const InitialKnowledgeIcon = ({ className, ...props }) => {
  return (
    <FontAwesomeIcon
      data-tooltip-content="Initial Knowledge"
      data-tooltip-id="evoli-tooltip"
      icon={faFlag}
      className={className}
      {...props}
    />
  );
};
