import React from "react";
import { useGetUnitStudentsInfoQuery } from "../../../../../store/analytics/analytics.api";
import { te } from "../../../../../utils/errorsTranslation";

const useFetch = ({ unit, limit, offset, orderBy, order, searchQuery }) => {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [studentList, setStudentList] = React.useState([]);
  const [itemsCount, setItemsCount] = React.useState(0);

  const {
    data: studentsData,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useGetUnitStudentsInfoQuery({
    unitUUID: unit.uuid,
    limit,
    offset,
    orderBy,
    order,
    searchQuery,
  });

  React.useEffect(() => {
    if (isError) {
      if (!error.data?.code && !error.code)
        setErrorMessage(te("general_error"));
      else setErrorMessage(te(error.data?.code ?? error.code));
    } else if (isSuccess) {
      setErrorMessage(null);
      setStudentList(studentsData.students ?? []);
      setItemsCount(studentsData.count ?? 0);
    }
  }, [error, isError, isSuccess, studentsData]);

  return {
    isLoading,
    errorMessage,
    isError,
    isSuccess,
    itemsCount,
    studentList,
  };
};

export default useFetch;
