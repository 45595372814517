import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useLazyGetContactMessageByUuidQuery } from "../../../../store/admin/admin.api";
import {
  MessageDetailContainer,
  MessageDetailPageContainer,
} from "../../styles";
import { useLocation, useParams } from "react-router-dom";
import { EvoliButton } from "../../../../styles/Forms.styles";
import RichTextbox from "../../../../components/Commons/RichTextbox/RichTextbox";

const AdminMessageDetails = () => {
  /* hooks */
  const location = useLocation();
  const params = useParams();

  const uuid = params.id;

  const [message, setMessage] = useState(location.state?.message);
  const [getMessage, { isLoading }] = useLazyGetContactMessageByUuidQuery();
  const editorRef = useRef(null);
  const [showReplyBox, setShowReplyBox] = useState(false);

  useEffect(() => {
    if (!message) {
      getMessage({ uuid })
        .unwrap()
        .then((res) => {
          setMessage(res);
        });
    }
  }, [getMessage, message, uuid]);

  const toggleReplyHandler = () => {
    setShowReplyBox((prev) => !prev);
  };

  if (!message || isLoading) return "Loading...";

  return (
    <AdminMessageDetailsPresentation
      message={message}
      showReplyBox={showReplyBox}
      toggleReplyHandler={toggleReplyHandler}
      editorRef={editorRef}
    />
  );
};

export default AdminMessageDetails;

const AdminMessageDetailsPresentation = ({
  message,
  showReplyBox,
  toggleReplyHandler,
  editorRef,
}) => {
  return (
    <MessageDetailPageContainer>
      <MessageDetailContainer showReplyBox={showReplyBox}>
        <Link className="back-to-message-list" to="/admin/messages">
          Back to message list
        </Link>
        <div className="message">
          <div className="message-header">
            <div>
              <div>Name: {message.name}</div>
              <div>Email: {message.email}</div>
            </div>
            <div>Sent: {new Date(message.createdAt).toLocaleString()}</div>
          </div>
          <div className="message-subject">Subject: {message.subject}</div>
          <div>{message.message}</div>
          {!showReplyBox && (
            <i
              onClick={toggleReplyHandler}
              className="reply-icon fas fa-reply"
            />
          )}
        </div>
        <div className="reply-box">
          <div className="reply-editor">
            <RichTextbox editorRef={editorRef} />
          </div>

          <div className="action-buttons">
            <EvoliButton variant="primary">Send</EvoliButton>
            <div onClick={toggleReplyHandler}>
              <svg
                className="close-icon"
                width={30}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM377 271c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-87-87-87 87c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 167c9.4-9.4 24.6-9.4 33.9 0L377 271z" />
              </svg>
            </div>
          </div>
        </div>
      </MessageDetailContainer>
    </MessageDetailPageContainer>
  );
};
