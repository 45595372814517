import React, { useEffect, useState } from "react";
import screenfull from "screenfull";
import classNames from "classnames";
import Div100vh from "react-div-100vh";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LessonVideoPlayer from "./components/LessonVideoPlayer/LessonVideoPlayer";
import useSocket from "./hooks/useSocket";
import Loading from "../../components/PageElements/Loading";
import Styles from "./styles";
import LessonFeedback from "./components/LessonFeedback/LessonFeedback";
import Settings from "./components/Settings/Settings";
import CourseContent from "./components/CourseContent/CourseContent";
import ClassroomFeedback from "./components/ClassroomFeedback/ClassroomFeedback";
import LessonQuestionsList from "./components/LessonQuestions/LessonQuestionsList";
import useFetch from "./hooks/useFetch";
import { useDispatch } from "react-redux";
import { resetMediaState } from "../../store/media/media.slice";

const StudentLessonPage = () => {
  //Params hook
  const params = useParams();
  //Dispatch hook
  const dispatch = useDispatch();

  //Queries
  const {
    isLoading,
    isSuccess,
    log,
    data: { lesson, myFeedback },
  } = useFetch(params.lessonUUID);

  //Handler for socket connection
  useSocket({
    lessonUUID: params.lessonUUID,
    skip: !isSuccess,
  });

  /* Component state */
  const defaultPanelWidth = 35;
  const defaultSidebarWidth = 5;
  const defaultPortraitContentHeight = 90;
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("questions");
  const [sidePanelWidth, setSidePanelWidth] = useState(defaultPanelWidth);
  const [sidebarWidth, setSidebarWidth] = useState(defaultSidebarWidth); //rem
  const [portraitContentHeight, setPortraitContentHeight] = useState(
    defaultPortraitContentHeight
  ); //vh
  const [showFeedback, setShowFeedback] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const goFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.request();
      setIsFullScreen(true);
      setSidebarWidth(0);
      setPortraitContentHeight(100);
    }
  };

  const exitFullScreen = () => {
    if (isFullScreen) {
      screenfull.exit();
      setIsFullScreen(false);
      setSidebarWidth(defaultSidebarWidth);
      setPortraitContentHeight(defaultPortraitContentHeight);
    }
  };

  //Handle the click on a menu item
  const clickMenuItemHandler = (tabName) => {
    if (tabName === selectedTab) setIsPanelOpen(!isPanelOpen);
    else setIsPanelOpen(true);
    setSelectedTab(tabName);
  };

  const onLessonEnd = () => {
    setShowFeedback(true);
    dispatch(resetMediaState());
    log("finish", lesson.endSecond);
    exitFullScreen();
  };

  useEffect(() => {
    dispatch(resetMediaState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Loading page={true} />;
  if (isSuccess)
    return (
      <Div100vh>
        <Styles.PageContainer
          isPanelOpen={isPanelOpen}
          panelWidth={sidePanelWidth}
          sidebarWidth={sidebarWidth}
          portraitContentHeight={portraitContentHeight}
          isFullScreen={isFullScreen}
        >
          {/* MAIN CONTENT: VIDEO  */}
          <div className="main-content">
            {showFeedback ? (
              <LessonFeedback
                lesson={lesson}
                onHide={() => setShowFeedback(false)}
                show={showFeedback}
              />
            ) : (
              <LessonVideoPlayer
                lesson={lesson}
                onLessonEnd={onLessonEnd}
                log={log}
                isFullScreen={isFullScreen}
                goFullScreen={goFullScreen}
                exitFullScreen={exitFullScreen}
              />
            )}
          </div>

          {/* SIDE PANEL */}
          {isPanelOpen && !isFullScreen && (
            <SidePanel
              lesson={lesson}
              defaultPanelWidth={defaultPanelWidth}
              clickMenuItemHandler={clickMenuItemHandler}
              selectedTab={selectedTab}
              setSidePanelWidth={setSidePanelWidth}
              sidePanelWidth={sidePanelWidth}
            />
          )}

          {/* MENU */}
          {!isFullScreen && (
            <Menu
              isPanelOpen={isPanelOpen}
              clickMenuItemHandler={clickMenuItemHandler}
              selectedTab={selectedTab}
              lesson={lesson}
              myFeedback={myFeedback}
              completeLessonHandler={onLessonEnd}
              sidebarWidth={sidebarWidth}
            />
          )}
        </Styles.PageContainer>
      </Div100vh>
    );
};

const SidePanel = ({
  lesson,
  sidePanelWidth,
  setSidePanelWidth,
  defaultPanelWidth,
  selectedTab,
  clickMenuItemHandler,
}) => {
  //Handlers for expand and compress buttons
  const clickCompressHandler = () => setSidePanelWidth(defaultPanelWidth);
  const clickExpandHandler = () => setSidePanelWidth(100);

  return (
    <Styles.SidePanel>
      <div className="flex-grow-1 content">
        {selectedTab === "questions" && <LessonQuestionsList lesson={lesson} />}
        {selectedTab === "dashboard" && <ClassroomFeedback lesson={lesson} />}
        {selectedTab === "course" && <CourseContent lesson={lesson} />}
        {selectedTab === "settings" && <Settings />}
      </div>
      {/* EXPAND, COMPRESS AND CLOSE BUTTONS */}
      {sidePanelWidth === defaultPanelWidth ? (
        <div className="resize-btn" onClick={clickExpandHandler}>
          <i className="fas fa-expand" />
        </div>
      ) : (
        <div className="resize-btn" onClick={clickCompressHandler}>
          <i className="fas fa-compress" />
        </div>
      )}
      <div
        className="close-btn"
        onClick={() => clickMenuItemHandler(selectedTab)}
      >
        <i className="fas fa-times" />
      </div>
    </Styles.SidePanel>
  );
};

const Menu = ({
  clickMenuItemHandler,
  selectedTab,
  isPanelOpen,
  lesson,
  myFeedback,
  completeLessonHandler,
  sidebarWidth,
}) => {
  //Translator hook
  const { t } = useTranslation("", { keyPrefix: "lecture.video_page" });
  //Redirect hook
  const navigate = useNavigate();

  //Handler for click home button
  const clickHomeHandler = () => {
    navigate(`/student/course/${lesson.unit.course.uuid}`);
  };

  //variables that checks if a button is selected
  const isQuestionsSelected = selectedTab === "questions" && isPanelOpen;
  const isDashboardSelected = selectedTab === "dashboard" && isPanelOpen;
  const isCourseSelected = selectedTab === "course" && isPanelOpen;
  const feedbackText = myFeedback ? "Completed" : "Complete the lesson";
  const feedbackButtonClassname = myFeedback ? " completed" : "no-feedback";

  return (
    <Styles.Sidenav expand="md" className="guide-2" sidebarWidth={sidebarWidth}>
      <div className="menu">
        {/* Home button */}
        <button className="menu-item guide-9" onClick={clickHomeHandler}>
          <i className="fas fa-home w-auto" />
          <span className="item-name">{t("home")}</span>
        </button>
        {/* Questions button */}
        <button
          className={classNames("menu-item guide-3", {
            active: isQuestionsSelected,
          })}
          onClick={() => clickMenuItemHandler("questions")}
        >
          <i className="fas fa-question w-auto" />
          <span className="item-name">{t("questions")}</span>
        </button>
        {/* Dashboard button */}
        <button
          className={classNames("menu-item", { active: isDashboardSelected })}
          onClick={() => clickMenuItemHandler("dashboard")}
        >
          <i className="fas fa-chart-bar w-auto" />
          <span className="item-name">Feedback</span>
        </button>
        {/* Course button */}
        <button
          className={classNames("menu-item", { active: isCourseSelected })}
          onClick={() => clickMenuItemHandler("course")}
        >
          <i className="fas fa-list w-auto" />
          <span className="item-name">Course</span>
        </button>
        {/* Show feedback button */}
        <button
          className={`menu-item ${feedbackButtonClassname} hide-sm mt-auto`}
          onClick={completeLessonHandler}
        >
          <span className="item-name">{feedbackText}</span>
        </button>
      </div>
    </Styles.Sidenav>
  );
};

export default StudentLessonPage;
