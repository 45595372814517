import React from "react";
import {
  useGetVideoIntervalsQuery,
  useGetVideoPausesQuery,
  useGetVisualizationsQuery,
} from "../../../../../store/analytics/analytics.api";
import { te } from "../../../../../utils/errorsTranslation";

const useFetch = ({ lesson, from, to }) => {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [intervalsList, setIntervalsList] = React.useState([]);

  const {
    data: visualizationsList,
    isLoading: isLoading1,
    isError: isError1,
    error: error1,
    isSuccess: isSuccess1,
  } = useGetVisualizationsQuery({
    lessonUUID: lesson.uuid,
    from,
    to,
  });

  const {
    data: videoIntervals,
    isLoading: isLoading2,
    isError: isError2,
    error: error2,
    isSuccess: isSuccess2,
  } = useGetVideoIntervalsQuery(lesson.uuid);

  const {
    data: videoPauses,
    isLoading: isLoading3,
    isError: isError3,
    error: error3,
    isSuccess: isSuccess3,
  } = useGetVideoPausesQuery(lesson.uuid);

  const isLoading = isLoading1 || isLoading2 || isLoading3;
  const isError = isError1 || isError2 || isError3;
  const error = error1 ?? error2 ?? error3;
  const isSuccess = isSuccess1 && isSuccess2 && isSuccess3;

  React.useEffect(() => {
    if (isError) {
      if (!error.data?.code && !error.code)
        setErrorMessage(te("general_error"));
      else setErrorMessage(te(error.data?.code ?? error.code));
    } else if (isSuccess) {
      setErrorMessage(null);
      setIntervalsList(
        videoIntervals.map((interval) => ({
          start: interval.start - lesson.startSecond,
          end: interval.end - lesson.startSecond,
        }))
      );
    }
  }, [isError, isSuccess, error, videoIntervals, lesson.startSecond]);

  return {
    data: {
      visualizationsList,
      intervalsList,
      videoPauses,
    },
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  };
};

export default useFetch;
