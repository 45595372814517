import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Navbar from "../../components/PageElements/Navbar";
import Loading from "../../components/PageElements/Loading";
import * as FormStyles from "../../styles/Forms.styles";
import { useLazyConfirmAccountQuery } from "../../store/auth/auth.api";
import PageContainer from "../../components/PageElements/PageContainer";
import Header from "../../components/PageElements/Header";
import Footer from "../../components/PageElements/Footer";
import BackToTop from "../../components/PageElements/BackToTop";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/auth/auth.slice";

const AccountConfirmedPage = () => {
  /* Params */
  const params = useParams();

  /* Queries */
  const [confirmAccount, { isLoading }] = useLazyConfirmAccountQuery();

  /* Component state */
  const [user, setUser] = useState(null);
  const [showError, setShowError] = useState(false);

  const isUserAlreadyLogged = useSelector(selectCurrentUser) !== null;

  //Try to confirm the account
  useEffect(() => {
    //Get the confirmation code from the URL
    const confirmationCode = params.confirmationCode;
    //If the confirmation code is present, call the API else show an error
    confirmationCode
      ? confirmAccount(confirmationCode)
          .unwrap()
          .then((user) => {
            setUser(user);
            setShowError(false);
            //If user is logged, then logout
          })
          .catch((err) => {
            setShowError(true);
          })
      : setShowError(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //If the page is loading, show the loading page
  if (isLoading) <Loading page={true} />;
  //Else show the page
  return (
    <>
      <Navbar dark={true} sticky />
      <PageContainer>
        <Header>
          <Container>
            <Header.Title>Account confirmation</Header.Title>
          </Container>
        </Header>
        <Container className="py-5">
          <Row>
            <Col
              lg={{ span: 5, offset: 1 }}
              sm={10}
              className="d-flex flex-column flex-grow-1 justify-content-center"
            >
              <AccountConfirmedResult
                user={user}
                showError={showError}
                isUserAlreadyLogged={isUserAlreadyLogged}
              />
            </Col>
          </Row>
        </Container>
      </PageContainer>
      <Footer />
      <BackToTop />
    </>
  );
};

const AccountConfirmedResult = ({ user, showError, isUserAlreadyLogged }) => {
  /* Translator function */
  const { t } = useTranslation("", { keyPrefix: "auth.account_confirmed" });

  //If the the user is present, then the account has been confirmed
  if (user)
    return (
      <>
        {/* If the user is already logged, show a message */}
        {isUserAlreadyLogged && (
          <div className="text-warning mb-2">
            <span className="fw-bolder">Warning:</span>
            <span className="ms-1">
              You are already logged. If you want to access with another
              account, please logout first.
            </span>
          </div>
        )}
        <h1 className="fs-3 fw-bolder text-uppercase">{t("title")}</h1>
        <p>
          {t("subtitle", {
            firstName: user.firstName,
            lastName: user.lastName,
          })}
        </p>
        <p>{t("description1")}</p>
        <Link to={"/login"}>
          <FormStyles.EvoliButton className="mt-3 fs-5">
            {t("login")}
          </FormStyles.EvoliButton>
        </Link>
      </>
    );

  //Else the confirmation code is wrong
  if (showError)
    return (
      <>
        <h1 className="fs-3 fw-bolder text-uppercase">{t("error_title")}</h1>
        <p className="w-100 mt-1 mb-0">{t("error")}</p>
      </>
    );
};

export default AccountConfirmedPage;
