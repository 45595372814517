//Build a number animation component with react-spring

import { useSpring, animated } from "react-spring";

const Number = ({ number }) => {
  const { numberValue } = useSpring({
    numberValue: number,
    from: { numberValue: 0 },
    delay: 500,
    config: {
      mass: 1,
      tension: 20,
      friction: 10,
    },
  });

  return <animated.div>{numberValue.to((n) => n.toFixed(0))}</animated.div>;
};

export default Number;
