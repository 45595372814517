import React, { useContext } from "react";
import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { ThemeContext } from "../../../../../../styles/ThemeContext";
import { toHHMMSS } from "../../../../../../utils/lecture.utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const VideoViewsLine = ({ intervals, start, end, pauseSeconds, seeks }) => {
  /* Load theme from Theme Context */
  const { theme } = useContext(ThemeContext);

  const duration = end - start;

  // Create an array of time intervals
  const timeInterval = Array.from({ length: duration }, (_, i) => i + 1);

  // Create an array of the concurrent views at each time interval
  const concurrentViews = timeInterval.map((time) => {
    return intervals.reduce((acc, cur) => {
      const startWithOffset = Math.floor(cur.start) - start;
      const endWithOffset = Math.floor(cur.end) - start;

      if (time >= startWithOffset && time < endWithOffset) {
        acc++;
      }

      return acc;
    }, 0);
  });

  //Create an array of points for the chart
  const pauses = pauseSeconds.map((value) => {
    return {
      x: value,
      y: concurrentViews[value],
    };
  });

  //Find max value in concurrentViews
  const maxValue = Math.max(...concurrentViews);

  const data = {
    labels: timeInterval,
    datasets: [
      {
        data: concurrentViews,
        label: "Views #",
        backgroundColor: `rgba(${theme.accentRGB},0.4)`,
        borderColor: `rgba(${theme.accentRGB},1)`,
        fill: true,
        tension: 0.4,
        pointRadius: 0,
      },
      //Add the dataset of pauses with only points and no line
      {
        data: pauses,
        label: "Pauses",
        backgroundColor: `transparent`,
        borderColor: `transparent`,
        pointRadius: 8,
        pointHoverRadius: 10,
        pointStyle: "rect",
        pointBackgroundColor: `rgba(${theme.primaryRGB},1)`,
        pointBorderColor: `rgba(${theme.primaryRGB},1)`,
        pointBorderWidth: 1,
        pointHoverBackgroundColor: `rgba(${theme.primaryRGB},1)`,
        pointHoverBorderColor: `rgba(${theme.primaryRGB},1)`,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "linear",
        beginAtZero: true,
        min: 0,
        max: duration,
        ticks: {
          beginAtZero: true,
          color: theme.secondary,
          callback: (tick) => toHHMMSS(tick),
        },
        grid: {
          display: true,
          borderDash: [5, 5],
          color: theme.secondary,
        },
        title: {
          display: true,
          text: "Video seconds",
          color: theme.primary,
        },
      },
      y: {
        beginAtZero: true,
        max: maxValue + 1,
        ticks: {
          stepSize: 1,
          color: theme.secondary,
        },
        grid: {
          display: true,
          color: theme.secondary,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          pointStyle: "rect",
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
        /* Edit the title value to x the HH:MM:SS of the x */
        callbacks: {
          title: (tooltipItem) => {
            return toHHMMSS(tooltipItem[0].parsed.x);
          },
          label: (tooltipItem) => {
            return `View number: ${tooltipItem.parsed.y}`;
          },
        },
      },
      zoom: {
        limits: {
          x: { min: 0, max: duration, minRange: 1 },
        },
        pan: {
          enabled: true, // SET PINCH ZOOM TO TRUE
          mode: "x",
          scaleMode: "x",
        },
        zoom: {
          wheel: {
            enabled: true, // SET SCROOL ZOOM TO TRUE
          },
          pinch: {
            enabled: true, // SET PINCH ZOOM TO TRUE
          },
          mode: "x",
          speed: 10,
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default VideoViewsLine;
