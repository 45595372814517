import { useGetUnitVisualizatorsQuery } from "../../../../../store/analytics/analytics.api";
import { useGetUnitFeedbackCountPerLessonQuery } from "../../../../../store/feedback/feedback.api";
import useErrorMessage from "../../../../../hooks/useErrorMessage";

const useFetch = ({ unit }) => {
  const {
    data: unitVisualizations,
    isLoading: isLoading1,
    isError: isError1,
    error: error1,
    isSuccess: isSuccess1,
  } = useGetUnitVisualizatorsQuery(unit.uuid);

  const {
    data: unitFeedbackCountPerLesson,
    isLoading: isLoading2,
    isError: isError2,
    error: error2,
    isSuccess: isSuccess2,
  } = useGetUnitFeedbackCountPerLessonQuery(unit.uuid);

  const isLoading = isLoading1 || isLoading2;
  const isError = isError1 || isError2;
  const error = error1 || error2;
  const isSuccess = isSuccess1 && isSuccess2;
  const errorMessage = useErrorMessage({ isError, error });

  return {
    data: {
      unitVisualizations,
      unitFeedbackCountPerLesson,
    },
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  };
};

export default useFetch;
