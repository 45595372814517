import React, { useRef, useState } from "react";
import {
  Col,
  Container,
  Pagination,
  ProgressBar,
  Row,
  Table,
} from "react-bootstrap";
import Loading from "../../../../components/PageElements/Loading";
import { useOutletContext } from "react-router-dom";
import { EvoliButton } from "../../../../styles/Forms.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import InfoTooltip from "../../../../components/Commons/InfoTooltip";
import { getLastAccessString } from "../../../../utils/time.utils";
import { toHHMMSS } from "../../../../utils/lecture.utils";
import usePagination from "./hooks/usePagination";
import useFetch from "./hooks/useFetch";
import SearchBar from "../../../../components/Form/SearchBar";
import LimitSelect from "../../../../components/Form/LimitSelect";

const UnitStudents = () => {
  const { unit } = useOutletContext();
  const totalLessons = unit.lessons?.length ?? 0;

  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(1);
  const [showNames, setShowNames] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryValue, setSearchQueryValue] = useState("");
  const searchInputRef = useRef(null);

  const {
    changeOrderHandler,
    offset,
    order,
    orderBy,
    orderValue,
    options,
    reset,
  } = usePagination({
    limit,
    page,
    searchInputRef,
    setSearchQuery,
    setSearchQueryValue,
  });

  const {
    isLoading,
    errorMessage,
    isError,
    isSuccess,
    itemsCount,
    studentList,
  } = useFetch({
    unit,
    limit,
    offset,
    orderBy,
    order,
    searchQuery,
  });

  const pages = Math.ceil(itemsCount / limit);
  const changePageHandler = (pageValue) => {
    if (pageValue >= 1 && pageValue <= pages) setPage(pageValue);
  };

  const changeSearchHandler = (e) => {
    const value = e.target.value;
    setSearchQueryValue(value);
  };

  const submitSearchHandler = (e) => {
    e.preventDefault();
    setSearchQuery(searchQueryValue);
    setPage(1);
  };

  if (isError) return <div className="text-danger fw-bold">{errorMessage}</div>;
  if (isLoading) return <Loading page={false} />;
  if (isSuccess)
    return (
      <Container className="py-5">
        <Row>
          <Col>
            <h2>Students analysis</h2>
            <p className="mb-4">
              Discover how many students are enrolled in your course and how
              they are progressing through the lessons of this unit.
            </p>
          </Col>
        </Row>
        <Row className="mb-4">
          <div className="d-flex flex-column flex-md-row gap-2 gap-md-3 w-100">
            {/* Order by firstName, lastName, lastCourseAccessDate, totalSecondsViewed, completedLessons */}
            <LimitSelect limit={limit} setLimit={setLimit} />
            <div className="d-flex flex-column gap-1">
              <label>Order by</label>
              <select
                className="form-select"
                value={orderValue}
                onChange={changeOrderHandler}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            {/* Search box */}
            <div className="d-flex flex-row align-items-end gap-2">
              <div className="d-flex flex-column flex-grow-1 gap-1">
                <label>Search</label>
                <SearchBar
                  className="w-100"
                  inputRef={searchInputRef}
                  placeholder={"Search my students"}
                  onChange={changeSearchHandler}
                  onSubmit={submitSearchHandler}
                />
              </div>
              {/* Reset filter and order */}
              <EvoliButton onClick={reset}>Reset</EvoliButton>
            </div>
          </div>
        </Row>
        <Row>
          <Table striped borderless hover>
            <thead>
              <tr className="text-center">
                <th className="d-flex flex-row justify-content-center align-items-center gap-2">
                  <span>Student</span>
                  <EvoliButton onClick={() => setShowNames(!showNames)}>
                    <FontAwesomeIcon icon={showNames ? faEyeSlash : faEye} />
                  </EvoliButton>
                </th>
                <th>
                  <span className="me-1"> Last unit access</span>
                  <InfoTooltip text="The last time the student accessed a lesson of the unit" />
                </th>
                <th>
                  <span className="me-1">Total connection time (HH:MM:SS)</span>
                  <InfoTooltip text="The total time the student spent in the lesson pages of the unit" />
                </th>
                <th>
                  <span className="me-1">Completed lessons</span>
                  <InfoTooltip text="The number of lessons of the unit that the student has given a feedback" />
                </th>
              </tr>
            </thead>
            <tbody>
              {studentList.map((student) => (
                <StudentItem
                  key={student.email}
                  student={student}
                  totalLessons={totalLessons}
                  showNames={showNames}
                />
              ))}
            </tbody>
          </Table>
        </Row>
        <Row>
          {/* Pagination section, show 12 courses */}
          <div className="d-flex flex-column gap-1">
            {/* [start index]-[end index] of [count] courses  */}
            <div className="d-flex flex-row justify-content-center">
              <span className="fs-6 fst-italic">
                {itemsCount === 0 ? 0 : page * limit - limit + 1}-
                {page * limit > itemsCount ? itemsCount : page * limit} of{" "}
                {itemsCount} students
              </span>
            </div>
            {/* Pagination */}
            <div className="d-flex flex-row justify-content-center">
              <Pagination>
                {page === 1 ? (
                  <Pagination.First disabled />
                ) : (
                  <Pagination.First onClick={() => changePageHandler(1)} />
                )}
                {page > 1 && (
                  <Pagination.Item onClick={() => changePageHandler(page - 1)}>
                    {page - 1}
                  </Pagination.Item>
                )}
                <Pagination.Item active>{page}</Pagination.Item>
                {page < pages && (
                  <Pagination.Item onClick={() => changePageHandler(page + 1)}>
                    {page + 1}
                  </Pagination.Item>
                )}
                {page < pages ? (
                  <Pagination.Last onClick={() => changePageHandler(pages)} />
                ) : (
                  <Pagination.Last disabled />
                )}
              </Pagination>
            </div>
          </div>
        </Row>
      </Container>
    );
};

const StudentItem = ({ student, totalLessons, showNames }) => {
  const { firstName, lastName, analytics } = student;

  //Desctructure analytics
  const completedLessons = analytics?.completedLessons ?? 0;
  //const lastAccessedLesson = analytics?.lastAccessedLesson ?? null;
  const lastUnitAccessDate = analytics?.lastUnitAccessDate ?? null;
  const totalSecondsViewed = analytics?.totalSecondsViewed ?? 0;

  const fullName = showNames ? `${lastName} ${firstName}` : "Hidden name";
  //Get the Viewing time in HH:MM format
  let viewTime = toHHMMSS(totalSecondsViewed);
  const percentage = Math.round((completedLessons / totalLessons) * 100);
  //Color is based on the percentage of completed lessons: red < 33%, yellow < 66%, green > 66%
  const color =
    percentage < 33 ? "danger" : percentage < 66 ? "warning" : "success";

  return (
    <tr className="w-100 text-center">
      {/* Full name */}
      <td className={!showNames ? "fst-italic" : ""}>{fullName}</td>
      {/* Last access */}
      <td>{getLastAccessString(lastUnitAccessDate)}</td>
      {/* Viewing time */}
      <td>{viewTime}</td>
      {/* Completed lessons */}
      <td>
        <ProgressBar
          min={0}
          max={totalLessons}
          now={completedLessons}
          label={
            <span className="fw-bold">
              {completedLessons} / {totalLessons}
            </span>
          }
          variant={color}
        />
      </td>
    </tr>
  );
};

export default UnitStudents;
