import React from "react";

const usePagination = ({
  limit,
  page,
  searchInputRef,
  setSearchQuery,
  setSearchQueryValue,
}) => {
  const options = [
    {
      label: "Student first name (A-Z)",
      value: "firstNameAsc",
    },
    {
      label: "Student first name (Z-A)",
      value: "firstNameDesc",
    },
    {
      label: "Student last name (A-Z)",
      value: "lastNameAsc",
    },
    {
      label: "Student last name (Z-A)",
      value: "lastNameDesc",
    },
    {
      label: "Last unit access date (oldest first)",
      value: "lastUnitAccessDateAsc",
    },
    {
      label: "Last unit access date (newest first)",
      value: "lastUnitAccessDateDesc",
    },
    {
      label: "Total seconds viewed (lowest first)",
      value: "totalSecondsViewedAsc",
    },
    {
      label: "Total seconds viewed (highest first)",
      value: "totalSecondsViewedDesc",
    },
    {
      label: "Completed lessons (lowest first)",
      value: "completedLessonsAsc",
    },
    {
      label: "Completed lessons (highest first)",
      value: "completedLessonsDesc",
    },
  ];

  const [order, setOrder] = React.useState("ASC"); //ASC or DESC
  const [orderBy, setOrderBy] = React.useState("lastName");
  const [orderValue, setOrderValue] = React.useState("lastNameAsc");
  const offset = (page - 1) * limit;

  const reset = React.useCallback(() => {
    setOrderValue("lastNameAsc");
    setOrder("ASC");
    setOrderBy("lastName");
    setSearchQuery("");
    setSearchQueryValue("");
    if (searchInputRef.current) searchInputRef.current.value = "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeOrderHandler = (e) => {
    const value = e.target.value;
    setOrderValue(value);
    //The value is in the format [field][order]
    //Example: firstNameAsc or lastNameDesc
    //If the value ends with Asc, the order is ASC, else DESC
    if (value.endsWith("Asc")) {
      setOrder("ASC");
      //Order by is the value without the last 3 characters
      setOrderBy(value.slice(0, -3));
    } else {
      setOrder("DESC");
      //Order by is the value without the last 4 characters
      setOrderBy(value.slice(0, -4));
    }
  };

  //Initialize students list
  React.useEffect(() => {
    reset();
  }, [reset]);

  return {
    changeOrderHandler,
    offset,
    orderBy,
    order,
    orderValue,
    options,
    reset,
  };
};

export default usePagination;
