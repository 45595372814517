import { Container } from "react-bootstrap";
import styled from "styled-components";

const Styles = {};

Styles.ErrorContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.primary};
  height: 100% !important;
  padding-top: 3rem;
  padding-bottom: 3rem;

  .maintenance-icon {
    font-size: 20vh;
  }

  .title {
    font-size: 3rem;
    font-weight: bold;
  }

  p {
    font-size: 1.4rem;
  }
`;

export default Styles;
