import React, { useContext, useState } from "react";
import Styles from "./styles";
import { Form } from "react-bootstrap";
import { toHHMMSS, toastApiError } from "../../../../../../utils/lecture.utils";
import { useSelector } from "react-redux";
import { selectSliderValue } from "../../../../../../store/media/media.slice";
import { EvoliButton } from "../../../../../../styles/Forms.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faThumbTack } from "@fortawesome/free-solid-svg-icons";
import { useCreateQuestionMutation } from "../../../../../../store/feedback/feedback.api";
import { toastSuccess } from "../../../../../../utils/Toast";
import { ClipLoader } from "react-spinners";
import { ThemeContext } from "../../../../../../styles/ThemeContext";

const QuestionPanel = ({ lesson, setAcceptEvents }) => {
  const { theme } = useContext(ThemeContext);
  const sliderValue = useSelector(selectSliderValue);
  const [isPinned, setIsPinned] = useState(true);
  const [question, setQuestion] = useState("");

  const buttonDisabled = question.trim().length === 0;

  //Query hooks
  const [createQuestion, { isLoading }] = useCreateQuestionMutation();

  const submitHandler = (event) => {
    event.preventDefault();

    //Shorten text under 255 characters
    const questionText = question.substring(0, 255);
    //Check if question is empty
    if (questionText.trim().length === 0) return;
    //Create question
    createQuestion({
      lessonUUID: lesson.uuid,
      text: questionText,
      second: Math.floor(sliderValue) + lesson.startSecond,
    })
      .unwrap()
      .then(() => {
        //Reset question text
        toastSuccess("Question created successfully");
        setQuestion("");
      })
      .catch((err) => {
        console.error(err);
        toastApiError(err);
      });
  };

  return (
    <Styles.QuestionPanel isPinned={isPinned}>
      <div className="pin" onClick={() => setIsPinned((p) => !p)}>
        <span>Ask a question</span>
        <FontAwesomeIcon icon={faThumbTack} />
      </div>
      <Form className="question-form" onSubmit={submitHandler}>
        <div className="time">
          <i className="far fa-clock" />
          <span>{toHHMMSS(sliderValue)}</span>
        </div>
        <Form.Control
          className="question-text"
          type="text"
          placeholder="Ask a question at any time; your peers and the teacher will be able to see it"
          maxLength="250"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          onFocus={() => setAcceptEvents(false)}
          onBlur={() => setAcceptEvents(true)}
        />
        <EvoliButton className="send" type="submit" disabled={buttonDisabled}>
          {isLoading ? (
            <ClipLoader color={theme.textPrimary} size={16} />
          ) : (
            <FontAwesomeIcon icon={faPaperPlane} />
          )}
        </EvoliButton>
      </Form>
    </Styles.QuestionPanel>
  );
};

export default QuestionPanel;
