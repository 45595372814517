import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  player: null, //Video player reference
  slideNumber: 1, //Current slide number
  playing: false, //Is the video playing?
  seeking: false, //Is the video seeking?
  volume: 50, //Volume of the video
  lastActiveVolume: 50, //Last active volume of the video
  sliderValue: 0, //Current slider value
  playbackRate: 1, //Playback rate of the video
  startSecond: 0, //Start second of the video
  endSecond: 0, //End second of the video
};

const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    //Set the current slide number
    setSlideNumber: (state, action) => {
      state.slideNumber = action.payload;
    },
    //Register the video player reference
    resetPlayer: (state, action) => {
      state.player = action.payload;
    },
    //Seek to a specific second
    seekTo: (state, action) => {
      const second = action.payload;
      //Seek the video
      state.player?.seekTo(second);
      state.sliderValue = second - state.startSecond;
    },
    //Set the start end end second
    setPlayingInterval: (state, action) => {
      state.startSecond = action.payload.startSecond;
      state.endSecond = action.payload.endSecond;
    },
    //Play the video
    play: (state) => {
      state.playing = true;
    },
    //Pause the video
    pause: (state) => {
      state.playing = false;
    },
    //Set the volume
    setVolume: (state, action) => {
      state.volume = action.payload;
    },
    //Set the last active volume
    setLastActiveVolume: (state, action) => {
      state.lastActiveVolume = action.payload;
    },
    //Set slider value
    setSliderValue: (state, action) => {
      state.sliderValue = action.payload;
    },
    //Set playback rate
    setPlaybackRate: (state, action) => {
      state.playbackRate = action.payload;
    },
    setIsSeeking: (state, action) => {
      state.seeking = action.payload;
    },
    resetMediaState: () => initialState,
  },
});

export const {
  resetPlayer,
  setSlideNumber,
  seekTo,
  setPlayingInterval,
  play,
  pause,
  setVolume,
  setLastActiveVolume,
  setSliderValue,
  setPlaybackRate,
  setIsSeeking,
  resetMediaState,
} = mediaSlice.actions;

export default mediaSlice.reducer;

export const selectSlideNumber = (state) => state.media.slideNumber;
export const selectPlaying = (state) => state.media.playing;
export const selectVolume = (state) => state.media.volume;
export const selectLastActiveVolume = (state) => state.media.lastActiveVolume;
export const selectSliderValue = (state) => state.media.sliderValue;
export const selectPlaybackRate = (state) => state.media.playbackRate;
export const selectIsSeeking = (state) => state.media.seeking;
export const selectIsPlayerReady = (state) => state.media.player !== null;
export const selectGetCurrentVideoSeconds = (state) => () =>
  state.media.player?.getCurrentTime();
