import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

/* Each item will be in the form:
  {
    name: "Home",
    to: "/",
    active: true,
  }
*/
const EvoliBreadcrumb = ({ items, ...props }) => {
  return (
    <Styles.Breadcrumb {...props}>
      {/* Map each item with a Breadcrumb.Item as Link */}
      {items.map((item, index) => (
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: item.to }}
          active={item.active === true}
          key={index}
        >
          {item.name}
        </Breadcrumb.Item>
      ))}
    </Styles.Breadcrumb>
  );
};

export default EvoliBreadcrumb;

const Styles = {};

Styles.Breadcrumb = styled(Breadcrumb)`
  //Remove the link style
  a {
    text-decoration: none !important;
    color: ${(props) => props.theme.textPrimary} !important;
    font-weight: bold;
  }

  //Change the color of the active item
  .active {
    color: ${(props) => props.theme.textSecondary} !important;
  }
`;
