import React from "react";
import { useGetTeachingDetailsQuery } from "../../../store/teachings/teachings.api";
import useLegacyEffect from "../../../hooks/useLegacyEffect";
import { useNavigate, useParams } from "react-router-dom";
import { te } from "../../../utils/errorsTranslation";

const useFetch = () => {
  /* Navigation hook */
  const navigate = useNavigate();
  /* Params hook */
  const params = useParams();

  const [skip, setSkip] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);

  /* Query hooks */
  const { data, isLoading, isError, error, isSuccess } =
    useGetTeachingDetailsQuery(params.courseUUID, {
      skip,
    });

  useLegacyEffect(() => {
    const courseUUID = params.courseUUID;
    if (!courseUUID) navigate("/teacher/home/courses");
    else setSkip(false);
  }, []);

  //Check if there is an error
  React.useEffect(() => {
    if (isError) {
      if (!error.data?.code && !error.code)
        setErrorMessage(te("general_error"));
      else setErrorMessage(te(error.data?.code ?? error.code));
    } else if (isSuccess) {
      setErrorMessage(null);
    }
  }, [data, isError, error, isSuccess]);

  return {
    data,
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  };
};

export default useFetch;
