import React, { useContext } from "react";
import { Scatter } from "react-chartjs-2";
import { toHHMMSS } from "../../../../../utils/lecture.utils";
import { ThemeContext } from "../../../../../styles/ThemeContext";
import styled from "styled-components";

const QuestionScatterPlot = ({ start, end, questions }) => {
  /* Load theme from Theme Context */
  const { theme } = useContext(ThemeContext);
  const duration = end - start;

  const data = {
    datasets: [
      {
        label: "Pauses",
        data: questions.map((q) => ({ x: q.second - start, y: 0 })),
        backgroundColor: `rgba(${theme.accentRGB},0.4)`,
        borderColor: `rgba(${theme.accentRGB},1)`,
        pointRadius: 8,
        pointHoverRadius: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "linear",
        beginAtZero: true,
        min: 0,
        max: duration,
        ticks: {
          beginAtZero: true,
          color: theme.primary,
          callback: (value) => toHHMMSS(value),
        },
        grid: {
          display: true,
          borderDash: [1, 1],
          color: theme.primary,
        },
        title: {
          display: true,
          text: "Video seconds",
          color: theme.primary,
        },
      },
      //No y axis
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
        /* Edit the title value to x the HH:MM:SS of the x */
        callbacks: {
          label: (tooltipItem) => toHHMMSS(tooltipItem.parsed.x),
        },
      },
      zoom: {
        limits: {
          x: { min: 0, max: duration, minRange: 1 },
        },
        pan: {
          enabled: true, // SET PINCH ZOOM TO TRUE
          mode: "x",
          scaleMode: "x",
        },
        zoom: {
          wheel: {
            enabled: true, // SET SCROOL ZOOM TO TRUE
          },
          pinch: {
            enabled: true, // SET PINCH ZOOM TO TRUE
          },
          mode: "x",
          speed: 10,
        },
      },
    },
  };

  return (
    <ChartContainer>
      <div className="chart-title">Questions density</div>
      <div className="chart-subtitle">
        The following chart shows the density of questions annotated in the
        video. Each point represents a questions, the x-axis represents the
        video time expressed in seconds.
      </div>
      <div className="chart-container-mono">
        <Scatter data={data} options={options} />
      </div>
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  width: 100%;

  .chart-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .chart-subtitle {
    font-size: 1rem;
  }

  .chart-container {
    width: 100%;
    height: 40vh;
  }

  .chart-container-mono {
    width: 100%;
    height: 70px;
  }
`;

export default QuestionScatterPlot;
