import React, { useState, useEffect } from "react";
import { LogContainer } from "../../styles";
import * as FormStyles from "../../../../styles/Forms.styles";
import { Form } from "react-bootstrap";
import { useGetLogsQuery } from "../../../../store/admin/admin.api";
import hljs from "highlight.js/lib/core";
import logSyntax from "highlight.js/lib/languages/accesslog";
import "highlight.js/styles/dark.css";

const AdminLog = () => {
  const today = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState(today);
  const changeDateHandler = (date) => {
    setDate(date);
  };
  /* Query hooks */
  const {
    isLoading,
    data: logs,
    isSuccess,
  } = useGetLogsQuery({
    date: date ? new Date(date).toISOString() : today,
  });

  useEffect(() => {
    hljs.registerLanguage("__logs", logSyntax);

    hljs.highlightAll();
  }, [logs]);

  return (
    <AdminLogPresentation
      date={date}
      logs={logs}
      changeDateHandler={changeDateHandler}
      isLoading={isLoading}
      isSuccess={isSuccess}
    />
  );
};

const AdminLogPresentation = ({
  date,
  logs,
  isLoading,
  isSuccess,
  changeDateHandler,
}) => {
  return (
    <div className="p-4 flex-1 overflow-hidden d-flex flex-column">
      <div className="d-flex flex-row gap-4 mb-3 align-items-center">
        <div>Filter by date</div>

        <Form.Group>
          <FormStyles.FormLabel>Date</FormStyles.FormLabel>
          <FormStyles.FormControl
            type="date"
            value={date}
            onChange={(e) => changeDateHandler(e.target.value)}
          />
        </Form.Group>
      </div>
      <LogContainer>
        {isLoading && <div>Loading...</div>}
        {isSuccess && logs.length === 0 && (
          <div>No logs found for this date</div>
        )}
        {isSuccess && logs.length > 0 && (
          <div>
            {logs.map((log, idx) => (
              <div key={idx}>
                <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                  <code className="__logs">{log}</code>
                </pre>
              </div>
            ))}
          </div>
        )}
      </LogContainer>
    </div>
  );
};

export default AdminLog;
