import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectCurrentUser } from "../store/auth/auth.slice";

/*This component is used to redirect the user to the student lecture page when
 * he clicks on the quick link. If the user is not logged in, he is redirected
 * to the login page. This component is used in the App.js file. */
const QuickRouter = (props) => {
  //Redirect hook
  const navigate = useNavigate();
  //Param hook
  const params = useParams();

  //Redux state
  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    const lectureUUID = params?.lectureCode;
    //Check if user is logged in
    if (!user) {
      navigate({
        pathname: "/login",
        state: { from: `/student/lecture/${lectureUUID}/0` },
      });
      return;
    } else {
      navigate(`/student/lecture/${lectureUUID}/0`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Render nothing
  return null;
};

export default QuickRouter;
