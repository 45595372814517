import styled from "styled-components";

const Styles = {};

Styles.QuestionPanel = styled.div`
  --question-panel-height: ${(p) => (p.isPinned ? "3rem" : "0")};

  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: var(--question-panel-height);
  padding: 0;
  transition: height 0.3s ease-in-out;

  .question-form {
    display: flex;
    flex-direction: row;
    gap: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .time {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    font-size: 1rem;
    border: 2px solid ${(p) => p.theme.primary};
    color: ${(p) => p.theme.textPrimary};
    background-color: ${(p) => p.theme.primary};
  }

  .pin {
    position: absolute;
    z-index: 100;
    left: 50%;
    top: -2rem;
    height: 2rem;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: ${(p) => p.theme.accent};
    color: ${(p) => p.theme.textPrimary};
    cursor: pointer;
    border-radius: 1rem 1rem 0 0;

    //Hide span when the panel is pinned
    span {
      display: ${(p) => (p.isPinned ? "none" : "block")};

      //Always hide when under 961px
      @media (max-width: 961px) {
        display: none;
      }
    }
  }

  .send {
    padding: 0.5rem 1rem;
    background-color: ${(p) => p.theme.accent};
    color: ${(p) => p.theme.textPrimary};
    border: none;
    border-radius: 0;
  }

  form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0;
  }
`;

export default Styles;
