import React from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

const useMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [active, setActive] = React.useState(0);

  //Memoized links
  const links = React.useMemo(
    () => [
      {
        name: "My enrollments",
        path: `/student/home/enrollments`,
        pattern: `/student/home/enrollments`,
      },
      {
        name: "New enrollment",
        path: `/student/home/new-enrollment`,
        pattern: `/student/home/new-enrollment`,
      },
    ],
    []
  );

  //Handle menu click event (change active menu item and redirect)
  const handleMenuClick = (id) => {
    if (id < links.length) {
      navigate(links[id].path);
    }
  };

  React.useEffect(() => {
    const pathname = location.pathname;
    links.forEach((link, index) => {
      const match = matchPath(
        {
          path: link.pattern,
          end: true,
        },
        pathname
      );
      if (!!match) {
        setActive(index);
      }
    });
  }, [active, links, location.pathname]);

  return {
    active,
    links,
    handleMenuClick,
  };
};

export default useMenu;
