import React from "react";
import styled from "styled-components";

const EvoliMultiButton = ({ active, setActive, texts }) => {
  return (
    <SEvoliMultiButton>
      <div className="tabs">
        {texts.map((text, index) => (
          <React.Fragment key={index}>
            <input
              type="radio"
              id={`radio-${index + 1}`}
              name="tabs"
              checked={active === index}
              onChange={() => setActive(index)}
            />
            <label className="tab" htmlFor={`radio-${index + 1}`}>
              {text}
            </label>
          </React.Fragment>
        ))}
        <span className="glider"></span>
      </div>
    </SEvoliMultiButton>
  );
};

const SEvoliMultiButton = styled.div`
  .tabs {
    display: flex;
    position: relative;
    background-color: rgba(${(props) => props.theme.secondaryRGB}, 0.4);
    padding: 0.5rem;
    border-radius: 99px;
  }

  .tabs * {
    z-index: 2;
  }

  input[type="radio"] {
    display: none;
  }

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.75rem;
    min-width: 6rem;
    font-size: 0.8rem;
    color: black;
    font-weight: 500;
    border-radius: 99px;
    cursor: pointer;
    transition: color 0.15s ease-in;
  }

  input[type="radio"]:checked + label {
    color: #185ee0;
  }

  input[type="radio"]:checked + label > .notification {
    background-color: #185ee0;
    color: #fff;
    margin: 0px;
  }

  input[id="radio-1"]:checked ~ .glider {
    transform: translateX(0);
  }

  input[id="radio-2"]:checked ~ .glider {
    transform: translateX(100%);
  }

  input[id="radio-3"]:checked ~ .glider {
    transform: translateX(200%);
  }

  .glider {
    position: absolute;
    display: flex;
    height: 1.75rem;
    min-width: 6rem;
    background-color: #e6eef9;
    z-index: 1;
    border-radius: 99px;
    transition: 0.25s ease-out;
  }

  @media (max-width: 961px) {
    .tabs {
      transform: scale(0.8);
    }
  }
`;

export default EvoliMultiButton;
