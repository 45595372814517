import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageContainer from "../../components/PageElements/PageContainer";
import Footer from "../../components/PageElements/Footer";
import BackToTop from "../../components/PageElements/BackToTop";
import Navbar from "../../components/PageElements/Navbar";

const MaintenancePage = () => {
  return (
    <>
      <Navbar dark={true} />
      <PageContainer>
        <Container className="py-5">
          <Row>
            <Col>
              <h1>
                We are currently under maintenance. Please come back later.
              </h1>
            </Col>
          </Row>
        </Container>
      </PageContainer>
      <Footer />
      <BackToTop />
    </>
  );
};

export default MaintenancePage;
