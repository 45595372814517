import styled from "styled-components";

const Styles = {};

Styles.ContentSection = styled.div`
  background-color: ${(p) => p.theme.textPrimary};
  color: ${(p) => p.theme.primary};
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

Styles.LessonItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 0.5rem;

  .lesson-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
  }
`;

export default Styles;
