import styled from "styled-components";

const Styles = {};

Styles.VideoWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
  background-color: black;
  overflow: hidden;
  width: 100%;
  /* Keep it the right aspect-ratio */
  aspect-ratio: 16/9;
  /* No clicking/hover effects */
  //pointer-events: none;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-color: black;

    /* Extend it beyond the viewport... */
    width: 300%;
    height: 100%;
    /* ...and bring it back again */
    margin-left: -100%;

    .ypt-title {
      display: none !important;
    }
  }
`;

export default Styles;
