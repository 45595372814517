import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../api/api.slice";

const initialState = {
  user: null,
  token: null,
  role: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    //Set user credentials once logged in
    setCredentials: (state, action) => {
      const { user, accessToken, role } = action.payload;
      state.user = user;
      state.token = accessToken;
      state.role = role;
    },
    //Privacy policy accepted
    acceptPrivacyPolicy: (state, _) => {
      state.user.privacyPolicy = true;
    },
    //Log out user
    logOut: (state, _) => {
      //Resetting the state
      return initialState;
    },
  },
  extraReducers: (builder) => {
    //Log out user on logout
    builder.addMatcher(
      apiSlice.endpoints.logout.matchFulfilled,
      (state, action) => {
        return initialState;
      }
    );
    //Change user data on update profile
    builder.addMatcher(
      apiSlice.endpoints.updateProfile.matchFulfilled,
      (state, action) => {
        const { firstName, lastName } = action.payload;
        state.user.firstName = firstName;
        state.user.lastName = lastName;
      }
    );
  },
});

export const { setCredentials, logOut, acceptPrivacyPolicy } =
  authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentRole = (state) => state.auth.role;
