import { useState } from "react";
import {
  useCreateEnrollmentMutation,
  useLazyGetCourseByCodeQuery,
} from "../../../../../store/enrollments/enrollments.api";
import { toastApiError } from "../../../../../utils/lecture.utils";
import { toastSuccess } from "../../../../../utils/Toast";
import { useNavigate } from "react-router-dom";

const useFetch = () => {
  const navigate = useNavigate();

  const [getCourseByCode, { isLoading1 }] = useLazyGetCourseByCodeQuery();
  const [enroll, { isLoading: isLoading2 }] = useCreateEnrollmentMutation();

  const isLoading = isLoading1 || isLoading2;

  const [courseFound, setCourseFound] = useState(null);
  const [noCourseFound, setNoCourseFound] = useState(false);

  const getCourseByCodeHandler = (code) => {
    if (code && code.trim().length > 0) {
      getCourseByCode(code)
        .unwrap()
        .then((course) => {
          if (course) {
            setCourseFound(course);
            setNoCourseFound(false);
          } else {
            setCourseFound(null);
            setNoCourseFound(true);
          }
        })
        .catch((err) => {
          toastApiError(err);
        });
    }
  };

  /* Handler for enrollment */
  const enrollHandler = (course) => {
    enroll(course.uuid)
      .then((_) => {
        toastSuccess("Successfully enrolled to the course");
        //Redirect to home page
        navigate("/student/home/enrollments");
      })
      .catch((err) => {
        //For example, if the user is already enrolled in the course
        toastApiError(err);
      });
  };

  return {
    isLoading,
    courseFound,
    noCourseFound,
    getCourseByCodeHandler,
    enrollHandler,
  };
};

export default useFetch;
