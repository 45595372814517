import React, { useState } from "react";
import { Container, Form, Row } from "react-bootstrap";
import * as FormStyles from "../../../../styles/Forms.styles";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../components/PageElements/Loading";
import { useCreateCourseMutation } from "../../../../store/teachings/teachings.api";
import { toastSuccess } from "../../../../utils/Toast";
import { toastApiError } from "../../../../utils/lecture.utils";
import { CirclePicker } from "react-color";
import Switch from "react-switch";
import {
  UnderstandingIcon,
  DifficultyIcon,
  InitialKnowledgeIcon,
} from "../../../../components/Commons/FeedbackIcons";
import TextArea from "../../../../components/Form/TextArea";
import Styles from "./styles";

const CreateCourse = () => {
  //Redirect hook
  const navigate = useNavigate();

  //Mutation hooks
  const [createCourse, { isLoading: isCreatingCourse }] =
    useCreateCourseMutation();

  //Create course handler
  const createCourseHandler = ({
    name,
    description,
    color,
    understandingScale,
    difficultyScale,
    difficultyEnabled,
    initialKnowledgeEnabled,
  }) => {
    createCourse({
      name,
      description,
      color,
      understandingScale,
      difficultyScale,
      difficultyEnabled,
      initialKnowledgeEnabled,
    })
      .unwrap()
      .then((course) => {
        toastSuccess(`Course ${course.name} created successfully!`);
        navigate(`/teacher/course/${course.uuid}/edit`);
      })
      .catch((error) => {
        toastApiError(error);
      });
  };

  if (isCreatingCourse) return <Loading />;
  return <CreateCourseForm createCourseHandler={createCourseHandler} />;
};

const CreateCourseForm = ({ createCourseHandler }) => {
  /* Component state */
  const [courseName, setCourseName] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const defaultColors = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
  ];
  const [courseColor, setCourseColor] = useState(defaultColors[0]);
  const [understandingScale, setUnderstandingScale] = useState(5);
  const [difficultyScale, setDifficultyScale] = useState(5);
  const [difficultyEnabled, setDifficultyEnabled] = useState(true);
  const [initialKnowledgeEnabled, setinitialKnowledgeEnabled] = useState(true);

  const isSubmitDisabled =
    courseName.trim().length === 0 ||
    courseDescription.trim().length === 0 ||
    !defaultColors.includes(courseColor);

  const submitHandler = (e) => {
    e.preventDefault();

    // Check if params are valid
    if (
      courseName.length > 0 &&
      courseName.length <= 100 &&
      courseDescription.length > 0 &&
      courseDescription.length <= 500 &&
      defaultColors.includes(courseColor)
    )
      createCourseHandler({
        name: courseName,
        description: courseDescription,
        color: courseColor,
        understandingScale: understandingScale,
        difficultyScale: difficultyScale,
        difficultyEnabled: difficultyEnabled,
        initialKnowledgeEnabled: initialKnowledgeEnabled,
      });
  };

  return (
    <Container>
      <Row className="my-4">
        <Form onSubmit={submitHandler}>
          <h4 className="mb-4">Course information</h4>
          <Form.Group className="mb-4">
            <FormStyles.FormLabel htmlFor="create-folder-name">
              Course name
            </FormStyles.FormLabel>
            <FormStyles.FormControl
              id="create-folder-name"
              required
              type="text"
              maxLength={100}
              placeholder="Insert course name"
              value={courseName}
              onChange={(e) => setCourseName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <FormStyles.FormLabel htmlFor="create-folder-description">
              Description
            </FormStyles.FormLabel>
            <TextArea
              id="create-folder-description"
              required
              type="textfield"
              maxLength={500}
              placeholder="Insert course description"
              value={courseDescription}
              onChange={(e) => setCourseDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-5">
            <p>Choose a color for your course:</p>
            <div className="d-flex flex-row justify-content-start gap-5">
              <CirclePicker
                color={courseColor}
                onChangeComplete={(color) => setCourseColor(color.hex)}
                className="d-flex justify-content-center"
              />
              <div className="d-flex flex-column justify-content-center align-items-center">
                <span className="fw-bolder">Selected:</span>
                <Styles.CircleColor color={courseColor} />
              </div>
            </div>
          </Form.Group>
          {/* Feedback settings */}
          <h4 className="mb-3">Feedback settings</h4>
          <p>
            Customize the feedback requests to be made to students at the end of
            each video lesson, along with the rating scale.
          </p>
          {/* Understanding */}
          <h5 className="ms-3 fs-6">
            <UnderstandingIcon /> Understanding
          </h5>
          <p>
            Question for the students: Please rate your level of understanding.
          </p>
          <Form.Label>Understanding scale:</Form.Label>
          {/* Orizontal radio buttons with 3, 5, 7, 10 options */}
          <Form.Group className="mb-3">
            <Form.Check
              inline
              label="3"
              name="understandingScale"
              type="radio"
              id="understandingScale3"
              checked={understandingScale === 3}
              onChange={() => setUnderstandingScale(3)}
            />
            <Form.Check
              inline
              label="5"
              name="understandingScale"
              type="radio"
              id="understandingScale5"
              checked={understandingScale === 5}
              onChange={() => setUnderstandingScale(5)}
            />
            <Form.Check
              inline
              label="7"
              name="understandingScale"
              type="radio"
              id="understandingScale7"
              checked={understandingScale === 7}
              onChange={() => setUnderstandingScale(7)}
            />
            <Form.Check
              inline
              label="10"
              name="understandingScale"
              type="radio"
              id="understandingScale10"
              checked={understandingScale === 10}
              onChange={() => setUnderstandingScale(10)}
            />
          </Form.Group>
          {/* Difficulty */}
          <h5 className="ms-3 mt-4 fs-6">
            <DifficultyIcon /> Difficulty
          </h5>
          <p>
            Question for the students: Please rate your perceived level of
            difficulty of the video-lesson
          </p>
          {/* Enable or disable difficulty with a switch */}
          <Form.Group className="mb-3 d-flex flex-row align-items-center gap-3">
            <Form.Label>Enable difficulty feedback: </Form.Label>
            <Switch
              onChange={() => setDifficultyEnabled((enabled) => !enabled)}
              checked={difficultyEnabled}
            />
          </Form.Group>
          {/* If difficulty is enabled, show difficulty scale */}
          {difficultyEnabled && (
            <>
              <Form.Label>Difficulty scale:</Form.Label>
              <Form.Group className="mb-3">
                <Form.Check
                  inline
                  label="3"
                  name="difficultyScale"
                  type="radio"
                  id="difficultyScale3"
                  checked={difficultyScale === 3}
                  onChange={() => setDifficultyScale(3)}
                />
                <Form.Check
                  inline
                  label="5"
                  name="difficultyScale"
                  type="radio"
                  id="difficultyScale5"
                  checked={difficultyScale === 5}
                  onChange={() => setDifficultyScale(5)}
                />
                <Form.Check
                  inline
                  label="7"
                  name="difficultyScale"
                  type="radio"
                  id="difficultyScale7"
                  checked={difficultyScale === 7}
                  onChange={() => setDifficultyScale(7)}
                />
                <Form.Check
                  inline
                  label="10"
                  name="difficultyScale"
                  type="radio"
                  id="difficultyScale10"
                  checked={difficultyScale === 10}
                  onChange={() => setDifficultyScale(10)}
                />
              </Form.Group>
            </>
          )}
          {/* Initial knowledge */}
          <h5 className="ms-3 mt-4 fs-6">
            <InitialKnowledgeIcon /> Initial knowledge
          </h5>
          <p>
            Question for the students: Please rate your prior knowledge of the
            video-lesson’s content
          </p>
          {/* Enable or disable initial knowledge with a switch */}
          <Form.Group className="mb-3 d-flex flex-row align-items-center gap-3">
            <Form.Label>Enable initial knowledge feedback: </Form.Label>
            <Switch
              onChange={() => setinitialKnowledgeEnabled((enabled) => !enabled)}
              checked={initialKnowledgeEnabled}
            />
          </Form.Group>
          {/* Submit button */}
          <div className="d-flex flex-row justify-content-center mt-4">
            <FormStyles.EvoliButton
              type="submit"
              size="lg"
              disabled={isSubmitDisabled}
            >
              Create course
            </FormStyles.EvoliButton>
          </div>
        </Form>
      </Row>
    </Container>
  );
};

export default CreateCourse;
