import React from "react";
import Loading from "../../../../components/PageElements/Loading";
import { useTranslation } from "react-i18next";
import DailyConnectionsHistogram from "./components/DailyConnectionsHistogram";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import VideoViewsLine from "./components/VideoViewsLine";
import Styles from "./styles";
import useFetch from "./hooks/useFetch";
import { useOutletContext } from "react-router-dom";
import PausesScatterPlot from "./components/PausesScatterPlot";

const VisualizationsAnalysis = () => {
  //Get lesson from context
  const { lesson } = useOutletContext();

  //Get exactly the day of one week ago
  const today = new Date();
  const oneWeekAgo = new Date(today.setDate(today.getDate() - 7));

  const [from, setFrom] = React.useState(oneWeekAgo);
  const [to, setTo] = React.useState(new Date());

  const {
    data: { visualizationsList, intervalsList, videoPauses },
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  } = useFetch({ lesson, from, to });

  return (
    <Styles.DashboardContainer fluid>
      {/* If loading data, show a loading spinner inside the container */}
      {isLoading ? (
        <Loading page={false} />
      ) : isError ? (
        <div className="text-danger fw-bold">{errorMessage}</div>
      ) : (
        isSuccess && (
          <ChartList
            lesson={lesson}
            visualizations={visualizationsList}
            intervalsList={intervalsList}
            pauses={videoPauses}
            from={from}
            to={to}
            setFrom={setFrom}
            setTo={setTo}
          />
        )
      )}
    </Styles.DashboardContainer>
  );
};

const ChartList = ({
  lesson,
  visualizations,
  intervalsList,
  pauses,
  from,
  to,
  setFrom,
  setTo,
}) => {
  //Translation hook
  const { t } = useTranslation("", {
    keyPrefix: "teacher-dashboard.visualizations_dashboard",
  });

  //Retrieve current date
  const currentDate = new Date();
  //Retrieve lecture creation date
  const lectureCreationDate = new Date(lesson.createdAt);

  return (
    <>
      <div className="d-flex flex-row justify-content-between gap-3 mt-3">
        <div className="d-flex align-items-center">
          <label htmlFor="start-date" className="me-2">
            Start Date:
          </label>
          <DatePicker
            type="date"
            id="start-date"
            value={from}
            onChange={(date) => setFrom(date)}
          />
        </div>
        <div className="d-flex align-items-center">
          <label htmlFor="end-date" className="me-2">
            End Date:
          </label>
          <DatePicker
            type="date"
            id="end-date"
            value={to}
            onChange={(e) => setTo(new Date(e.target.value))}
            style={{ width: "150px" }}
          />
        </div>
      </div>
      <Styles.ChartContainer>
        <div className="chart-title">{t("chart3_title")}</div>
        <div className="chart-subtitle">{t("chart3_description")}</div>
        <div className="chart-container">
          <DailyConnectionsHistogram
            lectureCreationDate={lectureCreationDate}
            visualizations={visualizations}
            currentDate={currentDate}
            from={from}
            to={to}
          />
        </div>
      </Styles.ChartContainer>
      {lesson.type === "video" && (
        <>
          <Styles.ChartContainer>
            <div className="chart-title">{t("chart1_title")}</div>
            <div className="chart-subtitle">{t("chart1_description")}</div>
            <div className="chart-container">
              <VideoViewsLine
                start={lesson.startSecond}
                end={lesson.endSecond}
                intervals={intervalsList}
              />
            </div>
          </Styles.ChartContainer>
          {/* Scatter plot with pauses density */}
          <Styles.ChartContainer>
            <div className="chart-title">Density of pauses</div>
            <div className="chart-subtitle">
              The following chart shows the density of pauses in the lecture.
              Each point represents a pause, the x-axis represents the video
              time expressed in seconds.
            </div>
            <div className="chart-container-mono">
              <PausesScatterPlot
                start={lesson.startSecond}
                end={lesson.endSecond}
                pauses={pauses}
              />
            </div>
          </Styles.ChartContainer>
        </>
      )}
    </>
  );
};

export default VisualizationsAnalysis;
