import { useEffect, useState } from "react";
import LessonsUnderstandingHistogram from "./components/LessonsUnderstandingHistogram";
import { useOutletContext } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import DoughnutChart from "../../../../components/Charts/DoughnutChart";
import LessonsDifficultyHistogram from "./components/LessonsDifficultyHistogram";
import LessonsInitialKnowledgeHistogram from "./components/LessonsInitialKnowledgeHistogram";
import {
  DifficultyIcon,
  InitialKnowledgeIcon,
  UnderstandingIcon,
} from "../../../../components/Commons/FeedbackIcons";
import InfoTooltip from "../../../../components/Commons/InfoTooltip";
import LessonFeedback from "../../../../components/LessonFeedback/LessonFeedback";
import useOrder from "./hooks/useOrder";
import { EvoliButton } from "../../../../styles/Forms.styles";

const UnitFeedbackAnalysis = () => {
  const { unit } = useOutletContext();

  const understandingScale = unit.course.understandingScale;
  const difficultyScale = unit.course.difficultyScale;
  const isDifficultyEnabled = unit.course.difficultyEnabled;
  const isInitialKnowledgeEnabled = unit.course.initialKnowledgeEnabled;
  const [understandingValuesList, setUnderstandingValuesList] = useState([]);
  const [difficultyValuesList, setDifficultyValuesList] = useState([]);
  const [initialKnowledgeValuesList, setInitialKnowledgeValuesList] = useState(
    []
  );

  const [limit, setLimit] = useState(100);
  const [selectedLessonUUID, setSelectedLessonUUID] = useState(
    unit?.lessons[0]?.uuid
  );

  const { changeOrderHandler, orderBy, order, orderValue, options, reset } =
    useOrder();

  useEffect(() => {
    const understandingValuesListTmp = [];
    const difficultyValuesListTmp = [];
    const initialKnowledgeValuesListTmp = [];
    //Initialize feedback values
    unit.lessons.forEach((lesson) => {
      lesson.feedback.forEach((feedback) => {
        understandingValuesListTmp.push(feedback.understanding);
        if (isDifficultyEnabled)
          difficultyValuesListTmp.push(feedback.difficulty);
        if (isInitialKnowledgeEnabled)
          initialKnowledgeValuesListTmp.push(feedback.initialKnowledge);
      });
    });
    setUnderstandingValuesList(understandingValuesListTmp);
    setDifficultyValuesList(difficultyValuesListTmp);
    setInitialKnowledgeValuesList(initialKnowledgeValuesListTmp);
  }, [isDifficultyEnabled, isInitialKnowledgeEnabled, unit.lessons]);

  if (unit)
    return (
      <Container className="py-5">
        <Row>
          <Col>
            <h2>Unit feedback analysis</h2>
            <p className="mb-4">
              Analyze the feedback from the students to understand their
              perception of the lessons.
            </p>
          </Col>
        </Row>
        <Row className="mb-5">
          {/* Understanding */}
          <Col xs={12} sm={4}>
            <div className="d-flex flex-column align-items-center justify-content-center gap-3">
              {/* Title */}
              <div className="d-flex flex-row align-items-center justify-content-center gap-1">
                <UnderstandingIcon className="me-1 fs-5" />
                <h2 className="fs-5">Students’ level of understanding</h2>
                <InfoTooltip
                  className="ms-1"
                  text={`The chart displays the percentages of students who have rated their understanding of the video lesson from 1 ("Completely unclear") to ${understandingScale} ("Completely clear").`}
                />
              </div>
              {/* Chart */}
              <div
                style={{ height: "23vh" }}
                className="d-flex flex-row justify-content-center align-items-center"
              >
                <DoughnutChart
                  feedbackList={understandingValuesList}
                  numOfLevels={understandingScale}
                />
              </div>
              {/* Description */}
              <span>
                Scale from 1
                <strong className="text-danger"> ("Completely unclear")</strong>
                <br />
                to {understandingScale}{" "}
                <strong className="text-success">("Completely clear")</strong>
              </span>
            </div>
          </Col>
          {/* Initial Knowledge */}
          {isInitialKnowledgeEnabled && (
            <Col xs={12} sm={4}>
              <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                {/* Title */}
                <div className="d-flex flex-row align-items-center justify-content-center gap-1">
                  <InitialKnowledgeIcon className="me-1 fs-5" />
                  <h2 className="fs-5">Students’ level of initial knowledge</h2>
                  <InfoTooltip
                    className="ms-1"
                    text={`The chart displays the percentages of students who have rated their initial knowledge of the video lesson from 1 ("Nothing") to ${understandingScale} ("Everything").`}
                  />
                </div>
                {/* Chart */}
                <div
                  style={{ height: "23vh" }}
                  className="d-flex flex-row justify-content-center align-items-center"
                >
                  <DoughnutChart
                    feedbackList={initialKnowledgeValuesList}
                    numOfLevels={understandingScale}
                  />
                </div>
                {/* Description */}
                <span>
                  Scale from 1
                  <strong className="text-danger"> ("Nothing")</strong>
                  <br />
                  to {understandingScale}{" "}
                  <strong className="text-success">("Everything")</strong>
                </span>
              </div>
            </Col>
          )}
          {/* Difficulty */}
          {isDifficultyEnabled && (
            <Col xs={12} sm={4}>
              <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                {/* Title */}
                <div className="d-flex flex-row align-items-center justify-content-center gap-1">
                  <DifficultyIcon className="me-1 fs-5" />
                  <h2 className="fs-5">Students’ perceived difficulty</h2>
                  <InfoTooltip
                    className="ms-1"
                    text={`The chart displays the percentages of students who have rated the difficulty of the video lesson from 1 ("Very Easy") to ${difficultyScale} ("Very Difficult").`}
                  />
                </div>
                {/* Chart */}
                <div
                  style={{ height: "23vh" }}
                  className="d-flex flex-row justify-content-center align-items-center"
                >
                  <DoughnutChart
                    feedbackList={difficultyValuesList}
                    numOfLevels={difficultyScale}
                    scaleInverted={true}
                  />
                </div>
                {/* Description */}
                <span>
                  Scale from 1
                  <strong className="text-success"> ("Very Easy")</strong>
                  <br />
                  to {difficultyScale}{" "}
                  <strong className="text-danger">("Very Difficult")</strong>
                </span>
              </div>
            </Col>
          )}
        </Row>
        {/* Understanding */}
        <Row className="mb-3">
          <h4 className="fs-4">
            <UnderstandingIcon className="me-1" />
            Student’s understanding across lessons
          </h4>
          <p>
            Discover which lessons were understood the most/least by the
            students.
          </p>
          <div
            className="d-flex flex-row justify-content-center align-items-center "
            style={{ height: "50vh" }}
          >
            <LessonsUnderstandingHistogram
              lessons={unit.lessons}
              numOfLevels={understandingScale}
            />
          </div>
        </Row>
        {/* Prior knowledge */}
        {isInitialKnowledgeEnabled && (
          <Row className="mb-3">
            <h4 className="fs-4">
              <InitialKnowledgeIcon className="me-1" />
              Student’s prior knowledge across lessons
            </h4>
            <p>
              Discover which lessons covered content that was already familiar
              to the students.
            </p>
            <div
              className="d-flex flex-row justify-content-center align-items-center "
              style={{ height: "50vh" }}
            >
              <LessonsInitialKnowledgeHistogram
                lessons={unit.lessons}
                numOfLevels={difficultyScale}
              />
            </div>
          </Row>
        )}
        {/* Difficulty */}
        {isDifficultyEnabled && (
          <Row className="mb-3">
            <h4 className="fs-4">
              <DifficultyIcon className="me-1" />
              Student’s perceived difficulty across lessons
            </h4>
            <p>
              Discover which lessons were perceived as the most/least difficult.
            </p>
            <div
              className="d-flex flex-row justify-content-center align-items-center "
              style={{ height: "50vh" }}
            >
              <LessonsDifficultyHistogram
                lessons={unit.lessons}
                numOfLevels={difficultyScale}
              />
            </div>
          </Row>
        )}
        <Row>
          <h4 className="fs-4">Comments from students</h4>
          <div className="d-flex flex-row align-items-center gap-2 mb-2">
            <span>Lesson:</span>
            <select
              className="form-select"
              aria-label="Select lesson"
              value={selectedLessonUUID}
              onChange={(e) => setSelectedLessonUUID(e.target.value)}
            >
              {unit.lessons.map((lesson) => (
                <option key={lesson.uuid} value={lesson.uuid}>
                  {lesson.name}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex flex-column flex-md-row gap-2 gap-md-3 w-100 mb-4">
            {/* Limit */}
            <div className="d-flex flex-column gap-1">
              <label>Limit</label>
              <select
                className="form-select"
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            {/* Order by course name, teacher name, completion rate, understanding rate */}
            <div className="d-flex flex-column gap-1">
              <label>Order by</label>
              <select
                className="form-select"
                value={orderValue}
                onChange={changeOrderHandler}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            {/* Reset filter and order */}
            <div className="d-flex flex-column justify-content-end">
              <EvoliButton onClick={reset}>Reset</EvoliButton>
            </div>
          </div>
          <LessonFeedback
            difficultyEnabled={isDifficultyEnabled}
            difficultyScale={difficultyScale}
            initialKnowledgeEnabled={isInitialKnowledgeEnabled}
            understandingScale={understandingScale}
            ù
            lessonUUID={selectedLessonUUID}
            limit={limit}
            order={order}
            orderBy={orderBy}
          />
        </Row>
      </Container>
    );
};

export default UnitFeedbackAnalysis;
