import React, { useContext } from "react";
import "chartjs-adapter-moment";
import { Bar } from "react-chartjs-2";
import moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ThemeContext } from "../../../../../styles/ThemeContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DailyConnectionsHistogram = ({ visualizations, from, to }) => {
  /* Load theme from Theme Context */
  const { theme } = useContext(ThemeContext);

  const startDate = moment(from, "DD-MM-YYYY");
  const endDate = moment(to, "DD-MM-YYYY");

  //Push of all day in string between startDate and endDate
  const getDaysBetweenDates = () => {
    const days = [];
    let day = startDate;
    while (day <= endDate) {
      days.push(day.toDate());
      day = day.clone().add(1, "d");
    }
    return days;
  };

  //Get the number of views per day (filteredconnections, startDate, endDate)
  const getData = () => {
    const connectionsData = [],
      usersData = [];
    const daysBetweenDates = getDaysBetweenDates(startDate, endDate);

    for (const day of daysBetweenDates) {
      const dailyConnections = visualizations.filter((v) => {
        return moment(v.startedAt).isSame(day, "day");
      });
      //Count distinct users per day
      const seen = new Set();
      for (const {
        user: { email },
      } of dailyConnections) {
        seen.add(email);
      }

      connectionsData.push(dailyConnections.length);
      usersData.push(seen.size);
    }
    return [connectionsData, usersData];
  };

  const dataValues = getData();

  const data = {
    labels: getDaysBetweenDates(from, to).map((d) =>
      moment(d).format("DD/MM/YY")
    ),
    datasets: [
      {
        data: dataValues[0],
        backgroundColor: `rgba(${theme.accentRGB},0.5)`,
        borderColor: `rgba(${theme.accentRGB},1)`,
        borderWidth: 1,
        barThickness: "flex",
        label: "Daily Connections",
      },
      {
        data: dataValues[1],
        backgroundColor: `rgba(${theme.secondaryRGB},0.5)`,
        borderColor: `rgba(${theme.secondaryRGB},1)`,
        borderWidth: 1,
        barThickness: "flex",
        label: "Daily Users",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: theme.secondary,
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: theme.secondary,
          stepSize: 1,
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: false,
      },
      zoom: {
        pan: {
          enabled: true, // SET PINCH ZOOM TO TRUE
          mode: "x",
          scaleMode: "x",
        },
        zoom: {
          wheel: {
            enabled: true, // SET SCROOL ZOOM TO TRUE
          },
          pinch: {
            enabled: true, // SET PINCH ZOOM TO TRUE
          },
          mode: "x",
          speed: 10,
        },
      },
    },
  };

  return <Bar className="h-100" data={data} options={options} />;
};

export default DailyConnectionsHistogram;
