import { Container } from "react-bootstrap";
import styled from "styled-components";

const Styles = {};

Styles.DashboardContainer = styled(Container)`
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  color: ${(p) => p.theme.primary};
`;

Styles.ChartContainer = styled.div`
  width: 100%;
  padding: 1rem;

  .chart-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .chart-subtitle {
    font-size: 1rem;
  }

  .chart-container {
    width: 100%;
    height: 40vh;
  }

  .chart-container-mono {
    width: 100%;
    height: 70px;
  }
`;

export default Styles;
