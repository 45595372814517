import { useEffect, useState } from "react";
import { useGetStudentVideoAnalyticsQuery } from "../../../../../../store/analytics/analytics.api";
import { te } from "../../../../../../utils/errorsTranslation";

const useFetch = ({ lessonUUID, studentEmail }) => {
  const { data, isLoading, isError, error, isSuccess } =
    useGetStudentVideoAnalyticsQuery({
      lessonUUID,
      studentEmail,
    });

  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (isError) {
      if (!error.data?.code && !error.code)
        setErrorMessage(te("general_error"));
      else setErrorMessage(te(error.data?.code ?? error.code));
    } else if (isSuccess) {
      setErrorMessage(null);
    }
  }, [error, isError, isSuccess]);
  return {
    data,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
  };
};

export default useFetch;
