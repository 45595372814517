import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Tooltip } from "react-tooltip";
import { ThemeProvider } from "styled-components";
// pages
import AccountConfirmedPage from "./pages/account/AccountConfirmedPage";
import ForgotPasswordPage from "./pages/account/ForgotPasswordPage";
import LoginPage from "./pages/auth/login/LoginPage";
import ProfilePage from "./pages/profile/ProfilePage";
import RegisterPage from "./pages/auth/register/RegisterPage";
import ResetPasswordPage from "./pages/account/ResetPasswordPage";
import IndexPage from "./pages/IndexPage";
import NotFoundPage from "./pages/errors/NotFoundPage";
// routers
import QuickRouter from "./routes/QuickRouter";
import PrivateRoutes from "./routes/PrivateRoutes";
//Style
import GlobalStyle from "./styles/GlobalReset.style";
import { useContext } from "react";
import { ThemeContext } from "./styles/ThemeContext";
import { useEffect } from "react";
import i18next from "i18next";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import StudentHome from "./pages/home-student/StudentHome";
import CreateCourse from "./pages/home-teacher/outlets/create-course/CreateCourse";
import TeacherCourses from "./pages/home-teacher/outlets/teacher-courses/TeacherCourses";
import StudentEnrollments from "./pages/home-student/outlets/student-enrollments/StudentEnrollments";
import TeacherHome from "./pages/home-teacher/TeacherHome";
import EnrollmentPage from "./pages/enrollment/EnrollmentPage";
import Teaching from "./pages/teaching/Teaching";
import Content from "./pages/teaching/outlets/content/Content";
import Edit from "./pages/teaching/outlets/edit/Edit";
import MyProfile from "./pages/profile/outlets/profile-info/MyProfile";
import EditProfile from "./pages/profile/outlets/edit-profile/EditProfile";
import StudentLessonPage from "./pages/lesson-student/StudentLessonPage";
import UnitDashboard from "./pages/unit-dashboard/UnitDashboard";
import UnitFeedbackAnalysis from "./pages/unit-dashboard/outlets/unit-feedback/UnitFeedbackAnalysis";
import UnitQuestions from "./pages/unit-dashboard/outlets/unit-questions/UnitQuestions";
import UnitVisualizations from "./pages/unit-dashboard/outlets/unit-visualizations/UnitVisualizations";
import UnitStudents from "./pages/unit-dashboard/outlets/unit-students/UnitStudents";
import TeacherLesson from "./pages/lesson-teacher/TeacherLesson";
import ClassroomFeedback from "./pages/lesson-teacher/outlets/classroom-feedback/ClassroomFeedback";
import StudentsList from "./pages/lesson-teacher/outlets/students-list/StudentsList";
import VisualizationsAnalysis from "./pages/lesson-teacher/outlets/visualizations-analysis/VisualizationsAnalysis";
import Video from "./pages/lesson-teacher/outlets/video/Video";
import LessonQuestionsList from "./pages/lesson-teacher/outlets/questions/LessonQuestionsList";
import NewEnrollment from "./pages/home-student/outlets/new-enrollment/NewEnrollment";
import Students from "./pages/teaching/outlets/students/Students";
import RedirectToHomeIfLogged from "./routes/RedirectToHomeIfLogged";
import AdminPage from "./pages/admin/AdminPage";
import AdminLog from "./pages/admin/outlets/logs/AdminLog";
import Insights from "./pages/admin/outlets/insights/Insights";
import AdminMessages from "./pages/admin/outlets/messages/AdminMessages";
import AdminMessageDetails from "./pages/admin/outlets/messages/AdminMessageDetails";
import MasterTables from "./pages/admin/outlets/master_tables/MasterTables";
import Security from "./pages/profile/outlets/security/Security";
import AdminBI from "./pages/admin/outlets/bi/AdminBI";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import AcceptPrivacyPolicy from "./pages/account/accept-privacy-policy/AcceptPrivacyPolicy";
import MaintenancePage from "./pages/maintenance/MaintenancePage";
import PrivacyPolicyEng from "./pages/privacy-policy/outlets/PrivacyPolicyEng";
import PrivacyPolicyIta from "./pages/privacy-policy/outlets/PrivacyPolicyIta";
import ActiveUsers from "./pages/admin/outlets/active-users/ActiveUsers";
import StudentCourseContent from "./pages/enrollment/components/course-content/StudentCourseContent";
import TeachingQuestions from "./pages/teaching/outlets/questions/TeachingQuestions";
import StudentCourseQuestions from "./pages/enrollment/components/questions/StudentCourseQuestions";

// Disable React DevTools in production
if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

function App() {
  const { theme, loadTheme } = useContext(ThemeContext);

  useEffect(() => {
    /* Load the theme */
    loadTheme();
    /* Load the language from the local storage */
    const language = window.localStorage.getItem("language");
    if (language) i18next.changeLanguage(language);
    else {
      i18next.changeLanguage("en");
      window.localStorage.setItem("language", "en");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toastOptions = {
    duration: 5000,
  };

  const unauthenticatedRoutes = {
    "/": IndexPage,
    "/confirm/:confirmationCode": AccountConfirmedPage,
    "/reset-password/:resetPasswordToken": ResetPasswordPage,
    "/quick/:lectureCode": QuickRouter,
    "/maintenance": MaintenancePage,
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Routes>
          {/* Unauthenticated Routes */}
          {Object.entries(unauthenticatedRoutes).map(([path, component]) => (
            <Route key={path} path={path} Component={component} />
          ))}
          {/* Student tree */}
          <Route path="student" element={<PrivateRoutes />}>
            <Route path="home" element={<StudentHome />}>
              <Route path="enrollments" element={<StudentEnrollments />} />
              <Route path="new-enrollment" element={<NewEnrollment />} />
            </Route>
            <Route path="course/:courseUUID" element={<EnrollmentPage />}>
              <Route path="" exact element={<StudentCourseContent />} />
              <Route path="questions" element={<StudentCourseQuestions />} />
            </Route>
            <Route path="lesson/:lessonUUID" element={<StudentLessonPage />} />
          </Route>

          {/* Teacher tree */}
          <Route path="teacher" element={<PrivateRoutes />}>
            <Route path="home" element={<TeacherHome />}>
              <Route path="courses" element={<TeacherCourses />} />
              <Route path="new-course" element={<CreateCourse />} />
            </Route>
            <Route path="course/:courseUUID" element={<Teaching />}>
              <Route path="content" element={<Content />} />
              <Route path="questions" element={<TeachingQuestions />} />
              <Route path="students" element={<Students />} />
              <Route path="edit" element={<Edit />} />
            </Route>
            <Route path="dashboard/:unitUUID" element={<UnitDashboard />}>
              <Route path="" exact element={<UnitFeedbackAnalysis />} />
              <Route path="questions" element={<UnitQuestions />} />
              <Route path="visualizations" element={<UnitVisualizations />} />
              <Route path="students" element={<UnitStudents />} />
            </Route>
            <Route path="lesson/:lessonUUID" element={<TeacherLesson />}>
              <Route path="" element={<ClassroomFeedback />} />
              <Route path="questions" element={<LessonQuestionsList />} />
              <Route
                path="visualizations"
                element={<VisualizationsAnalysis />}
              />
              <Route path="students" element={<StudentsList />} />
              <Route path="video/:seekTo?" element={<Video />} />
            </Route>
          </Route>

          {/* Profile tree */}
          <Route path="account" element={<PrivateRoutes />}>
            <Route path="" element={<ProfilePage />}>
              <Route path="" exact element={<MyProfile />} />
              <Route path="edit" element={<EditProfile />} />
              <Route path="security" element={<Security />} />
            </Route>
            <Route
              path="accept-privacy-policy"
              element={<AcceptPrivacyPolicy />}
            />
          </Route>

          {/* Redirect to home if logged */}
          <Route path="/" element={<RedirectToHomeIfLogged />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="forgot-password" element={<ForgotPasswordPage />} />
          </Route>

          <Route path="admin" element={<PrivateRoutes />}>
            {/* <Route path="models/lectures/:id" element={<AdminLUDetails />} /> */}
            <Route path="" element={<AdminPage />}>
              <Route path="" exact element={<Insights />} />
              <Route path="active-users" element={<ActiveUsers />} />
              <Route path="logs" element={<AdminLog />} />
              <Route path="messages" element={<AdminMessages />} />
              <Route path="messages/:id" element={<AdminMessageDetails />} />
              <Route path="bi" element={<AdminBI />} />
              {/*      
              <Route path="announces" element={<AdminAnnounces />} />
              <Route
                path="models/folders/:id"
                element={<AdminFolderDetails />}
              /> */}
              <Route path="models" element={<MasterTables />} />
            </Route>
          </Route>

          <Route path="privacy" element={<PrivacyPolicy />}>
            <Route path="" exact element={<PrivacyPolicyEng />} />
            <Route path="ita" element={<PrivacyPolicyIta />} />
          </Route>

          {/* Fallback when page is not found  */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        {/* Toasts */}
        <Toaster position="top-right" toastOptions={toastOptions} />
        {/* Tooltips */}
        <Tooltip id="evoli-tooltip" />
      </ThemeProvider>
    </Router>
  );
}

export default App;
