import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Loading from "../../components/PageElements/Loading";
import AppNavbar from "../../components/PageElements/Navbar";
import Footer from "../../components/PageElements/Footer";
import PageContainer from "../../components/PageElements/PageContainer";
import { Container } from "react-bootstrap";
import Styles from "./styles";
import EvoliBreadcrumb from "../../components/PageElements/EvoliBreadcrumb";
import { EvoliButton } from "../../styles/Forms.styles";
import EvoliBackgroundSpan from "../../components/Commons/EvoliBackgroundSpan";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import useMenu from "./hooks/useMenu";
import useFetch from "./hooks/useFetch";
import BackToTop from "../../components/PageElements/BackToTop";
import { formatDate } from "../../utils/time.utils";
import InfoTooltip from "../../components/Commons/InfoTooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toastSuccess } from "../../utils/Toast";

const Teaching = () => {
  const { active, handleMenuClick, links } = useMenu();
  const {
    data: course,
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  } = useFetch();

  const [showEnrollmentCode, setShowEnrollmentCode] = useState(false);

  const breadcrumbItems = [
    {
      name: "My Teachings",
      to: "/teacher/home/courses",
      active: false,
    },
    {
      name: course?.name,
      to: `/teacher/course/${course?.uuid}/content`,
      active: true,
    },
  ];

  const copyEnrollmentCodeHandler = () => {
    toastSuccess("Enrollment code copied to clipboard");
  };

  if (isLoading) return <Loading />;
  if (isError)
    return (
      <div className="text-bold text-danger text-center">{errorMessage}</div>
    );
  if (isSuccess)
    return (
      <>
        <AppNavbar dark sticky="true" />
        <PageContainer>
          {/* Header section: display the main course information */}
          <Styles.HeaderSection>
            <Container>
              {/* Breadcrumb */}
              <EvoliBreadcrumb items={breadcrumbItems} />
              {/* Title and brief description */}
              <h1 className="course-title">{course.name}</h1>
              {course.description && (
                <span className="course-brief-description">
                  {course.description}
                </span>
              )}
              {/* Enrollment count */}
              <div className="d-flex flex-row justify-content-start align-items-center gap-2 mt-3 fs-5">
                <i className="fas fa-users" />
                <span>{course.enrollments.length} students enrolled</span>
                <InfoTooltip text="Copy the code and share it with your students for them to access the course" />
                {/* Show or hide enrollment code */}
                {showEnrollmentCode ? (
                  <EvoliBackgroundSpan className="d-flex flex-row align-items-center gap-2">
                    {course.enrollmentCode}
                    {/* Hide btn */}
                    <EvoliButton onClick={() => setShowEnrollmentCode(false)}>
                      <FontAwesomeIcon icon={faEyeSlash} />
                    </EvoliButton>
                  </EvoliBackgroundSpan>
                ) : (
                  <EvoliButton onClick={() => setShowEnrollmentCode(true)}>
                    Show enrollment code
                  </EvoliButton>
                )}
                {/* Copy btn */}
                <CopyToClipboard
                  text={course.enrollmentCode}
                  onCopy={copyEnrollmentCodeHandler}
                >
                  <EvoliButton
                    data-tooltip-content="Copy enrollment code to clipboard"
                    data-tooltip-id="evoli-tooltip"
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </EvoliButton>
                </CopyToClipboard>
              </div>
              {/* Created by [instructor name] on [creation date] */}
              <div className="d-flex flex-row justify-content-start align-items-center gap-2 mt-2 fs-5">
                <i className="fas fa-user" />
                <div>
                  Created by{" "}
                  <span className="fw-bold">
                    {course.teacher.firstName} {course.teacher.lastName}
                  </span>{" "}
                  on {formatDate(new Date(course.createdAt))}
                </div>
              </div>
              {/* Menu */}
              <div className="menu">
                {/* For each link in links */}
                {links.map((link, index) => (
                  <div
                    key={index}
                    className={`menu-item ${active === index && "active"}`}
                    onClick={() => handleMenuClick(index)}
                  >
                    {link.name}
                  </div>
                ))}
              </div>
            </Container>
          </Styles.HeaderSection>
          {/* Display nested routers */}
          <Outlet context={{ course }} />
        </PageContainer>
        <Footer />
        <BackToTop />
      </>
    );
};

export default Teaching;
