import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  pause,
  play,
  seekTo,
  selectGetCurrentVideoSeconds,
  selectPlaying,
  selectVolume,
  setSliderValue,
  setVolume,
} from "../../../../../store/media/media.slice";
import useEvent from "react-use-event-hook";

const useKeyboard = ({ started, lesson, log, acceptEvents }) => {
  const dispatch = useDispatch();
  const playing = useSelector(selectPlaying);
  const volume = useSelector(selectVolume);
  const getVideoSecond = useSelector(selectGetCurrentVideoSeconds);

  const start = lesson.startSecond;
  const end = lesson.endSecond;

  const deltaSeek = (delta) => {
    //Get the current time
    const seekFromSecond = Math.round(getVideoSecond());
    //Set the new time
    let seekToSecond = seekFromSecond + delta;
    //If the new time is lower than 0, set it to 0
    if (seekToSecond < start) seekToSecond = start;
    //If the new time is greater than the max, set it to the max
    if (seekToSecond > end) seekToSecond = end;
    //Update the time
    dispatch(seekTo(seekToSecond));
    //Log the seek
    log("seek", seekFromSecond, seekToSecond);
    //Update the slider
    dispatch(setSliderValue(seekToSecond - start));
  };

  const keyboardListener = useEvent((e) => {
    if (started && acceptEvents) {
      switch (e.code) {
        case "ArrowUp":
          e.preventDefault();
          dispatch(setVolume(volume + 5));
          break;
        case "ArrowDown":
          e.preventDefault();
          dispatch(setVolume(volume - 5));
          break;
        case "ArrowLeft":
          e.preventDefault();
          deltaSeek(-5);
          break;
        case "ArrowRight":
          e.preventDefault();
          deltaSeek(5);
          break;
        case "Space":
          e.preventDefault();
          if (playing) dispatch(pause());
          else dispatch(play());
          break;
        default:
          break;
      }
    }
  });

  React.useEffect(() => {
    window.addEventListener("keydown", keyboardListener);
    return () => {
      window.removeEventListener("keydown", keyboardListener);
    };
  }, [keyboardListener]);

  return null;
};

export default useKeyboard;
