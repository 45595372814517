import React from "react";
import { Alert, Col, Container, Row, Table } from "react-bootstrap";
import { useGetConnectedUsersQuery } from "../../../../store/admin/admin.api";
import Loading from "../../../../components/PageElements/Loading";
import { te } from "../../../../utils/errorsTranslation";

const ActiveUsers = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetConnectedUsersQuery();

  const connections = data?.connections ?? [];
  const count = data?.count ?? 0;

  if (isLoading) return <Loading />;
  if (isError)
    return <Alert variant="danger">{te(error.data?.code ?? error.code)}</Alert>;
  if (isSuccess)
    return (
      <Container className="p-4">
        <Row>
          <Col>
            <h2 className="fw-bolder">Active users ({count})</h2>
            <p>This page will show the active users in the system.</p>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>First name</th>
                  <th>Last name</th>
                  <th>Email</th>
                  <th>Lesson</th>
                </tr>
              </thead>
              <tbody>
                {connections.map(({ user, lesson }, index) => (
                  <tr key={index}>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.email}</td>
                    <td>{lesson.name}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
};

export default ActiveUsers;
