import { useEffect, useState } from "react";
import { useGetEnrollmentDetailsQuery } from "../../../../../store/enrollments/enrollments.api";
import { te } from "../../../../../utils/errorsTranslation";

const useFetch = (courseUUID) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [units, setUnits] = useState([]);

  /* Query hooks */
  const {
    data: enrollment,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useGetEnrollmentDetailsQuery(courseUUID);

  //Check if there is an error
  useEffect(() => {
    if (isError) {
      if (!error.data?.code && !error.code)
        setErrorMessage(te("general_error"));
      else setErrorMessage(te(error.data?.code ?? error.code));
    } else if (enrollment) {
      setErrorMessage(null);
      setUnits(enrollment.course.units);
    }
  }, [enrollment, isError, error]);

  return { data: units, errorMessage, isLoading, isError, isSuccess };
};

export default useFetch;
