import {
  DifficultyIcon,
  InitialKnowledgeIcon,
  UnderstandingIcon,
} from "../../../../../components/Commons/FeedbackIcons";

export const userColumns = [
  {
    name: "lastName",
    header: "Last Name",
    getCell: function (data) {
      return <span>{data[this.name]}</span>;
    },
  },
  {
    name: "firstName",
    header: "First Name",
    getCell: function (data) {
      return <span>{data[this.name]}</span>;
    },
  },
  {
    name: "email",
    header: "Email",
    getCell: function (data) {
      return <span>{data[this.name]}</span>;
    },
  },
  {
    name: "role",
    header: "Role",
    getCell: function (data) {
      return <span>{data[this.name]}</span>;
    },
  },
  {
    name: "status",
    header: "Status",
    getCell: function (data) {
      return <span>{data[this.name]}</span>;
    },
  },
  {
    name: "createdAt",
    header: "Date Created",
    getCell: function (data) {
      return <span>{new Date(data[this.name]).toLocaleString()}</span>;
    },
  },
];

export const courseColumns = [
  {
    name: "enrollmentCode",
    header: "Code",
    getCell: function (data) {
      return <span>{data[this.name]}</span>;
    },
  },
  {
    name: "name",
    header: "Name",
    getCell: function (data) {
      return <span>{data[this.name]}</span>;
    },
  },
  {
    name: "teacher",
    header: "Teacher",
    getCell: function (data) {
      return (
        <span>{data.teacher.firstName + " " + data.teacher.lastName}</span>
      );
    },
  },
  {
    name: "feedback",
    header: "Feedback",
    getCell: function (data) {
      return (
        <div className="d-flex flex-row justify-content-start align-items-center gap-2">
          {/* Understanding */}
          <div className="d-flex flex-row justify-content-start align-items-center gap-1">
            <UnderstandingIcon className="text-success" />
            <span>{data.understandingScale}</span>
          </div>
          {/* Initial knowledge */}
          <div className="d-flex flex-row justify-content-start align-items-center gap-1">
            <InitialKnowledgeIcon
              className={
                data.initialKnowledgeEnabled ? "text-success" : "text-muted"
              }
            />
            <span>
              {data.initialKnowledgeEnabled ? data.understandingScale : "/"}
            </span>
          </div>
          {/* Difficulty */}
          <div className="d-flex flex-row justify-content-start align-items-center gap-1">
            <DifficultyIcon
              className={data.difficultyEnabled ? "text-success" : "text-muted"}
            />
            <span>{data.difficultyEnabled ? data.difficultyScale : "/"}</span>
          </div>
        </div>
      );
    },
  },
  {
    name: "createdAt",
    header: "Date Created",
    getCell: function (data) {
      return <span>{new Date(data[this.name]).toLocaleString()}</span>;
    },
  },
  {
    name: "lessonsCount",
    header: (
      <div className="text-center">
        <i className="fas fa-video"></i>
      </div>
    ),
    getCell: function (data) {
      return <span>{data[this.name]}</span>;
    },
  },
  {
    name: "enrollmentsCount",
    header: (
      <div className="text-center">
        <i className="fas fa-users"></i>
      </div>
    ),
    getCell: function (data) {
      return <span>{data[this.name]}</span>;
    },
  },
  {
    name: "lessonsWithFeedbackCount",
    header: (
      <div className="text-center">
        <i className="fas fa-comment"></i>
      </div>
    ),
    getCell: function (data) {
      return <span>{data[this.name]}</span>;
    },
  },
];
