import React from "react";
import styled from "styled-components";

const EvoliBackgroundSpan = ({ children, ...props }) => {
  return <Styles {...props}>{children}</Styles>;
};

export default EvoliBackgroundSpan;

const Styles = styled.span`
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.textPrimary};
  padding: 0.5rem;
`;
