import React from "react";
import {
  DifficultyIcon,
  InitialKnowledgeIcon,
  UnderstandingIcon,
} from "./FeedbackIcons";
import { getHexColors } from "../../utils/lecture.utils";

const StudentFeedbackResume = ({
  understanding,
  difficulty,
  initialKnowledge,
  difficultyEnabled = true,
  initialKnowledgeEnabled = true,
  understandingScale = 5,
  difficultyScale = 5,
  colored = true,
  percentage = false,
}) => {
  const understandingColors = getHexColors(understandingScale);
  const difficultyColors = getHexColors(difficultyScale ?? 5);
  const understandingColor = isNaN(understanding)
    ? "grey"
    : understandingColors[understanding - 1];
  const difficultyColor = isNaN(difficulty)
    ? "grey"
    : difficultyColors[difficultyScale - difficulty];
  const initialKnowledgeColor = isNaN(initialKnowledge)
    ? "grey"
    : understandingColors[initialKnowledge - 1];

  return (
    <div className="d-flex flex-row justify-content-center align-items-center gap-2">
      {/* Understanding */}
      <div className="d-flex flex-column align-items-center justify-content-center">
        {colored ? (
          <UnderstandingIcon style={{ color: understandingColor }} />
        ) : (
          <UnderstandingIcon />
        )}
        <span className="fs-6 fw-bold">
          {`${understanding ?? "/"}${percentage ? "%" : ""}`}
        </span>
      </div>
      {/* Difficulty */}
      {difficultyEnabled && (
        <div className="d-flex flex-column align-items-center justify-content-center">
          {colored ? (
            <DifficultyIcon
              style={{
                color: difficultyColor,
              }}
            />
          ) : (
            <DifficultyIcon />
          )}
          <span className="fs-6 fw-bold">
            {`${difficulty ?? "/"}${percentage ? "%" : ""}`}
          </span>
        </div>
      )}
      {/* Initial Knowledge */}
      {initialKnowledgeEnabled && (
        <div className="d-flex flex-column align-items-center justify-content-center">
          {colored ? (
            <InitialKnowledgeIcon style={{ color: initialKnowledgeColor }} />
          ) : (
            <InitialKnowledgeIcon />
          )}
          <span className="fs-6 fw-bold">
            {`${initialKnowledge ?? "/"}${percentage ? "%" : ""}`}
          </span>
        </div>
      )}
    </div>
  );
};

export default StudentFeedbackResume;
