import styled from "styled-components";

const Styles = {};

Styles.ChartContainer = styled.div`
  width: 100%;

  .chart-container {
    width: 100%;
    height: 40vh;
  }
`;

Styles.MiniBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  color: ${(props) => props.theme.primary};

  .header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;

    i {
      font-size: 2rem;
    }

    .data {
      font-size: 1.3rem;
    }
  }

  .description {
    font-size: 1rem;
    font-weight: bold;
  }
`;

export default Styles;
