import { useEffect, useState } from "react";
import {
  useGetEnrollmentsQuery,
  useGetTeachersQuery,
  useUnenrollMutation,
} from "../../../../../store/enrollments/enrollments.api";
import { te } from "../../../../../utils/errorsTranslation";
import { onUnenrollFromCourse } from "../../../../../utils/Alert";
import { toastSuccess } from "../../../../../utils/Toast";
import { toastApiError } from "../../../../../utils/lecture.utils";
import { useTranslation } from "react-i18next";

const useFetch = ({
  limit,
  offset,
  order,
  orderBy,
  selectedTeacher,
  searchQuery,
}) => {
  const { t } = useTranslation("", { keyPrefix: "home" });

  /* Queries */
  const {
    data: data1,
    isLoading: isLoading1,
    isError: isError1,
    error: error1,
    isSuccess: isSuccess1,
  } = useGetEnrollmentsQuery({
    limit,
    offset,
    order,
    orderBy,
    teacherEmail: selectedTeacher,
    searchQuery,
  });
  const {
    data: data2,
    isLoading: isLoading2,
    isError: isError2,
    error: error2,
    isSuccess: isSuccess2,
  } = useGetTeachersQuery();
  const [unenroll, { isLoading: isLoading3 }] = useUnenrollMutation();

  const isError = isError1 || isError2;
  const error = error1 ?? error2;
  const isLoading = isLoading1 || isLoading2 || isLoading3;
  const isSuccess = isSuccess1 && isSuccess2;

  const [errorMessage, setErrorMessage] = useState(null);
  const [enrollments, setEnrollments] = useState([]);
  const [enrollmentsCount, setEnrollmentsCount] = useState(0);
  const [teachers, setTeachers] = useState([]);

  //Check if there is an error
  useEffect(() => {
    if (isError) {
      if (!error.data?.code && !error.code)
        setErrorMessage(te("general_error"));
      else setErrorMessage(te(error.data?.code ?? error.code));
    } else if (isSuccess) {
      setErrorMessage(null);
      setEnrollments(data1.enrollments);
      setEnrollmentsCount(data1.count);
      setTeachers(data2);
    }
  }, [data1, isError, error, data2, isSuccess]);

  /* Handler for unenroll from courses, once the user clicks on "unenroll", the confirmation is asked through a modal */
  const unenrollHandler = (course) => {
    onUnenrollFromCourse(() => {
      unenroll(course.uuid)
        .unwrap()
        .then(() => {
          toastSuccess("Successfully unenrolled from course " + course.name);
        })
        .catch((err) => {
          toastApiError(err);
        });
    }, t);
  };

  return {
    data: { enrollments, enrollmentsCount, teachers },
    errorMessage,
    isLoading,
    isError,
    isSuccess,
    enrollmentsCount,
    unenrollHandler,
  };
};

export default useFetch;
