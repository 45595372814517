import React from "react";
import { useGetCourseQuestionsQuery } from "../../../store/feedback/feedback.api";
import useErrorMessage from "../../../hooks/useErrorMessage";

const useFetch = ({ courseUUID, limit, offset, order, orderBy }) => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetCourseQuestionsQuery({
      courseUUID: courseUUID,
      limit: limit,
      offset: offset,
      order: order,
      orderBy: orderBy,
    });

  const [questions, setQuestions] = React.useState([]);
  const [count, setCount] = React.useState(0);

  const errorMessage = useErrorMessage({ isError, error });

  React.useEffect(() => {
    if (isSuccess) {
      setQuestions(data?.rows);
      setCount(data?.count);
    }
  }, [data, isSuccess]);

  return {
    questions,
    count,
    isLoading,
    isSuccess,
    isError,
    errorMessage,
  };
};

export default useFetch;
