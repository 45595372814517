import React from "react";
import { Container } from "react-bootstrap";
import { Outlet, useParams } from "react-router-dom";
import PageContainer from "../../components/PageElements/PageContainer";
import Footer from "../../components/PageElements/Footer";
import Header from "../../components/PageElements/Header";
import Loading from "../../components/PageElements/Loading";
import Navbar from "../../components/PageElements/Navbar";
import EvoliBreadcrumb from "../../components/PageElements/EvoliBreadcrumb";
import useFetch from "./hooks/useFetch";
import useMenu from "./hooks/useMenu";
import BackToTop from "../../components/PageElements/BackToTop";

const UnitDashboard = () => {
  const { unitUUID } = useParams();

  //Fetch hook
  const {
    data: unit,
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  } = useFetch(unitUUID);
  // Menu hook
  const { active, links, handleMenuClick } = useMenu(unitUUID);

  const breadcrumbItems = [
    {
      name: "My Teachings",
      to: "/teacher/home/courses",
      active: false,
    },
    {
      name: unit?.course?.name ?? "Course",
      to: `/teacher/course/${unit?.course?.uuid}/content`,
      active: false,
    },
    {
      name: unit?.name ?? "Unit",
      to: `/teacher/dashboard/${unitUUID}`,
      active: true,
    },
  ];

  if (isLoading) return <Loading page={false} />;
  if (isError) return <div className="text-danger fw-bold">{errorMessage}</div>;
  if (isSuccess && unit)
    return (
      <>
        <Navbar />
        <PageContainer>
          <Header>
            <Container>
              <EvoliBreadcrumb items={breadcrumbItems} />
              <Header.Title>{unit.name}</Header.Title>{" "}
              <Header.Menu>
                {links.map((link, index) => (
                  <Header.MenuItem
                    key={index}
                    className={active === index && "active"}
                    onClick={() => handleMenuClick(index)}
                  >
                    {link.name}
                  </Header.MenuItem>
                ))}
              </Header.Menu>
            </Container>
          </Header>
          <Outlet context={{ unit }} />
        </PageContainer>
        <Footer />
        <BackToTop />
      </>
    );
};

export default UnitDashboard;
