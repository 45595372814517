import React from "react";
import * as SharedStyles from "../../../../styles/Shared.styles";
import Styles from "./styles";

const Reactions = ({ message, values, reactionHandler }) => {
  const reactionList = [
    "fa-thumbs-up",
    "fa-heart",
    "fa-grin-tears",
    "fa-sad-tear",
    "fa-angry",
  ];

  const clickReactionHandler = (index) => {
    reactionHandler(message.uuid, index + 5);
  };

  const noReactions = values.reduce((acc, curr) => acc + curr.value, 0) === 0;

  return (
    <Styles.ReactionRow>
      {reactionList.map((reaction, index) => {
        const reactObj = values[index];

        return (
          <Styles.Reaction
            key={index}
            visible={reactObj.value > 0}
            userReacted={reactObj.userReacted}
            className="w-auto px-1 reaction"
          >
            <SharedStyles.ClickableIcon
              className={`w-auto fas ${reaction}`}
              onClick={() => clickReactionHandler(index)}
            />
            <span className="w-auto fs-5">{reactObj.value}</span>
          </Styles.Reaction>
        );
      })}
      {noReactions && (
        <i className="placeholder-reaction w-auto p-1 fs-5 fas fa-grin" />
      )}
    </Styles.ReactionRow>
  );
};

export default Reactions;
