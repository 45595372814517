import { Button, Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";

export const EvoliButton = styled(Button)`
  border-radius: 1rem;
  padding: 0.4rem;

  ${(p) => !p.variant && `border: 1px solid ${p.theme.accent}`};
  ${(p) => !p.variant && `background-color: ${p.theme.accent} !important`};
  ${(p) => !p.variant && `color: ${p.theme.textPrimary} !important`};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    ${(p) =>
      !p.variant && `background-color: ${p.theme.textPrimary} !important`};
    ${(p) => !p.variant && `color: ${p.theme.accent} !important`};
  }

  &[disabled] {
    background-color: ${(p) => p.theme.secondary};
    color: ${(p) => p.theme.textSecondary};
    border: 1px solid ${(p) => p.theme.secondary};
  }
`;

export const DangerButton = styled(Button)`
  border-radius: 1rem;
  border: 1px solid ${(p) => p.theme.danger};
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SecondaryButton = styled(Button)`
  border-radius: 1rem;
  border: 1px solid ${(p) => p.theme.secondary};
  padding: 0.5rem;
  background-color: ${(p) => p.theme.secondary};
  color: ${(p) => p.theme.textPrimary};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus,
  &.active {
    background-color: ${(p) => p.theme.textPrimary};
    color: ${(p) => p.theme.accent};
  }

  &[disabled] {
    background-color: ${(p) => p.theme.secondary};
    color: ${(p) => p.theme.textSecondary};
    border: 1px solid ${(p) => p.theme.secondary};
  }
`;

export const LightButton = styled(Button)`
  border-radius: 1rem;
  border: 1px solid ${(p) => p.theme.accent};
  padding: 0.5rem;
  background-color: ${(p) => p.theme.textPrimary};
  color: ${(p) => p.theme.accent};
  display: flex;
  flex-direction: row;
  justify-content: center;

  &:hover,
  &:focus {
    background-color: ${(p) => p.theme.accent};
    color: ${(p) => p.theme.textPrimary};
  }

  &[disabled] {
    background-color: ${(p) => p.theme.secondary};
    color: ${(p) => p.theme.textSecondary};
    border: 1px solid ${(p) => p.theme.secondary};
  }
`;

export const CancelButton = styled(Button)`
  border-radius: 1rem;
  border: 1px solid ${(p) => p.theme.accent};
  padding: 0.5rem;
  background-color: ${(p) => p.theme.textPrimary};
  color: ${(p) => p.theme.accent};
  display: flex;
  flex-direction: row;
  justify-content: center;

  &:hover,
  &:focus {
    background-color: ${(p) => p.theme.secondary};
    color: ${(p) => p.theme.textPrimary};
  }

  &[disabled] {
    background-color: ${(p) => p.theme.secondary};
    color: ${(p) => p.theme.textSecondary};
    border: 1px solid ${(p) => p.theme.secondary};
  }
`;

export const FormCheck = styled(Form.Check)`
  .form-check-input {
    border: none;
    border-radius: 99em;
    border: 2px solid ${(p) => p.theme.primary};
  }

  .form-check-input:checked {
    background-color: ${(p) => p.theme.secondary};
    border-width: 0;
  }

  .form-check-input:focus {
    box-shadow: none;
  }

  input[type="radio"],
  input[type="checkbox"] {
    transform: scale(1.5);
  }

  .form-check-label {
    color: ${(props) =>
      props.dark ? props.theme.textPrimary : props.theme.primary};
  }
`;

export const FormGroup = styled(Form.Group)`
  position: relative;
`;

export const FormLabel = styled.label`
  z-index: 1;
  position: absolute;
  margin-top: -0.5rem;
  margin-left: 1.5rem;
  background-color: ${(props) =>
    props.dark ? props.theme.primary : props.theme.textPrimary};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.875rem;
  border-radius: 99em;
  text-transform: capitalize;
  color: ${(props) =>
    props.dark ? props.theme.textPrimary : props.theme.primary};
`;

export const FormControl = styled(Form.Control)`
  border-radius: 99em;
  box-shadow: none;
  padding: 0.5em 1.5em;
  background-color: ${(p) => p.theme.textPrimary};
  color: ${(p) => p.theme.primary};
  border: 3px solid ${(p) => p.theme.primary};
  height: 3.5rem;

  &::placeholder {
    text-transform: uppercase;
    color: ${(p) => p.theme.secondary};
  }

  &:focus {
    box-shadow: none;
  }

  &:disabled {
    background-color: ${(p) => p.theme.secondary};
  }

  &::file-selector-button {
    border-radius: 99em;
    transform: translateX(-1rem);
    background-color: ${(p) => p.theme.textPrimary};
    color: ${(p) => p.theme.primary};
  }

  &.show-pwd {
    border-radius: 99em 0 0 99em !important;
  }
`;

export const PasswordShowButton = styled(InputGroup.Text)`
  border-radius: 0 99em 99em 0;
  border: 3px solid ${(p) => p.theme.primary};
  border-left: none;
  background-color: ${(props) =>
    props.dark ? props.theme.primary : props.theme.textPrimary};
  box-shadow: none;
  color: ${(p) => p.theme.textSecondary};
  transition-duration: 200ms;
`;

export const Error = styled.div`
  border-radius: 99em;
  border: none;
  box-shadow: none;
  padding: 0.5em 1.5em;
  background-color: ${(p) => p.theme.textPrimary};
  color: red;
  font-size: 1rem;
  font-weight: 600;
`;

export const STimeInput = styled.input`
  text-align: right;
  height: 40px;
  width: 120px;
  padding: 0.5em 1em;
  background-color: ${(p) => p.theme.textPrimary};
  color: ${(p) => p.theme.primary};
  border-radius: 99em;
  border: 3px solid ${(p) => p.theme.primary};

  &::placeholder {
    text-transform: uppercase;
    color: ${(p) => p.theme.textSecondary};
  }
`;

export const FormSelect = styled(Form.Select)`
  border-radius: 99em;
  border: none;
  box-shadow: none;
  padding: 0.5em 1.5em;
  background-color: ${(p) => p.theme.textPrimary};
  color: ${(p) => p.theme.primary};
  border: 3px solid ${(p) => p.theme.primary};

  ::placeholder {
    text-transform: uppercase;
    color: ${(p) => p.theme.textSecondary};
  }

  :focus {
    box-shadow: none;
  }

  &:disabled {
    background-color: ${(p) => p.theme.secondary};
  }
`;
