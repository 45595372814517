import { useCallback, useEffect } from "react";
import {
  useGetFeedbackQuery,
  useGetStudentLessonDetailsQuery,
} from "../../../store/feedback/feedback.api";
import { useCreateLogMutation } from "../../../store/analytics/analytics.api";
import { useSelector } from "react-redux";
import { selectVisualizationCode } from "../../../store/feedback/feedback.slice";
import { toastApiError } from "../../../utils/lecture.utils";
import { useNavigate } from "react-router-dom";

const useFetch = (lessonUUID) => {
  const navigate = useNavigate();

  //Queries
  const {
    data: data1,
    isLoading: isLoading1,
    isError: isError1,
    error: error1,
    isSuccess: isSuccess1,
  } = useGetStudentLessonDetailsQuery(lessonUUID);
  const {
    data: data2,
    isLoading: isLoading2,
    isError: isError2,
    error: error2,
    isSuccess: isSuccess2,
  } = useGetFeedbackQuery(lessonUUID);
  const [createLog] = useCreateLogMutation();

  const visualizationCode = useSelector(selectVisualizationCode);

  const log = useCallback(
    (type, data, data2 = null) => {
      const body = {
        visualizationUUID: visualizationCode,
        type,
      };
      if (data2) body.data2 = data2;
      //This check is necessary since the data could be 0
      if (data !== null && data !== undefined) body.data = data;

      createLog(body)
        .unwrap()
        .catch((err) => toastApiError(err));
    },
    [createLog, visualizationCode]
  );

  const isError = isError1 || isError2;
  const error = error1 ?? error2;
  const isLoading = isLoading1 || isLoading2;
  const isSuccess = isSuccess1 && isSuccess2;

  /* Check the parameters */
  useEffect(() => {
    if (isError || !lessonUUID) {
      if (error) toastApiError(error);
      navigate("/student/home/enrollments");
    }
  }, [data1, data2, error, isError, isSuccess, lessonUUID, navigate]);

  return {
    isLoading,
    isError,
    isSuccess,
    error,
    log,
    data: {
      lesson: data1,
      myFeedback: data2,
    },
  };
};

export default useFetch;
