import {
  useCreateAnswerMutation,
  useDeleteQuestionMutation,
  useUpsertQuestionReactionMutation,
} from "../../../store/feedback/feedback.api";
import { toastSuccess } from "../../../utils/Toast";
import { toastApiError } from "../../../utils/lecture.utils";

const useMutation = () => {
  const [createAnswer, { isLoading: isLoading1 }] = useCreateAnswerMutation();
  const [upsertReaction, { isLoading: isLoading2 }] =
    useUpsertQuestionReactionMutation();
  const [deleteQuestion, { isLoading: isLoading3 }] =
    useDeleteQuestionMutation();

  const isLoading = isLoading1 || isLoading2 || isLoading3;

  const createAnswerHandler = (parent, text) => {
    createAnswer({ parentUUID: parent.uuid, text })
      .unwrap()
      .then((_) => {
        toastSuccess("Successfully created answer");
      })
      .catch((err) => {
        toastApiError(err);
      });
  };

  const deleteQuestionHandler = (uuid) => {
    deleteQuestion(uuid)
      .unwrap()
      .then((_) => {
        toastSuccess("Item successfully deleted");
      })
      .catch((err) => {
        toastApiError(err);
      });
  };

  const reactionHandler = (uuid, value) => {
    upsertReaction({ questionUUID: uuid, value })
      .unwrap()
      .catch((err) => {
        toastApiError(err);
      });
  };

  return {
    handlers: {
      createAnswerHandler,
      deleteQuestionHandler,
      reactionHandler,
    },
    isLoading,
  };
};

export default useMutation;
