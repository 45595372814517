import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import CourseQuestions from "../../../../components/course-questions/CourseQuestions";

const TeachingQuestions = () => {
  const { course } = useOutletContext();

  return (
    <Container className="py-5">
      <Row>
        <Col>
          <h2>Course questions</h2>
          <p className="mb-4">
            Help your student by answering questions about the course. Click on
            the video icon to watch the video section the question is about.
          </p>
          <CourseQuestions course={course} />
        </Col>
      </Row>
    </Container>
  );
};

export default TeachingQuestions;
