import React from "react";
import EvoliModal from "../Commons/EvoliModal";
import { Modal } from "react-bootstrap";
import SimpleVideoPlayer from "../VideoPlayers/SimpleVideoPlayer";
import Styles from "../VideoPlayers/styles";
import { EvoliButton } from "../../styles/Forms.styles";
import { useDispatch } from "react-redux";
import { seekTo } from "../../store/media/media.slice";

const VideoModal = ({ show, onHide, lesson, startSecond }) => {
  const dispatch = useDispatch();

  const goToQuestionHandler = () => {
    dispatch(seekTo(startSecond));
  };

  return (
    <EvoliModal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{lesson.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Styles.MediaContainer className="miniature">
          <SimpleVideoPlayer lesson={lesson} startAt={startSecond} />
        </Styles.MediaContainer>
      </Modal.Body>
      <Modal.Footer>
        <EvoliButton onClick={goToQuestionHandler}>
          Go to the second of the question
        </EvoliButton>
      </Modal.Footer>
    </EvoliModal>
  );
};

export default VideoModal;
