import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const useMenu = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [active, setActive] = React.useState(0); //0 for content, 1 for students, 2 for edit

  //Memoized links
  const links = React.useMemo(
    () => [
      {
        name: "Course content",
        path: `/student/course/${params.courseUUID}`,
        pattern: `/student/course/${params.courseUUID}`,
      },
      {
        name: "Questions",
        path: `/student/course/${params.courseUUID}/questions`,
        pattern: `/student/course/${params.courseUUID}/questions`,
      },
    ],
    [params.courseUUID]
  );

  //Handle menu click event (change active menu item and redirect)
  const handleMenuClick = (id) => {
    if (id < links.length) {
      navigate(links[id].path);
    }
  };

  React.useEffect(() => {
    const pathname = location.pathname;
    links.forEach((link, index) => {
      if (pathname === link.path) {
        setActive(index);
      }
    });
  }, [location, links]);

  return { active, handleMenuClick, links };
};

export default useMenu;
