import React from "react";
import { Col, Row } from "react-bootstrap";
import EvoliMultiButton from "../../../../components/Commons/EvoliMultiButton";
import Number from "../../../../components/Animations/Number";
import InfoTooltip from "../../../../components/Commons/InfoTooltip";
import Styles from "./styles";
import useFetch from "./hooks/useFetch";
import HashLoader from "react-spinners/HashLoader";
import { useTheme } from "styled-components";
import { getHours, getMinutes } from "../../../../utils/lecture.utils";

const Insights = () => {
  const theme = useTheme();

  const texts = ["Last 24 hours", "Last 7 days", "Last 30 days"];
  const [overviewPeriod, setOverviewPeriod] = React.useState(0); // 0 = 1 day, 1 = 7 days, 2 = 30 days

  const {
    data: insights,
    isFetching,
    isError,
    errorMessage,
  } = useFetch({
    days: overviewPeriod === 0 ? 1 : overviewPeriod === 1 ? 7 : 30,
  });

  const totalMinutes = isFetching ? 0 : getMinutes(insights?.totalTime ?? 0);
  const totalHours = isFetching ? 0 : getHours(insights?.totalTime ?? 0);

  const lastMinutes = isFetching ? 0 : getMinutes(insights?.newTotalTime ?? 0);
  const lastHours = isFetching ? 0 : getHours(insights?.newTotalTime ?? 0);

  if (isError) return <p className="text-danger fw-bolder">{errorMessage}</p>;
  return (
    <Styles.MainContainer className="p-4">
      <Row>
        <h2 className="fw-bolder">Cumulative statistics</h2>
        <p>
          Strength of your application. Have a look at the overall statistics.
        </p>
      </Row>
      <Row className="mb-4">
        <Col xs={12} sm={6} md={4}>
          <Styles.OverviewCard>
            <div className="content">
              <div className="number">
                {isFetching ? (
                  <HashLoader color={theme.primary} className="m-2" />
                ) : (
                  <div className="d-flex flex-row justify-content-center">
                    <Number number={totalHours} />
                    :
                    <Number number={totalMinutes} />
                  </div>
                )}
              </div>
              <div className="text">
                Total
                <br />
                view time{" "}
                <InfoTooltip text="The total time spent by all users in the lesson pages." />
              </div>
            </div>
          </Styles.OverviewCard>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Styles.OverviewCard>
            <div className="content">
              <div className="number">
                {isFetching ? (
                  <HashLoader color={theme.primary} className="m-2" />
                ) : (
                  <div className="d-flex gap-1">
                    <Number number={insights?.usersCount ?? 0} />(
                    <span className="text-success">
                      <Number number={insights?.connectedUsers ?? 0} />
                    </span>
                    )
                  </div>
                )}
              </div>
              <div className="text">
                Total
                <br />
                users{" "}
                <InfoTooltip text="The total number of users registered on the platform." />
              </div>
            </div>
          </Styles.OverviewCard>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Styles.OverviewCard>
            <div className="content">
              <div className="number">
                {isFetching ? (
                  <HashLoader color={theme.primary} className="m-2" />
                ) : (
                  <Number number={insights?.coursesCount ?? 0} />
                )}
              </div>
              <div className="text">
                Total
                <br />
                courses{" "}
                <InfoTooltip text="The total number of courses created on the platform." />
              </div>
            </div>
          </Styles.OverviewCard>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Styles.OverviewCard>
            <div className="content">
              <div className="number">
                {isFetching ? (
                  <HashLoader color={theme.primary} className="m-2" />
                ) : (
                  <Number number={insights?.lessonsCount ?? 0} />
                )}
              </div>
              <div className="text">
                Total
                <br />
                videos{" "}
                <InfoTooltip text="The total number of videos uploaded on the platform." />
              </div>
            </div>
          </Styles.OverviewCard>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Styles.OverviewCard>
            <div className="content">
              <div className="number">
                {isFetching ? (
                  <HashLoader color={theme.primary} className="m-2" />
                ) : (
                  <Number number={insights?.feedbackCount ?? 0} />
                )}
              </div>
              <div className="text">
                Total
                <br />
                Feedback{" "}
                <InfoTooltip text="The total number of feedbacks received on the platform." />
              </div>
            </div>
          </Styles.OverviewCard>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Styles.OverviewCard>
            <div className="content">
              <div className="number">
                {isFetching ? (
                  <HashLoader color={theme.primary} className="m-2" />
                ) : (
                  <Number number={insights?.questionsCount ?? 0} />
                )}
              </div>
              <div className="text">
                Total
                <br />
                Questions{" "}
                <InfoTooltip text="The total number of questions asked on the platform." />
              </div>
            </div>
          </Styles.OverviewCard>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <h2 className="fw-bolder">Last period insights</h2>
          <p>Have a look at the new things happening in your application.</p>
          <div className="d-flex flex-row justify-content-center">
            <EvoliMultiButton
              active={overviewPeriod}
              setActive={setOverviewPeriod}
              texts={texts}
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={12} sm={6} md={4}>
          <Styles.OverviewCard>
            <div className="content">
              <div className="number">
                {isFetching ? (
                  <HashLoader color={theme.primary} className="m-2" />
                ) : (
                  <div className="d-flex flex-row justify-content-center">
                    <Number number={lastHours} />
                    :
                    <Number number={lastMinutes} />
                  </div>
                )}
              </div>
              <div className="text">
                Total
                <br />
                view time{" "}
                <InfoTooltip text="The total time spent by all users in the lesson pages." />
              </div>
            </div>
          </Styles.OverviewCard>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Styles.OverviewCard>
            <div className="content">
              <div className="number">
                {isFetching ? (
                  <HashLoader color={theme.primary} className="m-2" />
                ) : (
                  <Number number={insights?.newUsersCount ?? 0} />
                )}
              </div>
              <div className="text">
                New
                <br />
                users{" "}
                <InfoTooltip text="A user is new if she has registered on the platform in the selected period." />
              </div>
            </div>
          </Styles.OverviewCard>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Styles.OverviewCard>
            <div className="content">
              <div className="number">
                {isFetching ? (
                  <HashLoader color={theme.primary} className="m-2" />
                ) : (
                  <Number number={insights?.newCoursesCount ?? 0} />
                )}
              </div>
              <div className="text">
                New
                <br />
                courses{" "}
                <InfoTooltip text="The number of courses created in the selected period." />
              </div>
            </div>
          </Styles.OverviewCard>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Styles.OverviewCard>
            <div className="content">
              <div className="number">
                {isFetching ? (
                  <HashLoader color={theme.primary} className="m-2" />
                ) : (
                  <Number number={insights?.newLessonsCount ?? 0} />
                )}
              </div>
              <div className="text">
                New
                <br />
                videos{" "}
                <InfoTooltip text="The number of videos uploaded in the selected period." />
              </div>
            </div>
          </Styles.OverviewCard>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Styles.OverviewCard>
            <div className="content">
              <div className="number">
                {isFetching ? (
                  <HashLoader color={theme.primary} className="m-2" />
                ) : (
                  <Number number={insights?.newFeedbackCount ?? 0} />
                )}
              </div>
              <div className="text">
                New
                <br />
                Feedback{" "}
                <InfoTooltip text="The number of feedbacks received in the selected period." />
              </div>
            </div>
          </Styles.OverviewCard>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Styles.OverviewCard>
            <div className="content">
              <div className="number">
                {isFetching ? (
                  <HashLoader color={theme.primary} className="m-2" />
                ) : (
                  <Number number={insights?.newQuestionsCount ?? 0} />
                )}
              </div>
              <div className="text">
                New
                <br />
                Questions{" "}
                <InfoTooltip text="The number of questions asked in the selected period." />
              </div>
            </div>
          </Styles.OverviewCard>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Styles.OverviewCard>
            <div className="content">
              <div className="number">
                {isFetching ? (
                  <HashLoader color={theme.primary} className="m-2" />
                ) : (
                  <Number number={insights?.activeUsersCount ?? 0} />
                )}
              </div>
              <div className="text">
                Active
                <br />
                users{" "}
                <InfoTooltip text="A user is active if she has visualized at least one video in the selected period." />
              </div>
            </div>
          </Styles.OverviewCard>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Styles.OverviewCard>
            <div className="content">
              <div className="number">
                {isFetching ? (
                  <HashLoader color={theme.primary} className="m-2" />
                ) : (
                  <Number number={insights?.activeCoursesCount ?? 0} />
                )}
              </div>
              <div className="text">
                Active
                <br />
                courses{" "}
                <InfoTooltip text="The number of courses in which at least a lesson has been visualized in the selected period." />
              </div>
            </div>
          </Styles.OverviewCard>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Styles.OverviewCard>
            <div className="content">
              <div className="number">
                {isFetching ? (
                  <HashLoader color={theme.primary} className="m-2" />
                ) : (
                  <Number number={insights?.activeLessonsCount ?? 0} />
                )}
              </div>
              <div className="text">
                Active
                <br />
                videos{" "}
                <InfoTooltip text="The number of videos visualized in the selected period." />
              </div>
            </div>
          </Styles.OverviewCard>
        </Col>
      </Row>
    </Styles.MainContainer>
  );
};

export default Insights;
