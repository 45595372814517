import styled from "styled-components";

const Styles = {};

Styles.ChartContainer = styled.div`
  width: 100%;
  padding: 1rem;

  .chart-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .chart-subtitle {
    font-size: 1rem;
  }

  .chart-container {
    width: 100%;
    height: 40vh;
  }
`;

export default Styles;
