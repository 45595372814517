import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

const EvoliModal = ({ children, ...props }) => {
  return <Styles.Modal {...props}>{children}</Styles.Modal>;
};

export default EvoliModal;

const Styles = {};

Styles.Modal = styled(Modal)`
  /* Edit the header */
  .modal-header {
    background-color: ${({ theme }) => theme.primary} !important;
    color: ${({ theme }) => theme.textPrimary} !important;

    .modal-title {
      text-transform: uppercase;
      text-align: center;
    }
  }

  /* Edit the body */
  .modal-body {
    background-color: ${({ theme }) => theme.textPrimary} !important;
    color: ${({ theme }) => theme.primary} !important;
  }

  /* Edit the footer */
  .modal-footer {
    background-color: ${({ theme }) => theme.secondary} !important;
    color: ${({ theme }) => theme.textPrimary} !important;
  }
`;
