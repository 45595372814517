import styled from "styled-components";

const Styles = {};

Styles.Label = styled.span`
  font-weight: bold;
  margin-right: 0.25rem;
  color: ${(p) => p.theme.primary};
  font-size: 1.2rem;
`;

Styles.Value = styled.span`
  color: ${(p) => p.theme.secondary};
  font-size: 1.5rem;
`;

export default Styles;
