import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const PrivacyPolicyIta = () => {
  return (
    <Container className="py-5">
      <Row>
        <Col>
          <h2 className="text-center">
            INFORMATIVA AI SENSI DELL'ART. 13 DEL REGOLAMENTO GENERALE SULLA
            PROTEZIONE DEI DATI PERSONALI (UE) 2016/679 (“REGOLAMENTO” O “GDPR”)
          </h2>

          <p>
            La presente Informativa si rivolge alle persone fisiche che si
            qualificano come utenti del sistema EVOLI, uno strumento di supporto
            alla didattica sviluppato in forma di prototipo per finalità di
            ricerca dal Politecnico di Milano, con riguardo al trattamento dei
            dati personali raccolti nell’ambito dell’utilizzo del sistema.
          </p>

          <h3>Titolare del trattamento</h3>
          <p>
            Titolare del trattamento è il Politecnico di Milano, nella figura
            del Direttore Generale su delega del Rettore pro-tempore. Punto di
            contatto: <a href="mailto:dirgen@polimi.it">dirgen@polimi.it</a>.
          </p>

          <h3>Responsabile protezione dati e punti di contatto </h3>
          <p>
            Mail:{" "}
            <a href="mailto:privacy@polimi.it" target="blank">
              privacy@polimi.it
            </a>
          </p>

          <h3>Responsabile interno del trattamento</h3>
          <p>
            Responsabile interno del trattamento è Nicoletta Di Blas. Punto di
            contatto:{" "}
            <a href="mailto:nicoletta.diblas@polimi.it" target="blank">
              nicoletta.diblas@polimi.it
            </a>
            .
          </p>

          <h3>Descrizione e finalità dello studio</h3>
          <p>
            Evoli è una piattaforma online dedicata all'insegnamento attraverso
            l'uso di video e materiali didattici multimediali. Progettata
            esclusivamente per supportare la didattica, Evoli ha come obiettivo
            di facilitare il dialogo studenti-docente sulla chiarezza dei
            materiali didattici condivisi, senza sostituirsi ai tradizionali
            sistemi di valutazione o controllo. Il docente si registra alla
            piattaforma dove crea uno spazio (“corso”) cui darà accesso ai suoi
            studenti. In questo spazio definisce delle unità di apprendimento e
            per ciascuna l’accesso a video caricati sulla piattaforma YouTube o
            ad altri materiali didattici. Gli studenti si registrano alla
            piattaforma, partecipano ai corsi, commentano i video/media, pongono
            domande, vedono le domande dei compagni e se ritengono propongono
            una propria risposta. Il docente ha accesso a una serie di dati
            relativi all’uso della piattaforma da parte degli studenti, in forma
            non anonima: non solo informazioni che gli studenti forniscono
            esplicitamente (es. domande su determinati argomenti), ma anche i
            dati di uso del sistema (tempo speso a guardare un video, eventuali
            interruzioni, ecc.). Gli studenti possono vedere le domande poste
            dagli altri studenti in forma non anonima; possono vedere in forma
            aggregata ed anonima i dati relativi alle opinioni degli altri
            studenti (es. il livello medio di comprensione di un video).
          </p>
          <p>
            Gli studenti che accedono e usano la piattaforma devono avere almeno
            16 anni di età per usare i servizi previsti nella piattaforma. Nel
            caso di minori di anni 16 occorrerà un’autorizzazione e consenso
            espresso dei genitori del minore.
          </p>

          <h3>Finalità del Trattamento, base giuridica e natura dei dati</h3>
          <p>
            Il Politecnico di Milano è titolare delle operazioni di trattamento
            correlate all’utilizzo della piattaforma EVOLI e alle attività di
            ricerca svolte sulla base dei dati raccolti e tratterà i Suoi dati
            personali comuni (nome, cognome, genere, livello scolastico in cui
            si insegna, o che si frequenta come studente, area disciplinare,
            email, nome dell’istituzione, paese, dati di log) solo su Suo
            previo, specifico ed esplicito consenso esclusivamente per il
            supporto alle attività didattiche e per la realizzazione di ricerche
            basate sui dati raccolti, soltanto nella misura in cui siano
            indispensabili in relazione a queste finalità. Nello specifico, la
            raccolta di dati personali tramite EVOLI persegue le finalità di
            seguito elencate:
          </p>
          <ol>
            <li>
              offrire uno strumento di miglioramento delle attività didattiche
              in cui video/media siano condivisi con gli studenti;
            </li>
            <li>
              supportare la ricerca sull’efficacia della tecnologia
              nell’educazione, in particolare per quanto riguarda i sistemi di
              annotazione video/media;
            </li>
          </ol>
          <p>
            La base di legittimità per il trattamento dei suoi dati per suddette
            finalità è il Suo consenso esplicito ai sensi dell’art. 6(1)(a). Il
            conferimento dei Suoi dati per tali finalità è facoltativo,
            tuttavia, essendo indispensabile all’accesso alla piattaforma, il
            suo eventuale rifiuto non Le consentirà di accedere ai servizi
            garantiti dalla piattaforma. È possibile revocare ex art. 7 del GDPR
            il consenso prestato per le suddette finalità in ogni momento senza
            fornire alcuna giustificazione. Non saranno raccolti ulteriori dati
            che La riguardano ferma restando l’utilizzazione di quelli
            eventualmente già raccolti per determinare, senza alterarli, i
            risultati della ricerca.
          </p>

          <h3>Modalità di trattamento e attori coinvolti</h3>
          <p>
            Al momento della registrazione: nome e cognome, genere, e-mail,
            livello scolastico, area disciplinare, affiliazione, paese. Durante
            l’uso: data e ora di accesso alla lezione, tempo di connessione alla
            lezione, comportamento dell’utente durante la visione del
            video/media (secondi del video in cui viene premuto play, pausa,
            “seek” in avanti e indietro). La piattaforma raccoglie inoltre una
            serie di dati forniti esplicitamente dall’utente, ovvero:
            auto-valutazione (espressa in scala numerica) di alcuni aspetti
            didatticamente rilevanti rispetto alla fruizione di video/media,
            come il livello di comprensione, il livello di difficoltà percepito,
            il livello di conoscenza previa del tema, il livello di interesse
            dell’argomento; commenti che spiegano l’autovalutazione proposta;
            domande su aspetti specifici dei video /media condivisi dal docente;
            eventuali risposte a domande poste dai compagni di studio.
          </p>
          <p>
            Il trattamento dei dati avverrà attraverso strumenti informatici e
            telematici, con logiche di organizzazione ed elaborazione correlate
            esclusivamente alle finalità di supporto alle attività didattiche e
            per finalità di ricerca e, comunque, in modo da garantire la
            sicurezza e la riservatezza degli stessi in conformità alla
            normativa vigente. In specifico, i dati saranno conservati su server
            del Politecnico di Milano, opportunamente criptati e protetti con
            password. Le informazioni che potrebbero consentire
            l’identificazione dei partecipanti (nome, cognome, e-mail) saranno
            archiviate in un database ospitato su un server del Politecnico di
            Milano e gestito internamente. I ricercatori autorizzati del
            Politecnico di Milano ed incaricati di analizzare i dati acquisiti
            non avranno accesso diretto al database, ma mediato tramite API di
            accesso. Gli attori coinvolti nel trattamento, unici autorizzati
            all’accesso ai dati raccolti, saranno afferenti al personale del
            Dipartimento di Elettronica, Informazione e Bioingegneria
            debitamente autorizzato.
          </p>
          <p>In sintesi, il Politecnico di Milano si impegna a:</p>
          <ul>
            <li>
              curare le fasi successive rispetto alle finalità sopraelencate, di
              elaborazione e di memorizzazione dei dati raccolti, in modo da non
              identificare direttamente gli interessati, procedendo alla
              pseudonimizzazione di tutte le informazioni;
            </li>
            <li>
              comunicare i dati e/o memorizzarli, in forma pseudonimizzata, in
              un database accessibile a tutti i partecipanti al progetto di
              ricerca;
            </li>
            <li>
              trattare i dati tramite i soli attori specificatamente designati;
            </li>
          </ul>
          <p>
            In merito all’uso della piattaforma Youtube, si rinvia alla
            specifica privacy policy qui riportata:{" "}
            <a
              href="https://www.youtube.com/intl/ALL_it/howyoutubeworks/our-commitments/protecting-user-data/#privacy-guidelines"
              target="blank"
            >
              https://www.youtube.com/intl/ALL_it/howyoutubeworks/our-commitments/protecting-user-data/#privacy-guidelines
            </a>
          </p>

          <h3>Uso dei dati personali in forma anonima</h3>
          <p>
            Si precisa che i dati saranno diffusi solo in forma rigorosamente
            anonima, ad esempio attraverso attività didattiche, pubblicazioni
            scientifiche, statistiche e convegni scientifici.
          </p>

          <h3>Ambito di circolazione dei dati</h3>
          <p>
            I dati personali saranno trattati dal Titolare all’interno del
            territorio dell’Unione Europea. Qualora per questioni di natura
            tecnica e/o operativa si renda necessario avvalersi di soggetti
            ubicati al di fuori dell’Unione Europea, oppure si renda necessario
            trasferire alcuni dei dati raccolti verso sistemi tecnici e servizi
            gestiti in cloud e localizzati al di fuori dell’area dell’Unione
            Europea, il trattamento sarà regolato in conformità a quanto
            previsto dal capo V del Regolamento UE e autorizzato in base a
            specifiche decisioni dell’Unione Europea. Saranno quindi adottate
            tutte le cautele necessarie al fine di garantire la più totale
            protezione dei dati personali, basando il trasferimento: a) su
            decisioni di adeguatezza dei Paesi terzi destinatari espresse dalla
            Commissione europea; b) su garanzie adeguate espresse dal soggetto
            terzo destinatario ai sensi dell’art. 46 del Regolamento UE; c)
            sull’adozione di norme vincolanti d’impresa, cd. Corporate binding
            rules.
          </p>

          <h3>Conservazione</h3>
          <p>
            I dati personali saranno conservati esclusivamente per il tempo
            strettamente necessario. Nello specifico:
          </p>
          <ol>
            <li>
              i dati raccolti per soddisfare la prima finalità di trattamento
              (supporto all’attività formativa) saranno conservati per il tempo
              strettamente necessario per il soddisfacimento delle attività
              previste;
            </li>
            <li>
              i dati raccolti per soddisfare la seconda finalità di trattamento
              (ricerca) saranno conservati per 5 anni, con possibilità di
              ulteriori 5 anni in formato pseudonimizzato;
            </li>
          </ol>
          <p>
            Successivamente i dati saranno resi anonimi. I dati saranno altresì
            trattati per finalità amministrative/contabili e conservati per il
            tempo strettamente necessario al perseguimento di tali finalità,
            fatto salvo il termine di dieci anni per assicurare gli adempimenti
            fiscali, contabili e amministrativi richiesti dalla legge e salvo
            eventualmente termini più lunghi, non determinabili a priori, in
            conseguenza a diverse condizioni di liceità del trattamento (ad
            esempio azioni giudiziarie che rendano necessario il trattamento per
            oltre dieci anni).
          </p>

          <h3>Esercizio dei diritti privacy</h3>
          <p>
            Lei potrà, ai sensi e per gli effetti degli artt. 15 e ss. del GDPR,
            accedere ai Suoi dati personali, verificarne contenuto, origine,
            esattezza, ubicazione (anche in relazione ai Paesi Terzi ove i dati
            si trovino e/o ai soggetti cui i Dati possono essere comunicati),
            chiederne copia, integrazione, aggiornamento, rettificazione e, nei
            casi previsti dalla Legge vigente, cancellazione, trasformazione in
            forma anonima, la limitazione, la portabilità dei dati, la revoca
            del consenso prestato ex art. 7 del GDPR; nonché proporre reclamo
            all'autorità di controllo competente ex articolo 77 del GDPR
            (Garante per la Protezione dei Dati Personali).
          </p>
          <p>
            La informiamo, inoltre, che lei potrà opporsi al trattamento dei
            suoi dati personali ai sensi dell’art. 21 del Regolamento. La
            modifica dei dati originari può avere effetti significativi sui
            risultati dello studio, per cui in caso di esercizio di diritti che
            comportano variazione/integrazione dei dati registrati, le modifiche
            richieste potranno essere annotate e registrate a margine dei dati
            originari senza modificare questi ultimi. Potrà interrompere in ogni
            momento e senza fornire alcuna giustificazione la Sua partecipazione
            alle attività didattiche supportate da Evoli, i cui dati vengono
            usati per finalità di ricerca; in tal caso, non saranno raccolti
            ulteriori dati che la riguardano ferma restando l'utilizzazione di
            quelli eventualmente già raccolti per determinare, senza alterarli,
            i risultati della ricerca. Per esercitare i Suoi diritti di privacy
            può contattare il DPO o il Titolare del trattamento ai recapiti
            sopra indicati.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicyIta;
