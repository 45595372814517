import React from "react";
import { useGetInsightsQuery } from "../../../../../store/admin/admin.api";

const useFetch = ({ days = 1 }) => {
  const [errorMessage, setErrorMessage] = React.useState(null);

  /* Query hooks */
  const { data, isError, error, isSuccess, isFetching } = useGetInsightsQuery({
    days,
  });

  //Check if there is an error
  React.useEffect(() => {
    if (isError) {
      if (!error.data?.code && !error.code) setErrorMessage("general_error");
      else setErrorMessage(error.data?.code ?? error.code);
    } else if (isSuccess) {
      setErrorMessage(null);
    }
  }, [data, isError, error, isSuccess]);

  return {
    data,
    isFetching,
    isError,
    errorMessage,
    isSuccess,
  };
};

export default useFetch;
