import React, { useState } from "react";
import Loading from "../../../../components/PageElements/Loading";
import { Col, Row } from "react-bootstrap";
import useFetch from "./hooks/useFetch";
import {
  DifficultyIcon,
  InitialKnowledgeIcon,
  UnderstandingIcon,
} from "../../../../components/Commons/FeedbackIcons";
import { useOutletContext } from "react-router-dom";
import InfoTooltip from "../../../../components/Commons/InfoTooltip";
import Styles from "./styles";
import { EvoliButton } from "../../../../styles/Forms.styles";
import useOrder from "./hooks/useOrder";
import FeedbackSummaryChart from "../../../../components/Charts/FeedbackSummaryChart";
import LessonFeedback from "../../../../components/LessonFeedback/LessonFeedback";

const ClassroomFeedback = () => {
  const { lesson } = useOutletContext();

  const [limit, setLimit] = useState(100);

  const { changeOrderHandler, orderBy, order, orderValue, options, reset } =
    useOrder();
  const { summary, isLoading, isError, errorMessage, isSuccess } = useFetch({
    lessonUUID: lesson.uuid,
  });

  const understandingScale = lesson.unit.course.understandingScale;
  const difficultyScale = lesson.unit.course.difficultyScale;
  const isDifficultyEnabled = lesson.unit.course.difficultyEnabled;
  const isInitialKnowledgeEnabled = lesson.unit.course.initialKnowledgeEnabled;

  if (isLoading) return <Loading page={false} />;
  if (isError) return <div className="text-danger fw-bold">{errorMessage}</div>;
  if (isSuccess)
    return (
      <>
        <Row className="mb-3">
          {/* Understanding */}
          <Col xs={12} sm={4}>
            <Styles.ChartContainer>
              {/* Title */}
              <div className="chart-title">
                <UnderstandingIcon className="me-1 fs-5" />
                <h2 className="fs-5">Students’ level of understanding</h2>
                <InfoTooltip
                  className="ms-1"
                  text={`The chart displays the percentages of students who have rated their understanding of the video lesson from 1 ("Completely unclear") to ${understandingScale} ("Completely clear").`}
                />
              </div>
              {/* Chart */}
              <div className="chart-wrapper">
                <FeedbackSummaryChart
                  summary={summary.understandingSummary}
                  numOfLevels={understandingScale}
                />
              </div>
              {/* Description */}
              <span>
                Scale from 1
                <strong className="text-danger"> ("Completely Unclear")</strong>
                <br />
                to {understandingScale}{" "}
                <strong className="text-success">("Completely Clear")</strong>
              </span>
            </Styles.ChartContainer>
          </Col>
          {/* Initial Knowledge */}
          {isInitialKnowledgeEnabled && (
            <Col xs={12} sm={4}>
              <Styles.ChartContainer>
                {/* Title */}
                <div className="chart-title">
                  <InitialKnowledgeIcon className="me-1 fs-5" />
                  <h2 className="fs-5">Students’ prior knowledge</h2>
                  <InfoTooltip
                    className="ms-1"
                    text={`The chart displays the percentages of students who have rated their initial knowledge of the video lesson from 1 ("Nothing") to ${understandingScale} ("Everything").`}
                  />
                </div>
                {/* Chart */}
                <div className="chart-wrapper">
                  <FeedbackSummaryChart
                    summary={summary.initialKnowledgeSummary}
                    numOfLevels={understandingScale}
                  />
                </div>
                {/* Description */}
                <span>
                  Scale from 1
                  <strong className="text-danger"> ("Nothing")</strong>
                  <br />
                  to {understandingScale}{" "}
                  <strong className="text-success">("Everything")</strong>
                </span>
              </Styles.ChartContainer>
            </Col>
          )}
          {/* Difficulty */}
          {isDifficultyEnabled && (
            <Col xs={12} sm={4}>
              <Styles.ChartContainer>
                {/* Title */}
                <div className="chart-title">
                  <DifficultyIcon className="me-1 fs-5" />
                  <h2 className="fs-5">Students’ perceived difficulty</h2>
                  <InfoTooltip
                    className="ms-1"
                    text={`The chart displays the percentages of students who have rated the difficulty of the video lesson from 1 ("Very Easy") to ${difficultyScale} ("Very Difficult").`}
                  />
                </div>
                {/* Chart */}
                <div className="chart-wrapper">
                  <FeedbackSummaryChart
                    summary={summary.difficultySummary}
                    numOfLevels={difficultyScale}
                    scaleInverted={true}
                  />
                </div>
                {/* Description */}
                <span>
                  Scale from 1
                  <strong className="text-success"> ("Very Easy")</strong>
                  <br />
                  to {difficultyScale}{" "}
                  <strong className="text-danger">("Very Difficult")</strong>
                </span>
              </Styles.ChartContainer>
            </Col>
          )}
        </Row>
        <Row>
          {/* Title */}
          <h4 className="mt-3">Comments</h4>
          <div className="d-flex flex-column flex-md-row gap-2 gap-md-3 w-100 mb-4">
            {/* Limit */}
            <div className="d-flex flex-column gap-1">
              <label>Limit</label>
              <select
                className="form-select"
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
            {/* Order by course name, teacher name, completion rate, understanding rate */}
            <div className="d-flex flex-column gap-1">
              <label>Order by</label>
              <select
                className="form-select"
                value={orderValue}
                onChange={changeOrderHandler}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            {/* Reset filter and order */}
            <div className="d-flex flex-column justify-content-end">
              <EvoliButton onClick={reset}>Reset</EvoliButton>
            </div>
          </div>
          {/* Feedback */}
          <LessonFeedback
            difficultyEnabled={isDifficultyEnabled}
            difficultyScale={difficultyScale}
            initialKnowledgeEnabled={isInitialKnowledgeEnabled}
            understandingScale={understandingScale}
            lessonUUID={lesson.uuid}
            limit={limit}
            order={order}
            orderBy={orderBy}
          />
        </Row>
      </>
    );
};

export default ClassroomFeedback;
