import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

/**
 * Shows a confirm alert when the user wants to delete a course
 * @param {Function} callback - the callback function
 * @param {Function} t - the translation function
 */
export function onDeleteCourse(callback, t) {
  MySwal.fire({
    title: "Are you sure?",
    text: "You are about to delete the entire course with its units and lessons. You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    cancelButtonText: "Cancel",
    confirmButtonText: "I'm sure",
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
}

/**
 * Shows a confirm alert when the user wants to delete a unit
 * @param {Function} callback - the callback function
 * @param {Function} t - the translation function
 */
export function onDeleteUnit(callback, t) {
  MySwal.fire({
    title: "Are you sure?",
    text: "You are about to delete the unit with its lessons. You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    cancelButtonText: "Cancel",
    confirmButtonText: "I'm sure",
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
}

/**
 * Shows a confirm alert when the user wants to delete a lesson
 * @param {Function} callback - the callback function
 * @param {Function} t - the translation function
 */
export function onDeleteLesson(callback, t) {
  MySwal.fire({
    title: "Are you sure?",
    text: "You are about to delete the lesson. You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    cancelButtonText: "Cancel",
    confirmButtonText: "I'm sure",
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
}

export function onUnenrollFromCourse(callback, t) {
  MySwal.fire({
    title: t("sure"),
    text: t("sure_sub2"),
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    cancelButtonText: t("cancel_alert"),
    confirmButtonText: t("confirm_alert"),
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
}

export function onDeleteMessage(uuid, callback, t) {
  MySwal.fire({
    title: t("sure"),
    text: t("sure_sub"),
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    cancelButtonText: t("cancel_alert"),
    confirmButtonText: t("confirm_alert"),
  }).then((result) => {
    if (result.isConfirmed) {
      callback("chat:delete", uuid);
    }
  });
}

export function confirmDeleteQuestion({ translationFunction, callback }) {
  MySwal.fire({
    title: translationFunction("sure"),
    text: translationFunction("sure_sub"),
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    cancelButtonText: translationFunction("cancel_alert"),
    confirmButtonText: translationFunction("confirm_alert"),
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
      //callback('feedback:question:delete', uuid);
    }
  });
}

export function onDeleteChapter(chapter, callback, t) {
  MySwal.fire({
    title: t("sure"),
    text: t("sure_sub", { name: chapter.name }),
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    cancelButtonText: t("cancel_alert"),
    confirmButtonText: t("confirm_alert"),
  }).then((result) => {
    if (result.isConfirmed) {
      callback("chapters/deleteChapter", { uuid: chapter.uuid });
    }
  });
}
