import styled from "styled-components";

const Styles = {};

Styles.HeaderSection = styled.div`
  position: relative;
  background-color: ${(p) => p.theme.secondary};
  color: ${(p) => p.theme.textPrimary};
  padding-top: 2rem;
  padding-bottom: 4rem;

  .course-title {
    font-size: 2rem;
    font-weight: 700;
  }

  .course-brief-description {
    font-size: 1.2rem;
    font-weight: 400;
  }

  .menu {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0;
    font-size: 1.2rem;
  }

  .menu-item {
    font-weight: 500;
    cursor: pointer;
    padding-bottom: 0.5rem;
    margin-bottom: 0;

    &.active {
      border-bottom: 0.3rem solid ${(props) => props.theme.textPrimary};
      padding-bottom: 0.2rem;
    }
  }
`;

export default Styles;
