import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { EvoliButton } from "../../../../styles/Forms.styles";
import { PasswordField } from "../../../../components/Commons/Form";
import { te } from "../../../../utils/errorsTranslation";
import * as FormStyles from "../../../../styles/Forms.styles";
import { useTranslation } from "react-i18next";
import {
  useDisconnectFromAllDevicesMutation,
  useUpdatePasswordMutation,
} from "../../../../store/auth/auth.api";
import { toastSuccess } from "../../../../utils/Toast";
import { getErrorMessageTranslation } from "../../../../utils/lecture.utils";
import Loading from "../../../../components/PageElements/Loading";
import { useDispatch } from "react-redux";
import { logOut } from "../../../../store/auth/auth.slice";

const Security = () => {
  const dispatch = useDispatch();

  const [updatePasswordErrorMsg, setUpdatePasswordErrorMsg] = useState("");

  const [updatePassword, { isLoading: isLoading1 }] =
    useUpdatePasswordMutation();
  const [disconnectFromAllDevices, { isLoading: isLoading2 }] =
    useDisconnectFromAllDevicesMutation();

  const isLoading = isLoading1 || isLoading2;

  /* Handler for the change password action */
  const updatePasswordHandler = (oldPassword, newPassword) => {
    updatePassword({ oldPassword, newPassword })
      .unwrap()
      .then((_) => {
        toastSuccess("Password changed correctly");
        setUpdatePasswordErrorMsg("");
      })
      .catch((err) => {
        setUpdatePasswordErrorMsg(getErrorMessageTranslation(err));
      });
  };

  const disconnectHandler = () => {
    disconnectFromAllDevices()
      .unwrap()
      .then((_) => {
        toastSuccess("Disconnected from all devices");
        dispatch(logOut());
      });
  };

  if (isLoading) return <Loading />;
  return (
    <Container className="py-5">
      <Row>
        <Col>
          <p>
            If you think someone else is using your account, you should change
            your password and log out of all devices.
          </p>
          <UpdatePasswordForm
            updatePasswordHandler={updatePasswordHandler}
            updatePasswordErrorMsg={updatePasswordErrorMsg}
            setUpdatePasswordErrorMsg={setUpdatePasswordErrorMsg}
          />
          <EvoliButton
            variant="warning"
            size="lg"
            className="mt-3"
            onClick={disconnectHandler}
          >
            Disconnect my account from all devices
          </EvoliButton>
        </Col>
      </Row>
    </Container>
  );
};

const UpdatePasswordForm = ({
  updatePasswordHandler,
  updatePasswordErrorMsg,
  setUpdatePasswordErrorMsg,
}) => {
  /* Translator function */
  const { t } = useTranslation("", { keyPrefix: "auth.profile" });

  /* Component state */
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //Submit form handler
  const submitHandler = (e) => {
    //Prevent page refresh
    e.preventDefault();

    //Check if the passwords match
    if (password !== confirmPassword && password.trim().length > 0)
      return setUpdatePasswordErrorMsg(te("passwords_match"));

    //Call the change password handler function
    updatePasswordHandler(oldPassword, password);

    //Reset the state
    setOldPassword("");
    setPassword("");
    setConfirmPassword("");
  };

  return (
    <Form onSubmit={submitHandler}>
      <Form.Group className="mb-4">
        <FormStyles.FormLabel dark htmlFor="profile-old-password">
          {t("old_password")}
        </FormStyles.FormLabel>
        <PasswordField
          id="profile-old-password"
          className="fs-4"
          type="password"
          placeholder={t("old_password")}
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <FormStyles.FormLabel dark htmlFor="profile-new-password">
          {t("new_password")}
        </FormStyles.FormLabel>
        <PasswordField
          id="profile-new-password"
          className="fs-4"
          type="password"
          placeholder={t("new_password")}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <FormStyles.FormLabel dark htmlFor="profile-confirm-new-password">
          {t("confirm_password")}
        </FormStyles.FormLabel>
        <PasswordField
          id="profile-confirm-new-password"
          className="fs-4"
          type="password"
          placeholder={t("confirm_password")}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Form.Group>
      <FormStyles.EvoliButton type="submit" className="mt-4">
        {t("change_password")}
      </FormStyles.EvoliButton>
      {updatePasswordErrorMsg && (
        <FormStyles.Error className="mt-3">
          {updatePasswordErrorMsg}
        </FormStyles.Error>
      )}
    </Form>
  );
};

export default Security;
