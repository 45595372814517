import { apiSlice } from "../api/api.slice";

export const analyticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createLog: builder.mutation({
      query: ({ visualizationUUID, type, data, data2 = null }) => {
        const body = { visualizationUUID, type, data };
        if (data2 !== null && data2 !== undefined && !isNaN(data2))
          body.data2 = data2;
        return {
          url: `/analytics/log`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [
        { type: "Visualizations", id: "Info" },
        { type: "VideoIntervals", id: "LIST" },
        { type: "VideoIntervals", id: "USER" },
      ],
    }),
    getVisualizationInfo: builder.query({
      query: (lessonUUID) => ({
        url: `/analytics/visualization-info/${lessonUUID}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: [{ type: "Visualizations", id: "Info" }],
    }),
    getVisualizations: builder.query({
      query: ({ lessonUUID, from, to }) => ({
        url: `/analytics/visualizations/${lessonUUID}/${encodeURIComponent(
          from
        )}/${encodeURIComponent(to)}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: [{ type: "Visualizations", id: "LIST" }],
    }),
    getUserVideoAnalytics: builder.query({
      query: (lessonUUID) => ({
        url: `/analytics/user-video-analytics/${lessonUUID}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: [{ type: "VideoIntervals", id: "USER" }],
    }),
    getStudentVideoAnalytics: builder.query({
      query: ({ lessonUUID, studentEmail }) => ({
        url: `/analytics/student-video-analytics/${lessonUUID}/${studentEmail}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
    }),
    getVideoIntervals: builder.query({
      query: (lessonUUID) => ({
        url: `/analytics/video-intervals/${lessonUUID}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
      providesTags: [{ type: "VideoIntervals", id: "LIST" }],
    }),
    getVideoPauses: builder.query({
      query: (lessonUUID) => ({
        url: `/analytics/video-pauses/${lessonUUID}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
    }),
    getStudentInfo: builder.query({
      query: ({
        lessonUUID,
        limit = 12,
        offset = 0,
        orderBy = "lastName",
        order = "ASC",
        searchQuery = null,
      }) => ({
        url: `/analytics/students-info/${lessonUUID}/${limit}/${offset}/${orderBy}/${order}${
          searchQuery ? "/" + searchQuery : ""
        }`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
    }),
    getUnitStudentsInfo: builder.query({
      query: ({
        unitUUID,
        limit = 12,
        offset = 0,
        orderBy = "lastName",
        order = "ASC",
        searchQuery = null,
      }) => ({
        url: `/analytics/unit-students-info/${unitUUID}/${limit}/${offset}/${orderBy}/${order}${
          searchQuery ? "/" + searchQuery : ""
        }`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
    }),
    getUnitVisualizators: builder.query({
      query: (unitUUID) => ({
        url: `/analytics/unit-visualizators/${unitUUID}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useCreateLogMutation,
  useGetVisualizationInfoQuery,
  useGetUserVideoAnalyticsQuery,
  useGetStudentVideoAnalyticsQuery,
  useGetVideoIntervalsQuery,
  useGetVisualizationsQuery,
  useGetVideoPausesQuery,
  useGetStudentInfoQuery,
  useGetUnitStudentsInfoQuery,
  useGetUnitVisualizatorsQuery,
} = analyticsApiSlice;
