import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Form, InputGroup } from "react-bootstrap";
import Reactions from "./components/reactions/Reactions";
import Answer from "./components/answer/Answer";
import { selectCurrentUser } from "../../store/auth/auth.slice";
import { confirmDeleteQuestion } from "../../utils/Alert";
import { seekTo } from "../../store/media/media.slice";
import { toHHMMSS } from "../../utils/lecture.utils";
import { EvoliButton } from "../../styles/Forms.styles";
import Styles from "./styles";
import useMutation from "./hooks/useMutation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faReply,
  faTrash,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

const QuestionItem = ({
  question,
  lesson,
  playerActive = true,
  clickVideoCallback = null,
  showLessonInfo = false,
}) => {
  const { t } = useTranslation("", { keyPrefix: "components.chat" });

  const {
    handlers: { createAnswerHandler, deleteQuestionHandler, reactionHandler },
  } = useMutation();

  //Component state
  const [showAnswers, setShowAnswers] = useState(false);
  const [showAnswerForm, setShowAnswerForm] = useState(false);

  //Redux state
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  //Desctructure question
  const {
    uuid,
    text,
    second,
    updatedAt,
    user: { lastName, firstName },
    reactions,
    user,
    children,
  } = question;

  const getReactionValues = () => {
    const values = new Array(5)
      .fill(0)
      .map(() => ({ value: 0, userReacted: false }));

    Object.values(reactions || []).forEach((reaction) => {
      values[reaction.value - 5].value += 1;

      if (reaction.user.email === currentUser.email)
        values[reaction.value - 5].userReacted = true;
    });
    return values;
  };

  const clickOnDeleteHandler = () => {
    confirmDeleteQuestion({
      translationFunction: t,
      callback: () => deleteQuestionHandler(uuid),
    });
  };

  const clickOnVideoSecondsHandler = () => {
    if (clickVideoCallback) clickVideoCallback(second);
    else dispatch(seekTo(second));
  };

  return (
    <Styles.Question>
      {showLessonInfo && (
        <div className="d-flex flex-row gap-2 mb-2">
          {lesson.unit && (
            <div className="d-flex flex-row gap-1">
              <span className="fw-bold">Unit: </span>
              <span>{lesson.unit.name}</span>
            </div>
          )}
          <div className="d-flex flex-row gap-1">
            <span className="fw-bold">Lesson: </span>
            <span>{lesson.name}</span>
          </div>
        </div>
      )}
      <div className="d-flex flex-row justify-content-between align-items-center">
        <span className="fw-bold">{`${lastName} ${firstName}`}</span>
        <span>
          {new Date(updatedAt).toLocaleString([], {
            dateStyle: "short",
            timeStyle: "short",
          })}
        </span>
      </div>
      <span>{text}</span>
      <div className="d-flex flex-row justify-content-between align-items-center mt-4 mx-2 gap-2">
        {/* Reactions on the left */}
        <Reactions
          message={question}
          values={getReactionValues()}
          reactionHandler={reactionHandler}
        />

        <div className="d-flex flex-row gap-2">
          {children.length > 0 && (
            <EvoliButton onClick={() => setShowAnswers((v) => !v)}>
              <FontAwesomeIcon
                className="me-1"
                icon={showAnswers ? faEyeSlash : faEye}
              />
              {showAnswers
                ? "Hide answers"
                : t("show_answers", { count: children.length })}
            </EvoliButton>
          )}
          {playerActive && (
            <EvoliButton variant="warning" onClick={clickOnVideoSecondsHandler}>
              <FontAwesomeIcon className="me-1" icon={faVideo} />
              Show video ({toHHMMSS(second - lesson.startSecond)})
            </EvoliButton>
          )}
          <EvoliButton onClick={() => setShowAnswerForm((s) => !s)}>
            <FontAwesomeIcon className="me-1" icon={faReply} />
            Reply
          </EvoliButton>
          {user.email === currentUser.email && (
            <EvoliButton
              variant="danger"
              onClick={() => clickOnDeleteHandler()}
            >
              <FontAwesomeIcon className="me-1" icon={faTrash} />
              Delete
            </EvoliButton>
          )}
        </div>
      </div>
      {showAnswerForm && (
        <CreateAnswerForm
          parent={question}
          sendAnswerHandler={createAnswerHandler}
          close={() => setShowAnswerForm(false)}
        />
      )}

      {children.length > 0 && showAnswers && (
        <div className="answers-list">
          {children.map((answer, index) => (
            <Answer
              key={answer.uuid}
              answer={answer}
              isFirst={index === 0}
              reactionHandler={reactionHandler}
              deleteQuestionHandler={deleteQuestionHandler}
            />
          ))}
        </div>
      )}
    </Styles.Question>
  );
};

const CreateAnswerForm = ({ parent, sendAnswerHandler, close }) => {
  //Component state
  const [reply, setReply] = useState("");
  const formRef = useRef(null);

  const clickSendHandler = (e) => {
    e.preventDefault();

    //Limit text to 255 characters
    const text = reply.trim().substring(0, 255);

    if (text.trim().length > 0 && parent) {
      sendAnswerHandler(parent, text);
      formRef.current.reset();
      close();
    }
  };

  return (
    <div className="d-flex flex-column gap-1 mt-3">
      <Form ref={formRef} onSubmit={clickSendHandler}>
        <InputGroup>
          <EvoliButton variant="danger" onClick={close}>
            <i className="fas fa-times" />
          </EvoliButton>
          <Form.Control
            className=""
            type="text"
            placeholder="Write an answer..."
            onChange={(e) => setReply(e.target.value)}
          />
          <EvoliButton type="submit">
            <i className="fas fa-paper-plane" />
          </EvoliButton>
        </InputGroup>
      </Form>
    </div>
  );
};

export default QuestionItem;
