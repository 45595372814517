import styled from "styled-components";

const Styles = {};

Styles.RoleSelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  border: 2px solid ${({ theme }) => theme.secondary};
  background-color: ${(p) => p.theme.textPrimary};

  span {
    width: 50%;
    padding: 0.5rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    color: ${({ theme }) => theme.primary};
    cursor: pointer;

    &.active {
      background-color: ${({ theme }) => theme.accent};
      border-radius: 30px;
      color: ${({ theme }) => theme.textPrimary};
    }
  }
`;

export default Styles;
