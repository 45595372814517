import styled from "styled-components";

const Styles = {};

Styles.Settings = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  color: ${(props) => props.theme.primary};
  padding: 2rem 1rem 1rem 1rem;
`;

export default Styles;
