import { useEffect, useState } from "react";
import { useGetClassroomFeedbackQuery } from "../../../store/feedback/feedback.api";
import { te } from "../../../utils/errorsTranslation";

const useFetch = ({
  lessonUUID,
  limit = 100,
  offset = 0,
  order = "DESC",
  orderBy = "updatedAt",
}) => {
  //Query hook
  const { data, isLoading, isError, error, isSuccess } =
    useGetClassroomFeedbackQuery({
      lessonUUID,
      limit,
      offset,
      order,
      orderBy,
    });

  //Component state
  const [errorMessage, setErrorMessage] = useState(null);

  //Check if there is an error
  useEffect(() => {
    if (isError) {
      if (!error.data?.code && !error.code)
        setErrorMessage(te("general_error"));
      else setErrorMessage(te(error.data?.code ?? error.code));
    } else if (isSuccess) {
      setErrorMessage(null);
    }
  }, [isError, error, isSuccess]);

  return {
    feedback: data?.rows ?? [],
    count: data?.count ?? 0,
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  };
};

export default useFetch;
