import React, { useEffect, useState } from "react";

import { te } from "date-fns/locale";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import { useGetUnitQuestionsQuery } from "../../../../store/feedback/feedback.api";

import Loading from "../../../../components/PageElements/Loading";
import QuestionItem from "../../../../components/Question/Question";
import VideoModal from "../../../../components/QuestionModal/VideoModal";

const UnitQuestions = () => {
  const { unit } = useOutletContext();

  const {
    data: unitData,
    isLoading: isLoading1,
    isError,
    error,
  } = useGetUnitQuestionsQuery(unit?.uuid);

  const [errorMessage, setErrorMessage] = useState(null);
  const [unitWithQuestions, setUnitWithQuestions] = useState(null);

  //Check if there is an error
  useEffect(() => {
    if (isError) {
      if (!error.data?.code && !error.code)
        setErrorMessage(te("general_error"));
      else setErrorMessage(te(error.data?.code ?? error.code));
    } else if (unitData) {
      setErrorMessage(null);
      setUnitWithQuestions(unitData);
    }
  }, [unitData, isError, error]);

  const isLoading = isLoading1;
  if (isLoading) return <Loading page={false} />;
  if (isError) return <div className="text-danger fw-bold">{errorMessage}</div>;
  if (unitWithQuestions)
    return <QuestionsAccordion lessons={unitWithQuestions.lessons} />;
};

const QuestionsAccordion = ({ lessons }) => {
  const defaultActiveKey = lessons.map((lesson) => lesson.uuid);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoSecond, setVideoSecond] = React.useState(0);
  const [lesson, setLesson] = React.useState(null);

  const clickVideoCallback = (second, lesson) => {
    setLesson(lesson);
    setVideoSecond(second);
    setShowVideoModal(true);
  };

  return (
    <Container className="py-5">
      <Row>
        <Col>
          <h2>Unit questions</h2>
          <p className="mb-4">
            Help your student by answering questions about the unit. Click on
            the video icon to watch the video section the question is about.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Accordion alwaysOpen defaultActiveKey={defaultActiveKey}>
            {lessons.map((lesson) => (
              <Accordion.Item eventKey={lesson.uuid} key={lesson.uuid}>
                <Accordion.Header>{lesson.name}</Accordion.Header>
                <Accordion.Body>
                  {/* If there are not questions, show a message */}
                  {lesson.taggedQuestions.length === 0 ? (
                    <div className="text-center">
                      There are no questions yet.
                    </div>
                  ) : (
                    <div className="d-flex flex-column gap-4">
                      {lesson.taggedQuestions.map((question) => (
                        <QuestionItem
                          key={question.uuid}
                          question={question}
                          lesson={lesson}
                          playerActive={true}
                          clickVideoCallback={() =>
                            clickVideoCallback(question.second, lesson)
                          }
                        />
                      ))}
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))}
            {/* Video modal */}
            {showVideoModal && (
              <VideoModal
                show={showVideoModal}
                onHide={() => setShowVideoModal(false)}
                lesson={lesson}
                startSecond={videoSecond}
              />
            )}
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default UnitQuestions;
