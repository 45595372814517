import React, { useState } from "react";
import Loading from "../../../../components/PageElements/Loading";
import { Pagination } from "react-bootstrap";
import styled from "styled-components";
import useFetch from "./hooks/useFetch";
import QuestionItem from "../../../../components/Question/Question";
import { useNavigate, useOutletContext } from "react-router-dom";
import QuestionScatterPlot from "./components/QuestionsScatterPlot";

const LessonQuestionsList = () => {
  const { lesson } = useOutletContext();

  const navigate = useNavigate();

  // Pagination state
  const [page, setPage] = useState(1);
  const limit = 12;
  const offset = (page - 1) * limit;

  //Fetch hook
  const {
    data: { questions, itemsCount },
    isLoading,
    isError,
    errorMessage,
    isSuccess,
  } = useFetch({ lessonUUID: lesson.uuid, limit, offset });

  const pages = Math.ceil(itemsCount / limit);

  const changePageHandler = (pageValue) => {
    if (pageValue >= 1 && pageValue <= pages) setPage(pageValue);
  };

  const clickVideoCallback = (second) => {
    navigate(`/teacher/lesson/${lesson.uuid}/video/${second}`);
  };

  if (isError) return <div className="text-danger fw-bold">{errorMessage}</div>;
  if (isLoading) return <Loading page={false} />;
  if (isSuccess && questions.length === 0)
    return (
      <Styles.Container>
        <div className="fst-italic">No questions yet.</div>
      </Styles.Container>
    );
  if (isSuccess)
    return (
      <Styles.Container>
        {/* Timebar scatter plot */}
        <QuestionScatterPlot
          start={lesson.startSecond}
          end={lesson.endSecond}
          questions={questions}
        />
        {/* Questions list */}
        <div className="title">Questions</div>
        <Styles.QuestionList>
          {questions.map((question) => (
            <QuestionItem
              key={question.uuid}
              question={question}
              lesson={lesson}
              clickVideoCallback={clickVideoCallback}
            />
          ))}
        </Styles.QuestionList>
        {/* Pagination section, show 12 items */}
        <Styles.PaginationContainer>
          {/* [start index]-[end index] of [count] courses  */}
          <div className="d-flex flex-row justify-content-center">
            <span className="fs-6 fst-italic">
              {itemsCount === 0 ? 0 : page * limit - limit + 1}-
              {page * limit > itemsCount ? itemsCount : page * limit} of{" "}
              {itemsCount} questions
            </span>
          </div>
          {/* Pagination */}
          <div className="d-flex flex-row justify-content-center">
            <Pagination>
              {page === 1 ? (
                <Pagination.First disabled />
              ) : (
                <Pagination.First onClick={() => changePageHandler(1)} />
              )}
              {page > 1 && (
                <Pagination.Item onClick={() => changePageHandler(page - 1)}>
                  {page - 1}
                </Pagination.Item>
              )}
              <Pagination.Item active>{page}</Pagination.Item>
              {page < pages && (
                <Pagination.Item onClick={() => changePageHandler(page + 1)}>
                  {page + 1}
                </Pagination.Item>
              )}
              {page < pages ? (
                <Pagination.Last onClick={() => changePageHandler(pages)} />
              ) : (
                <Pagination.Last disabled />
              )}
            </Pagination>
          </div>
          {/* End of pagination */}
        </Styles.PaginationContainer>
      </Styles.Container>
    );
};

export default LessonQuestionsList;

const Styles = {};

Styles.Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 0.5rem;
  padding: 1rem 1rem 1rem 1rem;

  .title {
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

Styles.QuestionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

Styles.Answer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  border-top: 1px solid ${(p) => p.theme.primary};

  .when {
    font-size: 0.8rem;
  }
`;

Styles.PaginationContainer = styled.div`
  min-height: 5rem;
  max-height: 5rem;
  overflow: hidden;
`;
