import React, { useState, useRef } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../components/PageElements/Navbar";
import * as SharedStyles from "../../../styles/Shared.styles";
import * as FormStyles from "../../../styles/Forms.styles";
import Loading from "../../../components/PageElements/Loading";
import { PasswordField } from "../../../components/Commons/Form";
import { getErrorMessageTranslation } from "../../../utils/lecture.utils";
import { useRegisterMutation } from "../../../store/auth/auth.api";
import PageContainer from "../../../components/PageElements/PageContainer";
import Header from "../../../components/PageElements/Header";
import Footer from "../../../components/PageElements/Footer";
import BackToTop from "../../../components/PageElements/BackToTop";

const RegisterPage = () => {
  /* Translator hook */
  const { t } = useTranslation("", { keyPrefix: "auth.register" });
  /* Redirect hook */
  const navigate = useNavigate();

  /* Queries */
  const [register, { isLoading: isRegistering, isSuccess: isRegistered }] =
    useRegisterMutation();

  /* Component state */
  const [registrationErrorMsg, setRegistrationErrorMsg] = useState("");
  const formRef = useRef(null);
  const errorRef = useRef(null);

  const registerHandler = (
    email,
    password,
    firstName,
    lastName,
    privacyAccepted
  ) => {
    register({ email, password, firstName, lastName, privacyAccepted })
      .unwrap()
      .then(() => {
        setRegistrationErrorMsg("");
        formRef.current?.reset();
      })
      .catch((err) => {
        setRegistrationErrorMsg(getErrorMessageTranslation(err));
        errorRef.current?.scrollIntoView();
      });
  };

  if (isRegistering) return <Loading page={true} />;
  return (
    <>
      <Navbar dark={true} />
      <PageContainer>
        <Header>
          <Container>
            <Header.Title>Registration</Header.Title>
          </Container>
        </Header>
        <Container className="py-5">
          <Row>
            {isRegistered ? (
              <Col
                lg={12}
                sm={12}
                className="d-flex flex-column justify-content-center text-center"
              >
                <SharedStyles.Title className="fs-1 fw-bolder mb-4 p-2 text-uppercase">
                  {t("success_title")}
                </SharedStyles.Title>
                <SharedStyles.Paragraph>
                  {t("success_message")}
                </SharedStyles.Paragraph>
                <div className="d-flex justify-content-center">
                  <FormStyles.EvoliButton onClick={() => navigate("/login")}>
                    {t("sign_in")}
                  </FormStyles.EvoliButton>
                </div>
              </Col>
            ) : (
              <Col lg={6} sm={12}>
                <RegisterForm
                  registerHandler={registerHandler}
                  setRegistrationErrorMsg={setRegistrationErrorMsg}
                  registrationErrorMsg={registrationErrorMsg}
                  formRef={formRef}
                  errorRef={errorRef}
                />
              </Col>
            )}
          </Row>
        </Container>
      </PageContainer>
      <Footer />
      <BackToTop />
    </>
  );
};

const RegisterForm = ({
  registerHandler,
  setRegistrationErrorMsg,
  registrationErrorMsg,
  formRef,
  errorRef,
}) => {
  /* Translator hook */
  const { t } = useTranslation("", { keyPrefix: "auth.register" });

  /* Component state */
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [researchPurposeAccepted, setResearchPurposeAccepted] = useState(false);

  const checkboxesAccepted = privacyAccepted && researchPurposeAccepted;

  /* Submit handler */
  const submitHandler = (e) => {
    // Prevent default form submission
    e.preventDefault();

    // Check if passwords match
    if (password !== confirmPassword) {
      setRegistrationErrorMsg(t("error1"));
      errorRef.current?.scrollIntoView();
    } else if (!email.includes("@") || !email.includes(".")) {
      //Check if email contains @ and .
      setRegistrationErrorMsg(t("error4"));
      errorRef.current?.scrollIntoView();
    } else if (email !== confirmEmail) {
      // Check if emails match
      setRegistrationErrorMsg(t("error2"));
      errorRef.current?.scrollIntoView();
    } else if (password.length < 8) {
      // Check if password is at least 8 characters long
      setRegistrationErrorMsg(t("error3"));
      errorRef.current?.scrollIntoView();
    } else if (!checkboxesAccepted) {
      setRegistrationErrorMsg("Please accept both checkboxes to register.");
      errorRef.current?.scrollIntoView();
    } else {
      //Try to register
      registerHandler(email, password, firstName, lastName, checkboxesAccepted);
    }
  };

  return (
    <Form
      ref={formRef}
      className="p-2"
      onSubmit={submitHandler}
      id="register-form"
    >
      <Form.Group className="mb-3">
        <FormStyles.FormLabel htmlFor="register-first-name">
          {t("first_name")}
        </FormStyles.FormLabel>
        <FormStyles.FormControl
          required
          id="register-first-name"
          placeholder={t("first_name")}
          className="fs-5"
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <FormStyles.FormLabel htmlFor="register-last-name">
          {t("last_name")}
        </FormStyles.FormLabel>
        <FormStyles.FormControl
          required
          id="register-last-name"
          placeholder={t("last_name")}
          className=" fs-5"
          onChange={(e) => setLastName(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <FormStyles.FormLabel htmlFor="register-email">
          {t("email")}
        </FormStyles.FormLabel>
        <FormStyles.FormControl
          required
          id="register-email"
          type="email"
          placeholder={t("email")}
          className="fs-5"
          onChange={(e) => setEmail(e.target.value)}
        />{" "}
      </Form.Group>
      <Form.Group className="mb-3">
        <FormStyles.FormLabel htmlFor="register-confirm-email">
          {t("confirm_email")}
        </FormStyles.FormLabel>
        <FormStyles.FormControl
          required
          id="registrer-confirm-email"
          type="email"
          placeholder={t("confirm_email")}
          className="fs-5"
          // disable on paste in confirm email to prevent user from accidentally pasting in the wrong email
          onPaste={(e) => e.preventDefault()}
          onChange={(e) => setConfirmEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <FormStyles.FormLabel htmlFor="register-password">
          {t("password")}
        </FormStyles.FormLabel>
        <PasswordField
          required
          id="register-password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <FormStyles.FormLabel htmlFor="register-confirm-password">
          {t("confirm_password")}
        </FormStyles.FormLabel>
        <PasswordField
          required
          id="register-confirm-password"
          // not disabling on paste in confirm password to allow user to paste complex passwords from password managers
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Form.Group>
      {/* Add checkbox for the privacy policy */}
      <Form.Check
        type="checkbox"
        checked={privacyAccepted}
        onChange={() => setPrivacyAccepted((c) => !c)}
        className="mb-3"
        label={
          <span>
            I have read and agree with the{" "}
            <Link to="/privacy" target="blank">
              Privacy Policy
            </Link>
          </span>
        }
        required
      />
      {/* Add checkbox for the research purpose */}
      <Form.Check
        type="checkbox"
        checked={researchPurposeAccepted}
        onChange={() => setResearchPurposeAccepted((c) => !c)}
        className="mb-3"
        label="I agree that my data are used for scientific research purposes"
        required
      />
      {/* Error message */}
      {registrationErrorMsg && (
        <FormStyles.Error ref={errorRef} className="mt-3">
          {registrationErrorMsg}
        </FormStyles.Error>
      )}
      <FormStyles.EvoliButton type="submit" className="mt-3 w-100 fs-5">
        {t("register")}
      </FormStyles.EvoliButton>
      <p className="w-100 text-center mt-1 mb-0">
        {t("already_account")} <Link to="/login">{t("sign_in")}</Link>
      </p>
    </Form>
  );
};

export default RegisterPage;
