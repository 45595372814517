import {
  useCreateUnitMutation,
  useCreateVideoLessonMutation,
  useDeleteCourseMutation,
  useDeleteLessonMutation,
  useDeleteUnitMutation,
  useSwapLessonsMutation,
  useSwapUnitsMutation,
  useUpdateCourseMutation,
  useUpdateLessonMutation,
  useUpdateUnitMutation,
} from "../../../../../store/teachings/teachings.api";
import { useNavigate } from "react-router-dom";
import {
  onDeleteCourse,
  onDeleteLesson,
  onDeleteUnit,
} from "../../../../../utils/Alert";
import { toastApiError } from "../../../../../utils/lecture.utils";
import { toastError, toastSuccess } from "../../../../../utils/Toast";

const useFetch = ({ course }) => {
  const navigate = useNavigate();

  /* Queries */
  const [createUnit, { isLoading: isLoading1 }] = useCreateUnitMutation();
  const [createVideoLesson, { isLoading: isLoading2 }] =
    useCreateVideoLessonMutation();
  const [updateCourse, { isLoading: isLoading3 }] = useUpdateCourseMutation();
  const [updateUnit, { isLoading: isLoading4 }] = useUpdateUnitMutation();
  const [updateLesson, { isLoading: isLoading5 }] = useUpdateLessonMutation();
  const [deleteCourse, { isLoading: isLoading6 }] = useDeleteCourseMutation();
  const [deleteUnit, { isLoading: isLoading7 }] = useDeleteUnitMutation();
  const [deleteLesson, { isLoading: isLoading8 }] = useDeleteLessonMutation();
  const [swapLessons, { isLoading: isLoading9 }] = useSwapLessonsMutation();
  const [swapUnits, { isLoading: isLoading10 }] = useSwapUnitsMutation();

  const isLoading =
    isLoading1 ||
    isLoading2 ||
    isLoading3 ||
    isLoading4 ||
    isLoading5 ||
    isLoading6 ||
    isLoading7 ||
    isLoading8 ||
    isLoading9 ||
    isLoading10;

  const createUnitHandler = (params, callback) => {
    createUnit(params)
      .unwrap()
      .then((unit) => {
        if (callback) callback();
        toastSuccess(`Unit ${unit.name} created successfully`);
      })
      .catch((err) => {
        toastApiError(err);
        navigate("/teacher/home/courses");
      });
  };

  const createLessonHandler = (params, callback) => {
    createVideoLesson(params)
      .unwrap()
      .then((lesson) => {
        if (callback) callback();
        toastSuccess(`Lesson ${lesson.name} created successfully`);
      })
      .catch((err) => {
        toastApiError(err);
        navigate("/teacher/home/courses");
      });
  };

  const swapLessonsHandler = (lesson, direction = "up") => {
    swapLessons({
      courseUUID: course.uuid,
      lessonUUID: lesson.uuid,
      direction,
    })
      .unwrap()
      .then(() => toastSuccess(`Lesson ${lesson.name} moved successfully`))
      .catch((err) => {
        toastApiError(err);
        navigate("/teacher/home/courses");
      });
  };

  const swapUnitsHandler = (unit, direction = "up") => {
    swapUnits({
      courseUUID: course.uuid,
      unitUUID: unit.uuid,
      direction,
    })
      .unwrap()
      .then(() => toastSuccess(`Unit ${unit.name} moved successfully`))
      .catch((err) => {
        toastApiError(err);
        navigate("/teacher/home/courses");
      });
  };

  const updateCourseHandler = ({ name, description, color }, callback) => {
    updateCourse({
      uuid: course.uuid,
      name,
      description,
      color,
    })
      .unwrap()
      .then(() => {
        if (callback) callback();
        toastSuccess("Course updated successfully");
      })
      .catch((err) => {
        toastError("Error updating course");
        navigate("/teacher/home/courses");
      });
  };

  const updateUnitHandler = ({ unitUUID, name }, callback) => {
    updateUnit({
      unitUUID,
      courseUUID: course.uuid,
      name,
    })
      .unwrap()
      .then(() => {
        if (callback) callback();
        toastSuccess("Unit updated successfully");
      })
      .catch((err) => {
        toastError("Error updating unit");
        navigate("/teacher/home/courses");
      });
  };

  const updateLessonHandler = ({ lessonUUID, name }, callback) => {
    updateLesson({
      lessonUUID: lessonUUID,
      courseUUID: course.uuid,
      name,
    })
      .unwrap()
      .then(() => {
        if (callback) callback();
        toastSuccess("Lesson updated successfully");
      })
      .catch((err) => {
        toastError("Error updating lesson");
        navigate("/teacher/home/courses");
      });
  };

  const deleteCourseHandler = () => {
    const callback = () => {
      deleteCourse(course.uuid)
        .unwrap()
        .then(() => {
          navigate("/teacher/home/courses");
          toastSuccess("Course deleted successfully");
        })
        .catch((err) => {
          toastError("Error while deleting course");
          navigate("/teacher/home/courses");
        });
    };

    onDeleteCourse(callback);
  };

  const deleteUnitHandler = (unitUUID) => {
    const callback = () => {
      deleteUnit({
        courseUUID: course.uuid,
        unitUUID,
      })
        .unwrap()
        .then(() => {
          toastSuccess("Unit deleted successfully");
        })
        .catch((err) => {
          toastError("Error while deleting unit");
          navigate("/teacher/home/courses");
        });
    };

    onDeleteUnit(callback);
  };

  const deleteLessonHandler = (lesson) => {
    const callback = () => {
      deleteLesson({
        courseUUID: course.uuid,
        lessonUUID: lesson.uuid,
      })
        .unwrap()
        .then(() => {
          toastSuccess(`Lesson ${lesson.name} deleted successfully`);
        })
        .catch((err) => {
          toastApiError(err);
          navigate("/teacher/home/courses");
        });
    };

    onDeleteLesson(callback);
  };

  return {
    isLoading,
    createUnitHandler,
    createLessonHandler,
    swapLessonsHandler,
    swapUnitsHandler,
    updateCourseHandler,
    updateUnitHandler,
    updateLessonHandler,
    deleteCourseHandler,
    deleteUnitHandler,
    deleteLessonHandler,
  };
};

export default useFetch;
