import styled from "styled-components";

const Styles = {};

Styles.Question = styled.div`
  position: relative;
  color: ${(p) => p.theme.primary};
  padding: 1.2rem 1rem;
  overflow-wrap: break-word;
  border: 1px solid ${(p) => p.theme.primary};
  border-radius: 2rem;
  background-color: rgba(${(p) => p.theme.secondaryRGB}, 0.1);

  .answers-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1.25rem;
    border-top: 2px solid ${(p) => p.theme.primary};
  }
`;

export default Styles;
