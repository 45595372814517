import { apiSlice } from "../api/api.slice";

export const adminApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //Calculate all BI and return the info about the errors
    calculateAllBI: builder.mutation({
      query: () => ({
        url: `/admin/bi/calculate-all`,
        method: "POST",
      }),
      transformResponse: (response) => response.data,
    }),

    //Get all the contact messages given a date
    getContactMessages: builder.query({
      query: ({ date }) =>
        `/admin/contact-messages${date ? `?date=${date}` : ""}`,
      transformResponse: (response) => response.data,
    }),

    //Get all the contact messages given a date
    getContactMessageByUuid: builder.query({
      query: ({ uuid }) => `/admin/contact-messages/${uuid}`,
      transformResponse: (response) => response.data,
    }),

    // get the logs given a date
    getLogs: builder.query({
      query: ({ date }) => `/admin/logs/${date}`,
      transformResponse: (response) => response.data,
    }),

    //Get insights
    getInsights: builder.query({
      query: ({ days }) => `/admin/insights/${days}`,
      transformResponse: (response) => response.data,
    }),

    //Send an announcement to a group of users
    sendAnnouncement: builder.mutation({
      query: ({ recipients, subject, message }) => ({
        url: `/admin/announcement`,
        method: "POST",
        body: {
          recipients,
          subject,
          message,
        },
      }),
      transformResponse: (response) => response.data,
    }),

    //Get all users given a limit and offset
    getAdminUsers: builder.query({
      query: ({ limit, offset, order, orderBy, searchQuery = "" }) =>
        `/admin/users/${limit}/${offset}/${order}/${orderBy}/${
          searchQuery || ""
        }`,
      transformResponse: (response) => response.data,
      providesTags: ["User"],
    }),

    //Get all courses given a limit and offset
    getAdminCourses: builder.query({
      query: ({ limit, offset, order, orderBy, searchQuery = "" }) =>
        `/admin/courses/${limit}/${offset}/${order}/${orderBy}/${
          searchQuery || ""
        }`,
      transformResponse: (response) => response.data,
    }),

    //Get active connections
    getConnectedUsers: builder.query({
      query: () => `/admin/connected-users`,
      transformResponse: (response) => response.data,
    }),

    // update a user by admin perspective given a previous email
    updateUser: builder.mutation({
      query: ({
        previousEmail,
        firstName,
        lastName,
        email,
        password,
        role,
        status,
      }) => ({
        url: `/admin/users/${previousEmail}`,
        method: "PATCH",
        body: {
          firstName,
          lastName,
          email,
          password,
          role,
          status,
        },
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["User"],
    }),

    //Delete a user by admin perspective given an email
    deleteUser: builder.mutation({
      query: ({ email }) => ({
        url: `/admin/users/${email}`,
        method: "DELETE",
      }),
      invalidatesTags: ["User"],
      transformResponse: (response) => response.data,
    }),

    // delete a folder given a folder id
    deleteFolder: builder.mutation({
      query: ({ folderId }) => ({
        url: `/admin/folders/${folderId}`,
        method: "DELETE",
      }),
      transformResponse: (response) => response.data,
    }),

    // get all learning units (lectures) given a limit and offset
    getLearningUnits: builder.query({
      query: ({ limit, offset, search }) =>
        `/admin/learning-units?${new URLSearchParams({
          limit,
          offset,
          search,
        }).toString()}`,
      transformResponse: (response) => response.data,
    }),

    // delete a learning unit (lecture) given a learning unit id
    deleteLearningUnit: builder.mutation({
      query: ({ lectureUUID }) => ({
        url: `/admin/learning-units/${lectureUUID}`,
        method: "DELETE",
      }),
      transformResponse: (response) => response.data,
    }),

    // get all the students enrolled in a course
    getStudentsInACourse: builder.query({
      query: ({ courseUUID }) => `/admin/courses/${courseUUID}/students`,
      transformResponse: (response) => response.data,
    }),

    // get all course details given a uuid
    getCourseDetailsFromAdmin: builder.query({
      query: ({ courseUUID }) => `/admin/courses/${courseUUID}`,
      transformResponse: (response) => response.data,
      providesTags: ["Course"],
    }),

    // delete enrollment given a course uuid and a student email
    deleteEnrollmentFromAdmin: builder.mutation({
      query: ({ courseUUID, userEmail }) => ({
        url: `/admin/courses/${courseUUID}/students/${userEmail}`,
        method: "DELETE",
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["Course"],
    }),
  }),
});

export const {
  useCalculateAllBIMutation,
  useGetContactMessagesQuery,
  useGetLogsQuery,
  useGetConnectedUsersQuery,
  useGetContactMessageByUuidQuery,
  useLazyGetContactMessageByUuidQuery,
  useSendAnnouncementMutation,
  useGetAdminUsersQuery,
  useGetAdminCoursesQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetInsightsQuery,
  useDeleteFolderMutation,
  useGetLearningUnitsQuery,
  useDeleteLearningUnitMutation,
  useGetStudentsInACourseQuery,
  useGetCourseDetailsFromAdminQuery,
  useDeleteEnrollmentFromAdminMutation,
} = adminApiSlice;
