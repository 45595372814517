import React from "react";
import { toast } from "react-hot-toast";
import styled from "styled-components";

export function toastSuccess(message) {
  toast.success(message, {
    position: "top-right",
    duration: 3000,
  });
}

export function toastSuccessBottom(message) {
  toast.success(message, {
    position: "bottom-right",
    duration: 3000,
  });
}

export function toastError(message) {
  toast.error(message, {
    position: "top-right",
    duration: 3000,
  });
}

export function toastIcon(icon, message) {
  toast(message, { icon, position: "top-left" });
}

export function toastQuestion(question) {
  const {
    text,
    user: { firstName, lastName },
  } = question;
  toast(
    (t) => (
      <QuestionToast>
        <div className="h-100 d-flex flex-column justify-content-center me-2">
          <i className="fa fa-question" />
        </div>
        <div className="d-flex flex-column">
          <b className="float-right">
            {firstName} {lastName}
          </b>
          <span>{text}</span>
        </div>
      </QuestionToast>
    ),
    {
      position: "top-left",
      duration: 7000,
      style: { border: `2px solid ${(p) => p.theme.primary}` },
    }
  );
}

const QuestionToast = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1.2 !important;
  color: ${(p) => p.theme.primary};

  i {
    font-size: 2rem;
  }
`;
